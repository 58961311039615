//Libaries
import React from 'react';

//Components
import Label from '../Label/label';

//CSS
import './inputfield.css';


//------------------------------------------------------
//  How to use the 'InputField' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <InputField
//          
//      //Input Field Properties
//      name={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.name} - "Name of the select box, you can use this in an event handler (event.target.name) to get the value,e.g. projectId"
//      placeholder={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.placeholder} - "Placeholder text for your text area, e.g. 'Enter a description'"
//      value={selectedValue.<FIELD NAME, e.g. description>} - "Variable that holds the entered value in the InputField: description"
//      className = shortinputfield/mediuminputfield/longinputfield - Choose one of the 3 options to determine length of the input field 
//          
//      //Function Properties
//      onChange="Name of the function that changes any changes in this component: {handleChange}"
//    >
//    </InputField>


//------------------------------------------------------
//  'InputField' component
//------------------------------------------------------


function InputField(props) { 
  

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //Functions to take a boolean value and change this to a CSS Style
  function toggleErrorBorderVisible(errorVisible){

    if (errorVisible === true){
      return {border: '2px solid rgb(211, 26, 26)'}
    }

  }


  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

  if(props.visibility === 'read'){
    return(
      <div>
        <Label label={props.value}></Label>
      </div>
    );
  }
  else if(props.visibility === 'hide'){
    return(
      <div>
        <Label style={{display: "none"}} label={props.value}></Label>
      </div>
    );
    }
  else{
    return (
      <>  
          {/* Input Field */}
          <input type="text" className={props.className} style={toggleErrorBorderVisible(props.errorVisible)} disabled={props.visibility === 'view'} placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange}/>
      </>
    );
  }

  //------------------------------------------------------
}


export default InputField;