//================================================================
//  Component: ExportToCSV
//  Documentation: https://www.npmjs.com/package/react-csv
//================================================================

//  Purpose: Input firestore data and converts to a CSV file, with a user friendly export button!

//  Properties:
//    - filename <Required> = <String> the name of the file
//    - data <Required> = <Array of Arrays with Objects OR Array of Objects>
//    - headers <Optional> = {[
//      { label: "Project Id", key: "projectId" },
//      { label: "latest Month Total", key: "latestMonthTotal" },
//      { label: "Previous Month Total", key: "previousMonthTotal" },
//    ]}

//  ExportToCSV:
//    <ExportToCSV
//      filename='emissions'
//      data={projectEmissionsChartData} 
//      headers={[
//        { label: "Project Id", key: "projectId" },
//        { label: "latest Month Total", key: "latestMonthTotal" },
//        { label: "Previous Month Total", key: "previousMonthTotal" },
//      ]}
//    ></ExportToCSV>    

//================================================================


//Libraries
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import * as moment from 'moment';

//Contexts

//Components

//Functions

//Images
import DownloadImage from '../Images/Icon_Download_CSV.svg';

//CSS
import './ExportToCSV.css';


export default function ExportToCSV({
  filename,
  headers,
  data,
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  


  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const csvLink = useRef();
    const [csvData, setCSVData] = useState([]);
  
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() => {

      if (data === undefined) return;

      setCSVData(data);

    }, [data]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <CSVLink 
      className="ExportToCSV-Container"  
      ref={csvLink}
      filename={`${filename}-${moment(new Date()).format('DD-MM-YYYY-HH-mm-ss')}.csv`}
      headers={headers}
      data={csvData} 
      target="_blank"
      >
      <img alt='download-icon' src={DownloadImage}></img>
    </CSVLink>
  )
}
