//================================================================
//  Component: Invite Passport Form
//================================================================

//  Purpose: Child component of 'newownershiptransfer', this is the invite form

//  Properties:
//    - setRequestType = {useState, setRequestType}
//    - setPageStatus = {useState, setPageStatus}
//    - newDocument= {object, this is the document for Firestore}

//  InvitePassportForm:
//    <InvitePassportForm
//      setRequestType = {setRequestType}
//      setPageStatus = {setPageStatus}
//      newDocument={newDocument}
//    ></InvitePassportForm>    

//================================================================


//Libraries
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { SetAppErrors } from '../../../Library/GlobalContexts';

//Components
import QuestionLabel from '../../../Components/QuestionLabel';
import InlineInputField from '../../../Components/InlineInputField/inlineinputfield';
import ErrorMessageLabel from '../../../Components/ErrorMessageLabel/index';

//Functions
import WriteDocument from '../../../Library/WriteDocument';

import {
    isString,
    errString,
} from '../../../Library/ValidateUserInput';
  
//CSS
import './OwnershipTransfer.css';

export default function InvitePassportForm({
    setPageStatus,
    newDocument
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Form Fields
    const [givenname, setGivenname] = useState('');
    const [givennameError, setGivennameError] = useState('');
    const [surname, setSurname] = useState('');
    const [surnameError, setSurnameError] = useState('');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function HandleInput(value, setErrorState, setState){
        setErrorState('');
        setState(value);
    
    }

    //Handles submitting the form, checks it is completed
    function handleSubmit(){

        //Validate fields for null values
        if (givenname.length === 0 && surname.length === 0 ) {
            setGivennameError('Enter a first name');
            setSurnameError('Enter a last name');
            return;
        }
        if (givenname.length === 0) {
            setGivennameError('Enter a first name');
            return;
        }
        if (surname.length === 0) {
            setSurnameError('Enter a last name');
            return;
        }

        //Validate first/last name
        if(!isString(givenname)){
            setGivennameError(`Enter a valid first name. Must contain a combination of ${errString}`);
            return;
        }

        if(!isString(surname)){
            setSurnameError(`Enter a valid last name. Must contain a combination of ${errString}`);
            return;
        }

        //Set page to pending
        setPageStatus('pending');

        //Submit request
        const documentId = `txn-${Date.now()}`;
        const tempDoc = {
            'transactionid': documentId,
            'newownergivenname': givenname,
            'newownersurname': surname,
        }
        const document = Object.assign(newDocument,tempDoc);
        WriteDocument('project-owner-transfer', documentId, document, false)
        .then(() =>{
            setPageStatus('success');
            
        }).catch((error) =>{
            console.log(`Failed to submit invite form. Error ${error}`);
            setAppErrors(`Failed to submit invite form. Error ${error}`);
            setPageStatus('error-fatal');

        })
    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='Form-Body'>

        {/* Givenname */}
        <QuestionLabel question={"First name"}></QuestionLabel>

        <InlineInputField
        name='givenname'
        value={givenname}
        placeholder='e.g Jane'
        position = "right"
        inputFieldLength={"mediuminputfield"}
        onChange={(event) => HandleInput(event.target.value, setGivennameError, setGivenname)}
        >
        </InlineInputField>

        <ErrorMessageLabel errorMessage={givennameError} errorVisible={true}></ErrorMessageLabel>

        {/* Surname */}
        <QuestionLabel question={"Last name"}></QuestionLabel>

        <InlineInputField
        //Inline Input Field Properties
        name='surname'
        value={surname}
        placeholder='e.g Smith'
        position = "right"
        inputFieldLength={"mediuminputfield"}
        onChange={(event) => HandleInput(event.target.value, setSurnameError, setSurname)}
        >
        </InlineInputField>

        <ErrorMessageLabel errorMessage={surnameError} errorVisible={true}></ErrorMessageLabel>

        {/* Submit Buttons & error message */}
        <div className='Button-Group'>
            <button className='Primary-Button' onClick={handleSubmit}>Submit</button>
            <button className='Secondary-Button' onClick={() => navigate('/projects/ownershiptransfer')}>Cancel</button>
        </div>

    </div>
  )
}
