//==========================================================================
//   Component: Unattended Projects Table Row
//==========================================================================

//   Purpose: Allows user to expand/collapse row in the unattended projects table

//   Properties: 
//   - rowData = {data used to populate the rows in the table}

//   Example: 
//   <UnattendedProjectsTableRow
//      rowData={row}
//   ></UnattendedProjectsTableRow>

//------------------------------------------------------


//Libraries
import React, { useState, useContext } from 'react';
import * as moment from 'moment';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Images
import ExpandIcon from '../../../Components/Images/Icon_Expand_Black.svg';
import LightBulb from '../../../Components/Images/LightBulb.png';

//CSS
import './sustainabilitycomponents.css';

export default function ProjectEmissionsTableRow({rowData}) {

    //------------------------------------------------------
    //  Define variables
    //------------------------------------------------------

        var projectInsights = rowData.projectactivity[0].associatedinsights

        //Format Date > Last refresh time
        var lastRefreshTime = moment(rowData.lastrefreshtime.toDate()).format('llll')

    //------------------------------------------------------
    //  useContexts and React Router
    //------------------------------------------------------

        const getUser = useContext(GetUser);

        //React router
        const navigate = useNavigate();

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

        //Used to determine if the row is expanded/collapsed
        const [rowExpanded, setRowExpanded] = useState(false);

    //------------------------------------------------------
    //  Define Functions
    //------------------------------------------------------

        function goToDeleteProject(projectid){

            getUser.preferences.globalSelector.selectedView = 'project'
            getUser.preferences.globalSelector.selectedResource = projectid

            navigate('/projects/projectdelete');

        }

    //------------------------------------------------------
    //  Return HTML
    //------------------------------------------------------

        //------------------------------------------------------
        //  Row Expanded
        //------------------------------------------------------
        
        if(rowExpanded) {
            return (
                <>
                    <tr>
                        <td>{rowData.projectid}</td>
                        <td>{rowData.carbonFootprintDailyKgCO2 ? rowData.carbonFootprintDailyKgCO2 : 0}</td>
                        <td>{lastRefreshTime}</td>
                        <td>
                            <img className="Expand-Icon" src={ExpandIcon} style={{transform: "rotate(90deg)"}} alt="Expand_Icon" onClick={() => setRowExpanded(false)}></img>
                        </td>
                    </tr>

                    {/* Row Expanded > Show Unattended Projects */}
                    <tr>
                        <td colSpan="5">
                        {
                            <div className='Unattended-Projects-Row-Container'>
                                <label className='UPR-Recommendation-Label'>
                                    <img src={LightBulb} alt="Lightbulb" style={{width: "1em", margin: "0px 10px"}}></img>
                                    <b>Recommendation:</b> Consider deleting this project by clicking <b style={{textDecoration: "underline", color: "blue", fontWeight: "normal"}} onClick={() => goToDeleteProject(rowData.projectid)}> here</b>.
                                </label>

                                <table className="Unattended-Projects-Table">
                                    <colgroup>
                                        <col span="1" style={{width: "30%", backgroundColor: "#F2F2F2", fontWeight: "bold"}}></col>
                                        <col span="1" style={{width: "70%"}}></col>
                                    </colgroup>
                                    <tbody>
                                        {/* API Activity */}
                                        <tr>
                                            <td>Daily avg. API calls - service accounts</td>
                                            <td>{projectInsights.activeServiceAccountDailyCount ? projectInsights.activeServiceAccountDailyCount : 0} calls</td>
                                        </tr>
                                        <tr>
                                            <td>Daily avg. API calls - produced</td>
                                            <td>{projectInsights.apiClientDailyCount ? projectInsights.apiClientDailyCount : 0} calls</td>
                                        </tr>
                                        <tr>
                                            <td>Daily avg. API calls - consumed</td>
                                            <td>{projectInsights.consumedApiDailyCount ? projectInsights.consumedApiDailyCount : 0} calls</td>
                                        </tr>
                                        <tr>
                                            <td>Daily avg. API calls - datastore</td>
                                            <td>{projectInsights.datastoreApiDailyCount ? projectInsights.datastoreApiDailyCount : 0} calls</td>
                                        </tr>

                                        {/* Networking Activity */}
                                        <tr>
                                            <td>Networking - VPC egress bytes</td>
                                            <td>{projectInsights.vpcEgressDailyBytes ? projectInsights.vpcEgressDailyBytes : 0} bytes</td>
                                        </tr>
                                        <tr>
                                            <td>Networking - VPC ingress bytes</td>
                                            <td>{projectInsights.vpcIngressDailyBytes ? projectInsights.vpcIngressDailyBytes : 0} bytes</td>
                                        </tr>

                                        {/* Cloud Services Usage */}
                                        <tr>
                                            <td>Daily avg. usage - App Engine instances</td>
                                            <td>{projectInsights.activeAppengineInstanceDailyCount ? projectInsights.activeAppengineInstanceDailyCount : 0} instances</td>
                                        </tr>
                                        <tr>
                                            <td>Daily avg. usage - Cloud SQL instances</td>
                                            <td>{projectInsights.activeCloudsqlInstanceDailyCount ? projectInsights.activeCloudsqlInstanceDailyCount : 0} instances</td>
                                        </tr>
                                        <tr>
                                            <td>Daily avg. usage - Cloud GCE instances</td>
                                            <td>{projectInsights.activeGceInstanceDailyCount ? projectInsights.activeGceInstanceDailyCount : 0} instances</td>
                                        </tr>
                                        <tr>
                                            <td>Daily avg. usage - BigQuery Jobs</td>
                                            <td>{projectInsights.bigqueryInflightJobDailyCount ? projectInsights.bigqueryInflightJobDailyCount : 0} jobs</td>
                                        </tr>
                                        <tr>
                                            <td>Daily avg. usage - Storage requests</td>
                                            <td>{projectInsights.gcsRequestDailyCount ? projectInsights.gcsRequestDailyCount : 0} requests</td>
                                        </tr>

                                        {/* Other */}
                                        <tr>
                                            <td>Has active OAuth tokens</td>
                                            <td>{projectInsights.hasActiveOauthTokens ? "Yes" : "No"}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        }
                        </td>
                    </tr>
                </>
            )
        }

        //------------------------------------------------------
        //  Row Collapsed
        //------------------------------------------------------

        else {
            return (
                <tr>
                    <td>{rowData.projectid}</td>
                    <td>{rowData.carbonFootprintDailyKgCO2 ? rowData.carbonFootprintDailyKgCO2 : 0}</td>
                    <td>{lastRefreshTime}</td>
                    <td>
                        <img className="Expand-Icon" src={ExpandIcon} alt="Expand_Icon" onClick={() => setRowExpanded(true)}></img>
                    </td>
                </tr>
            )
        }
        
}
