//------------------------------------------------------
//  Handler for all login events
//  Designed to handle the login process, handle failures and if success give a user an getAccessToken
//  The 'LoginHandler' is built to handle all 'getAppStatus' states
//------------------------------------------------------


//Libraries
import React, { useContext } from 'react';
import App from '../App';
import { BrowserRouter } from 'react-router-dom';
import {GetAppStatus, GetAppErrors} from './GlobalContexts';

//Components
import Login from './Login';
import Logout from './Logout';

//Images
import Logo from '../Components/Images/Icon_LendleaseCloudLogo.svg';
import llLogo from '../Components/Images/LL_Logo.png';
import LoadingIcon from '../Components/Images/Loading_Ripple.svg';
import GrumpyCat from '../Components/Images/GrumpyCat.png';
import CloudGraphic from '../Components/Images/Cloud_Graphic.svg';

//Styling for login page
import './LoginHandler.css';

function LoginHandler() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getAppStatus = useContext(GetAppStatus);
    const getAppErrors = useContext(GetAppErrors);

  //------------------------------------------------------
  //  Authorised Users
  //------------------------------------------------------

    if (getAppStatus === 'authenticated'){

      //Return the App
      return (
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      )
    }

  //------------------------------------------------------
  //  Pending
  //------------------------------------------------------

    else if (getAppStatus === 'pending'){

      return (
        <div className='LoginHandler-Pending'>
          <img alt='loading-circle-icon' src={LoadingIcon}></img>
          <Logout title='Cancel'></Logout>
        </div>
      )
    }

  //------------------------------------------------------
  //  Failed
  //------------------------------------------------------

    else if (getAppStatus === 'failed'){

      //------------------------------------------------------
      //  Handles each known error message, with a generic catch all response!
      //------------------------------------------------------

        function HandleErrorMessage(message){

          //General catch, to ensure something exists
          if (message === undefined) return;

          //Allow third-party cookies
          if (message.includes('web-storage-unsupported')){
            return(
              <div>
                Third party cookies are disabled. Enable <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop'>third-party cookies</a> in your browser and try again.
              </div>
            )
          }

          //Failed to verify the users credenitals, user must close tab and sign in again
          if (message.includes('auth/invalid-credential')){
            return(
              <div>
                Failed verify your credentials. Close the browser and try again.
              </div>
            )
          }

          //Failed to verify the users credenitals, user must close tab and sign in again
          if (message.includes('auth/popup-closed-by-user')){
            return(
              <div>
                You closed the sign in pop up. Click 'return to the sign in page' and try again.
              </div>
            )
          }

          //User is blocking popups
          if (message.includes('auth/popup-blocked')){
            return(
              <div>
                Browser is blocking pop ups. Allow <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop'>pop ups</a> and try again.
              </div>
            )
          }

          //There is an existing Firebase account with this users UPN 
          if (message.includes('auth/account-exists-with-different-credential')){
            return(
              <div>
                Found an existing account with the same email address. Contact <a href='mailto:ServiceDesk@lendlease.com'>ServiceDesk@lendlease.com</a> and provide the error message below.
              </div>
            )
          }

          //General catch all
          else{
            return(
              <div>
                Unknown issue has occurred. Contact <a href='mailto:ServiceDesk@lendlease.com'>ServiceDesk@lendlease.com</a> and provide the error message below.
              </div>
            )
          }
        }

      //------------------------------------------------------
      //  Show 'Oops, something went wrong!' page 
      //------------------------------------------------------

        return (
          <div className='LoginHandler-Failed-Container'>

            <img className='LoginHandler-Failed-Img' alt='Session Timed Out!' src={GrumpyCat}></img>
          
            <h2 className='LoginHandler-Failed-Title'>Oops, something went wrong!</h2>

            <div className='LoginHandler-Failed-Solution'>
              {HandleErrorMessage(getAppErrors)}
            </div>

            <div className='LoginHandler-Failed-Button'>
              <Logout title='Return to sign in page'></Logout>
            </div>

          <div className='LoginHandler-Failed-Error'>
            <h3>Error message</h3>
            {getAppErrors}
          </div>

          </div>
        )
    }


  //------------------------------------------------------
  //  Un-Authorised Users
  //------------------------------------------------------

    else if (getAppStatus === 'unauthenticated'){
      return (
        <>
          {/* Header */}
          <div className='Header'>
            <img alt='Lendlease Cloud Lockup' src={Logo} style={{width: '200px'}}/> 
          </div>

          {/* LoginPage-Body */}
          <div className='Login-Body-Container Login-Grid'>

            {/* Left Side -> Heading, Text & Buttons */}
            <div className="Grid-Left">
                
                <h1>Lendlease Cloud Portal</h1>
                <p>Build, manage and monitor your Google Cloud Platform resources in a single, unified console.</p>

                {/* Buttons */}
                <div className='Button-Group' style={{gap: "15px"}}>
                    <Login/>
                    <button 
                      className="Secondary-Button"
                      onClick={() => window.location.href =`${process.env.REACT_APP_CS_ONBOARDING_URL}`}>Sign up
                    </button>
                </div>
              </div>

              {/* Right Side -> Cloud Graphic */}
              <div className="Grid-Right">
                <img className='Grid-Image' alt="Cloud Graphic" src={CloudGraphic} />
              </div>
        
            </div> 

            {/* Footer */}
            <div className='Footer'>
              <img alt='Lendlease Logo' src={llLogo} style={{width: '100px', paddingRight: '1rem'}}/>
              <p>For support, please contact <a href='mailto:ServiceDesk@lendlease.com'>ServiceDesk@lendlease.com</a></p>
            </div>
        </>
      )
    }

  //------------------------------------------------------
}

export default LoginHandler;