//================================================================
//  Component: Global Selector Modal
//================================================================

//  Purpose: This is global selector modal

//  Example:
//   <GlobalSelectorModal></GlobalSelectorModal>

//================================================================


//Libraries
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetUser } from '../../../Library/GlobalContexts';

//Components

//Functions

//Images
import InfoIcon from '../../Images/Icon_Info_Black.svg';

//CSS
import '../navbar.css'


export default function GlobalSelectorModal() {

  //------------------------------------------------------
  //  useContexts and react router
  //------------------------------------------------------
    
    const getUser = useContext(GetUser);
    const setUser = useContext(SetUser);
    const navigate = useNavigate();

  //------------------------------------------------------
  //  Define variables
  //------------------------------------------------------

    // Filter by active projects > Sort allProjects alphabetically  
    const allProjects = getUser?.profile?.views?.allProjectsMetadata?.filter((project) => { return project.status === 'active'})?.sort(function (a, b) {
      if (a.projectid < b.projectid) return -1;
      if (a.projectid > b.projectid) return 1;
      return 0;
    });

    // Sort allPortfolios alphabetically
    const allPortfolios = getUser?.profile?.views?.allPortfoliosMetadata?.sort(function (a, b) {
      if (a.portfolioname < b.portfolioname) return -1;
      if (a.portfolioname > b.portfolioname) return 1;
      return 0;
    });

    const allOrganizations = [{
      "name": 'lendlease.cloud',
      "description": "Lendlease GCP organization"
    }]
    
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Set modal visibility for the global selector
    function showModal(visibility) {
    
      if(visibility === undefined) {

        getUser.preferences.globalSelector.visible = !getUser.preferences.globalSelector.visible;
        getUser.preferences.globalSelector.selectedResource = 'none';

      } else {

        getUser.preferences.globalSelector.visible = visibility;

      }

      //Update getUser context
      setUser({...getUser})      

    };

    //Set selected view (in useContext) to dropdown value
    function setSelectedView(value) {

      // Exception for 'organization' views > take user back to home page
      if (getUser.preferences.globalSelector.selectedView === 'organization') {

        navigate('/home');

      }

      getUser.preferences.globalSelector.selectedView = value;

      //Resetting the selected resource to none when the view changes > User will be prompted to select a resource
      //DON'T CHANGE THIS PLEASE (if you need to - talk to BenShinCent)
      getUser.preferences.globalSelector.selectedResource = 'none';

      setUser({...getUser});

    };

    //Set selected resource (in useContext) to dropdown value
    function setSelectedResource(value) {

      getUser.preferences.globalSelector.selectedResource = value;
      setUser({...getUser});

      //Hide modal
      showModal(false);
      
    };

    //Update styling of labels depending on project type
    function styleProjectLabels(label){
      if(label === 'Sandbox') {
        return {
          backgroundColor: "#A214C6",
          padding: "5px 10px",
          color: "white"
        }
      }

      else {
        return {
          backgroundColor: "#18B4EA",
          color: "white"
        }
      }

    }

    //Used to style the selected resource
    function highlightSelectedResource(resource){
      
      if (resource === getUser?.preferences?.globalSelector?.selectedResource) 
        return "Global-Selector-Resource-Row-Active"; 
      else 
        return "Global-Selector-Resource-Row";

    }

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //   Selected view === 'organization'
    //------------------------------------------------------

      if(getUser?.preferences?.globalSelector?.selectedView === 'organization') {
        return (
          <div className='Modal-Background'>
            <div className='Global-Selector-Modal-Container'>

              {/* Header */}
              <div className='Global-Selector-Modal-Header'>
                <div className='Global-Selector-Modal-Header-Left'>
                  Select from 
                  <select className="Global-Selector-View-Dropdown" name="globalSelector" id="globalSelector" value={getUser?.preferences?.globalSelector?.selectedView} onChange={(e) => setSelectedView(e.target.value)}>
                    <option value="project">Projects</option>
                    <option value="portfolio">Portfolios</option>
                    <option value="organization">Organizations</option>
                  </select>
                </div>
              </div>

              {/* Custom Message */}
              <label className='Global-Selector-Message'>
                <img className='Global-Selector-Info-Icon' src={InfoIcon} alt="Information Icon"></img>
                Select an organization below.
              </label>
              
              {/* Body */}
              <table className="Global-Selector-Resource-Table">
                <colgroup>
                  <col span="1" style={{width: "30%"}}></col>
                  <col span="1" style={{width: "70%"}}></col>
                </colgroup>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {allOrganizations?.map((organization, index) =>
                    (
                      <tr key={index} className={highlightSelectedResource(organization.name)} onClick={() => setSelectedResource(organization.name)}>
                        <td className='Global-Selector-Resource-Link'>{organization.name}</td>
                        <td className='Global-Selector-Description'>{organization.description}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>

              {/* Footer */}
              <div className='Global-Selector-Modal-Footer'>
              
                {/* Cancel Button */}
                <button className='Global-Selector-Cancel' onClick={() => showModal(false)}>CANCEL</button>
              
              </div>

            </div>
          </div>
        )
      }


    //------------------------------------------------------
    //   Selected view === 'portfolio'
    //------------------------------------------------------

      else if(getUser?.preferences?.globalSelector?.selectedView === 'portfolio') {
        return (
          <div className='Modal-Background'>
            <div className='Global-Selector-Modal-Container'>

              {/* Header */}
              <div className='Global-Selector-Modal-Header'>
                <div className='Global-Selector-Modal-Header-Left'>
                  Select from 
                  <select className="Global-Selector-View-Dropdown" name="globalSelector" id="globalSelector" value={getUser?.preferences?.globalSelector?.selectedView} onChange={(e) => setSelectedView(e.target.value)}>
                    <option value="project">Projects</option>
                    <option value="portfolio">Portfolios</option>
                    <option value="organization">Organizations</option>
                  </select>
                </div>
                <div className='Global-Selector-Modal-Header-Right'>
                  <Link to="/portfolios/create">
                    <button className='Primary-Button' onClick={() => showModal(false)}>Create Portfolio</button>
                  </Link>
                </div>
              </div>

                {/* Custom Message */}
                <label className='Global-Selector-Message'>
                  <img className='Global-Selector-Info-Icon' src={InfoIcon} alt="Information Icon"></img>
                  {getUser?.getUser?.profile?.views?.portfolioViewer?.length === 0 ?
                    <>Click <b>Create Portfolio</b> to create a new portfolio.</>
                    :
                    "Select a portfolio below"
                  }
                </label>
              
              {/* Body */}
              <table className="Global-Selector-Resource-Table">
                <colgroup>
                  <col span="1" style={{width: "30%"}}></col>
                  <col span="1" style={{width: "70%"}}></col>
                </colgroup>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {allPortfolios?.map((portfolio, index) =>
                    (
                      <tr key={index} className={highlightSelectedResource(portfolio.portfolioname)} onClick={() => setSelectedResource(portfolio.portfolioid)}>
                        <td className='Global-Selector-Resource-Link'>{portfolio.portfolioname}</td>
                        <td className='Global-Selector-Description'>{portfolio.description}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>

              {/* Footer */}
              <div className='Global-Selector-Modal-Footer'>
              
                {/* Cancel Button */}
                <button className='Global-Selector-Cancel' onClick={() => showModal(false)}>CANCEL</button>
              
              </div>

            </div>
          </div>
        )
      }
    

    //------------------------------------------------------
    //   Selected view === 'project'
    //------------------------------------------------------

      else {
        return (
          <div className='Modal-Background'>
            <div className='Global-Selector-Modal-Container'>
              
              {/* Header */}
              <div className='Global-Selector-Modal-Header'>
                <div className='Global-Selector-Modal-Header-Left'>
                  Select from 
                  <select className="Global-Selector-View-Dropdown" name="globalSelector" id="globalSelector" value={getUser?.preferences?.globalSelector?.selectedView} onChange={(e) => setSelectedView(e.target.value)}>
                    <option value="project">Projects</option>
                    <option value="portfolio">Portfolios</option>
                    <option value="organization">Organizations</option>
                  </select>
                </div>
                <div className='Global-Selector-Modal-Header-Right'>
                  <Link to="/projects/create">
                    <button className='Primary-Button' onClick={() => showModal(false)}>Create Project</button>
                  </Link>
                </div>
              </div>

              {/* Custom Message */}
              <label className='Global-Selector-Message'>
                <img className='Global-Selector-Info-Icon' src={InfoIcon} alt="Information Icon"></img>
                {allProjects?.length === 0 ?
                  <>Click <b>Create Project</b> to create a new project.</>
                    :
                  "Select a project below"
                }
              </label>
              
              {/* Body */}
              <div className='Global-Selector-Resource-Table-Container'>
                <table className="Global-Selector-Resource-Table">
                  <colgroup>
                    <col span="1" style={{width: "30%"}}></col>
                    <col span="1" style={{width: "40%"}}></col>
                    <col span="1" style={{width: "30%"}}></col>
                  </colgroup>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Description</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allProjects?.map((project, index) =>
                      (
                        <tr key={index} className={highlightSelectedResource(project.projectid)}  onClick={() => setSelectedResource(project.projectid)}>
                          <td className='Global-Selector-Resource-Link'>{project.projectid}</td>
                          <td className='Global-Selector-Description'>{project.description ? project.description : "-"}</td>
                          <td><label className='Global-Selector-Resource-Labels' style={styleProjectLabels(project.type)}>{project.type}</label></td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>

              {/* Footer */}
              <div className='Global-Selector-Modal-Footer'>
              
                {/* Cancel Button */}
                <button className='Global-Selector-Cancel' onClick={() => showModal(false)}>CANCEL</button>
              
              </div>

            </div>
          </div>
        )
      }
}


