//================================================================
//  Attach to the Shared VPC Network Form
//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import Disclaimer from '../../Components/Disclaimer/disclaimer'
import DocumentLink from '../../Components/KnowledgeHubComponents/documentlink';

//Functions
import ReactBackend from '../../Library/reactBackend'
import GetDocument from '../../Library/GetDocument';

//CSS
import './SharedVPCAttach.css';

//Images
import PrideCat from '../../Components/Images/PrideCat.png';
import GrumpyFatal from '../../Components/Images/GrumpyCat.png';

function SharedVPCAttach(){
  
  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------
   
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);
    var projectId = getUser?.preferences?.globalSelector?.selectedResource

    //React Router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to change between > 'disclaimer', 'accessdenied', 'onload', 'pending', 'success', 'error-invalid', 'error-fatal'
    const [formStatus, setFormStatus] = useState('pending');

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

  //------------------------------------------------------
  //  Define Functions > callAPI & HandleResponse
  //------------------------------------------------------


    //Used to call "submitSharedVPCAttachmentRequest"
    const callAPI = async () => {

      //Set formStatus to 'pending'
      setFormStatus('pending')

      var requestBody = {
        "projectId": projectId
      }

      //Call React Backend - Submit Form Response
      var response = await ReactBackend('submitSharedVPCAttachmentRequest', requestBody)

      //Switch on formStatus
      if(response.status === 'success'){
            
        //Set formStatus to 'success'
        setFormStatus('success')

      } else if(response.status === 'error-invalid'){

        //Set formStatus to 'error-invalid'
        setAppErrors(response?.responseBody?.message)
        setFormStatus('error-invalid')

      } else if(response.status === 'error-fatal'){
          
        //Set formStatus to 'error-fatal'
        setFormStatus('error-fatal')

      } else if(response.status === 'error-timeout'){
          
        //Set formStatus to 'error-timeout'
        setFormStatus('error-timeout')

      }

      return response

    }   

    //Gets project SVPC attachment data from Firestore
    async function getProjectVPCMetadata(selectedResource){
                      
      //Fetch project info
      const queryResponse = GetDocument("projects", selectedResource)

      return Promise.resolve(queryResponse)
      .then((results) => {

        // Check the project is attached & then set form status
        if (results?.sharedvpcattached === 'true'){
          setFormStatus('error-other')

        } else{
          setFormStatus('disclaimer')

        }

      })
      .catch((error) =>{
        console.log(`'getProjectVPCMetadata' failed to complete. Error Message: ${error.message}`);
        setAppErrors(`'getProjectVPCMetadata' failed to complete. Error Message: ${error.message}`);

        //Set status to 'error-fatal'
        setFormStatus('error-fatal')
      })

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //When project ID selected > APIs
    useEffect(() => {

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);
    
      //Call the functions
      getProjectVPCMetadata(getUser?.preferences?.globalSelector?.selectedResource)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getUser])   

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------


  return(
    <PageComponent
      requireSelectedResource={true}
      requireSelectedViews={['project']}
      requiredRoles={['projectOwner', 'projectOperator']}
      requiredEnvironments={['Standard']}
      status={formStatus}
      header={
        <PageHeader
          title={'Attach Project to Shared VPC'}
          body={
            <div>
              Click the Attach Project to VPC" button below to attach your project <b style={{color: "#212c61"}}>{projectId}</b> to the Shared VPC network.
              <br></br>
              For more information, refer to <DocumentLink docId="1401389196" title="Shared VPC - How to Get Started"></DocumentLink>.
              <br></br> 
            </div>  
          }
        ></PageHeader>
      }
      breadcrumb={
        {
          'name': 'Shared VPC',
          'route': '/sharedvpc'
        }
      }
      disclaimerContent={
        <Disclaimer
          cancelRoute="/sharedvpc"
          setStatus={setFormStatus}
          setStatusValue="onload"
          acceptanceCriteria={[
            <ul>
              <li>I am authorized to perform this action.</li>
              <li>I am a Lendlease employee.</li>
              <li>I understand and acknowledge the <a href="https://info.lendlease.cloud/servicedescriptions" target="_blank" rel="noopener noreferrer">Service Descriptions</a>.</li>
              <li>I have the Compute Engine API enabled in my project</li>
              <li>I have no existing private VPC networks within my project.</li>
            </ul>
          ]}
        >
        </Disclaimer>
      }
      body={
        <div className="Attach-To-VPC-Pane">
          <div className='Button-Group'>
            <button className='Primary-Button' onClick={() => {callAPI()}}> Attach Project To VPC </button>
            <button className="Secondary-Button" onClick={() => {navigate("/sharedvpc")}}> Cancel </button>
          </div>
        </div>
      }
      successContent={
        <div className='PageComponent-Errors-Container'>
          <img style={{width: "35%"}} src={PrideCat} alt="happy cat"/>
          <h2>Success!</h2>
          <p> 
            Your request to attach to the shared VPC network is complete..
            <br></br><br></br>
              <b>To manage your access to the Shared VPC network, you will need to provide a service account by clicking the button below.</b><br></br><br></br>
              <button className="modify-button" style={{color: "white", cursor: "pointer"}} onClick={() => {navigate("/sharedvpc/manage")}}> Manage VPC Access  </button>
            </p>
        </div>
      }
      errorOtherContent={
        <div className='PageComponent-Errors-Container'>
        <img alt="Error Other" src={GrumpyFatal}></img>
        <h2>Oops something went wrong</h2>
        <p>
          An error occurred while we processed your request.
        </p>
        <p> 
          <b>Message:</b> The project <i>{projectId}</i> is already attached, return to the main Shared VPC page to manage permissions - Other Error.
        </p>
      </div>
      }
    ></PageComponent>
  );


  //------------------------------------------------------
}

export default SharedVPCAttach;