//================================================================
//  Project Ownership Lookup Page
//================================================================

//Libraries
import React, { useEffect, useReducer, useState, useContext } from 'react';

//Contexts
import { SetAppErrors } from '../../Library/GlobalContexts';

//Functions
import reactBackend from '../../Library/reactBackend';
import getCollection from '../../Library/GetCollection';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import ProductFilterPane from '../../Components/ProductFilterPane/productfilterpane';
import Tableresponse from '../../Components/TableResponse/tableresponse';
import ExportToCSV from '../../Components/ExportToCSV/ExportToCSV';

//CSS
import './projectownerlookup.css';



function ProjectOwnerLookup(){

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to hold the 'Project ID' field inputs
    const [projectIdValue, setprojectIdValue] = useState("");

    //Used to toggle visibility of 'Find Owner' botton
    const [findOwnerButtonDisabled, setFindOwnerButtonDisabled] = useState(true)

    //Used to save the API response from GetProjects API
    const [getProjectAPIResponse, setGetProjectAPIResponse] = useState("Please search for a project above.");

    //Used to save all projects
    const [allProjects, setAllProjects] = useState([]);

    //Used to store API status from GetProjects API >  'success', 'error-invalid', 'error-fatal'
    const [getProjectAPIStatus, setGetProjectAPIStatus] = useState('success');
  
    //Used to select the fields the API response will return
    const selectFields = ["projectid", "projectowner", "status"]


  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    //Used to toggle error message
    const [errorState, setErrorState] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        errorProjectTextboxVisible: false,
        errorProjectTextboxMessage: "",
      }
    );


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Function to validate the Project ID
    function IsValidProjectId(projectId) {  
      if (/^([a-z][a-z0-9]*)$/.test(projectId)) {  
        return false;
      }
      return true;
    }

    //Function to show red border on error in 'Project ID' field
    function toggleErrorBorderVisible(errorVisible){

      if (errorVisible === true){
        return {border: '2px solid rgb(211, 26, 26)'}
      }

    }
    
    //Function to show error message in 'Project ID' field
    function toggleErrorMessageVisible(errorVisible){

      if (errorVisible === true){
        return {visibility: 'visible'}
      }
      else{
        return {display: 'none'}
      }
    }

  //------------------------------------------------------
  //  API Calls
  //------------------------------------------------------

    //Used to call "getProjects"
    async function getProjectsAPI(projectIdOnSubmit){

      const requestBody = {
        "projectId": projectIdOnSubmit,
        "selectFields":  selectFields,
      }

      setGetProjectAPIStatus('pending')

      //Call React Backend - Submit Form Response
      let response = await reactBackend('getProjects', requestBody)

      //Set API Response
      setGetProjectAPIResponse(response.responseBody.message)

      //Switch on 'activeProjectStatus'
      if(response.status === 'success'){

        //Set 'activeProjectStatus' to 'success'
        setGetProjectAPIStatus('success')

        if(response.responseBody.message.length <= 0) {
          response = "Project not found. Please check the project ID and try again."
          setGetProjectAPIResponse(response)
        }

      } else if(response.status === 'error-invalid'){

        //Set 'activeProjectStatus' to 'error-invalid'
        setGetProjectAPIStatus('error-invalid')

      } else if(response.status === 'error-fatal'){
        //Set 'activeProjectStatus' to 'error-fatal'
        setGetProjectAPIStatus('error-fatal')

      } else if(response.status === 'error-timeout'){
          
        //Set 'activeProjectStatus' to 'error-timeout'
        setGetProjectAPIStatus('error-timeout')
      }

      return response

    }

  //------------------------------------------------------
  //  Event Handler > Service Account Form Submission & Field Changes
  //------------------------------------------------------

    //Event Handler when 'Find owner' button is pressed
    const findOwnerSubmit = e => {

      getProjectsAPI(projectIdValue);

    }

    //Event Handler for 'Project ID' field changes
    const handleProjectIdChange = evt => {

      const newValue = evt.target.value;

      // Field is empty > Set 'errorProjectTextboxVisible' to true
      if(newValue.length <= 0){

        setErrorState({ errorProjectTextboxVisible: true });
        setErrorState({ errorProjectTextboxMessage: "Please enter a Project ID." });
          
      // Project Name is invalid > Set 'errorProjectTextboxVisible' to true
      } else if(IsValidProjectId(newValue)){

        setErrorState({ errorProjectTextboxVisible: true });
        setErrorState({ errorProjectTextboxMessage: "Invalid project ID. The project name must start with a lowercase letter and have lowercase letters or numbers."});
        
      // Default border and error visibility
      } else {

        setErrorState({ errorProjectTextboxVisible: false });

      }

      //Set emailValue to input values 
      setprojectIdValue(newValue);
  
    };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Load the page > Get all projects and groups
    useEffect(() => {

      //Get 'projects' collection
      getCollection('projects').then((results) =>{

        //Check if no projects > Return message
        if (results.length <= 0) {

          results = 'Failed to extract all projects.';
        
        };

        //Set all projects to results
        setAllProjects(results);

      }).catch((error) =>{

        setAppErrors(`Unable to get 'projects' collection ${error}`);
        setGetProjectAPIStatus("error-fatal");
        
      });      
    },[setAppErrors]);
    

    //When 'Project ID' field is change > enable/disable 'Find owner' button
    useEffect(() => {

      if(projectIdValue.length <= 0 || errorState.errorProjectTextboxVisible){

        setFindOwnerButtonDisabled(true);

      } else {

        setFindOwnerButtonDisabled(false);

      }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[projectIdValue]);


    //Disable the 'Submit' button if a request is in progress
    useEffect(() => {

      if(getProjectAPIStatus === 'pending') {

        setFindOwnerButtonDisabled(true);

      } else {

        setFindOwnerButtonDisabled(false);

      }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getProjectAPIStatus]);


  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    return(
      <PageComponent
        requireSelectedResource={true}
        requireSelectedViews={['organization']}
        requiredRoles={['portalUser', 'portalAdmin']}
        requiredRolesValue={'true'}
        header={
          <PageHeader
            title={'Project Owner Lookup'}
            body={
              <p>
              Enter a GCP project ID below, then click 'Find owner' to identify the owner of any active or historical GCP project. Wildcards/partial searches not supported.
              <br></br> 
              To download a list of all GCP projects, click the 'Download CSV' button. 
              </p>  
            }
          ></PageHeader>
        }
        body={
          <div>

            {/* Project Filter Pane */}
            <ProductFilterPane
              productFilterComponents={
                <>
                  <label className="project-id-label"> Project ID:  </label>
                  <input type="text"
                      className="project-id-textbox"
                      style={toggleErrorBorderVisible(errorState.errorProjectTextboxVisible)}
                      placeholder="e.g. projectid12345"
                      name={"Project ID: "}
                      value={projectIdValue} onChange={handleProjectIdChange}/>
                      
                  <label className="project-id-error-label" style={toggleErrorMessageVisible(errorState.errorProjectTextboxVisible)}>
                    {errorState.errorProjectTextboxMessage}
                  </label>
                  
                  <button className="find-owner-button" disabled={findOwnerButtonDisabled} onClick={findOwnerSubmit}>
                    Find owner
                  </button>

                {/*------------------------------------------------------------------ */
                /*   Export Project Owners
                /*------------------------------------------------------------------ */ }

                  {/* Export to CSV button */}
                  <ExportToCSV
                    filename='projects'
                    headers={[
                      { label: "projectId", key: "projectid" },
                      { label: "Owner", key: "projectowner" },
                      { label: "Status", key: "status" }
                    ]}
                    data={allProjects} 
                  ></ExportToCSV>
            
              </>
              }
            ></ProductFilterPane>

            {/* Project Owner Lookup  */}
            <div className="Table-Pane" style={{marginTop: "40px"}}>

              {/*------------------------------------------------------------------ */}
              {/*   Returned project details                                        */}
              {/*------------------------------------------------------------------ */}  
    
                <Tableresponse
                  content={
                    getProjectAPIResponse.length <= 0 || typeof(getProjectAPIResponse) === 'string' ? (
                      <div className="table-response-text-container">
                        <p className="table-message">{getProjectAPIResponse}</p> 
                      </div>
                    ) : (
                      <table className="Data-Table-Container">
                        <colgroup>
                          <col span="1" style={{width: "30%"}}></col>
                          <col span="1" style={{width: "70%"}}></col>
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>Project ID</th>
                            <th>Project Owner</th>
                          </tr>
    
                          {
                            getProjectAPIResponse.map((row, index) => (
                              <tr key={index}>
                                <td>{row.projectid}</td>
                                <td>{row.projectowner}</td>
                              </tr>
                            ))
                          }
    
                        </tbody>
                      </table>
                    )
                  }
                  response={getProjectAPIResponse}
                  status={getProjectAPIStatus}
                  setStatus={setGetProjectAPIStatus}
                  setAltLoadScreen={true}
                ></Tableresponse>
              </div>
          </div>
        }
        ></PageComponent>
      )

  //------------------------------------------------------
}

export default ProjectOwnerLookup