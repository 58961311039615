//Libaries
import React from 'react';

//Images
import HelpIcon from '../Images/HelpTextIcon.svg'

//CSS
import './index.css';


//------------------------------------------------------
//  How to use the 'QuestionLabel' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <QuestionLabel
//          
//      //Label Properties
//      question={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.question} - "Question value to display on the form."
//      helpText={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.helpText} - "Help text that appears when you hover over the help icon"
//      readOnly =  true or false, change the field to readonly
//          
//    >
//    </QuestionLabel>


//------------------------------------------------------
//  'QuestionLabel' component
//------------------------------------------------------


function QuestionLabel(props) { 
  

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //Functions to take a boolean value and change this to a CSS Style
  function toggleHelpTextVisible(helpText){
    if(helpText){
      return {display: 'inline-block'}
    }
    else{
      return {display: 'none'}
    }
  }

  
  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------


   //Hide radio buttons and just show text
   if (props.visibility === 'read'){
    return(
      //Question Label
      <div className="question-container">
          <label style={props.style} className="question-label">{props.question}</label>
          <div className="tooltip" style={toggleHelpTextVisible(props.helpText)}>
            <img className="help-icon" alt="help-icon" src={HelpIcon}></img>
            <span className="tooltiptext"> {props.helpText}</span>
          </div> 
      </div>
    );
  }

  if (props.visibility === 'hide'){
    return(
      //Question Label
      <div style={{display: "none"}} className="question-container">
          <label style={props.style} className="question-label">{props.question.slice(0, -1)}</label>
      </div>
    );
  }
  
  //Show radio buttons
  else{
    return (
      //Question Label
      <div className="question-container">
          <label style={props.style} className="question-label">{props.question}</label>
          <div className="tooltip" style={toggleHelpTextVisible(props.helpText)}>
            <img className="help-icon" alt="help-icon" src={HelpIcon}></img>
            <span className="tooltiptext"> {props.helpText}</span>
          </div> 
      </div>
    );
  }


  //------------------------------------------------------
}


export default QuestionLabel;