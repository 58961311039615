//==========================================================================
//   Function: Write Document
//   Description: Updates an existing Firestore document (always returns a promise)
//   Documentation:
//   - https://firebase.google.com/docs/firestore/manage-data/add-data#update-data
//==========================================================================

//   Example - How to use this function: 
//
//    UpdateDocument('users', emailaddress, document)
//    .then((results) =>{
//      console.log("results", results)
//    })
//    .catch((error) =>{
//      console.log("error", error)
//      setRequestType('failed')
//      setAdminError(`UpdateDocument failed in Users.js, error ${error}`)
//      setUserError("Failed to update user, please try again and if the issue persists contact your Adminstrator.")
//    })

//==========================================================================

//Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';
import { doc, updateDoc } from "firebase/firestore";


export default async function UpdateDocument(collectionId, documentId, documentObject){

  //------------------------------------------------------
  //  Update one document with an object 
  //------------------------------------------------------

    //Update existing document
    try {

      //Firestore Database reference
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      //Set collection
      const collectionDocRef = doc(db, collectionId, documentId);

      //Write to document
      return updateDoc(collectionDocRef, documentObject);

    } catch (error) {

      throw new Error(`Function UpdateDocument failed to complete, error ${error}`);

    }

  //------------------------------------------------------
}