//Libaries
import React, {useState, useEffect} from 'react';

//CSS
import './datalistfield.css';


//------------------------------------------------------
//  How to use the 'DataListField' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <DataListField
//          
//      //Inline Input Field Properties
//      name={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.name} - "Name of the select box, you can use this in an event handler (event.target.name) to get the value,e.g. projectId"
//      placeholder={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.placeholder} - "Placeholder text for your text area, e.g. 'Enter a description'"
//      value={selectedValue.<FIELD NAME, e.g. description>} - "Variable that holds the entered value in the InlineInputField: description"
//      position=left, right or middle (middle required the 'inlinefieldlabelEnd' parm) - position of the inline field label relative to the input field
//      inlinefieldlabel =  text you want to present next to the field
//      inlinefieldlabelEnd = <IF YOU USE position 'middle'>
//      visibility =  "read", "hide", "edit"
//      fullSize= true if 100% width, otherwise 30%
// 
//      //Function Properties
//      onChange="Name of the function that changes any changes in this component: {handleChange}"
//    >
//    </DataListField>


//------------------------------------------------------
//  'InlineInputField' component
//------------------------------------------------------


function DataListField(props) { 
  

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------


  const [random, setRandom] = useState();


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //Functions to take a boolean value and change this to a CSS Style
  function toggleErrorBorderVisible(errorVisible){

    if (errorVisible === true){
      return {border: '1px solid rgb(211, 26, 26)'}
    }

  }


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------


    // Update fields
    useEffect(() =>{
      
      if (random === undefined) {
        setRandom(String(Date.now()) + String(Math.random()))
      }

    }, [random, setRandom]);


  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------


  if(props.visibility === 'read' || random === undefined){
    return(
      <input disabled type="text" className="datalistfield" value={props.value}/>
    );
  }
  else if(props.visibility === 'hide'){
    return null
    }
  else{
      return(
        <div>
          {/* Datalist Field */
          props.fullSize === true ? (
            <input list={`${props.name}-${random}-options`} className="datalistfield-fullsize" style={toggleErrorBorderVisible(props.errorVisible)} placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange} onPaste={props.onChange} onBlur={props.onBlur}/>
          ) : (
            <input list={`${props.name}-${random}-options`} className="datalistfield" style={toggleErrorBorderVisible(props.errorVisible)} placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange} onPaste={props.onChange} onBlur={props.onBlur}/>
          )
          }
          <datalist id={`${props.name}-${random}-options`}>
            {props.options.map((row, index) => (
              <option>{row}</option>
            ))}
          </datalist>
        </div>
      );
  }


  //------------------------------------------------------
}

export default DataListField;