//------------------------------------------------------
//  Function used to refresh a page
//------------------------------------------------------

function refreshPage(){

    //------------------------------------------------------
    //  Refresh the Page
    //------------------------------------------------------
    
      window.location.reload(false)
    
    //--------------------------------------------------------
  
  }
  
export default refreshPage;