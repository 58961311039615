//==========================================================================
//   Component: Write Usage Metric
//==========================================================================

//   Purpose: Component that gets the current route and writes metric to Firestore collection

//   Example: 
//   <WriteUsageMetric></WriteUsageMetric>

//------------------------------------------------------

//Libraries
// eslint-disable-next-line
import React, { useEffect, useContext } from 'react';
import {useLocation} from 'react-router';
import moment from 'moment';

//Contexts
import { GetFireBaseUser, SetAppErrors } from './GlobalContexts';

//Functions
import WriteDocument from './WriteDocument';


function WriteUsageMetric(){

    //------------------------------------------------------
    //  useLocation > Get current route/pathname
    //------------------------------------------------------

        const routerUseLocation = useLocation();
        var currentPath = (routerUseLocation.pathname).toString()

    //------------------------------------------------------
    //  useContext > Get Firebase UID
    //------------------------------------------------------

        const getFireBaseUser = useContext(GetFireBaseUser);
        const setAppErrors = useContext(SetAppErrors);

    //------------------------------------------------------
    //  Prepare Document Inputs
    //------------------------------------------------------

        //Get current datetime > 'YYYY-MM-DD HH:mm:ss' format 
        const date = Date.now()
        const currentDateTime = moment(date).format('YYYY-MM-DD HH:mm:ss')



        //Get the service name from the route
        const arrayOfServices = ["dns", "firewall", "sharedvpc", "invoices", "projects", "groups", "sccfindings", "portfolios", "projectownerlookup", "sustainability", "support", "knowledgehub", "profile"]
        const serviceName = arrayOfServices.filter(service => currentPath.includes(service))[0]

    //------------------------------------------------------
    //  Define useEffects 
    //------------------------------------------------------

        useEffect(() => {

            //Conditions
            if (getFireBaseUser === undefined) return;
            if(getFireBaseUser?.uid === undefined || serviceName === undefined) return;
            
            //Create unique ID using email address and current timestamp
            const id = `${getFireBaseUser?.uid}-${date}`

            //Prepare document > Write to Firestore
            const usageDocument = {
                "id": id,
                "route": currentPath,
                "servicename": serviceName,
                "datetime": currentDateTime,
                "uid": getFireBaseUser?.uid
            }
            
            WriteDocument('portalusage', id, usageDocument, false)
            .catch((error) =>{
                console.log(`Unable to write usage metric for ${currentPath}. Error: ${error}`);
                setAppErrors(`Unable to write usage metric for ${currentPath}. Error: ${error}`);
            })

        // eslint-disable-next-line
        }, [currentPath])


    //------------------------------------------------------
    //  Return null (Function runs in App.js > no HTML) 
    //------------------------------------------------------

        return null
  
}

//------------------------------------------------------

export default WriteUsageMetric;