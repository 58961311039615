//================================================================
//  Create/modify/delete firewall rules page
//================================================================

//Libraries
import React, { useContext, useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import DocumentLink from '../../Components/KnowledgeHubComponents/documentlink';
import Disclaimer from '../../Components/Disclaimer/disclaimer';
import QuestionLabel from '../../Components/QuestionLabel';
import ErrorMessageLabel from '../../Components/ErrorMessageLabel';
import TextArea from '../../Components/TextArea/textarea';
import InputField from '../../Components/InputField/inputfield';
import InlineInputField from '../../Components/InlineInputField/inlineinputfield';
import RadioButtons from '../../Components/RadioButtons/radiobuttons';
import Label from '../../Components/Label/label';
import ListItem from '../../Components/ListItem/listitem';

//Functions
import ReactBackend from '../../Library/reactBackend';
import { isString, errString, isStringUnsignedInteger } from '../../Library/ValidateUserInput';

//Images
import workingCatGraphic from '../../Components/Images/workingCatGraphic.svg';

//CSS
import './Firewall.css';


export default function FirewallRule() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
  const navigate = useNavigate();
  const getUser = useContext(GetUser);
  const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  Retrieve paramaters from getUser global context
  //------------------------------------------------------

  let fields = getUser?.preferences?.firewall?.fields;
  let requestType = getUser?.preferences?.firewall?.action;

  // Need to fill these in even if missing, otherwise will generate errors
  // prior to the "onload" useEffect returning back to the Firewall page
  // If missing, "onload" useEffect will force back to the Firewall page
  if (fields === undefined || requestType === undefined) {
    requestType = 'missing'
    fields = {
      "projectId":{
        "visibility": "read",
        "value": ""
      },
      "ruleName":{
        "visibility": "read",
        "value": ""
      },
      "description":{
        "visibility": "read",
        "value": ""
      },
      "target":{
        "visibility": "read",
        "value": ""
      },
      "direction":{
        "visibility": "read",
        "value": ""
      },
      "action":{
        "visibility": "read",
        "value": ""
      },
      "ipRangeFilter":{
        "visibility": "read",
        "value": ""
      },
      "serviceAccountFilter":{
        "visibility": "read",
        "value": ""
      },
      "tcpPorts":{
        "visibility": "read",
        "value": ""
      },
      "udpPorts":{
        "visibility": "read",
        "value": ""
      },
      "priority":{
        "visibility": "read",
        "value": ""
      }
    }
  }


  //------------------------------------------------------
  //  Define the 'egress' or 'ingress' form options
  //------------------------------------------------------

  //Define variables for whether we display/hide source filters
  var hideSourceFilter = false;
  var filterType = "Source";
  var filterHelpText = "Set filters to apply your rule to specific sources of traffic.";
  var accStructureText = " Must contain 6-30 lowercase letters, numbers or hyphens, followed by '@', then lowercase letters, numbers, hyphens or full stops (.)"


  //Check if direction passed as a prop > set 'filterVisible'
  if(fields.direction.value === 'egress'){
    hideSourceFilter = true;
    filterType = "Destination";
    filterHelpText = "Set filters to apply your rule to specific destinations where the traffic is going.";

  }
  
  //------------------------------------------------------
  //  Define Variables
  //------------------------------------------------------

    //Get the givenname and surname 
    const givenname = getUser?.profile?.givenname;
    const surname =  getUser?.profile?.surname;

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    //https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns
    //Extract form questions from Firestore collection
    // eslint-disable-next-line
    const [formQuestions, setFormQuestions] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        q1: {
          question: "Firewall Rule Name *",
          name: "firewallName",
          placeholder: "",
          helpText: "Please enter a name for your firewall rule. Lowercase letters, numbers and hyphens are allowed. Note* - The rule name will be prefixed with your selected project ID. "
        },
        q2: {
          question: "Description *",
          name: "description",
          placeholder: "Enter a description",
          helpText: "Please enter a description of your firewall rule, for your reference."
        },
        q3: {
          question: "Target Service Account *",
          name: "target",
          placeholder: "e.g. myaccount@"+ fields.projectId.value + ".iam.gserviceaccount.com",
          helpText: "Please specify a target service account from your selected project. Resources running as this target service account will be authorised to receive/send network traffic. You can also enter a Google managed service account from your project e.g.: '222-compute@developer.gserviceaccount.com'."
        },
        q4: {
          question: "Direction of traffic *",
          name: "direction",
          placeholder: "ingress",
          helpText: "Ingress applies to incoming traffic. Egress applies to outbound traffic."
        },
        q5: {
          question: "Action on match *",
          name: "action",
          placeholder: "allow",
          helpText: "When traffic matches your parameters, you can allow or deny that traffic."
        },
        q6: {
          question: "Source IP Range *",
          name: "ipRangeFilter",
          placeholder: "For example, 0.0.0.0/0, 192.168.2.0/24",
          helpText: "Traffic is only allowed from sources within these IP address ranges. Use CIDR notation when entering ranges."
        },
        q7: {
          question: "Source Service Account *",
          name: "serviceAccountFilter",
          placeholder: "service-account-name@project-id.iam.gserviceaccount.com",
          helpText: "You can specify a service account from the current project or from any linked Shared VPC project. This filter will apply your rule to specific sources of traffic, using this service account."
        },
        q8: {
          question: "Protocols and Ports *",
          name: "tcpPorts",
          placeholder: "e.g. 22, 443, 1024-1026",
          helpText: "Please specify a port number or a range of ports below. To allow all ports, please enter the range '0-65535' in both of the fields below."
        },
        q9: {
          question: "Protocols and Ports *",
          name: "udpPorts",
          placeholder: "e.g. 53, 8767-8769",
          helpText: "Please specify a port number or a range of ports below. To allow all ports, please enter the range '0-65535' in both of the fields below."
        },
        q10: {
          question: "Priority *",
          name: "priority",
          placeholder: "Priority can be 1000 - 60000",
          helpText: "You can specify the order that a rule will be evaluated within your project. Rules with lower numbers get prioritized first. Default is 1000."
        }
      }
    );

    //Holds the inputs of the Form
    const [selectedValue, setSelectedValue] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        description: fields.description.value,
        target: "",
        targetServiceAccountArray: [],
        firewallName: fields.ruleName.value,
        direction: fields.direction.value,
        action: fields.action.value,
        ipRangeFilter: fields.ipRangeFilter.value,
        serviceAccountFilter: "",
        serviceAccountFilterArray: [],
        tcpPorts: fields.tcpPorts.value,
        udpPorts: fields.udpPorts.value,
        priority: fields.priority.value
      }
    );

    //Holds the styling for all fields > Used for error messaging
    const [errorState, setErrorState] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        descriptionErrorVisible: false,
        descriptionErrorMessage: "Please enter a description.",
        targetErrorVisible: false,
        targetErrorMessage: "Please enter a valid service account ID, e.g. exampleaccount@myproject.iam.gserviceaccount.com",
        firewallNameErrorVisible: false,
        firewallNameErrorMessage: "Error: Invalid Firewall Rule Name. The firewall rule name can have 0 - 61 lowercase letters, numbers, or hyphens and cannot end with a hyphen. Please update the service name field above, to meet these requirements.",
        directionErrorVisible: false,
        directionErrorMessage: "Please select one of the options above.",
        actionErrorVisible: false,
        actionErrorMessage: "Please select one of the options above.",
        ipRangeFilterErrorVisible: false,
        ipRangeFilterErrorMessage: "Please enter an IP range above. Use CIDR notation when entering ranges.",
        serviceAccountFilterErrorVisible: false,
        serviceAccountFilterErrorMessage: "Please enter a valid service account, e.g. exampleaccount@myproject.iam.gserviceaccount.com",
        sourceFilterErrorVisible: false,
        sourceFilterErrorMessage: "Please enter at least one of the source filters above.",
        portsErrorVisible: false, 
        portsErrorMessage: "Please specify a port number or a range of ports in at least one of the fields above.",
        tcpPortsErrorVisible: false,
        tcpPortsErrorMessage: "Invalid tcp port. Numeric characters, commas and hyphens allowed.",
        udpPortsErrorVisible: false,
        udpPortsErrorMessage: "Invalid udp port. Numeric characters, commas and hyphens allowed.",
        priorityErrorVisible: false,
        priorityErrorMessage: "Please enter a number between 1000 to 60000."
      }
    );

    //Holds the filter information, i.e. source/destination filters
    const [filterState, setFilterState] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        filterType: filterType,
        filterHelpText: filterHelpText,
        hideSourceFilter: hideSourceFilter
      }
    );


  //------------------------------------------------------
  //  Prepare labels and request body parameters
  //------------------------------------------------------


  //If requestType === 'create' > add project ID prefix
  var firewallNameWithSpaces;
  if (requestType === "create"){
  
    firewallNameWithSpaces = fields.projectId.value + "-" +  selectedValue.firewallName;

  } else {
  
    firewallNameWithSpaces = selectedValue.firewallName;
  
  }
  
  //Prepare firewall rule name > remove spaces and set to lowercase
  let firewallName = firewallNameWithSpaces.replace(/\s+/g, "").toLowerCase();

  //Prepare arrays for radio buttons > direction & action
  let directions = ["ingress", "egress"];
  let actions = ["allow", "deny"];

  //Prepare array for the IP range > Split IP range string into an array
  let ipRanges = String(selectedValue.ipRangeFilter).split(',');
  let ipRangesArray = [];

  //Remove spaces in the array 
  ipRanges.forEach(function(element){

    var ip = element.trim();
    if (ip.length > 0) ipRangesArray.push(ip);

  });

  //Prepare TCP and UDP Ports as an array
  //Separate ports and append to array
  var tcpPorts = String(selectedValue.tcpPorts).split(',');
  let tcpPortsArray = [];
  var udpPorts = String(selectedValue.udpPorts).split(',');
  let udpPortsArray = [];

  //Remove spaces in the array 
  tcpPorts.forEach(function(element){

    var port = element.trim();
    if (port.length > 0) tcpPortsArray.push(port);

  });

  udpPorts.forEach(function(element){

    var port = element.trim();
    if (port.length > 0) udpPortsArray.push(port);
  
  });

  
  //------------------------------------------------------
  //  Prepare List of Account IDs
  //------------------------------------------------------
  
    //Define arrayOfAccountIds (empty array)
    var arrayOfAccountIds = [];

   //If target value from props is not null > prepare array of accounts
    if(fields.target.value.length > 0){  

      var targetServiceAccountIds = String(fields.target.value).split(',');
      
      //Loop through accountIDs and add 'type' property, i.e. 'new' or 'deleted' account
      targetServiceAccountIds.forEach(element => {
        
        var obj = {
          "type": "existing",
          "account": element.trim()
        }

        arrayOfAccountIds.push(obj)

      });
    }

  //------------------------------------------------------
  //  Prepare List of Service Account Filters
  //------------------------------------------------------
  
    //Define empty array
    var arrayOfSAFilters = [];


   //If value from props is not null > prepare array of accounts
    if(fields.serviceAccountFilter.value.length > 0){  

      var serviceAccountFilters = String(fields.serviceAccountFilter.value).split(',');     

      //Loop through accounts and add 'type' property, i.e. 'new' or 'deleted' account
      serviceAccountFilters.forEach(element => {
        
        var obj = {
          "type": "existing",
          "account": element.trim()
        }

        arrayOfSAFilters.push(obj)

      });

    }

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  //Used to save page status > 'pending', 'successContent', 'error-invalid', 'error-fatal', 'error-timeout' & 'error-other'
  const [pageStatus, setPageStatus] = useState();
  const [pageTitle, setPageTitle] = useState();

  //If selected project changes, go back to Firewall page
  const [currentProject, setCurrentProject] = useState();

  //Used to store the list of items displayed on the page
  const [items, setItems] = useState(arrayOfAccountIds); 
  const [saFilters, setSAFilters] = useState(arrayOfSAFilters); 

  //Used to store the list of 'initial' items passed as props
  const initialItems = targetServiceAccountIds; 
  const initialSAFilters = serviceAccountFilters;
  
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Used to change the 'PageDetailHeader' content 
    function DefinePageDetailHeader(){

      if (requestType === 'delete'){
        return (
          <div>
            You are deleting firewall rule {fields?.ruleName?.value}
          </div>   
        );
      }
      else{
        return (
          <div>
            <b style={{color: "#212c61"}}>Prerequisites</b><br></br>
            <ul>
              <li>You have a service account provisioned in your project, that is used as the run account for your compute resource.</li>
            </ul>
  
            For more information, refer to <DocumentLink docId="1119551520" title="Firewall Services - How to Get Started"></DocumentLink>.
          </div>   
        );
      }
    }

  //------------------------------------------------------
  //  Field Validation Functions (Regex)
  //  * - Paste Regex in 'https://regexr.com/' for explanation
  //------------------------------------------------------
  
  //Function to validate Firewall Rule Name > 0-61 characters, lowercase alphanumeric characters including hyphens
  function isValidFirewallName(firewallRuleName) {  
    if (/^(?:[a-z](?:[-a-z0-9]{0,61}[a-z0-9])?)$/.test(firewallRuleName)) {  
      return (true)  
    }  
    return (false)  
  }  

  //Function to validate IP range uses CIDR notation
  function isValidIP(ipaddress) {  
    if (/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))?$/g.test(ipaddress)) {  
      return (true)  
    }  
    return (false)  
  }
    
  //Function to validate the target service account ID
  function isValidServiceAccount(ServiceAccount){  
    if (/^[a-z0-9][-a-z0-9]{5,29}@([a-z0-9][-.a-z0-9]*)?[a-z0-9]$/.test(ServiceAccount)) {  
      return (true)  
    }  
    return (false)  
  }  

  //Function to validate the protocols and ports > allow numbers, commas and hyphens 
  function isValidPort(Port){  
    if (/^[ 0-9,-]*$/g.test(Port)) {  
      return (true)  
    }  
    return (false)  
  } 


  //------------------------------------------------------
  //  Define Functions > callAPI & HandleResponse
  //------------------------------------------------------


  //Used to call "submitFireWallRuleRequest"
  async function callAPI(){

    var requestBody = {
      "projectId": fields.projectId.value,
      "requestType": requestType,
      "name": firewallName,
      "description": selectedValue.description,
      "target": selectedValue.targetServiceAccountArray,
      "direction": selectedValue.direction,
      "action": selectedValue.action,
      "serviceAccountFilter": selectedValue.serviceAccountFilterArray,
      "ipRangeFilter": ipRangesArray,
      "tcpPorts": tcpPortsArray,
      "udpPorts": udpPortsArray,
      "priority": selectedValue.priority,
      "givenname": givenname, 
      "surname": surname
    }

    //Call React Backend - Submit Form Response
    var response = await ReactBackend('submitFireWallRuleRequest', requestBody);

    //Switch on formStatus
    if(response?.status === 'success'){
          
      //Set formStatus to 'success'
      setPageStatus('success');

    } else if(response?.status === 'error-invalid'){

      //Set formStatus to 'error-invalid'
      setAppErrors(response?.responseBody?.message)
      setPageStatus('error-invalid');

    } else {

      //Set formStatus to 'error-fatal'
      setPageStatus('error-fatal');

    }

    return response;
  }   

  //------------------------------------------------------
  //  Define Functions > Adding/Removing target service accounts
  //------------------------------------------------------


    //Function for adding an item to the list
    const addTargetAccount = serviceAccount => {

      //Check for duplicate items
      var duplicateItems = items.filter(item => item.account === serviceAccount && (item.type === 'new' || item.type === 'existing'));
      var duplicateDeletedItem = items.filter(item => item.account === serviceAccount && item.type === 'deleted');

      //---VALIDATION CHECKS--//

      // Prevent more than 10 service accounts
      if(selectedValue.targetServiceAccountArray.length >= 10){

        setErrorState({ targetErrorVisible: true });
        setErrorState({ targetErrorMessage: "Maximum of 10 service accounts supported"});

      //No value provided > Set 'errorVisible' to true
      } else if(serviceAccount.length <= 0){

        setErrorState({ targetErrorVisible: true });
        setErrorState({ targetErrorMessage: "Please enter a service account email address and click the 'Add' button below."});
          
      //Invalid format > Set 'errorVisible' to true
      } else if(!isValidServiceAccount(serviceAccount)){

        setErrorState({ targetErrorVisible: true });
        setErrorState({ targetErrorMessage: "Invalid service account email address." + accStructureText});
      
      //Check for duplicate accounts 
      } else if(duplicateItems.length > 0){

        setErrorState({ targetErrorVisible: true });
        setErrorState({ targetErrorMessage: "This account has already been added to the list above."});

      //Invalid projectId > Set 'errorVisible' to true; Note this assumes the input is in a valid service account format hence its positions after the service account is valid checks
      // possible accepted values: 
      //    - somename@projectid.iam.gserviceaccount.com
      //    - projectnumber-xxxxx@yyyyy.gserviceaccount.com
      //    - xxx-projectnumber@yyyyy.gserviceaccount.com
      //    - projectnumber@yyyyy.gserviceaccount.com
      //
      } else if(! serviceAccount.endsWith(".gserviceaccount.com")){
        setErrorState({ targetErrorVisible: true });
        setErrorState({ targetErrorMessage: "Invalid service account projectId. Please enter a service account email address from the same project."});
        
      //Default border and error visibility
      } else {

          setErrorState({ targetErrorVisible: false });

          //Change type for deleted items to 'new' - if re-added to the list
          if(duplicateDeletedItem.length > 0){

            let updatedItems = [...items];
            let index = items.findIndex(item => item.account === serviceAccount);
            updatedItems[index] = {...updatedItems[index], type: 'new'};
            setItems(updatedItems);
          
          //Add item to list
          } else {

            //Prepare service account object
            let serviceAccountObj = {
              "type": "new",
              "account": serviceAccount
            }
            
            //Add item to list
            const newItems = [...items, serviceAccountObj];
            setItems(newItems);
          }

          //Reset value to blank
          setSelectedValue({ target: "" });
      }

    };

    //Function for removing the item from the list
    const removeTargetAccount = index => {

      //Change the type to 'deleted' in items
      let updatedItems = [...items];
      updatedItems[index] = {...updatedItems[index], type: 'deleted'}

      //Update display items list
      setItems(updatedItems);

    };

    //Function for re-adding the item to the list
    const reAddTargetAccount = index => {

      //Change the type to 'new' in items
      let updatedItems = [...items];
      updatedItems[index] = {...updatedItems[index], type: 'new'};
  
      //Update display items list
      setItems(updatedItems);
  
    };


  //------------------------------------------------------
  //  Define Functions > Adding/Removing SA Filters
  //------------------------------------------------------


    //Function for adding an item to the list
    const addSAFilter = serviceAccount => {

      //Check for duplicate items
      var duplicateFilters = saFilters.filter(item => item.account === serviceAccount && (item.type === 'new' || item.type === 'existing'));
      var duplicateDeletedFilters = saFilters.filter(item => item.account === serviceAccount && item.type === 'deleted');

      //---VALIDATION CHECKS--//
      //No value provided > Set 'errorVisible' to true 
      if(serviceAccount.length <= 0){

        setErrorState({ sourceFilterErrorVisible: true });
        setErrorState({ sourceFilterErrorMessage: "Please enter a service account email address and click the 'Add' button below."});
          
      //Invalid format > Set 'errorVisible' to true
      } else if(!isValidServiceAccount(serviceAccount)){

        setErrorState({ sourceFilterErrorVisible: true });
        setErrorState({ sourceFilterErrorMessage: "Invalid service account email address." + accStructureText});
      
      //Check for duplicate accounts 
      } else if(duplicateFilters.length > 0){

        setErrorState({ sourceFilterErrorVisible: true });
        setErrorState({ sourceFilterErrorMessage: "This account has already been added to the list above."});

      //Invalid projectId > Set 'errorVisible' to true; Note this assumes the input is in a valid service account format hence its positions after the service account is valid checks
      // possible accepted values: 
      //    - somename@projectid.iam.gserviceaccount.com
      //    - projectnumber-xxxxx@yyyyy.gserviceaccount.com
      //    - xxx-projectnumber@yyyyy.gserviceaccount.com
      //    - projectnumber@yyyyy.gserviceaccount.com
      //
      } else if(! serviceAccount.endsWith(".gserviceaccount.com")){

        setErrorState({ sourceFilterErrorVisible: true });
        setErrorState({ sourceFilterErrorMessage: "Invalid service account projectId. Please enter a valid service account email address."});
        
      //Default border and error visibility
      } else {

          setErrorState({ sourceFilterErrorVisible: false });

          //Change type for deleted items to 'new' - if re-added to the list
          if(duplicateDeletedFilters.length > 0){

            let updatedItems = [...saFilters];
            let index = saFilters.findIndex(item => item.account === serviceAccount);
            updatedItems[index] = {...updatedItems[index], type: 'new'};
            setSAFilters(updatedItems);
          
          //Add item to list
          } else {

            //Prepare service account object
            let serviceAccountObj = {
              "type": "new",
              "account": serviceAccount
            }
            
            //Add item to list
            const newItems = [...saFilters, serviceAccountObj];
            setSAFilters(newItems);
          }

          //Reset value to blank
          setSelectedValue({ serviceAccountFilter: "" });
      }

    };

    //Function for removing the item from the list
    const removeSAFilter = index => {

      //Change the type to 'deleted' in items
      let updatedItems = [...saFilters];
      updatedItems[index] = {...updatedItems[index], type: 'deleted'};

      //Update display items list
      setSAFilters(updatedItems);

    };

    //Function for re-adding the item to the list
    const reAddSAFilter = index => {

      //Change the type to 'new' in items
      let updatedItems = [...saFilters];
      updatedItems[index] = {...updatedItems[index], type: 'new'};
  
      //Update display items list
      setSAFilters(updatedItems);
  
    };

  //------------------------------------------------------
  //  Event Handler > Form Submission
  //------------------------------------------------------


  //Event handler when form is submitted > validate all fields
  const eventHandlerOnSubmit = e => {  

    //This prevents the form from been submitted
    var preventSubmit = false;

    //Description field empty or invalid > Set 'errorVisible' to true
    if(selectedValue.description.length <= 0){
      setErrorState({ descriptionErrorVisible: true });
      setErrorState({ descriptionErrorMessage: "Please enter a description."});
      preventSubmit = true;

    } else if (!isString(selectedValue.description)) {

      setErrorState({ descriptionErrorVisible: true });
      setErrorState({ descriptionErrorMessage: `Invalid description. The description can contain ${errString}.`});
      preventSubmit = true;

    }

    //Target service account Array empty > Set 'errorVisible' to true
    if(selectedValue.targetServiceAccountArray.length <= 0){

      setErrorState({ targetErrorVisible: true });
      setErrorState({ targetErrorMessage: "Please enter a service account email address and click the 'Add' button below."})
      preventSubmit = true;
    
    }

    //Firewall rule name field empty > Set 'errorVisible' to true
    if(selectedValue.firewallName.length <= 0){

      setErrorState({ firewallNameErrorVisible: true });
      setErrorState({ firewallNameErrorMessage: "Please enter a firewall rule name."})
      preventSubmit = true;

    }
    
    //Invalid firewall rule name > Set 'errorVisible' to true
    if(!isValidFirewallName(firewallName)){

      setErrorState({ firewallNameErrorVisible: true });
      setErrorState({ firewallNameErrorMessage: "Invalid firewall rule name. The firewall rule name can have 1 - 61 lowercase letters, numbers, or hyphens and cannot end with a hyphen."});  
      preventSubmit = true;

    }

    //Direction field empty > Set 'errorVisible' to true
    if(selectedValue.direction.length <= 0){
      setErrorState({ directionErrorVisible: true });
      preventSubmit = true;
    }
    
    //Action field empty > Set 'errorVisible' to true
    if(selectedValue.action.length <= 0){

      setErrorState({ actionErrorVisible: true });
      preventSubmit = true;

    }

    //Ingress rule AND both IP Range and Service Account fields are empty > Set 'errorVisible' to true
    if((selectedValue.direction === '' || selectedValue.direction === 'ingress') && selectedValue.ipRangeFilter.length <= 0 && selectedValue.serviceAccountFilterArray.length <= 0){

      setErrorState({ sourceFilterErrorVisible: true });
      preventSubmit = true;

    } else {

      setErrorState({ sourceFilterErrorVisible: false });

    }

    //If direction is 'egress' > Clear the service account field
    if(selectedValue.direction === 'egress'){

      setSelectedValue({ serviceAccountFilter: "" });
      setSelectedValue({ serviceAccountFilterArray: [] });
      setSAFilters([]);

      //If IP range is empty or invalid > Set 'errorVisible' to true
      if(selectedValue.ipRangeFilter.length <= 0  || errorState.ipRangeFilterErrorVisible === true){

        setErrorState({ ipRangeFilterErrorVisible: true });
        preventSubmit = true;
      
      }
    }
    
    //Both port fields empty > Set 'errorVisible' to true
    if(selectedValue.tcpPorts.length <= 0 && selectedValue.udpPorts <= 0){

      setErrorState({ portsErrorVisible: true });
      preventSubmit = true;

    }
    
    //Invalid tcp port > Set 'errorVisible' to true
    if(!isValidPort(selectedValue.tcpPorts)){

      setErrorState({ tcpPortsErrorVisible: true });
      setErrorState({ portsErrorVisible: false });
      preventSubmit = true;
    }
    
    //Invalid udp port > Set 'errorVisible' to true
    if(!isValidPort(selectedValue.udpPorts)){

      setErrorState({ udpPortsErrorVisible: true });
      setErrorState({ portsErrorVisible: false });
      preventSubmit = true;

    }
    
    //Priority field empty > Set 'errorVisible' to true
    if(selectedValue.priority.length <= 0){

      setErrorState({ priorityErrorVisible: true });
      setErrorState({ priorityErrorMessage: "Please enter a number between 1000 to 60000." });
      preventSubmit = true;

    }
    
    //Priority field is not number, has spaces or < 1000 or > 60000 > Set 'errorVisible' to true
    if(!isStringUnsignedInteger(selectedValue.priority) || selectedValue.priority < 1000 || selectedValue.priority > 60000){

      setErrorState({ priorityErrorVisible: true });
      setErrorState({ priorityErrorMessage: "Invalid input. Please enter a number between 1000 to 60000." });
      preventSubmit = true;

    } 
    
    if (errorState.descriptionErrorVisible === true ||
      errorState.firewallNameErrorVisible === true ||
      errorState.directionErrorVisible === true ||
      errorState.actionErrorVisible === true ||
      errorState.ipRangeFilterErrorVisible === true ||
      errorState.serviceAccountFilterErrorVisible === true ||
      errorState.sourceFilterErrorVisible === true ||
      errorState.portsErrorVisible === true ||
      errorState.tcpPortsErrorVisible === true ||
      errorState.udpPortsErrorVisible === true ||
      errorState.priorityErrorVisible === true) {

        preventSubmit = true;

      }

    //Allow the form to be submitted
    if (preventSubmit === false){

      setPageStatus('pending');
      e.preventDefault();
      callAPI();

    }

    //Prevent reload
    e.preventDefault();
  }

  //------------------------------------------------------
  //  Event Handler > Field Changes
  //------------------------------------------------------

    //Event Handler for form submission
    const handleAdd = e => {

      //Prevent refresh
      e.preventDefault();

        //Add item
        addTargetAccount(selectedValue.target);
    }


    const handleAddSAFilter = e => {

      //Prevent refresh
      e.preventDefault();

      //Add item
      addSAFilter(selectedValue.serviceAccountFilter);
    }

    //Event Handler for field changes 
    const handleChange = evt => {
        
      const name = evt.target.name;
      const newValue = evt.target.value;

      //Validate field - description
      if(name === 'description'){
        //If field is empty or invalid > Set 'errorVisible' to true
        if(newValue.length <= 0){

          setErrorState({ descriptionErrorVisible: true });
          setErrorState({ descriptionErrorMessage: "Please enter a description."});

        } else if (!isString(newValue)) {

          setErrorState({ descriptionErrorVisible: true });
          setErrorState({ descriptionErrorMessage: `Invalid description. The description can contain ${errString}.`});

        } else {

          setErrorState({ descriptionErrorVisible: false });

        }
      }

      //Validate field - action 
      if(name === 'action'){

        //If field is empty > Set 'errorVisible' to true
        if(newValue.length <= 0){

          setErrorState({ actionErrorVisible: true });

        } else {

          setErrorState({ actionErrorVisible: false });

        }
      }

      //Validate field - direction
      //Change source/destination filter based on direction (ingress/egress)
      if(name === 'direction'){

        if(newValue === "egress") {

          //Set to destination filters 
          setFilterState({ filterType: "Destination" });
          setFilterState({ filterHelpText: "Set filters to apply your rule to specific destinations where the traffic is going." });
          setFilterState({ hideSourceFilter: true });
          setSelectedValue({ serviceAccountFilter: "" });
          setSelectedValue({ serviceAccountFilterArray: [] });
          setSAFilters([]);

        } else {

          //Set to source filters 
          setFilterState({ filterType: "Source" });
          setFilterState({ filterHelpText: "Set filters to apply your rule to specific sources of traffic." });
          setFilterState({ hideSourceFilter: false });

        }

        //If field is empty > Set 'errorVisible' to true
        if(newValue.length <= 0){

          setErrorState({ directionErrorVisible: true });

        } else {

          setErrorState({ directionErrorVisible: false });

        }

      }

      //Validate field - firewall rule name
      if(name === 'firewallName'){
          
        //Field is empty > Set 'errorVisible' to true
        if(newValue.length <= 0){

          setErrorState({ firewallNameErrorVisible: true });
          setErrorState({ firewallNameErrorMessage: "Please enter a firewall rule name."});
        
          //Ends in a hyphen > Set 'errorVisible' to true
        } else if(newValue.endsWith("-")){

          setErrorState({ firewallNameErrorVisible: true });
          setErrorState({ firewallNameErrorMessage: "Firewall rule name cannot end with a hyphen."});
        
        //Exceeds character limit > Set 'errorVisible' to true
        } else if(firewallName.length > 61){

          setErrorState({ firewallNameErrorVisible: true });
          setErrorState({ firewallNameErrorMessage: firewallName.length + "/61 characters used. Firewall rule name exceeded character limit."});
        
        //Invalid firewall rule name > Set 'errorVisible' to true
        } else if(!isValidFirewallName(newValue)){

          setErrorState({ firewallNameErrorVisible: true });
          setErrorState({ firewallNameErrorMessage: "Invalid firewall rule name. The firewall rule name can have 1 - 61 lowercase letters, numbers, or hyphens and cannot end with a hyphen."});  
        
          //Default border and error visibility
        } else {            

          setErrorState({ firewallNameErrorVisible: false });

        }
      }

      //Validate field - Service Account
      if(name === 'serviceAccountFilter'){
          
        //Ingress rule AND both IP Range and Service Account fields are empty > Set 'errorVisible' to true
        if(selectedValue.direction === 'ingress' && selectedValue.serviceAccountFilterArray.length === 0 && newValue.length <= 0 && selectedValue.ipRangeFilter.length <= 0){

          setErrorState({ sourceFilterErrorVisible: true });
          return;

        }
        
        // Ensure ONE source filter is provided
        if(newValue.length === 0 && selectedValue.serviceAccountFilterArray.length === 0 && selectedValue.ipRangeFilter.length === 0){

          setErrorState({ sourceFilterErrorVisible: true });
          setErrorState({ sourceFilterErrorMessage: "Please enter at least one of the source filters above." });
          return;

        }

        // Prevent more than 10 service accounts
        if(selectedValue.serviceAccountFilterArray.length >= 10 ){
        
          setErrorState({ sourceFilterErrorMessage: "Maximum of 10 service accounts supported" });
          setErrorState({ sourceFilterErrorVisible: true });
          return;
        
        } 

        //Default border and error visibility
        setErrorState({ serviceAccountFilterErrorVisible: false });
        setErrorState({ sourceFilterErrorVisible: false });
        setErrorState({ ipRangeFilterErrorVisible: false });

      }

      //Validate field - tcp ports 
      if(name === 'tcpPorts'){
          
        //Field is empty > Set 'errorVisible' to true
        if(newValue.length <= 0 && selectedValue.udpPorts.length <= 0){

          setErrorState({ portsErrorVisible: true });
          setErrorState({ tcpPortsErrorVisible: false });
          setErrorState({ udpPortsErrorVisible: false });
        
          //Invalid tcp port > Set 'errorVisible' to true
        } else if(!isValidPort(newValue)){

          setErrorState({ tcpPortsErrorVisible: true });
          setErrorState({ portsErrorVisible: false });
        
          //Default border and error visibility
        } else {

          setErrorState({ tcpPortsErrorVisible: false });
          setErrorState({ portsErrorVisible: false });
          
        }
      }

      //Validate field - udp ports 
      if(name === 'udpPorts'){
        
        //Field is empty > Set 'errorVisible' to true
        if(newValue.length <= 0 && selectedValue.tcpPorts.length <= 0){

          setErrorState({ portsErrorVisible: true });
          setErrorState({ tcpPortsErrorVisible: false });
          setErrorState({ udpPortsErrorVisible: false });
      
        //Invalid udp port > Set 'errorVisible' to true
        } else if(!isValidPort(newValue)){
      
          setErrorState({ udpPortsErrorVisible: true });
          setErrorState({ portsErrorVisible: false });
        
        //Default border and error visibility
        } else{

          setErrorState({ udpPortsErrorVisible: false });
          setErrorState({ portsErrorVisible: false });

        }
      }

      //Validate field - priority > No spaces && between 1000 - 60000
      if(name === 'priority'){
        
        //If field is empty > Set 'errorVisible' to true
        if(newValue.length <= 0){

          setErrorState({ priorityErrorVisible: true });
          setErrorState({ priorityErrorMessage: "Please enter a number between 1000 to 60000." });
        
        //If field is not number, has spaces or < 1000 or > 60000 > Set 'errorVisible' to true
        } else if(!isStringUnsignedInteger(newValue) || newValue < 1000 || newValue > 60000){
        
          setErrorState({ priorityErrorVisible: true });
          setErrorState({ priorityErrorMessage: "Invalid input. Please enter a number between 1000 to 60000." });
        
        } else {

          setErrorState({ priorityErrorVisible: false });

        }
      }
      
      //Set selected value to field values 
      setSelectedValue({ [name]: newValue });
    };


    //Event handler for IP ranges 
    const handleIPChange = evt => {

      const name = evt.target.name;
      const newValue = evt.target.value;

      //Split IP ranges into an array
      let ipRanges = String(newValue).split(',');
          
      //Ingress rule AND both IP Range and Service Account fields are empty > display error message
      if(selectedValue.direction === 'ingress' && newValue.length <= 0 && selectedValue.serviceAccountFilterArray.length <= 0){

        setErrorState({ sourceFilterErrorVisible: true });
        setErrorState({ ipRangeFilterErrorVisible: false });
      
      //Egress rule AND IP range is empty or invalid > display error message
      } else if(selectedValue.direction === 'egress' && newValue.length <= 0){

        setErrorState({ ipRangeFilterErrorVisible: true });

      //Default border and error visibility
      } else {

        setErrorState({ sourceFilterErrorVisible: false });
        setErrorState({ ipRangeFilterErrorVisible: false });

        //Loop through IP ranges > Validate IP 
        ipRanges.forEach(function(element){

          var ip = element.trim();

          //Validate IP address
          if(ip.length > 0 && !isValidIP(ip)){

            //Invalid IP > Set error visible
            setErrorState({ ipRangeFilterErrorVisible: true });

          //Hide error message
          } else {
            
            setErrorState({ ipRangeFilterErrorVisible: false });
            setErrorState({ sourceFilterErrorVisible: false });
            setErrorState({ serviceAccountFilterErrorVisible: false });

          }
        
        });

      } 

      //Set selected value to field value
      setSelectedValue({ [name]: newValue });

    };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------
  
    // On load, get project and request information
    useEffect(() => {

      if(getUser === undefined) return;

      if(getUser.preferences?.globalSelector?.selectedResource === 'none') {
        navigate('/firewall');
        return;
      }

      // Set page title based on request
      if(getUser.preferences?.firewall?.action === 'create') {

        setPageTitle('Create a Firewall Rule');

      } else if (getUser.preferences?.firewall?.action === 'modify') {

        setPageTitle('Modify a Firewall Rule');

      } else if (getUser.preferences?.firewall?.action === 'delete') {

        setPageTitle('Delete a Firewall Rule');

      } else {

        navigate('/firewall');
        return;        

      }

      // If onload, show disclaimer and save current project ID
      if(currentProject === undefined) {

        setPageStatus('disclaimer');
        setCurrentProject(getUser.preferences?.globalSelector?.selectedResource);

        // Set some initial values
        setItems(arrayOfAccountIds);
        setSelectedValue({ targetServiceAccountArray: initialItems});

        setSAFilters(arrayOfSAFilters);
        setSelectedValue({ serviceAccountFilterArray: initialSAFilters});

      // Changed project in global selector, so go back to Firewall page
      } else if (currentProject !== getUser.preferences?.globalSelector?.selectedResource) {

        navigate('/firewall');
        return;
      
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

    // If page status is set to pending and it's a delete action
    // Means the disclaimer was accepted, so call the API
    useEffect(() => {

      if(pageStatus === 'pending' && requestType === 'delete') {

        callAPI();
    
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageStatus]);

  //When the items are added/removed > add to list of service accounts
  useEffect(() => {

    //Filter items to extract 'new' and 'existing' items only
    var newExistingItems = items.filter(item => (item.type === 'new' || item.type === 'existing'))

    //Extract only the account values and push to a new array
    var newExistingAccounts = [];

    newExistingItems.forEach(element => {
      
      newExistingAccounts.push(element.account);

    });

    setSelectedValue({ targetServiceAccountArray: newExistingAccounts});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[items]);


  //When the items are added/removed > add to list of service accounts Filters
  useEffect(() => {

      //Filter items to extract 'new' and 'existing' items only
      var newExistingFilters = saFilters.filter(item => (item.type === 'new' || item.type === 'existing'));

      //Extract only the account values and push to a new array
      var newExistingAccounts = [];

      newExistingFilters.forEach(element => {
        
        newExistingAccounts.push(element.account);

      });

      setSelectedValue({ serviceAccountFilterArray: newExistingAccounts});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[saFilters])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedViews={['project']}
        requireSelectedResource={true}
        requiredRoles={['projectOwner', 'projectOperator']}
        requiredEnvironments={['Standard']}
        status={pageStatus}
        breadcrumb={{
          name: "Firewall",
          route: "/firewall"
        }}
        disclaimerContent={[
          <Disclaimer
            cancelRoute="/firewall"
            setStatus={setPageStatus}
            setStatusValue={requestType === 'delete' ? "pending" : "onload"}
            acceptanceCriteria={[
              <ul>
                <li>I am authorized to perform this action</li>
              </ul>
            ]}
          ></Disclaimer>
        ]}
        header={
          <PageHeader
            title={pageTitle}
            body={DefinePageDetailHeader()}
          ></PageHeader>
        }
        body={
          <div className="Form-Container">
            <form className="Form-Body" id="firewallruleform" onSubmit={eventHandlerOnSubmit}>

            {/*------------------------------------------------------------------ */}
            {/*   Project ID *                                                    */}
            {/*------------------------------------------------------------------ */}              
            

              <QuestionLabel question={"Project ID "} visibility={fields.projectId.visibility}></QuestionLabel>

                <Label label={fields.projectId.value}></Label>


            {/*------------------------------------------------------------------ */}
            {/*   q1 - Firewall Rule Name *                                       */}
            {/*------------------------------------------------------------------ */}              
            

              <QuestionLabel question={formQuestions.q1.question} helpText = {formQuestions.q1.helpText} visibility={fields.ruleName.visibility}></QuestionLabel>

                <InlineInputField
                  //Inline Input Field Properties
                  inputFieldLength="shortinputfield"
                  name={formQuestions.q1.name}
                  placeholder={formQuestions.q1.placeholder}
                  value={selectedValue.firewallName}
                  helpText = {formQuestions.q1.helpText}
                  inlinefieldlabel= {fields.projectId.value + "-"} 
                  position = "left"
                  errorVisible={errorState.firewallNameErrorVisible}
                  visibility={fields.ruleName.visibility}

                  //Function Properties
                  onChange={handleChange}
                >
                </InlineInputField>

              <ErrorMessageLabel errorMessage={errorState.firewallNameErrorMessage} errorVisible={errorState.firewallNameErrorVisible}></ErrorMessageLabel>


            {/*------------------------------------------------------------------ */}
            {/*   q2 - Description *                                              */}
            {/*------------------------------------------------------------------ */}   

              <QuestionLabel question={formQuestions.q2.question} helpText = {formQuestions.q2.helpText}></QuestionLabel>

                <TextArea
                  //TextArea Properties
                  name={formQuestions.q2.name}
                  placeholder={formQuestions.q2.placeholder}
                  value={selectedValue.description}
                  errorVisible={errorState.descriptionErrorVisible}
                  visibility={fields.description.visibility}
                  
                  //Function Properties
                  onChange={handleChange}
                >
                </TextArea>

              <ErrorMessageLabel errorMessage={errorState.descriptionErrorMessage} errorVisible={errorState.descriptionErrorVisible}></ErrorMessageLabel>

            {/*------------------------------------------------------------------ */}
            {/*   q3 - Target Service Account *                            */}
            {/*------------------------------------------------------------------ */}

              {/* List of Service Accounts */}
              <div className="items">
                  <br></br>
                  <QuestionLabel question={formQuestions.q3.question} helpText = {formQuestions.q3.helpText}></QuestionLabel>

                  {/* If items exist > render a list  */}
                  {items.length > 0 ? 
                    (

                      items.map((item, index) => (
                        <ListItem
                            item={item.account}
                            index={index}
                            removeItem={removeTargetAccount}
                            readdItem={reAddTargetAccount}
                            type={item.type}
                            disabled={fields.target.visibility !== "edit"}
                        />
                      ))

                      
                    ) :
                    (
                      <p><em>No service accounts attached.</em></p>
                    )
                  }
              </div>

              {/* Add Service Account Form */}
              <div className="create-item" >
                  <form onSubmit={handleAdd}>
                  
                  {/*Render Paragraph only if the visibility is set to edit */}
                  { fields.target.visibility === "edit" && <p style={{fontSize: "15px", lineHeight: "1.6"}}>Enter a Service Account email address and click the '+ Add' button below.</p> }
                
                    <InlineInputField
                        //Inline Input Field Properties
                        name={formQuestions.q3.name}
                        placeholder={formQuestions.q3.placeholder}
                        value={selectedValue.target}
                        position = "right"
                        errorVisible={errorState.targetErrorVisible}
                        inputFieldLength={"longinputfield"}
                        visibility={fields.target.visibility}
                            
                        //Function Properties
                        onChange={handleChange}
                    >
                    </InlineInputField>

                    {/* Error Message - Invalid Service Account ID  */}
                    <ErrorMessageLabel errorMessage={errorState.targetErrorMessage} errorVisible={errorState.targetErrorVisible}></ErrorMessageLabel>

                    <br></br>

                    {/* Add Button - disabled if target visibility props is not set to edit*/}
                    <input className="Firewall-Add-Account-Button" value="+ Add" disabled={fields.target.visibility !== "edit"} onClick={handleAdd}></input>

                    <br></br>
                    <br></br>
                  </form>
              </div>

            {/*------------------------------------------------------------------ */}
            {/*   q4 - Direction *                                                */}
            {/*------------------------------------------------------------------ */}      

              <QuestionLabel question={formQuestions.q4.question} helpText = {formQuestions.q4.helpText} visibility={fields.direction.visibility}></QuestionLabel>
                
                <RadioButtons
                  //Radio Button Properties 
                  name={formQuestions.q4.name}
                  arrayOfButtons={directions}
                  value= {fields.direction.value}
                  visibility={fields.direction.visibility}

                  //Function Properties
                  onChange={handleChange}
                  >
                </RadioButtons>

              <ErrorMessageLabel errorMessage={errorState.directionErrorMessage} errorVisible={errorState.directionErrorVisible}></ErrorMessageLabel>

            {/*------------------------------------------------------------------ */}
            {/*   q5 - Action *                                                   */}
            {/*------------------------------------------------------------------ */}     
              
              <QuestionLabel question={formQuestions.q5.question} helpText = {formQuestions.q5.helpText} visibility={fields.action.visibility}></QuestionLabel>
                
                <RadioButtons
                    //Radio Button Properties 
                    name={formQuestions.q5.name}
                    arrayOfButtons={actions}
                    value= {fields.action.value}
                    visibility={fields.action.visibility}

                    //Function Properties
                    onChange={handleChange}
                    >
                </RadioButtons>

              <ErrorMessageLabel errorMessage={errorState.actionErrorMessage} errorVisible={errorState.actionErrorVisible}></ErrorMessageLabel>  


            {/*------------------------------------------------------------------ */}
            {/*   q6 - Source IP Range *                                          */}
            {/*------------------------------------------------------------------ */}
            
            <QuestionLabel question={filterState.filterType + " Filters *"} helpText = {filterState.filterHelpText}></QuestionLabel>

              <div hidden={filterState.hideSourceFilter}>
                <p style={{fontSize: "15px"}}>Please provide one or both {filterState.filterType.toLowerCase()} filters below.</p>
              </div>

              <QuestionLabel style={{fontSize: "15px", fontWeight: "normal"}} question={filterState.filterType + " IP Range"} helpText = {"Traffic is only allowed to " + filterState.filterType.toLowerCase() + "s within these IP address ranges. Use CIDR notation when entering ranges."}></QuestionLabel>
              
                <InputField
                  //Input Field Properties
                  name={formQuestions.q6.name}
                  placeholder={formQuestions.q6.placeholder}
                  value={selectedValue.ipRangeFilter}
                  className="mediuminputfield"
                  errorVisible={errorState.ipRangeFilterErrorVisible}
                  visibility={fields.ipRangeFilter.visibility}
                      
                  //Function Properties
                  onChange={handleIPChange}
                >
                </InputField>  
              
              <ErrorMessageLabel errorMessage={errorState.ipRangeFilterErrorMessage} errorVisible={errorState.ipRangeFilterErrorVisible}></ErrorMessageLabel>


            {/*------------------------------------------------------------------ */}
            {/*   q7 - Service Account Filter                                     */}
            {/*------------------------------------------------------------------ */}     
            
            {/* Service Account Filter */}
            <div className="items-sa-filters" hidden={filterState.hideSourceFilter}>

                <QuestionLabel style={{fontSize: "15px", fontWeight: "normal"}} question={"OR"}></QuestionLabel>
                <QuestionLabel style={{fontSize: "15px", fontWeight: "normal"}} question={"Source Service Account"} helpText = {formQuestions.q7.helpText}></QuestionLabel>
                
                {/* If items exist > render a list  */}
                {saFilters.length > 0 ? 
                  (
                    saFilters.map((item, index) => (
                      <ListItem
                          item={item.account}
                          index={index}
                          removeItem={removeSAFilter}
                          readdItem={reAddSAFilter}
                          type={item.type}
                          disabled={fields.serviceAccountFilter.visibility !== "edit"}
                      />
                    ))

                    
                  ) :
                  (
                    <p style={{fontSize: "14px", lineHeight: "1.6"}}><em>No service accounts filters attached.</em></p>
                  )
                }
            </div>

            {/* Add Service Account Form */}
            <div className="create-item-sa-filters" hidden={filterState.hideSourceFilter}>
                <form onSubmit={handleChange}>
                
                {/*Render Paragraph only if the visibility is set to edit */}
                { fields.serviceAccountFilter.visibility === "edit" && <p style={{fontSize: "15px", lineHeight: "1.6"}}>Enter a Service Account email address and click the '+ Add' button below.</p> }
              
                  <InlineInputField
                      //Inline Input Field Properties
                      name={formQuestions.q7.name}
                      placeholder={formQuestions.q7.placeholder}
                      value={selectedValue.serviceAccountFilter}
                      position = "right"
                      errorVisible={errorState.sourceFilterErrorVisible}
                      inputFieldLength={"longinputfield"}
                      visibility={fields.serviceAccountFilter.visibility}
                          
                      //Function Properties
                      onChange={handleChange}
                  >
                  </InlineInputField>

                  {/* Error Message - Invalid Service Account ID  */}
                  <ErrorMessageLabel errorMessage={errorState.sourceFilterErrorMessage} errorVisible={errorState.sourceFilterErrorVisible}></ErrorMessageLabel>

                  <br></br>

                  {/* Add Button - disabled if visibility props is not set to edit*/}
                  <input className="Firewall-Add-Account-Button" value="+ Add" disabled={fields.serviceAccountFilter.visibility !== "edit"} onClick={handleAddSAFilter}></input>

                  <br></br>
                  <br></br>
                </form>
            </div>


            {/*------------------------------------------------------------------ */}
            {/*   q8 & q9 - Protocols & Ports *                                   */}
            {/*------------------------------------------------------------------ */}     
              

              <QuestionLabel question={formQuestions.q8.question} helpText = {formQuestions.q8.helpText}></QuestionLabel>

                <InlineInputField
                    //Inline Input Field Properties
                    name={formQuestions.q8.name}
                    placeholder={formQuestions.q8.placeholder}
                    value={selectedValue.tcpPorts}
                    helpText = {formQuestions.q8.helpText}
                    inlinefieldlabel= "tcp:  "
                    position = "left"
                    errorVisible={errorState.tcpPortsErrorVisible}
                    inputFieldLength="shortinputfield"
                    visibility={fields.tcpPorts.visibility}
                        
                    //Function Properties
                    onChange={handleChange}
                >
                </InlineInputField>

                <ErrorMessageLabel errorMessage={errorState.tcpPortsErrorMessage} errorVisible={errorState.tcpPortsErrorVisible}></ErrorMessageLabel>
                <br></br>

                <InlineInputField
                    //Inline Input Field Properties
                    name={formQuestions.q9.name}
                    placeholder={formQuestions.q9.placeholder}
                    value={selectedValue.udpPorts}
                    helpText = {formQuestions.q9.helpText}
                    inlinefieldlabel= "udp: "
                    position = "left"
                    errorVisible={errorState.udpPortsErrorVisible}
                    inputFieldLength="shortinputfield"
                    visibility={fields.udpPorts.visibility}
                        
                    //Function Properties
                    onChange={handleChange}
                >
                </InlineInputField>

              <ErrorMessageLabel errorMessage={errorState.udpPortsErrorMessage} errorVisible={errorState.udpPortsErrorVisible}></ErrorMessageLabel>
              <ErrorMessageLabel errorMessage={errorState.portsErrorMessage} errorVisible={errorState.portsErrorVisible}></ErrorMessageLabel>


            {/*------------------------------------------------------------------ */}
            {/*   q10 - Priority *                                                */}
            {/*------------------------------------------------------------------ */}   


            <QuestionLabel question={formQuestions.q10.question} helpText = {formQuestions.q10.helpText}></QuestionLabel>
                
                <InputField
                  //Input Field Properties
                  name={formQuestions.q10.name}
                  placeholder={formQuestions.q10.placeholder}
                  value={selectedValue.priority}
                  className="mediuminputfield"
                  errorVisible={errorState.priorityErrorVisible}
                  visibility={fields.priority.visibility}
                      
                  //Function Properties
                  onChange={handleChange}
                >
                </InputField>

              <ErrorMessageLabel errorMessage={errorState.priorityErrorMessage} errorVisible={errorState.priorityErrorVisible}></ErrorMessageLabel>


            {/*------------------------------------------------------------------ */}
            {/*   Submit Button                                                   */}
            {/*------------------------------------------------------------------ */}   

              <input className="form-submit-button" type="submit" value="Submit"></input>
            
            {/*------------------------------------------------------------------ */}   

            </form>
          
          </div>
        }
        successContent={
          <div className='PageComponent-Errors-Container'>
            <img style={{margin: "20px 0px", width: "40%"}} src={workingCatGraphic} alt="working cat"/>
            <h2>Thanks for your request. We're working on it!</h2>
            <p>
              You have successfully submitted a firewall request for project {fields.projectId.value}.
            </p>
          </div>
        }
      ></PageComponent>
    )
}
