//================================================================
//  Edit Budget and Cost Form
//================================================================

//Libraries
import React, {useReducer, useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors, SetUser } from '../../../Library/GlobalContexts';

//Functions
import GetDocument from '../../../Library/GetDocument';
import WriteDocument from '../../../Library/WriteDocument';
import ReactBackend from '../../../Library/reactBackend';

import {
  isStringUnsignedCurrency,
  isString
} from '../../../Library/ValidateUserInput';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import FormResponse from '../../../Components/FormResponse/formresponse'
import QuestionLabel from '../../../Components/QuestionLabel'
import ErrorMessageLabel from '../../../Components/ErrorMessageLabel'
import InlineInputField from '../../../Components/InlineInputField/inlineinputfield'
import DataListField from '../../../Components/DataListField/datalistfield';
import ConfirmationModal from '../../../Components/ConfirmationModal/confirmationmodal'

//Images
import WarningIcon from '../../../Components/Images/Warning.png';

//CSS
import './editbudgetandcost.css'

export default function EditBudgetAndCost() {

  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setUser = useContext(SetUser);
    const setAppErrors = useContext(SetAppErrors);

    //React Router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to change between > 'accessdenied', 'onload', 'pending', 'success', 'error-invalid', 'error-fatal', 'error-other'
    const [formStatus, setFormStatus] = useState('onload');

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    const [initialProject, setInitialProject] = useState();

    //Used to determine if the modal is visible
    const [modalVisible, setModalVisible] = useState(false);

    //Hold list of all valid PTEs
    const [projectCodeList, setProjectCodeList] = useState();
    const [taskNumberList, setTaskNumberList] = useState([]);

    //Holds list of matching PTEs user can select from
    const [projectCodeOptions, setProjectCodeOptions] = useState([]);
    const [taskNumberOptions, setTaskNumberOptions] = useState([]);

    //Hold Oracleproject name for valid project code
    const [oracleProjectName, setOracleProjectName] = useState('');

    //Determines if the task number or expenditure type was automatically set
    const [taskNumberAutoSet, setTaskNumberAutoSet] = useState(false);
    const [expTypeAutoSet, setExpTypeAutoSet] = useState(false);

    //Do an initial check to start off with
    const [initialLoad, setInitialLoad] = useState(true);

    //Used to save the API response
    const [apiResponse, setAPIResponse] = useState({});

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------
  
    //Holds the form inputs
    const [selectedValue, setSelectedValue] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        budget:          '0',
        projectCode:     '0',
        taskNumber:      '',
        expenditureType: ''
      })

    //Indicates whether PTE values are valid or not
    const [validPteValues, setValidPteValues] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        projectCode: null,
        taskNumber: null,
        expenditureType: true // Always true until expenditure type validation is possible
      }
    );

    //Used to toggle error messages
    const [errorState, setErrorState] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        budgetErrorVisible: false,
        budgetErrorMessage: "",
        projectCodeErrorVisible: false,
        projectCodeErrorMessage: "",
        taskNumberErrorVisible: false,
        taskNumberErrorMessage: "",
        expenditureTypeErrorVisible: false,
        expenditureTypeErrorMessage: "",
        pteWarningVisible: false,
        pteWarningMessage: "",
        noChangeErrorVisible: false
      })

  //------------------------------------------------------
  //  Load list of project codes
  //------------------------------------------------------

  if(projectCodeList === undefined) {
    GetDocument('oracleProjects', '+projectCodes')
    .then((results) => {
      if(results?.projectCodes === undefined || !Array.isArray(results.projectCodes)) {
        setAppErrors(`Could not retrieve project codes from document 'oracleProjects/+projectCodes' in Firestore`)
        setFormStatus('error-fatal')
        return;
      }

      setProjectCodeList(results.projectCodes)

    }).catch((error) => {
      setAppErrors(`Could not retrieve project codes from document 'oracleProjects/+projectCodes' in Firestore. Error: ${error.message}`)
      setFormStatus('error-fatal')
      return;
    })
  }

  //------------------------------------------------------
  //  Define Functions
  //------------------------------------------------------

    //Used to reset the selected view and resource and return to projects page
    function returnToProjects(){

      getUser.preferences.globalSelector.selectedView = 'organization';
      getUser.preferences.globalSelector.selectedResource = 'lendlease.cloud';

      // Update the global context
      setUser({...getUser});

      // Navigate to the correct page
      navigate('/projects');

    }

    // No valid task number yet, so clear everything related to a task number
    function clearTaskNumberInfo() {
      setValidPteValues({ taskNumber: false });
      setTaskNumberOptions([]);
    }

    // No valid project code yet, so clear everything related to a project code
    function clearProjectCodeInfo() {
      setValidPteValues({ projectCode: false });
      setTaskNumberList([]);
      setProjectCodeOptions([]);
      setOracleProjectName('');
      setTaskNumberOptions([]);

      // On initial load, need to leave taskNumber as undefined
      // As we want to delay PTE validation warnings until all checks are complete
      if (!initialLoad) {
        setValidPteValues({ taskNumber: false });
      }
    }

    // Function to find matching project codes
    // If exact match, get info and task numbers
    function matchProjectCodes(input) {
      // Get list of matching project codes and show in project code datalist dropdown
      const searchResult = projectCodeList.filter(x => x.startsWith(input))
      setProjectCodeOptions(searchResult)

      // Exact match
      if(searchResult.length === 1 && searchResult[0] === input) {        
        // Get document for project code from oracleProjects collection
        GetDocument('oracleProjects', input)
        .then((results) => {

          // Project code has document, so accept as valid project code
          setValidPteValues({ projectCode: true })

          // Missing/invalid task number list, so cannot validate
          if(results?.taskNumbers === undefined || results.taskNumbers.constructor.name !== 'Object') {
            setTaskNumberList([]);
            clearTaskNumberInfo();
            return;
          }
          
          // Show the complete list of task numbers in the task number datalist dropdown
          setTaskNumberList(results.taskNumbers)

          // Set the project name if we have it
          if(results.projectName !== undefined) {
            setOracleProjectName(results.projectName)
          }
    
        // Something went wrong with this project code info, so pretend it's not valid
        }).catch((error) => {
          clearProjectCodeInfo()
          return;
        })

      // Haven't pinpointed on exact project code yet
      } else {
        setValidPteValues({ projectCode: false }) 

        // On initial load, need to leave taskNumber as undefined
        // As we want to delay PTE validation warnings until all checks are complete
        if (!initialLoad) {
          setValidPteValues({ taskNumber: false });
        }

        setTaskNumberList([])
        setTaskNumberOptions([])
        setOracleProjectName('')
      }

    }

    // Function to find matching task numbers
    function matchTaskNumbers(input) {

      // We don't have a task number list, so we cannot search for it
      if(taskNumberList.length <= 0) {
        clearTaskNumberInfo();
        return;
      }

      // No value entered for task number, so show all valid task numbers
      if(input.length <= 0) {
        setTaskNumberOptions(Object.keys(taskNumberList))
        setValidPteValues({ taskNumber: false })
        return;
      }

      // Search for task number
      const searchResult = Object.keys(taskNumberList).filter(x => x.startsWith(input))
      setTaskNumberOptions(searchResult)

      // Exact match
      if(searchResult.length === 1 && searchResult[0] === input) {
        setValidPteValues({ taskNumber: true })

        // If no expenditure type provided by user and task number is 11050
        // set expenditure type to 'ICT Costs' automatically
        if(selectedValue.expenditureType.length <= 0 && input === '11050') {
          setSelectedValue({ expenditureType: 'ICT Costs' })
          ValidateExpenditureType('ICT Costs', false, true) // formSubmit = false, autoset = true
        }      

      // Multiple matches
      } else {
        setValidPteValues({ taskNumber: false })
      }
    }

  //------------------------------------------------------
  //  Define Functions > Field Validation (Regex)
  //  * - Paste Regex in 'https://regexr.com/' for explanation
  //------------------------------------------------------
  
    //Function to validate the budget
    function ValidateBudget(input) {  

      //If budget field empty > Show error
      if(input.length <= 0){
        setErrorState({ budgetErrorVisible: true })
        setErrorState({ budgetErrorMessage: "Budget cannot be blank."})
        return false
      }

      //If budget not float with 2 decimal places > Show error
      else {

        if (isStringUnsignedCurrency(input)) {
          setErrorState({ budgetErrorVisible: false })
          return true

        } else {
          setErrorState({ budgetErrorVisible: true })
          setErrorState({ budgetErrorMessage: "Only numbers are allowed, 0 to 9 (cents optional, eg. 150.00)"})
          return false

        }
      }
    } 

    //Function to validate the project code
    function ValidateProjectCode(input, formSubmit) {
      //If new data, clear PTE warning
      if (!formSubmit) {
        setErrorState({ pteWarningVisible: false });

        // Any change to project code will clear the auto set task number and expenditure type
        if(taskNumberAutoSet) {
          setTaskNumberAutoSet(false)
          setSelectedValue({ taskNumber: '' })
          setErrorState({ taskNumberErrorVisible: true })
          setErrorState({ taskNumberErrorMessage: "Task Number cannot be blank."})
        }

        if(expTypeAutoSet) {
          setExpTypeAutoSet(false)
          setSelectedValue({ expenditureType: '' })
          setErrorState({ expenditureTypeErrorVisible: true });
          setErrorState({ expenditureTypeErrorMessage: "Expenditure type cannot be blank."})
        }
      }

      //If project code empty > Show error and clear info derived from the project code
      if(input.length <= 0){
        setErrorState({ projectCodeErrorVisible: true })
        setErrorState({ projectCodeErrorMessage: "Project Code cannot be blank."})
        clearProjectCodeInfo();
        return false

      //If project code is not number> Show error and clear info derived from the project code
      } else {

        if (/^\d+$/.test(input)) {
          setErrorState({ projectCodeErrorVisible: false })

          //Don't process on valid form submission
          if (!formSubmit) {
            //Only bother processing if there's at least three characters
            if(input.length >= 3) {
              matchProjectCodes(input)

            //Definitely no matching project code, so clear info derived from the project code
            }else{
              clearProjectCodeInfo();
            }
          }
  
          return true

        } else {
          setErrorState({ projectCodeErrorVisible: true })
          setErrorState({ projectCodeErrorMessage: "Only numbers are allowed, 0 to 9"})
          clearProjectCodeInfo();
          return false

        }
      }
    } 

    //Function to validate the task number
    function ValidateTaskNumber(input, formSubmit, autoSet) {
      //If new data, clear PTE warning
      if (!formSubmit) {
        setErrorState({ pteWarningVisible: false });

        // Any change to task number will clear the auto set expenditure type
        if(expTypeAutoSet) {
          setExpTypeAutoSet(false)
          setSelectedValue({ expenditureType: '' })
          setErrorState({ expenditureTypeErrorVisible: true });
          setErrorState({ expenditureTypeErrorMessage: "Expenditure type cannot be blank."})
        }

        // Indicates whether the field was changed automatically or by the user
        setTaskNumberAutoSet(autoSet)
      }

      //If task number empty > Show error and show all valid task numbers
      if(input.length <= 0){
        setErrorState({ taskNumberErrorVisible: true })
        setErrorState({ taskNumberErrorMessage: "Task Number cannot be blank."})
        matchTaskNumbers(input)
        return false

      //If task number is not valid > Show error and clear valid task number
      } else {

        if (/^[a-zA-Z0-9.]+$/.test(input)) {
          setErrorState({ taskNumberErrorVisible: false })

          //Task number checks don't apply on valid form submission
          if(!formSubmit) {
            matchTaskNumbers(input)
          }

          return true

        } else {
          setErrorState({ taskNumberErrorVisible: true })
          setErrorState({ taskNumberErrorMessage: "Please enter a valid Oracle task number, e.g. 1000.1000. Numbers and dots allowed."})
          clearTaskNumberInfo();
          return false

        }
      }
    }
      
    //Function to validate the expenditure type
    function ValidateExpenditureType(input, formSubmit, autoSet) {
      //If new data, clear PTE warning
      if (!formSubmit) {
        setErrorState({ pteWarningVisible: false });

        // Indicates whether the field was changed automatically or by the user
        setExpTypeAutoSet(autoSet)
      }

      //If expenditure type is empty > Show error
      if(input.length <= 0){
        setErrorState({ expenditureTypeErrorVisible: true });
        setErrorState({ expenditureTypeErrorMessage: "Expenditure type cannot be blank."})
        return false

      } else {
        //Check expenditure type is a valid field
        if (isString(input)) {
          setErrorState({ expenditureTypeErrorVisible: false })
          return true

        } else {
          setErrorState({ expenditureTypeErrorVisible: true })
          setErrorState({ expenditureTypeErrorMessage: "Please enter a valid Oracle expenditure type"})
          return false
        }
      }
    }

    // Checks for PTE errors and show warnings
    function pteValidation() {
      // Don't bother showing PTE warning if the fields haven't been filled out yet
      if (selectedValue.projectCode.length <= 0 || selectedValue.taskNumber.length <= 0 || selectedValue.expenditureType.length <= 0) return

      // Don't bother showing PTE warning if there are worse things going on
      if (errorState.projectCodeErrorVisible || errorState.taskNumberErrorVisible || errorState.expenditureTypeErrorVisible) return
    
      // Invalid project code
      if (!(validPteValues.projectCode)) {

        setErrorState({
          pteWarningMessage: `Unknown Oracle Project Code '${selectedValue.projectCode}' provided. You may proceed to submit however we recommended checking that your Oracle Project Code is correct.`,
          pteWarningVisible: true
        });

      // Invalid task number
      } else if (!(validPteValues.taskNumber)) {

        setErrorState({
          pteWarningMessage: `Unknown Task Number '${selectedValue.taskNumber}' provided. You may proceed to submit however we recommended checking that your Oracle Task Number is correct.`,
          pteWarningVisible: true
        });

      // Invalid expenditure type
      } else if (!(validPteValues.expenditureType)) {

        setErrorState({
          pteWarningMessage: `Unknown Expenditure Type '${selectedValue.expenditureType}' provided. You may proceed to submit however we recommended checking that your Oracle Expenditure Type is correct.`,
          pteWarningVisible: true
        });

      }
    }

  //------------------------------------------------------
  //  Event Handler > Field Changes
  //------------------------------------------------------

    //Event Handler for field changes 
    const handleChange = evt => {

      const name = evt.target.name;
      const newValue = evt.target.value;

      //Validate field - budget
      if(name === 'budget'){
        ValidateBudget(newValue)
      }

      //Validate field - projectCode
      if(name === 'projectCode'){
        ValidateProjectCode(newValue, false) //formSubmit = false
      }

      //Validate field - taskNumber
      if(name === 'taskNumber'){
        ValidateTaskNumber(newValue, false, false) // formSubmit = false, autoSet = false
      }

      //Validate field - expenditureType
      if(name === 'expenditureType'){
        ValidateExpenditureType(newValue, false, false) // formSubmit = false, autoSet = false
      }

      //Set selected value to field values
      setSelectedValue({ [name]: newValue })
      setErrorState({ noChangeErrorVisible: false })

    }
  
  //------------------------------------------------------
  //  Event Handler > On Exit Component (onBlur)
  //------------------------------------------------------
  
    //Event Handler for field changes 
    const handleBlur = evt => {

      const name = evt.target.name;
      
      // Process if PTE details have changed
      if (name === 'projectCode' || name === 'taskNumber' || name === 'expenditureType') {
        pteValidation()
      }
    }

  //------------------------------------------------------
  //  Define Functions > callAPI & HandleResponse
  //------------------------------------------------------

    //Used to call "submitProjectBudgetRequest"
    async function callAPI(){
      
      // API Call to update budget in GCP
      var submitProjectRequestBody = {
        "projectId": initialProject.projectid,
        "budget": parseFloat(selectedValue.budget)
      }
      
      //Call React Backend - Submit Form Response
      var response = await ReactBackend('submitProjectBudgetRequest', submitProjectRequestBody)
    
      //Set API Response
      setAPIResponse(response)

      //Switch on formStatus for both function responses
      if(response.status === 'success'){

        // If API call successful, update budget and PTE in Firestore
        const projectDocument = {
          "budget": parseFloat(selectedValue?.budget),
          "projectcode": selectedValue?.projectCode,
          "tasknumber": selectedValue?.taskNumber,
          "expendituretype": selectedValue?.expenditureType
        }

        // Update document in Firestore
        WriteDocument('projects', selectedValue?.projectid, projectDocument, true)
        .then((results) =>{
          setFormStatus('success');
          
        })
        .catch((error) =>{
          setAppErrors(`Failed to update budget and cost in project collection. Error: ${error}`);
          setFormStatus('error-fatal');
        })

      } else if(response.status === 'error-fatal'){
            
        //Set formStatus to 'error-fatal'
        setFormStatus('error-fatal')

      } else if(response.status === 'error-invalid'){

        //Set formStatus to 'error-invalid'
        setFormStatus('error-invalid')

      } else {
            
        //Set formStatus to 'error-timeout'
        setFormStatus('error-timeout')

      }

    }

  //------------------------------------------------------
  //  Event Handler > Parent Form Submission
  //------------------------------------------------------

    //Event handler when form is submitted > validate all fields
    function eventHandlerOnSubmit() {

      if(initialProject?.environment === "sand") {
        setAppErrors(`Budget/PTE change submitted for sandbox ${document?.projectid}`)
        setFormStatus('error-fatal')
      }

      //Check if changes made to the form > Show error
      if(selectedValue?.budget === initialProject?.budget &&
        selectedValue?.projectCode === initialProject?.projectCode &&
        selectedValue?.taskNumber === initialProject?.taskNumber &&
        selectedValue?.expenditureType === initialProject?.expenditureType) 
      {

        setErrorState({ noChangeErrorVisible: true })

      // Proceed only if all fields validate OK
      } else if (
        ValidateBudget(selectedValue?.budget) && 
        ValidateProjectCode(selectedValue?.projectCode, true) && // formSubmit = true
        ValidateTaskNumber(selectedValue?.taskNumber, true) && // formSubmit = true, autoSet = (not used)
        ValidateExpenditureType(selectedValue?.expenditureType, true)) // formSubmit = true, autoSet = (not used)
      {
      
        //First submit (ie. no confirmation modal yet), show modal if PTE has changed (not just parseFloat(selectedValue.budget))
        if(!modalVisible && 
          (parseInt(selectedValue?.projectCode) !== initialProject?.projectCode ||
          selectedValue?.taskNumber !== initialProject?.taskNumber ||
          selectedValue?.expenditureType !== initialProject?.expenditureType)) 
          {

          //Show Modal
          setModalVisible(true)
        
        //'Continue' clicked on modal, or just a budget change
        } else {

          //Hide Modal
          setModalVisible(false)

          // Wait for API and submit form
          setFormStatus('pending')
          callAPI()
        }
      }
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------
  
    useEffect(() => {

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);
      
      //Get project information > Pre-fill the form
      GetDocument("projects", getUser?.preferences?.globalSelector?.selectedResource)
      .then((document) =>{

        const projectObject = {
          'projectid': document?.projectid,
          'budget': String(document?.budget),
          'taskNumber': document?.tasknumber,
          'expenditureType': document?.expendituretype,
          'projectCode': document?.projectcode,
          'environment': document?.environment
        }

        //Set Initial Project
        setInitialProject(projectObject)

        //Set Form Inputs
        setSelectedValue(projectObject)

      })
      .catch((error) =>{
        setAppErrors(`Failed to get the project document, error ${error}`);
        setFormStatus('error-invalid');

      });

    // eslint-disable-next-line
    },[getUser])

    // Perform first time validations
    useEffect(() => {

      // Haven't initialised yet, so no point validating project code
      if (initialProject === undefined) return;
      if (initialProject.projectCode === undefined) return;

      // Only run on initial load of PTE data
      if (initialLoad) {
  
        // Haven't kicked off any checks yet, so let's do that
        if (validPteValues.projectCode === null) {
          ValidateProjectCode(initialProject.projectCode, false) // formSubmit = false
        }
        

        // Initial PTE checks are done, so let's show warnings if there is a problem
        if (validPteValues.projectCode !== null && validPteValues.taskNumber !== null) {
          pteValidation();
          setInitialLoad(false)
        }
      }

      // Task number will be validated automatically if project code is valid

    // eslint-disable-next-line
    }, [initialProject, validPteValues]);

    // When the task numbers for a project code are made available
    useEffect(() => {

      // Haven't initialised yet, so no point validating task number
      if (initialProject === undefined) return;
      if (initialProject.taskNumber === undefined) return;
      if (validPteValues.projectCode === null) return;
      

      // If no task number provided by user and 11050 is a valid task number for this project
      // set it automatically
      if(selectedValue.taskNumber.length <= 0 && Object.keys(taskNumberList).includes('11050')) {
        setSelectedValue({ taskNumber: '11050' })
        ValidateTaskNumber('11050', false, true) // formSubmit = false, autoSet = true
      
      //Re-check validity of whatever task is there
      } else {
        ValidateTaskNumber(selectedValue.taskNumber, false, false) // formSubmit = false, autoSet = false
      }

    // eslint-disable-next-line
    }, [taskNumberList]);
    
    

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  return (
    <PageComponent
      requireSelectedResource={true}
      requireSelectedViews={['project']}
      requiredRoles={['projectOwner', 'projectOperator']}
      requiredEnvironments={['Standard']}
      breadcrumb={{
        "name": "Projects", 
        "route": "/projects",
        "view": "organization",
        "resource": "lendlease.cloud"
      }}
      header={
        <PageHeader
          title={"Edit Budget and Cost Centre"}
          body={
            <p style={{margin: "0px"}}>
              Use this page to amend the budget and cost centre details for project <b>{selectedValue.projectid}</b>.
              <br></br>
              <b>Note:</b> Cost centre changes made before 5th of the month will take effect in <u>this</u> billing cycle. 
              Cost centre changes made after 6th of the month will take effect in <u>next</u> months billing cycle.
            </p>   
          }
        ></PageHeader>
      }
      body={
        <FormResponse
          requestType={"onload"}
          setRequestType={setFormStatus}
          requestTypeDisplayname={"Return to Projects"}
          formStatus={formStatus}
          setFormStatus={setFormStatus}
          initialState={"onload"}
          apiResponse={apiResponse}
          workflowType={"http"}
          formOnload={[
            <div className="Form-Container"> 

              <div className="Form-Body" onSubmit={eventHandlerOnSubmit}>

                {/*------------------------------------------------------------------ */}
                {/*   Budget                                                          */}
                {/*------------------------------------------------------------------ */}
                

                <QuestionLabel question={"Monthly Budget"} helpText = {"You will receive a budget alert when you reach 50%, 75% and 100% of your actual spend. Your project will still continue to operate as normal after reaching the 100% threshold. Refer to the FAQ's for more info"}></QuestionLabel>
                
                <InlineInputField
                  name={"budget"}
                  placeholder={initialProject?.budget}
                  value={selectedValue.budget}
                  position = "middle"
                  inlinefieldlabelEnd = "(USD)"
                  errorVisible={errorState.budgetErrorVisible}
                  inputFieldLength="shortinputfield"
                  onChange={handleChange}
                >
                </InlineInputField>
                
                <ErrorMessageLabel errorMessage={errorState.budgetErrorMessage} errorVisible={errorState.budgetErrorVisible}></ErrorMessageLabel>


                {/*------------------------------------------------------------------ */}
                {/*   Project Code                                                    */}
                {/*------------------------------------------------------------------ */}


                <QuestionLabel question={"Oracle Project Code"} helpText = {"Lendlease Project Code"}></QuestionLabel>
                    
                <DataListField
                  name={"projectCode"}
                  placeholder={initialProject?.projectCode}
                  value={selectedValue?.projectCode}
                  position = "right"
                  errorVisible={errorState.projectCodeErrorVisible}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={projectCodeOptions}
                  >
                </DataListField>

                <ErrorMessageLabel errorMessage={errorState.projectCodeErrorMessage} errorVisible={errorState.projectCodeErrorVisible}></ErrorMessageLabel>

                { /* Show Oracle project name if we have a valid project code */
                  oracleProjectName.length > 0 ? (
                    <div className='Project-Edit-Budget-response-label'>{oracleProjectName}</div>
                  ) : (null)
                }


                {/*------------------------------------------------------------------ */}
                {/*   Task Number                                                     */}
                {/*------------------------------------------------------------------ */}


                <QuestionLabel question={"Oracle Task Number"} helpText = {"Lendlease Task Number, e.g. 2000.11050"}></QuestionLabel>

                <DataListField
                  name={"taskNumber"}
                  placeholder={initialProject?.taskNumber}
                  value={selectedValue?.taskNumber}
                  position = "right"
                  errorVisible={errorState.taskNumberErrorVisible}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={taskNumberOptions}
                  >
                </DataListField>

                <ErrorMessageLabel errorMessage={errorState.taskNumberErrorMessage} errorVisible={errorState.taskNumberErrorVisible}></ErrorMessageLabel>


                {/*------------------------------------------------------------------ */}
                {/*   Expenditure Type                                                */}
                {/*------------------------------------------------------------------ */}

                <QuestionLabel question={"Oracle Expenditure Type"} helpText = {"Where the project costs will be allocated, for example 'ICT Costs'."}></QuestionLabel>

                <DataListField
                  name={"expenditureType"}
                  placeholder={initialProject?.expenditureType}
                  value={selectedValue?.expenditureType}
                  position = "right"
                  errorVisible={errorState.expenditureTypeErrorVisible}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={[]}
                  >
                </DataListField>

                <ErrorMessageLabel errorMessage={errorState.expenditureTypeErrorMessage} errorVisible={errorState.expenditureTypeErrorVisible}></ErrorMessageLabel>


                {/*------------------------------------------------------------------ */}
                {/*   Invalid PTE label (if required)                                 */}
                {/*------------------------------------------------------------------ */}

                {
                    errorState.pteWarningVisible ? (
                      <div className='PageComponent-Label'>
                        <div className='PageComponent-LabelMessage'>
                          <img style={{marginRight: "15px"}} src={WarningIcon} alt="Warning Icon"></img>
                          <p>
                            {errorState.pteWarningMessage}
                          </p>
                        </div>
                      </div>
  
                    ) : (null)
                  }

                {/*------------------------------------------------------------------ */}
                {/*   Submit Button                                                   */}
                {/*------------------------------------------------------------------ */}   

                <div className="Button-Group">
                  <button className="Primary-Button" onClick={()=>eventHandlerOnSubmit()}>Submit</button>
                  <button className='Secondary-Button' onClick={() => returnToProjects()}>Cancel</button>
                </div>

                <ErrorMessageLabel errorMessage="No changes made. Please change the above values before clicking Submit." errorVisible={errorState.noChangeErrorVisible}></ErrorMessageLabel>

              </div>

              {/*------------------------------------------------------------------ */}
              {/*   Confirmation Modal                                              */}
              {/*------------------------------------------------------------------ */}   
              
              <ConfirmationModal
                header="Do you wish to continue?"
                body={[
                  <div>
                    <p>You are making cost centre changes for project <b>{selectedValue.projectid}</b>.</p>
                    {
                      errorState.pteWarningVisible ? (
                        <p>{errorState.pteWarningMessage}</p>
                      ) : (null)
                    } 
                    <p>Please note, cost centre changes made before 5th of the month will take effect in <i>this</i> billing cycle. Cost centre changes made after 6th of the month will take effect in <i>next</i> months billing cycle.</p>
                    <p>If you would like to go back without saving your changes, click <b>Cancel</b>.</p>
                  </div>
                ]}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                eventHandlerOnSubmit={eventHandlerOnSubmit}
              ></ConfirmationModal>
              
            </div>
          ]}
          formSuccessDescription={[
            <p style={{lineHeight: "1.7"}}>
              Your request to edit the budget and cost of project <b>{getUser?.preferences?.globalSelector?.selectedResource}</b> is complete.
            </p>
          ]}
        ></FormResponse>
      }
    ></PageComponent>
  )
}
