//================================================================
//  Firewall Page
//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GetDocument from '../../Library/GetDocument';
import QueryDocument from '../../Library/QueryDocument';
import ReactBackend from '../../Library/reactBackend';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import VideoPlayer from '../../Components/VideoPlayer/videoPlayer';
import DocumentLink from '../../Components/KnowledgeHubComponents/documentlink';
import RequestSummary from '../../Components/RequestSummary/requestsummary';
import ConfirmationModal from '../../Components/ConfirmationModal/confirmationmodal';
import FirewallTableRow from './FirewallComponents/FirewallTableRow';

//Images
import InfoIcon from '../../Components/Images/Icon_Info_Black.svg';

//CSS
import './Firewall.css';


export default function Firewall() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

    //React router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'success', 'error-invalid', 'error-fatal', 'error-timeout' & 'error-other'
    const [pageStatus, setPageStatus] = useState();

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    // IaC service account
    const [firewallServiceAccount, setFirewallServiceAccount] = useState('');

    // List of firewall rules for project
    const [firewallRules, setFirewallRules] = useState([]);

    // Whether all firewall rule table rows should be expanded or collapsed
    const [allRowsExpanded, setAllRowsExpanded] = useState(false);

    // Status and API response for the firewall jobs table
    const [firewallJobsStatus, setFirewallJobsStatus] = useState('onload');
    const [firewallRequestResponse, setFirewallRequestResponse] = useState([]);

    //Used to determine if the delete confirmation modal is visible
    const [modalVisible, setModalVisible] = useState(false);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Retrieve firewall management type and service account from project Firestore document
    // Might not exist for old projects, so defaults to 'portal'
    const getFirewallManagementTypeAPI = async () => {

      var managementType = 'portal'

      GetDocument('projects', getUser?.preferences?.globalSelector?.selectedResource)
      .then((results) =>{
        
        if(results?.firewallmanagementtype !== undefined) {
          managementType = results.firewallmanagementtype;
        }

        if (!('firewall' in getUser.preferences)) {
          getUser.preferences.firewall = {}
        }

        getUser.preferences.firewall = {
          'managementType': managementType
        }

        if (results?.firewallusermanagedsa !== undefined) {
          setFirewallServiceAccount(results.firewallusermanagedsa)
        } else {
          setFirewallServiceAccount('')
        }

      }).catch((error) => {

        setAppErrors(`Could not retrieve management type from project document '${getUser?.preferences?.globalSelector?.selectedResource}' in Firestore. Error: ${error.message}`)
        setPageStatus('error-invalid')
        return;

      })

    }

    //------------------------------------------------------

    // Retrieve firewall rules for the project
    const getFirewallRulesAPI = async () =>  {

      let extractedRules = []

      QueryDocument('firewall', [["projectid", "==", getUser?.preferences?.globalSelector?.selectedResource]]).then((rules) =>{
        
        for (const rule of rules) {
          const ruleStruct = {
            'projectId': rule.projectid === undefined ? '-' : rule.projectid,
            'creator': rule.creator === undefined ? '-' : rule.creator,
            'requestType': rule.requesttype === undefined ? '-' : rule.requesttype,
            'name': rule.name === undefined ? '-' : rule.name,
            'description': rule.description === undefined ? '-' : rule.description,
            'target': rule.target === undefined ? '-' : rule.target,
            'action': rule.action === undefined ? '-' : rule.action,
            'direction': rule.type === undefined ? '-' : rule.type,
            'serviceAccountFilter': rule.serviceaccountfilter === undefined ? '-' : rule.serviceaccountfilter,
            'ipRangeFilter': rule.iprangefilter === undefined ? '-' : rule.iprangefilter,
            'tcpPorts': rule.tcpports === undefined ? '-' : rule.tcpports,
            'udpPorts': rule.udpports === undefined ? '-' : rule.udpports,
            'priority': rule.priority === undefined ? '-' : rule.priority,
            'transactionId': rule.transactionid === undefined ? '-' : rule.transactionid,
            'givenname': rule.creatorgivenname === undefined ? '-' : rule.creatorgivenname,
            'surname': rule.creatorsurname === undefined ? '-' : rule.creatorsurname,
            'buildId': rule.buildid === undefined ? '-' : rule.buildid,
            'status': rule.status === undefined ? '-' : rule.status
          }

          extractedRules.push(ruleStruct)
        }

        setFirewallRules(extractedRules)

      }).catch((error) => {
        setAppErrors(`Could not retrieve firewall rules from firewall collection in Firestore. Error: ${error.message}`)
        setPageStatus('error-invalid')
        return;

      })

    }

    //------------------------------------------------------

    // Retrieve previous firewall requests for this project
    const getFirewallRequestsAPI = async () => {
      
      var requestBody = {
        "requestType": "project",
        "projectId": getUser?.preferences?.globalSelector?.selectedResource,
        "jobName": ["submitChangeFirewallManagementTypeRequest", "submitFireWallRuleRequest", "firewall-builder-api" ]
      }
    
      setFirewallJobsStatus('pending')

      //Call React Backend - Submit Form Response
      var response = await ReactBackend('getJobs', requestBody)

      //Set API Response
      setFirewallRequestResponse(response.responseBody.message)
  
      //Switch on status
      if(response.status === 'success'){
            
        //Set status to 'success'
        setFirewallJobsStatus('success')
  
      } else if(response.status === 'error-invalid'){
  
        //Set status to 'error-invalid'
        setAppErrors(response?.responseBody?.message)
        setFirewallJobsStatus('error-invalid')
  
      } else if(response.status === 'error-fatal'){
          
        //Set status to 'error-fatal'
        setFirewallJobsStatus('error-fatal')
  
      } else if(response.status === 'error-timeout'){
          
        //Set status to 'error-timeout'
        setFirewallJobsStatus('error-timeout')
  
      }
  
      return response
  
    }

  //------------------------------------------------------
  //  Event Handler > Delete Confirmation
  //------------------------------------------------------

    //Event handler when form is submitted > validate all fields
    const eventHandlerOnSubmit = e => {
      e.preventDefault();
      if (e.keyCode === 13){
        return;
      }
      setModalVisible(false);
      navigate('/firewall/rule');
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // When selected project changes > load the page and collapse all firewall rule rows
    useEffect(() => {

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView !== 'project') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible === true) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      // Only act if project has changed
      setAllRowsExpanded(false);

      //Firebase/API Calls
      getFirewallManagementTypeAPI()
      getFirewallRulesAPI()
      getFirewallRequestsAPI()

    // eslint-disable-next-line
    }, [getUser]);
    
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    // Don't load the page yet if we haven't retrieved the management type
    return (
      <PageComponent
        requireSelectedViews={['project']}
        requireSelectedResource={true}
        requiredRoles={['projectOwner', 'projectOperator']}
        requiredEnvironments={['Standard']}
        status={pageStatus}
        header={
          <PageHeader
            title={'Firewall'}
            modaltitle={'How to Manage Your Firewall Rules'}
            modalbody={
              <div>
                <div className='modal-description' style={{lineHeight: "1.5"}}>
                  <br></br>
                  For more information, refer to <DocumentLink docId="1119551520" title="Firewall Services - How to Get Started"></DocumentLink>.
                </div>
                <VideoPlayer
                  item="Video_Firewall_22_12_2021.mp4"
                  modalVisible={modalVisible}
                ></VideoPlayer>
              </div>
            }
            body={
              <div>
                Firewall rules control incoming or outgoing traffic to an instance. 
                By default, incoming traffic from outside your network is blocked.
              </div>
            }
          ></PageHeader>
        }
        body={
          <div>

            {/*------------------------------------------------------------------ */}
            {/*   Choose How You Manage Your Firewall Rules                       */}
            {/*------------------------------------------------------------------ */}  

            <div className="Table-Pane">

              <h2 className="Page-Subheading">Choose how you manage your firewall rules</h2>

              { getUser.preferences?.firewall?.managementType === 'portal' ? 
                //Firewall rules managed in protal
                (
                  <p style={{lineHeight: "1.7"}}> 
                    You are currently managing your firewall rules here via the portal. 
                    <br></br>
                    To manage your firewall rules via IaC (Infrastructure as Code), click the button below.
                  </p>

                // Firewall rules managed via IaC
                ) : (
                  <p style={{lineHeight: "1.7"}}> 
                    You are managing your firewall rules using IaC (Infrastructure as Code). 
                    <br></br>
                    You are currently using the service account, '<em>{firewallServiceAccount}</em>' to manage your firewall rules.
                    <br></br>
                    If you wish to manage your rules using the portal, click the button below.
                  </p>
                )
              }

              {/* Change How You Manage Your Firewall Rules */}
              <button className="Primary-Button" style={{margin: "5px 0px"}} onClick={e => {
                navigate('/firewall/management')
              }}> Change How You Manage Firewall Rules </button>

            </div>

            {/*------------------------------------------------------------------ */}
            {/*   Active Firewall Rules                                           */}
            {/*------------------------------------------------------------------ */}  

            <div className='Table-Pane' style={{marginTop: "40px"}}>
              
              {/* Header */}
              <div className='Firewall-Active-Rules-Header'>
                <div className='Firewall-Active-Rules-Header-Text'>
                  <h2 className="Page-Subheading">Active Firewall Rules</h2>
                </div>
                <div className='Firewall-Active-Rules-Header-Buttons'>
                  <button className="Primary-Button" disabled={firewallRules.length === 0} onClick={()=> {
                    setAllRowsExpanded(true)
                  }}>Expand all</button>
                  
                  <button className="Primary-Button" disabled={firewallRules.length === 0} onClick={()=> {
                    setAllRowsExpanded(false)
                  }}>Collapse all</button>
                  
                  <button className="Primary-Button" disabled={getUser.preferences?.firewall?.managementType !== 'portal'} onClick={()=> {
                    getUser.preferences.firewall = {
                      'action': 'create',
                      'fields': {
                        "projectId":{
                          "visibility": "read",
                          "value": getUser?.preferences?.globalSelector?.selectedResource
                        },
                        "ruleName":{
                          "visibility": "edit",
                          "value": ""
                        },
                        "description":{
                          "visibility": "edit",
                          "value": ""
                        },
                        "target":{
                          "visibility": "edit",
                          "value": ""
                        },
                        "direction":{
                          "visibility": "edit",
                          "value": ""
                        },
                        "action":{
                          "visibility": "edit",
                          "value": ""
                        },
                        "ipRangeFilter":{
                          "visibility": "edit",
                          "value": ""
                        },
                        "serviceAccountFilter":{
                          "visibility": "edit",
                          "value": ""
                        },
                        "tcpPorts":{
                          "visibility": "edit",
                          "value": ""
                        },
                        "udpPorts":{
                          "visibility": "edit",
                          "value": ""
                        },
                        "priority":{
                          "visibility": "edit",
                          "value": ""
                        }
                      }
                    }
                    navigate('/firewall/rule');
                  }}>Create a firewall rule</button>
                </div>
              </div>

              {
                firewallRules.length === 0 ? (
                  <div className='PageComponent-Label'>
                    <div className='PageComponent-LabelMessage'>
                      <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
                      <p>
                        No firewall rules found.
                      </p>
                    </div>
                  </div>
                 ) : (
                  getUser.preferences?.firewall?.managementType === 'portal' ? (
                    /* Table with edit and delete buttons */
                    <table className='Data-Table-Container'>
                      <colgroup>
                        <col span="1" style={{width: "5%"}}></col>
                        <col span="1" style={{width: "5%"}}></col>
                        <col span="1" style={{width: "40%"}}></col>
                        <col span="1" style={{width: "35%"}}></col>
                        <col span="1" style={{width: "5%"}}></col>
                        <col span="1" style={{width: "5%"}}></col>
                        <col span="1" style={{width: "5%"}}></col>
                      </colgroup>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Action</th>
                          <th>Direction</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          firewallRules.map((row, index) =>(
                            <FirewallTableRow
                              rowData={row}
                              allRowsExpanded={allRowsExpanded}
                              setAllRowsExpanded={setAllRowsExpanded}
                              managementType={getUser.preferences?.firewall?.managementType}
                              setModalVisible={setModalVisible}
                            ></FirewallTableRow>
                          ))
                        }
                      </tbody>
                    </table>
                  ) : (
                  /* Table without edit and delete buttons */
                    <table className='Data-Table-Container'>
                      <colgroup>
                        <col span="1" style={{width: "40%"}}></col>
                        <col span="1" style={{width: "35%"}}></col>
                        <col span="1" style={{width: "5%"}}></col>
                        <col span="1" style={{width: "5%"}}></col>
                        <col span="1" style={{width: "5%"}}></col>
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Action</th>
                          <th>Direction</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          firewallRules.map((row) =>(
                            <FirewallTableRow
                              rowData={row}
                              allRowsExpanded={allRowsExpanded}
                              setAllRowsExpanded={setAllRowsExpanded}
                              managementType={getUser.preferences?.firewall?.managementType}
                              setModalVisible={setModalVisible}
                            ></FirewallTableRow>
                          ))
                        }
                      </tbody>
                    </table>
                  )
                )
              }

              {/*------------------------------------------------------------------ */}
              {/*   Confirmation Modal                                              */}
              {/*------------------------------------------------------------------ */}   
              
              <ConfirmationModal
                  header="Do you wish to continue?"
                  body={[
                    <div>
                      <p>You are about to delete firewall rule <b>{getUser?.preferences?.firewall?.fields?.ruleName?.value}</b>.</p>
                      <p>If you would like to go back without saving your changes, click <b>Cancel</b>.</p>
                    </div>
                  ]}
                  modalVisible={modalVisible}
                  setModalVisible={setModalVisible}
                  eventHandlerOnSubmit={eventHandlerOnSubmit}
              ></ConfirmationModal>

            </div>

            {/*------------------------------------------------------------------ */}
            {/*   Requests Summary                                                */}
            {/*------------------------------------------------------------------ */}  

            <div className='Table-Pane' style={{marginTop: "40px"}}>

              <h2 className="Page-Subheading">Requests Summary</h2>

                <RequestSummary
                  status={firewallJobsStatus}
                  setStatus={setFirewallJobsStatus}
                  rows={firewallRequestResponse}
                ></RequestSummary>

            </div>
            
          </div>
        }
      ></PageComponent>
    )
}