//------------------------------------------------------
//  Function to check a users access to a role
//  Returns:
//       true = User has the required role
//       false = User doesnt have the required role
//------------------------------------------------------


//------------------------------------------------------
//  How to use:
//------------------------------------------------------


//requiredRoles == The list of roles the component requires
//userRoles == firestore "roles" field (EVERYTHING)
//filterByResource ==  blank or a projectId/PortfolioId

// requiredRoles = ["projectOwner", "projectOperator"]
// userRoles = {
//   "projectOwner":[
//     "project123"
//   ],
//   "projectOperator":[
//     "project123"
//   ]
// }
// filterByResource = "project123"


//------------------------------------------------------
//  Function
//------------------------------------------------------

function CheckUserRole(requiredRoles, userRoles, filterByResource){
  
  //------------------------------------------------------
  //  Environment Variables
  //------------------------------------------------------

  let hasRole = false

  //------------------------------------------------------
  //  Extract the 'userRoles' for the selected resource
  //------------------------------------------------------
  

    try {
      requiredRoles.forEach(requiredRole => {

        const resources = userRoles[requiredRole]

        resources.forEach(resource => {
          if(resource === filterByResource){
            hasRole = true;
          }
          
        });
      });

    } catch (error) {}


  //------------------------------------------------------
  //  return results
  //------------------------------------------------------


    return hasRole;
  
  
  //--------------------------------------------------------

}

export default CheckUserRole;