//------------------------------------------------------
//  Component: Knowledge Article Link
//------------------------------------------------------

// Purpose: To display a knowledge article link in the search results

// Properties:
//  - title - "string" - title of the document
//  - excerpt - "description" - short excerpt from the knowledge article
//  - label - "string" - label to categorise the articles
//  - lastmodifieddate - "string" - date the article was last modified

// Example:
//  Designed to use a map, like below:
//  {knowledgeArticles.map(object => (
//     <KnowledgeArticleLink 
//       title={object.title}
//       excerpt={object.excerpt}
//       label={object.labels[1].replace('-', ' ')}
//       lastmodified={object.lastModified}
//     ></KnowledgeArticleLink>
// ))}
//
//------------------------------------------------------

//Libraries
import React from 'react';

//CSS
import './knowledgearticle.css'

function KnowledgeArticleLink({article, setSelectedArticle}) {

    //------------------------------------------------------
    //  Define variables
    //------------------------------------------------------

        //Label > Exclude 'cs-customer' label and capitalize and remove dash
        var labels = article.labels.filter(label => label !== 'cs-customer')[0].replace('-', ' ')

    //------------------------------------------------------
    //  Return HTML
    //------------------------------------------------------
    
        return (
            <div className="Knowledge-Article-Link" onClick={() => setSelectedArticle(article)}>
                <h3 className="Knowledge-Article-Title">{article.title}</h3>

                 <p>{article.excerpt}</p>
                
                <div className='Knowledge-Article-Tags'>
                    <div className="Knowledge-Article-Label">
                        {labels}
                    </div>

                    <div className="Knowledge-Article-Date">
                        <b>Last Modified: </b>{article.friendlyModified}
                    </div>
                </div>
            </div>
        )
    }

//------------------------------------------------------

export default KnowledgeArticleLink;