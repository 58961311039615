//==========================================================================
//   Component: Unattended Projects Table
//==========================================================================

//   Purpose: Table displaying all unattended projects and their associated insights

//   Properties: 
//   - unattendedProjectsData = {data used to populate the rows in the table}

//   Example: 
//   <UnattendedProjectsTable
//       unattendedProjectsData={unattendedProjectsTableData}
//   ></UnattendedProjectsTable>
//------------------------------------------------------

//Libraries
import React from 'react'

//Components
import UnattendedProjectsTableRow from './unattendedprojectstablerow'

//CSS
import './sustainabilitycomponents.css'

export default function UnattendedProjectsTable({unattendedProjectsData}) {

    //------------------------------------------------------
    //  Return HTML
    //------------------------------------------------------

    return (
        <div className="Sustainability-Response-Success">
            { 
                unattendedProjectsData?.length > 0 ? 
            
                //If emissions data exists > display table
                (
                    <table className="Project-Emissions-Table">
                        <colgroup>
                            <col span="1" style={{width: "25%"}}></col>
                            <col span="1" style={{width: "30%"}}></col>
                            <col span="1" style={{width: "25%"}}></col>
                            <col span="1" style={{width: "10%"}}></col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>Project</th>
                                <th>Carbon Emissions this month (kgCO2e)</th>
                                <th>Refreshed</th>
                            </tr>
                            {
                                unattendedProjectsData?.map((row) => (
                                    <UnattendedProjectsTableRow
                                        rowData={row}
                                    ></UnattendedProjectsTableRow>
                            ))
                            }
                        </tbody>
                    </table>

                ) : 

                //If no emissions data > display message
                (
                    <div style={{padding: "20px"}}>
                        No unattended projects found.
                    </div>
                )
            }
        </div>
    )
}
