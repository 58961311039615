//------------------------------------------------------
//  Video Player is designed to play videos hosted by Lendlease Cloud
//  This component DOES NOT support videos outside of our GCS bucket!
//  How to use component:
//
//      1. The mp4 video should be inside this bucket:
//          cs-customerportal-bucket-cloudconciergedev
//          or
//          cs-customerportal-bucket-ccprod73345
//
//      2. Import the below component:
//      <VideoPlayer
//          item="October_Sessions.mp4"
//          modalVisible = {useState, with the value of 'true' or 'false'}
//      ></VideoPlayer>
//
//------------------------------------------------------

//Libraries
import React, {useState, useEffect, useRef} from 'react';

//Functions
import ReactBackend from '../../Library/reactBackend'

//Components
import ComponentResponse from '../ComponentResponse/componentresponse'


export default function VideoPlayer(props) {

  //------------------------------------------------------
  //  props
  //------------------------------------------------------

    const item = props.item;
    const vidRef = useRef(null);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //API States > 'pending', 'success', 'error-invalid', 'error-fatal' & 'error-timeout'
    const [signedUrlStatus, setSignedUrlStatus] = useState('pending');
    const [signedUrlStatusResponse, setSignedUrlStatusResponse] = useState();

  //------------------------------------------------------
  //  Define API calls
  //------------------------------------------------------

    //Used to call "createSignedUrl" API
    async function createSignedUrl(){

        var requestBody = {
            "item": item
        }
  
        setSignedUrlStatus('pending')

        //Call React Backend - Submit Form Response
        var response = await ReactBackend('createSignedUrl', requestBody)
  
        //Set API Response
        setSignedUrlStatusResponse(response.responseBody.message)
  
        //Switch on 'activeProjectStatus'
        if(response.status === 'success'){
              
          //Set 'activeProjectStatus' to 'success'
          setSignedUrlStatus('success')
  
        } else if(response.status === 'error-invalid'){
  
          //Set 'activeProjectStatus' to 'error-invalid'
          setSignedUrlStatus('error-invalid')
  
        } else if(response.status === 'error-fatal'){
            
          //Set 'activeProjectStatus' to 'error-fatal'
          setSignedUrlStatus('error-fatal')
  
        } else if(response.status === 'error-timeout'){
            
          //Set 'activeProjectStatus' to 'error-timeout'
          setSignedUrlStatus('error-timeout')
  
      }
        return response
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Onload call API
    useEffect(() => {
      
      createSignedUrl()
                      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return(
        <ComponentResponse
          status={signedUrlStatus}
          setStatus={setSignedUrlStatus}
          successcontent={
            <video ref={vidRef} style={{marginTop: '5px', marginBottom: '10px'}} width="100%" height="100%" controls>
              <source src={signedUrlStatusResponse} type="video/mp4"></source>
              Your browser does not support the video tag.
            </video>
          }
        >
        </ComponentResponse>
    )

  //------------------------------------------------------

}
