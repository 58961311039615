//================================================================
//  Component: Project Selector Component
//================================================================

//  Purpose: This is a child component of 'newownershiptransfer', handles what projects will be shown to the user/cs admin

//  Properties:
//    - setProjects = {useState, setProjects}
//    - requester = {useState, requester}
//    - setRequester = {useState, setRequester}
//    - requester = {useState, requester}
//    - setRequester = {useState, setRequester}
//    - setServiceNowRef = {useState, setServiceNowRef}
//    - serviceNowRef = {useState, serviceNowRef}
//    - setValidPreviousOwner = {useState, setPreviousOwner}
//    - requesterError = {useState, requesterError}
//    - serviceNowRefError = {useState, serviceNowRefError}

//  Example:
//    <ProjectSelectorComponent
//      name={"Mark Bennett"}
//      message={"stop breaking stuff!"}
//    ></ProjectSelectorComponent>    

//================================================================

//Libraries
import React, { useState, useEffect, useContext } from 'react'

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Functions
import CheckUserRole from '../../../Library/checkUserRole';
import QueryDocument from '../../../Library/QueryDocument';

// Components
import QuestionLabel from '../../../Components/QuestionLabel';
import InlineInputField from '../../../Components/InlineInputField/inlineinputfield';
import ErrorMessageLabel from '../../../Components/ErrorMessageLabel/index';

//CSS
import './OwnershipTransfer.css';

export default function ProjectSelectorComponent({
  projects,
  setProjects,
  requester,
  setRequester,
  setServiceNowRef,
  serviceNowRef,
  setValidPreviousOwner,
  requesterError,
  serviceNowRefError
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
    
    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  redux & role check
  //------------------------------------------------------
  
    //CS Admins can change the requestor > this allows the query of Projects
    const userRoles = getUser?.profile?.roles;
    const csAdmin = CheckUserRole(['portalAdmin'], userRoles, 'true');

    const currentUsersProjects = getUser?.profile?.views?.projectsOwnerActive;
    const currentUserSandboxProjects = getUser?.profile?.views?.projectsSandboxesActive;

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to store the previous owner of the project
    const [previousOwner, setPreviousOwner] = useState('');
    const [previousOwnerError, setPreviousOwnerError] = useState('');

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Handles what projects users see > onload
    useEffect(() =>{

      //Conditions
      if(csAdmin === undefined) return;
      if(currentUsersProjects === undefined) return;

      //User is NOT a CS admin > return their projects
      if(csAdmin === false) return setProjects(currentUsersProjects.filter(
        x => !currentUserSandboxProjects.includes(x)));

    // eslint-disable-next-line
    }, []);

    //Allows CS admins to search for projects, by the owners email address
    useEffect(() =>{

      //Conditions
      if(csAdmin === undefined) return;
      if(previousOwner === undefined) return;

      //A regular user, so set previous owner to requester
      if(csAdmin === false) {
        setValidPreviousOwner(requester.toLowerCase());
      };

      if(previousOwner.length > 0) {

        //Clear the 'onload' error message
        setPreviousOwnerError('');

        //Validate the CS Admins input
        const domain = previousOwner.split('@')[1]
        if(domain !== undefined && domain.toLowerCase() === 'lendlease.com'){

          //Valid input, get the users projects
          QueryDocument('projects', [
            ['projectowner', '==', previousOwner.toLowerCase()],
            ['status', '==', 'active'],
            ['environment', 'in', ['nprd', 'prod']]
          ]).then((projects) =>{

            const results = [];
            projects.forEach((project) =>{
              results.push(project.projectid)
            })
            setProjects(results);
            setValidPreviousOwner(previousOwner.toLowerCase());

          }).catch((error) =>{

            setPreviousOwnerError(`Unable to query "projects" collection, error: ${error.message}`);

          });
          
        } else {
          setPreviousOwnerError('Only Lendlease people can own projects!');
        }
      }

    }, [csAdmin, previousOwner, setProjects, setValidPreviousOwner, requester]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //CS Admins can update the 'requestor'
    if (csAdmin === true){
      return (
        <div>

          {/* Requestor Field */}
          <QuestionLabel question={"Requestor"} helpText="Hi CS Admin, type in the line manager's emailaddress that approved this transfer."></QuestionLabel>
          
          <InlineInputField
            name='requester'
            value={requester}
            position = "right"
            inputFieldLength={"mediuminputfield"}
            visibility={'edit'}
            onChange={(event) => setRequester(event.target.value)}
          >
          </InlineInputField>
          <ErrorMessageLabel errorMessage={requesterError} errorVisible={true}></ErrorMessageLabel>


          {/* Service Now Reference */}
          <QuestionLabel question={"<Optional> ServiceNow reference"} helpText='Hi CS Admin, type in the ServiceNow ticket number.'></QuestionLabel>
          
          <InlineInputField
            name='serviceNowRef'
            value={serviceNowRef}
            position = "right"
            inputFieldLength={"mediuminputfield"}
            visibility={'edit'}
            onChange={(event) => setServiceNowRef(event.target.value)}
          >
          </InlineInputField>
          <ErrorMessageLabel errorMessage={serviceNowRefError} errorVisible={true}></ErrorMessageLabel>

          {/* Previous Owner */}
          <QuestionLabel question={"Previous Owner"} helpText='Hi CS Admin, type in a Project Owners emailaddress. All ACTIVE projects will be returned.'></QuestionLabel>
          
          <InlineInputField
            name='previousOwner'
            value={previousOwner}
            position = "right"
            inputFieldLength={"mediuminputfield"}
            visibility={'edit'}
            onChange={(event) => setPreviousOwner(event.target.value)}
          >
          </InlineInputField>

          {/* Error - No projects found */}
          <ErrorMessageLabel errorMessage={"No projects found."} errorVisible={previousOwner?.length > 0 && projects?.length === 0}></ErrorMessageLabel>

          {/* Error - No previous owner */}
          <ErrorMessageLabel errorMessage={previousOwnerError} errorVisible={true}></ErrorMessageLabel>

        </div>
      )
    }

    //Catch all is a typically user > unable to change the 'requestor' field
    else {
      return (
        <div>
          {/* Requestor Field */}
          <QuestionLabel question={"Requestor"}></QuestionLabel>
          
          <InlineInputField
            name='requester'
            value={requester}
            position = "right"
            inputFieldLength={"mediuminputfield"}
            visibility={'read'}
            onChange={(event) => setRequester(event.target.value)}
          >
          </InlineInputField>

        </div>
      )
    }

}
