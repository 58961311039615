//==========================================================================
//   Function: Document Listener
//   Description: Creates a Firebase document listener
//   Documentation:
//      https://firebase.google.com/docs/firestore/query-data/listen
//      https://firebase.google.com/docs/reference/android/com/google/firebase/firestore/DocumentSnapshot
//
//
//   //------------------------------------------------------
//   //   How to use this function: 
//   //------------------------------------------------------
//   
//      1. Create a useEffect
//      2. In the useEffect, define an 'onLoad' function, this will run once when the page first loads:
//         
//        function onLoad(document){
//
//          console.log('Getting the current document!', document);
//
//        }
//
//      3. In the useEffect, define an 'onChange' function, this will run each time a change occurs:
//         
//          function onChange(document, snapshot){
//          
//              console.log('Change happened!', document);
//          
//          }
//
//      4. In the useEffect, define an 'onError' function, this will run each time an error occurs:
//  
//          function onError(error){
//          
//              console.log('Something broke, error:', error);
//          
//          }
//
//      5. Import and call 'QueryListener', note it returns a 'unsubscribe' callback. You WILL need this later!
//
//        const unsubscribe = QueryListener('workflows', [
//          ['productid', '==', productId],
//          ['status', '==', 'active']
//        ], onLoad, onChange, onError)
//
//      6. Finally, we need ensure the document listener is shutdown when the component 'unmounts'
//
//        return () =>{
//  
//          unsubscribe();
//  
//        };
//
//
//     //------------------------------------------------------
//     //   Please see the example below:
//     //------------------------------------------------------
//
//      useEffect(()=>{
//    
//        if (productId === undefined) return;
//    
//        function onLoad(document){
//          setProductObject(document);
//        }
//    
//        function onChange(document, previousValue){
//          if(previousValue === undefined) return;
//          if(previousValue?.pricingamount !== document?.pricingamount) setShowModal(true);   
//        }
//    
//        function onError(error){
//          setRequestType('error-fatal');
//          setError(`Failed to get product information, unable to create the document listener error: ${error}`);  
//        }
//    
//        const unsubscribe = QueryListener('workflows', [
//          ['productid', '==', productId],
//          ['status', '==', 'active']
//        ], onLoad, onChange, onError)
//    
//        return () =>{
//          unsubscribe();
//        };
//    
//      }, [productId]);
//
//
//------------------------------------------------------

//Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore, onSnapshot, collection, query, where, orderBy, limit } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';


export default function QueryListener(collectionId, queries, onLoad, onChange, onError, sortBy, limitBy){

  try {

    //Firestore database reference
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    
    //Set collection
    const collectionRef = collection(db, collectionId);

    //------------------------------------------------------
    //  Prepare query arguments 
    //------------------------------------------------------

    //Temp array used to store query arguments
    var args = []

    //Extract list of queries from array
    if(queries.length > 0){
      queries.forEach((query) => {
        const queryArg = where(query[0], query[1], query[2])
        args.push(queryArg)
      })
    }

    //Check if sortBy exists > add to query arguments
    if(sortBy?.length > 0) {
      const orderByArg = orderBy(sortBy[0], sortBy[1])
      args.push(orderByArg)
    }

    //Check if limitBy exists > add to query arguments
    if(limitBy) {
      const limitArg = limit(limitBy)
      args.push(limitArg)
    }

    //Create query
    const q = query(collectionRef, ...args);


    // Invoke listener > call 'onLoad' function
    const unsubscribe = onSnapshot(q, (snapshot) => {

      const results = [];

      snapshot.forEach((doc) => {

        results.push(doc.data());
      
      });

      onLoad(results);

    });


    // Invoke listener > call 'onChange' function
    onSnapshot(q, (snapshot) => {

      const results = [];

      snapshot.forEach((doc) => {

        results.push(doc.data());
      
      });

      onChange(results);

    })

    // Return the callback function > Allows the caller to stop listening to changes
    return unsubscribe;

  } catch (error) {

    onError(`QueryListener has failed to complete, error: ${error}`);

  }

}
