//================================================================
//  Modify Groups Page
//================================================================

//Libraries
import React, { useContext } from 'react';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Functions

//Components
import PageHeader from '../../../Components/PageHeader/PageHeader';
import GroupManagement from '../../../Components/GroupManagement/GroupManagement';

//CSS


export default function ModifyProjectGroups(){

  //------------------------------------------------------
  //  Contexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------
  
  return (
    <GroupManagement
      groupId={getUser?.preferences?.projectgroup?.id}
      requestType={getUser?.preferences?.projectgrouptype}
      header={
        <PageHeader
          title={"Edit Your Group"}
          body={
            <p>
              Please modify the group description and/or enter the user(s) you would like to grant access to this group. Once complete, click 'Submit' below.
              <br></br>
              <b>Note</b> - All users must have a Cloud Passport account. If they do not have an account, you will be prompted to invite them when you click 'Submit'.
            </p>    
          }
        ></PageHeader>
      }
      breadcrumb={{
        "name": "Manage Groups", 
        "route": "/projects/projectgroups"
      }}
      requireSelectedResource={true}
      requireSelectedViews={['project']}
      requiredRoles={['projectOwner', 'projectOperator']}
      requiredRolesValue={getUser?.preferences?.globalSelector?.selectedResource}
      requiredEnvironments={['Sandbox', 'Standard']}
    ></GroupManagement>
  );

  //------------------------------------------------------

};
