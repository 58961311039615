//================================================================
//  Component: Disclaimer
//================================================================

//  Purpose:
//    This is a generic component to return a simple disclaimer with the text 'I am authorized to perform this action'

//  Properties:
//    - cancelRoute = Provide a string of the previous pages route, '/firewall' or '/dns'
//    - setStatus = Provide a UseState that handled your page status, 'pageStatus', 'formStatus', 'apiStatus', etc
//    - setStatusValue = Provide a string of accepted status, 'pending', 'onload', 'success', etc
//    - acceptanceCriteria = HTML content of the list

//  Example:
//
//    <Disclaimer
//     cancelRoute="/projects"
//     setStatus={setApiStatus}
//     setStatusValue="onload"
//     acceptanceCriteria={
//       <ul>
//         <li>I am authorized to perform this action</li>
//       </ul>
//     }
//     ></Disclaimer>

//================================================================

//Components
import {useLocation, useNavigate} from 'react-router';
import ReactBackend from '../../Library/reactBackend'

//CSS
import './disclaimer.css';

function Disclaimer({
  cancelRoute,
  setStatus,
  setStatusValue,
  acceptanceCriteria
}) { 
  
  //------------------------------------------------------
  //  UseLocation from 'react-router'
  //------------------------------------------------------

    const routerUseLocation = useLocation();
    const navigation = useNavigate();
    let useLocationVal = (routerUseLocation.pathname).toString()
  
  //------------------------------------------------------
  //  Define Functions > submitDisclaimerAcceptance & HandleResponse
  //------------------------------------------------------

    //Used to call "submitDisclaimerAcceptance"
    function acceptanceHandler(){

      var acceptanceCriteriaArray = []
      var disclaimer = []
      if (acceptanceCriteria === undefined || acceptanceCriteria.length === 0) return;
    
      // Loop through the acceptance criteria.  some criteria have children, some dont
      acceptanceCriteria?.forEach(item => {
      
        if(Array.isArray(item.props.children)){
          // make a new array of the children
          acceptanceCriteriaArray = item.props.children

          // loop through that new array
          acceptanceCriteriaArray?.forEach(newitem => {

            if(Array.isArray(newitem.props.children)){
              // total hack job to glue together an acceptance criteria where a URL is present.  Need to find a smarter way to do this
              disclaimer.push(newitem.props.children[0] + newitem.props.children[1].props.children)
            }
            else{
              // add the object to the disclaimer array 
              disclaimer.push(newitem.props.children)
            }

          } 
          )
        }
        else{
          disclaimer.push(item.props.children.props.children)
        }

        }
        
      );

      var requestBody = {
        "disclaimer": disclaimer,
        "route": useLocationVal
      }   

      //Call React Backend - Submit  Response
      ReactBackend('submitDisclaimerAcceptance', requestBody)
      

      //Set Page Status 
      setStatus(setStatusValue);

    }     

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    return (
      <div className="disclaimer-parent-container">

        {/*------------------------------------------------------------------ */}
        {/*   Header                                                          */}
        {/*------------------------------------------------------------------ */}

        <div>
          <h2> Lendlease Cloud Disclaimer </h2>
          <p>
            <b>By proceeding past this page, I acknowledge that I understand my responsiblities in the cloud</b><br></br>
          </p>
          {acceptanceCriteria}
        </div>

        {/*------------------------------------------------------------------ */}
        {/*   Button Panel "I Accept" or "Cancel" */}
        {/*------------------------------------------------------------------ */} 
          
        <div className='Button-Group'>
          <button className='Primary-Button' onClick={() => acceptanceHandler()}> I Accept </button>
          <button className='Secondary-Button' onClick={() => navigation(cancelRoute)}> Cancel </button>
        </div>

      </div> 
    );

  //------------------------------------------------------
}

export default Disclaimer;