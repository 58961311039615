//================================================================
//  Component: Breadcrumbs
//================================================================

//  Purpose: This is a breadcrumbs component (simple version with a back button)

//  Properties:
//    - pageName = <String> - Name of the page you want the user to return to, e.g. Projects
//    - pageRoute = <String> - React route of the page you want to return to, e.g. '/projects'
//    - pageView = <String> - Set the Global Selectors view, e.g. 'projects'
//    - pageResource = <String> - Set the Global Selectors resource, e.g. 'project-123'

//  Example (See Page Component):
//  <Breadcrumbs
//    pageName={breadcrumb?.name}
//    pageRoute={breadcrumb?.route}
//    pageView={breadcrumb?.view}
//    pageResource={breadcrumb?.resource}
//  ></Breadcrumbs>

//================================================================


//Libraries
import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom"

//Contexts
import { GetUser, SetUser } from '../../Library/GlobalContexts';

//CSS
import './breadcrumbs.css';


export default function Breadcrumbs({
  pageName,
  pageRoute,
  pageView,
  pageResource,
}) {

  //------------------------------------------------------
  //  useContext and React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setUser = useContext(SetUser);

    //React Router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Handles the onClick event, skips any undefined props
    function OnClickHandler(){

      if (pageView !== undefined) {

        getUser.preferences.globalSelector.selectedView = pageView;

      }
      if (pageResource !== undefined) {

        getUser.preferences.globalSelector.selectedResource = pageResource;
      
      }

      if (pageRoute !== undefined) {
        
        navigate(pageRoute);
      
      }

      // Update the global contexts
      setUser({...getUser});
      
    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className="Breadcrumbs-Container">
      <p className="Breadcrumbs-Link" onClick={() => OnClickHandler()}> Return to {pageName}</p>
    </div>
  )
}
