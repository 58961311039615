//==========================================================================
//   Component: Invoice
//==========================================================================

//   Purpose: Shows an Invoice

//   Properties: 
//   - invoice = {invoice document}
//   - baseCurrency = The billing currency at the time the invoice was generated

//   Example:
//   <Invoice
//     invoice={row}
//     baseCurrency={'USD'}
//   ></Invoice>

//------------------------------------------------------


//Libraries
import React from 'react'

//Components
import QuestionLabel from '../../../Components/QuestionLabel/index';

//CSS
import '../Billing.css'


export default function Invoice({
  invoice,
  baseCurrency
}) {

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Converts a number to a string with a fixed number of decimal places
  function toDP(val, dp) {
    return parseFloat(val).toLocaleString(undefined, { maximumFractionDigits: parseInt(dp), minimumFractionDigits: parseInt(dp) });
  }


  //Function to format in proper currency
    function FormatCurrency(projectCurrencyTotal, projectCurrencyCode) {

      if (projectCurrencyCode === 'EUR'){

        return new Intl.NumberFormat('de-DE',{style: 'currency', currency: 'EUR', currencyDisplay: "symbol"}).format(projectCurrencyTotal);
      } 
      else if (projectCurrencyCode === 'AUD'){

        return new Intl.NumberFormat('en-US',{style: 'currency', currency: 'AUD', currencyDisplay: "symbol"}).format(projectCurrencyTotal);

      }
      else if (projectCurrencyCode === 'GBP'){

        return new Intl.NumberFormat('en-GB',{style: 'currency', currency: 'GBP', currencyDisplay: "symbol"}).format(projectCurrencyTotal);

      }
      else if (projectCurrencyCode === 'SGD'){

        return new Intl.NumberFormat('en-US',{style: 'currency', currency: 'SGD', currencyDisplay: "symbol"}).format(projectCurrencyTotal);

      }
      else if (projectCurrencyCode === 'USD'){

        return new Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD', currencyDisplay: "symbol"}).format(projectCurrencyTotal);

      }
      else {

        return new Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD', currencyDisplay: "symbol"}).format(projectCurrencyTotal);

      }
    }

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    return (
      <div className='Invoice-Container'>

        {/*------------------------------------------------------------------ */}
        {/*   Title                                                           */}
        {/*------------------------------------------------------------------ */}

        <div className="invoice-subheading">
          GCP Usage Invoice
        </div>

        {/*------------------------------------------------------------------ */}
        {/*   Invoice Properties                                              */}
        {/*------------------------------------------------------------------ */}

        <div className="invoice-invoiceproperties">
          <table>
            <tbody>
              <tr>
                <th>Issue Date</th>
                <th>{invoice?.creationDate}</th>
              </tr>
              <tr>
                <th>Invoice Number</th>
                <th>{invoice?.invoicenumber}</th>
              </tr>
              <tr>
                <th>Invoice Period</th>
                <th>{invoice?.invoicePeriod}</th>
              </tr>
              <tr>
                <th>Project Code</th>
                <th>{invoice?.projectCode}</th>
              </tr>
              <tr>
                <th>Task Number</th>
                <th>{invoice?.taskNumber}</th>
              </tr>
              <tr>
                <th>Expenditure Type</th>
                <th>{invoice?.expenditureType}</th>
              </tr>
              
              <tr>
                <th>Project Id</th>
                <th>{invoice?.projectId}</th>
              </tr>
              <tr>
                <th>Project Owner</th>
                <th>{invoice?.projectOwner}</th>
              </tr>
            </tbody>
          </table>
        </div>

        {/*------------------------------------------------------------------ */}
        {/*   Service Usage                                                   */}
        {/*------------------------------------------------------------------ */}

        {/* Service Usage Title */}
        <div className="invoice-tabletitle">
          <QuestionLabel
            question={"Service Usage"}
          ></QuestionLabel>
        </div>

        {/* Service Usage Table */}
        <div className="invoice-childtable">
            <table>
              <tbody>
                <tr>
                  <th style={{"fontWeight": "bolder"}}>Name</th>
                </tr>
                {
                  invoice?.cloudProviderServices && (
                    Object.entries(invoice?.cloudProviderServices)?.map((object) => (
                      <tr>
                        <th style={{"text-align": "left"}}>{object[0]}</th>
                        <th style={{"text-align": "right"}}>${toDP(object[1], 4)}</th>
                      </tr>
                    ))
                  )
                }
              </tbody>
            </table>
        </div>

        {/* Service Usage Totals */}
        <div className="invoice-totalstable">
            <table>
              <tbody>
                <tr>
                  <th style={{"fontWeight": "bolder"}}>Totals ({baseCurrency})</th>
                  <th style={{"text-align": "right"}}>
                    {
                      invoice?.Totals?.cloudProviderServices ? (
                        `$${toDP(invoice?.Totals?.cloudProviderServices, 2)}`
                      ) : (
                        `$0.00`
                      )
                    }
                  </th>
                </tr>
              </tbody>
            </table>
        </div>

        {/*------------------------------------------------------------------ */}
        {/*   Google Supplied Discount                                        */}
        {/*------------------------------------------------------------------ */}

        {/* Google Supplied Discount Title */}
        <div className="invoice-tabletitle">
          <QuestionLabel
            question={"Google Supplied Discount"}
            helpText={"Google provides discounts depending on your product usage. These savings are calculated by Google and applied to your invoice"}
          ></QuestionLabel>
        </div>

        {/* Google Supplied Discount Table */}
        <div className="invoice-childtable">
            <table>
              <tbody>
                <tr>
                  <th style={{"fontWeight": "bolder"}}>Name</th>
                </tr>
                {
                  invoice?.googleSuppliedDiscounts && (
                    Object.entries(invoice?.googleSuppliedDiscounts)?.map((object) => (
                      <tr>
                        <th style={{"text-align": "left"}}>{object[0]}</th>
                        <th style={{"text-align": "right"}}>${toDP(object[1], 4)}</th>
                      </tr>
                    ))
                  )
                }
              </tbody>
            </table>
        </div>

        {/* Google Supplied Discount Totals */}
        <div className="invoice-totalstable">
            <table>
              <tbody>
                <tr>
                  <th style={{"fontWeight": "bolder"}}>Totals ({baseCurrency})</th>
                  <th style={{"text-align": "right"}}>
                    {
                      invoice?.Totals?.googleSuppliedDiscounts ? (
                        `$${toDP(invoice?.Totals?.googleSuppliedDiscounts, 2)}`
                      ) : (
                        `$0.00`
                      )
                    }
                  </th>
                </tr>
              </tbody>
            </table>
        </div>

        {/*------------------------------------------------------------------ */}
        {/*   Cloud Platform Services                                         */}
        {/*------------------------------------------------------------------ */}

        {/* Cloud Platform Services Title */}
        <div className="invoice-tabletitle">
          <QuestionLabel
            question={"Cloud Platform Services"}
            helpText={[
              <span>
                If you would like to learn how you can take advantage of Google's Premium Support offerings, please contact the <a target="_blank" rel="noopener noreferrer" style={{color: '#0C6CDE'}} href="https://info.lendlease.cloud/theguild">Lendlease Cloud team</a>
              </span>
            ]}
          ></QuestionLabel>
        </div>

        {/* Cloud Platform Services Table */}
        <div className="invoice-childtable">
            <table>
              <tbody>
                <tr>
                  <th style={{"fontWeight": "bolder"}}>Name</th>
                </tr>
                {
                  invoice?.cloudStudioServices && (
                    Object.entries(invoice?.cloudStudioServices)?.map((object) => (
                      <tr>
                        <th style={{"text-align": "left"}}>{object[0]}</th>
                        <th style={{"text-align": "right"}}>${toDP(object[1], 4)}</th>
                      </tr>
                    ))
                  )
                }
              </tbody>
            </table>
        </div>

        {/* Cloud Platform Services Totals */}
        <div className="invoice-totalstable">
            <table>
              <tbody>
                <tr>
                    <th style={{"fontWeight": "bolder"}}>Totals ({baseCurrency})</th>
                    <th style={{"text-align": "right"}}>
                      {
                        invoice?.Totals?.cloudStudioServices ? (
                          `$${toDP(invoice?.Totals?.cloudStudioServices, 2)}`
                        ) : (
                          `$0.00`
                        )
                      }
                    </th>
                </tr>
              </tbody>
            </table>
        </div>

        {/*------------------------------------------------------------------ */}
        {/*   Cloud Platform Discount                                         */}
        {/*------------------------------------------------------------------ */}

        {/* Cloud Platform Discount Title */}
        <div className="invoice-tabletitle">
          <QuestionLabel
            question={"Cloud Platform Discount"}
          ></QuestionLabel>
        </div>

        {/* Cloud Platform Discount Table */}
        <div className="invoice-childtable">
            <table>
              <tbody>
                <tr>
                  <th style={{"fontWeight": "bolder"}}>Name</th>
                </tr>
                {
                  invoice?.cloudStudioDiscounts && (
                    Object.entries(invoice?.cloudStudioDiscounts)?.map((object) => (
                      <tr>
                        <th style={{"text-align": "left"}}>{object[0]}</th>
                        <th style={{"text-align": "right"}}>${toDP(object[1], 4)}</th>
                      </tr>
                    ))
                  )
                }
              </tbody>
            </table>
        </div>

        {/* Cloud Platform Discount Totals */}
        <div className="invoice-totalstable">
            <table>
              <tbody>
                <tr>
                    <th style={{"fontWeight": "bolder"}}>Totals ({baseCurrency})</th>
                    <th style={{"text-align": "right"}}>
                      {
                        invoice?.Totals?.cloudStudioDiscounts ? (
                          `$${toDP(invoice?.Totals?.cloudStudioDiscounts, 2)}`
                        ) : (
                          `$0.00`
                        )
                      }
                    </th>
                </tr>
              </tbody>
            </table>
        </div>


        {/*------------------------------------------------------------------ */}
        {/*   Subtotals                                                       */}
        {/*------------------------------------------------------------------ */}

        {/* Subtotals Title */}
        <div className="invoice-substitle">
            Subtotals
        </div>

        {/* Subtotals Totals */}
        <div className="invoice-invoicetotalstable">
            <table>
              <tbody>
                <tr>
                  <th style={{"fontWeight": "bolder"}}>Totals ({baseCurrency})</th>
                  <th style={{"text-align": "right"}}>
                    {
                      invoice?.Totals?.subTotal ? (
                        `$${toDP(invoice?.Totals?.subTotal, 2)}`
                      ) : (
                        `$0.00`
                      )
                    }
                  </th>
                </tr>
              </tbody>
            </table>
        </div>

        {/*------------------------------------------------------------------ */}
        {/*   Credit                                                          */}
        {/*------------------------------------------------------------------ */}

        <div className="invoice-substitle">
            Credit
        </div>
        <div className="invoice-invoicetotalstable">
            <table>
              <tbody>
                <tr>
                  <th style={{"fontWeight": "bolder"}}>Totals ({baseCurrency})</th>
                  <th style={{"text-align": "right"}}>
                    {
                      invoice?.Totals?.creditApplied ? (
                        `$${toDP(invoice?.Totals?.creditApplied, 2)}`
                      ) : (
                        `$0.00`
                      )
                    }
                  </th>
                </tr>
              </tbody>
            </table>
        </div>


        {/*------------------------------------------------------------------ */}
        {/*   Invoice Total                                                   */}
        {/*------------------------------------------------------------------ */}

        {/* Invoice Total Title */}
        <div className="invoice-substitle">
            Invoice Total
        </div>
        
        {/* Invoice Total Table */}
        <div className="invoice-invoicetotalstable">
            <table>
              <tbody>
                <tr>
                  <th style={{"fontWeight": "bolder"}}>Totals ({baseCurrency})</th>
                  <th style={{"text-align": "right"}}>${toDP(invoice?.Totals?.invoiceTotal, 2)}</th>
                </tr>

                {/* Project Currency Total */}
                {
                  invoice?.Totals?.projectCurrencyTotal !== undefined && invoice?.Totals?.projectCurrencyTotal > 0 && (
                    <>
                      <tr>
                        <th>Currency conversion: ({invoice?.Totals?.projectCurrencyCode}) at {invoice?.Totals?.exchangeRate}</th>
                        <th style={{"text-align": "right"}}>{FormatCurrency(invoice?.Totals?.projectCurrencyTotal, invoice?.Totals?.projectCurrencyCode)}</th>
                      </tr>
                    </>
                  )
                }
              </tbody>
            </table>
        </div>

        {/*------------------------------------------------------------------ */}
        {/*   Credit Remaining                                                */}
        {/*------------------------------------------------------------------ */}

        {
          invoice?.totalCreditRemaining && (
            <>
              <div className="invoice-substitle">
                  Credit Remaining
              </div>
              {/* Credit Remaining Table */}
              <div className="invoice-creditremaingtable">
                  <table>
                    <tbody>
                      <tr>
                        <th>Credit Owner</th>
                        <td style={{"text-align": "right"}}>{invoice?.creditOwner}</td>
                      </tr>
                      <tr>
                        <th>Total Credit Remaining</th>
                        <th style={{"text-align": "right"}}>${toDP(invoice?.creditRemainingBalance, 2)}</th>
                      </tr>
                    </tbody>
                  </table>
              </div>
            </>
          )
        }

        {/*------------------------------------------------------------------ */}
        {/*   Note                                                            */}
        {/*------------------------------------------------------------------ */}

        {
          invoice?.note && (
            <>
              <div className="invoice-substitle">
                Notes
              </div>

              <div className="invoice-note">
                <lo>
                {
                  invoice?.note?.map((item) => (
                    <li> 
                      {item}
                    </li>
                  ))
                }
                </lo>
              </div>
            </>
          )
        }
      </div>
    )
      
}
