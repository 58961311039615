//------------------------------------------------------
//  UserContextController
//    Created by the Lendlease Cloud Software Engineers 14/09/2022
//
//    Description:
//      Background service is the future replacement for the legacy 'getProfile' service. It listens for route or document changes and updates our GlobalContext
//
//    How to use:
//      Speak with the Software engineers! The TLDR, you don't directly use this service. Instead, updating the route
//      or getUser context will trigger this service to update Firestore and maintain the Global Context!
//
//    Should I make changes to this service?
//      Without asking the Software Engineers, NO! This is a critical service and changes should be carefully reviewed!
//  
//------------------------------------------------------


//Libraries
// eslint-disable-next-line
import React, {useContext, useState, useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';

//Contexts
import {GetFireBaseUser, GetUser, SetUser, SetAppStatus, SetAppErrors} from '../Library/GlobalContexts';

//Functions & Actions
import DocumentListener from './DocumentListener';
import WriteDocument from './WriteDocument';


export default function UserContextController(){

  //------------------------------------------------------
  //  useParams from 'react-router'
  //------------------------------------------------------

    const [searchParams, setSearchParams] = useSearchParams();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const FirebaseUserObject = useContext(GetFireBaseUser);
    const getUser = useContext(GetUser);
    const setUser = useContext(SetUser);
    const setAppStatus = useContext(SetAppStatus);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Used to determine if the URL is first being loaded > true, false
    const [initialRoute, setInitialRoute] = useState(true);

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //------------------------------------------------------
    //  Onload > Trigger a request to update the users document
    //    1. Creates a document in the requests collection
    //    2. The requests collection is monitored by a CF 
    //    3. this picks up the job and invoked the MDB-UserMetadata service
    //------------------------------------------------------

    useEffect(() => {
      
      if (FirebaseUserObject === undefined) return;

      // Extract user's emailaddress
      const emailaddress = FirebaseUserObject.email.split('#ext#')[0].replace('_', '@');

      //Define request body
      const documentId = `req-${Date.now()}`;
      const requestBody = {
        'emailaddress': emailaddress,
        'request': 'userMetadata',
        'transactionid': documentId,
      };

      //Write to 'requests' collection
      WriteDocument('requests', documentId, requestBody).catch((error) =>{

        setAppErrors('Failed to request an update of the users profile, error', error);
        console.log('Failed to request an update of the users profile, error', error);

      });
       
    // eslint-disable-next-line
    },[FirebaseUserObject]);

    //------------------------------------------------------
    //  Onload > Setup the realtime listener for 'users' collection
    //    1. Listerns for changes in Firestore and updates the 'getUser' useContext
    //------------------------------------------------------

    useEffect(() => {
      
      if (FirebaseUserObject === undefined) return;

      // Extract user's emailaddress
      const emailaddress = FirebaseUserObject.email.split('#ext#')[0].replace('_', '@');

      // Handle users document load
      function onLoad(document){

        getUser.profile = document;

        // User document saved to the useContext (setUser will trigger the useEffect)
        setUser({...getUser});

      }

      // Handle changes in the users document
      function onChange(document){
        
        getUser.profile = document;

        // User document saved to the useContext, but DONT TRIGGER any useEffects
        // This is really important, please talk with Benno if the onLoad vs onChange doesn't make sense!
        setUser({...getUser});

      }

      // Handle errors
      function onError(error){

        // Unable to get user document!
        setAppStatus('failed');
        setAppErrors(`Unable to setup document listener for 'user' collection, error ${error}`);

      }

      // Setup the document listener
      const unsubscribe = DocumentListener('users', emailaddress, onLoad, onChange, onError);

      // Stop listening for changes when 'GetProfile' 'unMounts'
      // This is really important for other implementations of document listeners, if you have questions speak with Benno
      return () => {
        unsubscribe();
      }

    // eslint-disable-next-line
    },[FirebaseUserObject]);
  
    //------------------------------------------------------
    //  Onload > take route information > updated user useContext
    //    1. Example: http://localhost:3000/sharedvpc?view=portfolio&id=portfolio-1664335875
    //------------------------------------------------------

    useEffect(() => {

      // Get search parameters from the URL
      const view = searchParams.get('view');
      const id = searchParams.get('id');
      
      if (initialRoute === false) return;
      if (view === undefined || id === undefined) return setInitialRoute(false);
      if (id === null || view === null) return setInitialRoute(false);

      // Save the search parameters from the URL to the user context
      getUser.preferences.globalSelector.selectedView = view;
      getUser.preferences.globalSelector.selectedResource = id;

      setUser({...getUser});
      setInitialRoute(false);

    // eslint-disable-next-line
    }, [getUser]);

    //------------------------------------------------------
    //  OnChange > Update the searchParams
    //------------------------------------------------------

    useEffect(() => {

      // Get search parameters from the URL
      const view = searchParams.get('view');
      const id = searchParams.get('id');
      
      if (initialRoute === true) return;
      if (getUser.preferences.globalSelector.selectedView === view && getUser.preferences.globalSelector.selectedResource === id) return;

      searchParams.set('view', getUser.preferences.globalSelector.selectedView);
      searchParams.set('id', getUser.preferences.globalSelector.selectedResource);
      setSearchParams(searchParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

  //------------------------------------------------------
  //  Due to the rule of hooks, I am returning nothing in App.js
  //  This prevents the API from looping, but allows me to call it once!
  //------------------------------------------------------

  return null;
  
}
