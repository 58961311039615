//================================================================
//  Budgets page
//================================================================

//Libraries
import React, { useContext, useEffect, useState, useReducer } from 'react';

//Contexts
import { GetUser, SetAppErrors} from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import InputField from '../../Components/InputField/inputfield';
import ExportToCSV from '../../Components/ExportToCSV/ExportToCSV';

//Functions
import GetDocument from '../../Library/GetDocument';

//Images
import goToConsoleImg from '../../Components/Images/projects_goToConsole.svg';

//CSS
import './Budgets.css';


export default function Budgets() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Holds the inputs of the Form //
    const [formData, setFormData] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {

        // Search input
        search: "",

        // Firestore data
        portfolio: {},
        allProjects: [],
        filteredProjects: [],

      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function handleSearch(value){

      // Save search value
      formData.search = value;

      // Reset search
      if (value.length === 0)  {

        formData.filteredProjects = formData.allProjects;
        return setFormData(formData);

      } 

      // Filter by project id
      formData.filteredProjects = formData.allProjects.filter((project) => (
        project.projectid.startsWith(value)
      ));

      setFormData(formData);

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() => {

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);
  
      // Portfolio view was selected
      if (getUser.preferences.globalSelector.selectedView === 'portfolio') {

        // Extract project data based on the list of project ids
        GetDocument('portfolios', getUser.preferences.globalSelector.selectedResource).then((portfolio) => {
        
          formData.portfolio = portfolio;
          setFormData(formData);

          const projectPromises = [];
          portfolio.projects?.forEach((project) => {
           
            projectPromises.push(
              GetDocument('projects', project)
            )

          });

          return Promise.all(projectPromises).then((projects) => {

            const results = [];
            projects.forEach((project) => {

              results.push(project);

            });

            formData.allProjects = results;
            formData.filteredProjects = results;
            setFormData(formData);

            setPageStatus('onload');

          });
          

        }).catch((error) =>{

          setPageStatus('error-fatal');
          setAppErrors(`Unable to get Portfolio for ${getUser.preferences.globalSelector.selectedResource}, ${error}`);

        });

      }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getUser, setAppErrors]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedViews={['portfolio']}
        requireSelectedResource={true}
        requiredRoles={['portfolioOwner', 'portfolioViewer']}
        requiredRolesValue={getUser.preferences.globalSelector.selectedResource}
        status={pageStatus}
        header={
          <PageHeader
            title={'Budgets'}
            body={
              <>
                Overview of lendlease.cloud budgets for projects in '{formData.portfolio?.portfolioname}'
              </>
            }
          ></PageHeader>
        }
        body={
          <>

            <div className='Budget-Header'>

              {/* Search box */}
              <div className='Budget-Search'>
                <InputField
                  name={"search-box"}
                  placeholder={"Filter by project id"}
                  value={formData.search}
                  className="longinputfield"
                  onChange={(e) => handleSearch(e.target.value)}
                  >
                </InputField>
                
              </div>

              {/* Export to CSV */}
              <ExportToCSV
                filename='projects'
                headers={[
                  { label: "Project id", key: "projectid" },
                  { label: "Description", key: "description" },
                  { label: "Project owner", key: "projectowner" },
                  { label: "Budget", key: "budget" }
                ]}
                data={formData.filteredProjects} 
              ></ExportToCSV>

            </div>

            {/* Table */}
            <table className="Data-Table-Container">
              <colgroup>
                <col span="1" style={{width: "20%"}}></col>
                <col span="1" style={{width: "30%"}}></col>
                <col span="1" style={{width: "20%"}}></col>
                <col span="1" style={{width: "10%"}}></col>
                <col span="1" style={{width: "10%"}}></col>
              </colgroup>
              <tbody>
                <tr>
                  <th>Project id</th>
                  <th>Description</th>
                  <th>Project owner</th>
                  <th>Budget</th>
                  <th>Billing console</th>
                </tr>
                {
                  formData?.filteredProjects?.map((row, index) => (
                    <tr key={index}>
                      <td>{row.projectid}</td>
                      <td>{row.description}</td>
                      <td>{row.projectowner}</td>
                      <td>{row.budget}</td>
                      <td>
                        {/* Benno ~ Hardcoded the url to Prod */}
                        <a target='_blank' rel='noopener noreferrer' href={`https://console.cloud.google.com/billing/01AB67-830E86-AE70EE/reports;projects=${row.projectid}?organizationId=1021084428519`}>
                          <img style={{width: '20px'}} src={goToConsoleImg} alt="Go to project GCP console icon"></img>
                        </a>
                      </td>                            
                    </tr>
                  ))
                }
              </tbody>
            </table>

          </>
        }
      ></PageComponent>
    )
}