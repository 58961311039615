//Libraries
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

//Components
import UnauthorisedUser from '../UnauthorisedUser/unauthoriseduser'
import LoadingIcon from '../Images/Loading_Ripple.svg'

//Images
import GrumpyFatal from '../Images/GrumpyCat.png'
import PrideCat from '../../Components/Images/PrideCat.png';
import workingCatGraphic from '../Images/workingCatGraphic.svg'
import errorFatal from '../Images/errorFatal.svg'

//CSS
import './formresponse.css'

function FormResponse(props){

  //------------------------------------------------------
  //  How to use this component
  //-----------------------------------------------------

  // <FormResponse
  //   setRequestType={props.setRequestType}
  //   formStatus={formStatus}
  //   setFormStatus={setFormStatus}
  //   formDisclaimer={ <INSERT THE DISCLAIMER COMPONENT> }
  //   formOnload={ <INSERT YOUR FORM CONTENT>}
  //   formSuccessDescription={ <INSERT YOUR SUCCESSFUL TEXT>}
  //   apiResponse={apiResponse}
  //   workflowType={"http"} 
  //   initialState={"onload/pending/etc."} 
  // >
  // </FormResponse>

  //Component Properties
  //   setRequestType= setState function used to set the request type to 'view/create/modify/delete'
  //   formStatus= "onload/pending/accessdenied/disclaimer/error-invalid/error-fatal/error-timeout" - used to determine what is displayed on the page
  //   setFormStatus= setState function used to update the formStatus
  //   formDisclaimer={ <INSERT THE DISCLAIMER COMPONENT> }
  //   formOnload={ <INSERT YOUR FORM CONTENT>}
  //   formSuccessDescription={ <INSERT YOUR SUCCESSFUL TEXT>} - this is the success message you want to display on the page for your HTTP workflow
  //   apiResponse={apiResponse}
  //   workflowType={"http"} - determine the type of workflow (http/pubsub) and displays correct success page
  //   initialState= This is used in the 'Try Again' button to reset the form to it's initial state, e.g. 'onload'

  //------------------------------------------------------
  //  Extract props 
  //------------------------------------------------------
  
    const setRequestType = props.setRequestType
    const formStatus = props.formStatus
    const setFormStatus = props.setFormStatus
    const formDisclaimer = props.formDisclaimer
    const formOnload = props.formOnload
    const formSuccessDescription = props.formSuccessDescription
    const formErrorOther = props.formErrorOther
    const workflowType = props.workflowType
    // temp prop to handle redirects to homepage from project deletion -> Boolean value to alter breadcrumbs //

    //Handler if these values are null
    let transactionId;
    let errorMessage;
    let initialState;
  
    try {
      initialState = props.initialState
      transactionId = props.apiResponse.responseBody.transactionId
      errorMessage = props.apiResponse.responseBody.message
    } catch (error) {
      initialState = "onload"
      // eslint-disable-next-line
      transactionId = ""
      errorMessage = ""
    }
    
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------


    //Load the page whenever the page status changes 
    useEffect(() => {
      window.scrollTo(0, 0)
    },[formStatus])


  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    /*---------------------------------------------------- */
    /*   Status = 'accessdenied'                           */
    /*---------------------------------------------------- */

    if (formStatus === 'accessdenied'){

      return (
        <UnauthorisedUser
          message={
            <p>
              Sorry it seems like you do not have the necessary permissions to access this page. 
              <br></br>
              <br></br>
              If you feel this is a mistake, contact the Lendlease Cloud Team <a href = "https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96&sysparm_category=109f0438c6112276003ae8ac13e7009d" target="_blank" rel="noopener noreferrer"> here</a>.
            </p>
          }
          requestType={setRequestType}
        >
        </UnauthorisedUser>
      );
    }

    //------------------------------------------------------
    //  formStatus === "disclaimer"
    //------------------------------------------------------
    
    else if (formStatus === 'disclaimer'){
      return formDisclaimer;
    }

    //------------------------------------------------------
    //  formStatus == "onload"
    //------------------------------------------------------
    
    else if(formStatus === 'onload'){
      return formOnload;
    }

    //------------------------------------------------------
    //  formStatus === "pending"
    //------------------------------------------------------
    
    else if(formStatus === 'pending'){
      return (
        <div className="FormResponse-Container" style={{height: "100vh"}}>
          <img alt="loading-circle-icon" src={LoadingIcon}></img>
            <h3 style={{ color: "#383838"}}>Please wait while we process your request.</h3>
            <div className="pageresponse-button-container">
              <Link to="/home">
                <button className="form-submit-button">CANCEL</button>
              </Link>
            </div>
        </div>
      );
    }

    //------------------------------------------------------
    //  formStatus === "success"
    //------------------------------------------------------

    else if(formStatus === 'success'){
      
      //If 'workflowType' is 'http' > Display HTTP success page
      if(workflowType === 'http') {
        return (
          <div className="FormResponse-Success">

            {/* Success Image - Happy Cat*/}
            <img style={{width: "35%"}} src={PrideCat} alt="happy cat"/>

            {/* Success Title */}
            <p className="FormResponse-Success-Title">Request Successful</p>
  
            {/* Table Summary */}
            <div className="formresponse-table-container" >
  
              {/* Summary */}
              <div style={{fontSize: "18px", fontWeight: "lighter"}}>
                {formSuccessDescription}
              </div>
  
            </div>
          </div>
        );
      
      //Else (if pub/sub) > Display Pub/Sub success page
      } else {
        return(
          <div className="FormResponse-Success">
            
            {/* Success Image - Working Cat*/}
            <img style={{margin: "20px 0px"}} src={workingCatGraphic} alt="working cat"/>

            {/* Success Title */}
            <p className="FormResponse-Success-Title">Thanks for your request. We're working on it!</p>
  
            {/* Summary */}
            <div style={{fontSize: "18px", fontWeight: "lighter"}}>
              {formSuccessDescription ? (
                formSuccessDescription
              ) : (
                <>
                  <p>You will receive a confirmation email shortly.</p>
                  <p>Make sure you follow the steps in the email to complete your request.</p>
                </>
              )}

            </div>
          </div>
        )
      }
      
    }

    //------------------------------------------------------
    //  formStatus === "error-invalid"
    //------------------------------------------------------

    else if(formStatus === 'error-invalid'){
      return (
        <div className="FormResponse-Container">
          {/* Grumpy Cat Image */}
          <img style={{margin: "20px 0px"}} alt="Error Fatal Grumpy Cat" src={GrumpyFatal}></img>

          {/* Title */}
          <p className="FormResponse-Success-Title">Oops you've missed something</p>

          {/* Error Message */}
          <p style={{lineHeight: "1.7"}}> <b>Error:</b> {errorMessage}</p>
          <p> If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message above. </p>

          {/* Try Again Button */}
          <button className="form-submit-button" onClick={()=>{setFormStatus(initialState)}}>Try Again</button>
        </div>
      );
    }

    //------------------------------------------------------
    //  formStatus === "error-fatal"
    //------------------------------------------------------

    else if(formStatus === 'error-fatal'){
        return (
          <div className="FormResponse-Container">
            {/* Plug Image */}
            <img style={{margin: "20px 0px"}} alt="Error Fatal" src={errorFatal}></img>

            {/* Title */}
           <p className="FormResponse-Success-Title">Oops something went wrong</p>

            {/* Error Message */}
            <p style={{lineHeight: "1.7"}} >
              Click the button below to try again. 
              <br></br>
              If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
            </p>
            <p style={{lineHeight: "1.7"}}> <b>Error:</b> {errorMessage}</p>
            
            {/* Return Home Button */}
            <div>
              <Link to="/home">
                <button className="form-submit-button">Return Home</button>
              </Link>
            </div>
          </div>
      );
    }

    //------------------------------------------------------
    //  formStatus === "error-other"
    //------------------------------------------------------

    else if(formStatus === 'error-other'){
      return formErrorOther
    }

    //------------------------------------------------------
    //  formStatus === "error-timeout"
    //------------------------------------------------------

    else {
      return (
        <div className="FormResponse-Container">
          {/* Plug Image */}
          <img style={{margin: "20px 0px"}} alt="Error Timeout" src={errorFatal}></img>

          {/* Title */}
         <p className="FormResponse-Success-Title">Oops something went wrong</p>

          {/* Error Message */}
          <p style={{lineHeight: "1.7"}} >
            Click the button below to try again. 
            <br></br>
            If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
          </p>
          <p style={{lineHeight: "1.7"}}> <b>Error:</b> CSError-500</p>
          
          {/* Return Home Button */}
          <div>
            <Link to="/home">
              <button className="form-submit-button">Return Home</button>
            </Link>
          </div>
        </div>
      );
    }
  
  //------------------------------------------------------
}

export default FormResponse;