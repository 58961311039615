//================================================================
//  Create Project Form
//================================================================

//Libraries
import React, { useReducer, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import Disclaimer from '../../../Components/Disclaimer/disclaimer';
import QuestionLabel from '../../../Components/QuestionLabel';
import ErrorMessageLabel from '../../../Components/ErrorMessageLabel';
import SelectBox from '../../../Components/SelectBox/selectbox';
import InlineInputField from '../../../Components/InlineInputField/inlineinputfield';
import DataListField from '../../../Components/DataListField/datalistfield';
import DocumentLink from '../../../Components/KnowledgeHubComponents/documentlink';
import ConfirmationModal from '../../../Components/ConfirmationModal/confirmationmodal';

//Functions
import ReactBackend from '../../../Library/reactBackend';
import GetDocument from '../../../Library/GetDocument';
import { isString, errString, isStringEmail, isStringUnsignedCurrency } from '../../../Library/ValidateUserInput';


//Images
import StandardIcon from '../../../Components/Images/Standard_Icon.svg';
import SandboxIcon from '../../../Components/Images/Sandbox_Icon.svg';
import WarningIcon from '../../../Components/Images/Warning.png';
import workingCatGraphic from '../../../Components/Images/workingCatGraphic.svg';

//CSS
import '../ProjectCreate/ProjectCreate.css';


export default function ProjectCreate() {

  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------
    
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

    //React Router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  Define variables 
  //------------------------------------------------------

    var reservedWords = ["google", "lendlease", "appengine"]

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------
    
    //Used to change between > 'disclaimer', 'accessdenied', 'onload', 'pending', 'success', 'error-invalid', 'error-fatal'
    const [formStatus, setFormStatus] = useState('disclaimer');

    //Used to control visibility of fields for 'Standard' Projects
    const [hideStandardProjectFields, setHideStandardProjectFields] = useState('hide');

    //Used to control visibility of 'project Name' > only shown when a project type is selected
    const [hideProjectName, setHideProjectName] = useState('hide');
    const [hideEssentialContact, setHideEssentialContact] = useState('hide');

    //Used to determine if the invalid PTE confirmation modal is visible
    const [pteModalVisible, setPteModalVisible] = useState(false);

    //Hold list of all valid PTEs
    const [projectCodeList, setProjectCodeList] = useState();
    const [taskNumberList, setTaskNumberList] = useState([]);

    //Holds list of matching PTEs user can select from
    const [projectCodeOptions, setProjectCodeOptions] = useState([]);
    const [taskNumberOptions, setTaskNumberOptions] = useState([]);

    //Hold Oracleproject name for valid project code
    const [oracleProjectName, setOracleProjectName] = useState('');

    //Determines if the task number or expenditure type was automatically set
    const [taskNumberAutoSet, setTaskNumberAutoSet] = useState(false);
    const [expTypeAutoSet, setExpTypeAutoSet] = useState(false);


  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    //Holds the inputs of the Form
    const [selectedValue, setSelectedValue] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        projectId: "",
        projectType: "",
        projectName: "",
        description: "",
        budget: "",
        projectCode: "",
        taskNumber: "",
        expenditureType: "",
        supportContact: "",
        deploymentAccount: "",
      }
    );

    //Indicates whether PTE values are valid or not
    const [validPteValues, setValidPteValues] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        projectCode: false,
        taskNumber: false,
        expenditureType: true // Always true until expenditure type validation is possible
      }
    );

    //Used to toggle error messages
    const [errorState, setErrorState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
          projectTypeErrorVisible: false,
          projectTypeErrorMessage: "",
          projectNameErrorVisible: false,
          projectNameErrorMessage: "",
          budgetErrorVisible: false,
          budgetErrorMessage: "",
          projectCodeErrorVisible: false,
          projectCodeErrorMessage: "",
          taskNumberErrorVisible: false,
          taskNumberErrorMessage: "",
          expenditureTypeVisible: false,
          expenditureTypeErrorMessage: "",
          pteWarningVisible: false,
          pteWarningMessage: "",
          supportContactErrorVisible: false,
          supportContactErrorMessage: "",
          deploymentAccountErrorVisible: false,
          deploymentAccountErrorMessage: "",
          labelSupportQErrorVisible: false,
          labelSupportQErrorMessage: "",
          descriptionErrorVisible: false,
          descriptionErrorMessage: "",
          projectMgmtTypeErrorVisible: false,
          projectMgmtTypeErrorMessage: "",

        }
    );

  //------------------------------------------------------
  //  Load list of project codes
  //------------------------------------------------------

  if(projectCodeList === undefined) {
    GetDocument('oracleProjects', '+projectCodes')
    .then((results) => {
      if(results?.projectCodes === undefined || !Array.isArray(results.projectCodes)) {
        setAppErrors(`Could not retrieve project codes from document 'oracleProjects/+projectCodes' in Firestore`)
        setFormStatus('error-fatal')
        return;
      }

      setProjectCodeList(results.projectCodes)

    }).catch((error) => {
      setAppErrors(`Could not retrieve project codes from document 'oracleProjects/+projectCodes' in Firestore. Error: ${error.message}`)
      setFormStatus('error-fatal')
      return;
    })
  }

  //------------------------------------------------------
  //  Define Functions > Field Validation (Regex)
  //  * - Paste Regex in 'https://regexr.com/' for explanation
  //------------------------------------------------------

    //Function to validate the 'projectName'
    function IsValidProjectName(projectName) {  
      if (/^(?:[a-z](?:[a-z0-9]{0,22}[a-z0-9])?)$/.test(projectName)) {  
        return false;
      }
      return true;
    }  

    //Function to check for reserved words in the project name
    function checkReservedWords(projectName){

      var isValid = true;

      //Loop through the reserved words and check if it exists in the project name
      reservedWords.forEach(word => {

        if(projectName.toLowerCase().includes(word)){
          isValid = false;
        } 

      });

      return isValid
    }

    function IsValidDescription(description){
      if (description.length > 250 || !isString(description)){
        return true;
      }
      return false;
    }

    //Function to validate the project code
    function IsValidProjectCode(input) {  
      if (/^\d+$/.test(input)) {  
        return false;
      }  
      return true;
    } 

    //Function to validate the task number
    function IsValidTaskNumber(input) {  
      if (/^[a-zA-Z0-9.]+$/.test(input)) {
        return false;
      }  
      return true;
    } 

    //Function to validate the 'labelSupportQ'
    function IsValidLabelSupportQ(labelSupportQ) {  
      if (/^[a-z0-9](?:[a-z0-9_-\s]{0,29})(?:[a-z0-9])$/.test(labelSupportQ)) {  
        return false;
      } 
      
      return true;
    }

    //Setting Project - Sandbox or Standard 
    function SetProjectType(newValue) {

      if(newValue === "Standard"){
        selectedValue.budget = "";
        selectedValue.projectCode = "";
        selectedValue.taskNumber = "";
        selectedValue.expenditureType = "";
        selectedValue.supportContact = "";
        selectedValue.deploymentAccount = "";
        selectedValue.labelEnvironment = "";
        selectedValue.labelDataclass = "";
        selectedValue.labelSupportQ = "";
        selectedValue.projectManagementType = "";
        selectedValue.networkType = "standalone";

        setHideStandardProjectFields('edit')
        setHideProjectName('edit')
        setHideEssentialContact('read')
        clearProjectCodeInfo()
        
        // Reset some warnings to give users a chance
        setErrorState({
          budgetErrorVisible: false,
          projectCodeErrorVisible: false,
          taskNumberErrorVisible: false,
          expenditureTypeErrorVisible: false,
          pteWarningVisible: false
        })
      }

      else if(newValue === "Sandbox"){
        selectedValue.budget = "200";
        selectedValue.projectCode = "260018";
        selectedValue.taskNumber = "11050";
        selectedValue.expenditureType = "ICT Costs";
        selectedValue.supportContact = getUser?.profile?.emailaddress;
        selectedValue.deploymentAccount = "";
        selectedValue.labelEnvironment = "unknown";
        selectedValue.labelDataclass = "unknown";
        selectedValue.labelSupportQ = "na";
        selectedValue.projectManagementType = "na";
        selectedValue.networkType = "standalone";
        
        setHideStandardProjectFields('hide')
        setHideProjectName('edit')
        setHideEssentialContact('read')

        // All values are true as they are hard-coded
        setValidPteValues({
          projectCode: true,
          taskNumber: true,
          expenditureType: true
        })
        setErrorState({ pteWarningVisible: false })
      }
      else{
        setHideProjectName('hide')
        setHideEssentialContact('hide')
        clearProjectCodeInfo()
      }
    
      setSelectedValue({ 'projectType': newValue });
    }

    //Used to reset the selected view and resource and return to projects page
    function returnToProjects(){

      getUser.preferences.globalSelector.selectedView = 'organization';
      getUser.preferences.globalSelector.selectedResource = 'lendlease.cloud';

      navigate('/projects');

    }

    // No valid task number yet, so clear everything related to a task number
    function clearTaskNumberInfo() {
      setValidPteValues({ taskNumber: false });
      setTaskNumberOptions([]);
    }

    // No valid project code yet, so clear everything related to a project code
    function clearProjectCodeInfo() {
      setValidPteValues({ projectCode: false });
      setTaskNumberList([]);
      setProjectCodeOptions([]);
      setOracleProjectName('');
      clearTaskNumberInfo();
    }

    // Function to find matching project codes
    // If exact match, get info and task numbers
    function matchProjectCodes(input) {
      // Get list of matching project codes and show in project code datalist dropdown
      const searchResult = projectCodeList.filter(x => x.startsWith(input))
      setProjectCodeOptions(searchResult)

      // Exact match
      if(searchResult.length === 1 && searchResult[0] === input) {        
        // Get document for project code from oracleProjects collection
        GetDocument('oracleProjects', input)
        .then((results) => {

          // Project code has document, so accept as valid project code
          setValidPteValues({ projectCode: true })

          // Missing/invalid task number list, so cannot validate
          if(results?.taskNumbers === undefined || results.taskNumbers.constructor.name !== 'Object') {
            setTaskNumberList([]);
            clearTaskNumberInfo();
            return;
          }
          
          // Show the complete list of task numbers in the task number datalist dropdown
          setTaskNumberList(results.taskNumbers)

          // Set the project name if we have it
          if(results.projectName !== undefined) {
            setOracleProjectName(results.projectName)
          }
    
        // Something went wrong with this project code info, so pretend it's not valid
        }).catch((error) => {
          clearProjectCodeInfo()
          return;
        })

      // Haven't pinpointed on exact project code yet
      } else {
        setValidPteValues({ projectCode: false, taskNumber: false }) // Add expenditure type to this in future when expenditure type is validated
        setTaskNumberList([])
        setTaskNumberOptions([])
        setOracleProjectName('')
      }

    }

    // Function to find matching task numbers
    function matchTaskNumbers(input) {

      // We don't have a task number list, so we cannot search for it
      if(taskNumberList.length <= 0) {
        clearTaskNumberInfo();
        return;
      }

      // No value entered for task number, so show all valid task numbers
      if(input.length <= 0) {
        setTaskNumberOptions(Object.keys(taskNumberList))
        setValidPteValues({ taskNumber: false })
        return;
      }

      // Search for task number
      const searchResult = Object.keys(taskNumberList).filter(x => x.startsWith(input))
      setTaskNumberOptions(searchResult)

      // Exact match
      if(searchResult.length === 1 && searchResult[0] === input) {
        setValidPteValues({ taskNumber: true })

        // If no expenditure type provided by user and task number is 11050
        // set expenditure type to 'ICT Costs' automatically
        if(selectedValue.expenditureType.length <= 0 && input === '11050') {
          setSelectedValue({ expenditureType: 'ICT Costs' })
          fieldChangeValidation('expenditureType', 'ICT Costs', true) // autoset = true
        }

      // Multiple matches
      } else {
        setValidPteValues({ taskNumber: false })
      }
    }


    // Set the value for attachSVPC when tick box has been ticked
    function setNetworkType(checked) {

      if (checked) {
        selectedValue.networkType = "sharedvpc";

      } else {
        selectedValue.networkType = "standalone";
      }

    };

  //------------------------------------------------------
  //  Define Functions > callAPI & HandleResponse
  //------------------------------------------------------

    //Used to call "submitProjectRequest"
    async function callAPI(){

        var requestBody = {
          "requestType": "create",
          "projectType": selectedValue.projectType,
          "projectId": selectedValue.projectId,
          "projectName": selectedValue.projectName,
          "description": selectedValue.description,
          "givenname": getUser?.profile?.givenname,
          "surname": getUser?.profile?.surname,
          "budget": selectedValue.budget,
          "projectCode": selectedValue.projectCode,
          "taskNumber": selectedValue.taskNumber,
          "expenditureType": selectedValue.expenditureType,
          "supportContact": String(selectedValue.supportContact).toLowerCase(),
          "deploymentAccount": selectedValue.deploymentAccount,
          "labelEnvironment": selectedValue.labelEnvironment,
          "labelDataclass": selectedValue.labelDataclass,
          "labelSupportQ": selectedValue.labelSupportQ,
          "projectManagementType": selectedValue.projectManagementType,
          "networkType": selectedValue.networkType,
        }

        //Call React Backend - Submit Form Response
        var response = await ReactBackend('submitProjectRequest', requestBody)

        //Switch on formStatus
        if(response.status === 'success'){
              
          //Set formStatus to 'success'
            setFormStatus('error-other')

        } else if(response.status === 'error-invalid'){

          //Set formStatus to 'error-invalid'
          setFormStatus('error-invalid')

        } else if(response.status === 'error-fatal'){
            
          //Set formStatus to 'error-fatal'
          setFormStatus('error-fatal')

        } else if(response.status === 'error-timeout'){
              
          //Set formStatus to 'error-timeout'
          setFormStatus('error-timeout')

        }

        return response
    }   

  //------------------------------------------------------
  //  Function > Field change validation
  //------------------------------------------------------

  function fieldChangeValidation(name, newValue, autoSet) {

    //Validate field - projectName
    if(name === 'projectName'){

      //Field is empty > Set 'errorVisible' to true
      if(newValue.length <= 0){
        setErrorState({ projectNameErrorVisible: true });
        setErrorState({ projectNameErrorMessage: "Please enter a project name."});

      //Project Name is invalid > Set 'errorVisible' to true
      } else if(IsValidProjectName(newValue)){
        setErrorState({ projectNameErrorVisible: true });
        setErrorState({ projectNameErrorMessage: "Invalid project name. The project name must start with a lowercase letter and have up to 25 lowercase letters or numbers."});
        
      //Contains a reserved word > Set 'errorVisible' to true
      } else if(!checkReservedWords(newValue)){
        setErrorState({ projectNameErrorVisible: true });
        setErrorState({ projectNameErrorMessage: "Invalid project name. The project name cannot contain reserved words including 'lendlease', 'google' and 'appengine'."});
      
      } else {
          setErrorState({ projectNameErrorVisible: false });
      }
    }

    //Validate field - description
    if(name === 'description'){

      //Field is empty > Set 'errorVisible' to true
      if(newValue.length <= 0){
        setErrorState({ descriptionErrorVisible: true });
        setErrorState({ descriptionErrorMessage: "Please enter a description."});

      //Project Name is invalid > Set 'errorVisible' to true
      } else if(IsValidDescription(newValue)){
        setErrorState({ descriptionErrorVisible: true });
        setErrorState({ descriptionErrorMessage: `The description cannot be more than 250 characters and must contain ${errString}`});
        
      } else {
          setErrorState({ descriptionErrorVisible: false });
      }
    }

    //Validate field - projectManagementType
    if(name === 'projectManagementType'){

      //Field is empty > Set 'errorVisible' to true
      if(newValue.length <= 0){
        setErrorState({ projectMgmtTypeErrorVisible: true });
        setErrorState({ projectMgmtTypeErrorMessage: "Please select who will manage this project."});

      } else {
          setErrorState({ projectMgmtTypeErrorVisible: false });
      }
    }


    //Validate field - budget
    if(name === 'budget' ){

      //Field is empty > Set 'errorVisible' to true
      if(newValue.length <= 0){
        setErrorState({ budgetErrorVisible: true });
        setErrorState({ budgetErrorMessage: "Please enter a budget."});
      }
      else{
        if(!isStringUnsignedCurrency(newValue)){
          setErrorState({ budgetErrorVisible: true });
          setErrorState({ budgetErrorMessage: "Only numbers are allowed, 0 to 9 (cents optional, eg. 150.00)"});
        }else{
          setErrorState({ budgetErrorVisible: false });
        }
      }
    }


    //Validate field - projectCode
    if(name === 'projectCode'){
      //New data, so clear PTE warning
      setErrorState({ pteWarningVisible: false });

      // Any change to project code will clear the auto set task number and expenditure type
      if(taskNumberAutoSet) {
        setTaskNumberAutoSet(false)
        setSelectedValue({ taskNumber: '' })
        setErrorState({ taskNumberErrorVisible: true });
        setErrorState({ taskNumberErrorMessage: "Please enter a Task Number."});
      }

      if(expTypeAutoSet) {
        setExpTypeAutoSet(false)
        setSelectedValue({ expenditureType: '' })
        setErrorState({ expenditureTypeErrorVisible: true });
        setErrorState({ expenditureTypeErrorMessage: "Please enter a expenditure type."});
      }

      //Field is empty > Set 'errorVisible' to true and clear info derived from the project code
      if(newValue.length <= 0){
        setErrorState({ projectCodeErrorVisible: true });
        setErrorState({ projectCodeErrorMessage: "Please enter a Project Code."});
        clearProjectCodeInfo();
      }
      
      else{
        //Field is invalid > Set 'errorVisible' to true and clear info derived from the project code
        if(IsValidProjectCode(newValue)){
          setErrorState({ projectCodeErrorVisible: true });
          setErrorState({ projectCodeErrorMessage: "Only numbers are allowed, 0 to 9"});
          clearProjectCodeInfo();

        }else{
          setErrorState({ projectCodeErrorVisible: false });
          
          //Only bother processing if there's at least three characters
          if(newValue.length >= 3) {
            matchProjectCodes(newValue)

          //Definitely no matching project code, so clear info derived from the project code
          }else{
            clearProjectCodeInfo();
          }
        }
      }
    }


    //Validate field - taskNumber
    if(name === 'taskNumber'){
      //New data, so clear PTE warning
      setErrorState({ pteWarningVisible: false });

      // Any change to task number will clear the auto set expenditure type
      if(expTypeAutoSet) {
        setExpTypeAutoSet(false)
        setSelectedValue({ expenditureType: '' })
        setErrorState({ expenditureTypeErrorVisible: true });
        setErrorState({ expenditureTypeErrorMessage: "Please enter a expenditure type."});
      }

      // Indicates whether the field was changed automatically or by the user
      setTaskNumberAutoSet(autoSet)

      //Field is empty > Set 'errorVisible' to true and show all valid task numbers
      if(newValue.length <= 0){
        setErrorState({ taskNumberErrorVisible: true });
        setErrorState({ taskNumberErrorMessage: "Please enter a Task Number."});
        matchTaskNumbers(newValue)
      }

      else{
        //Field is invalid > Set 'errorVisible' to true and clear valid task number
        if(IsValidTaskNumber(newValue)){
          setErrorState({ taskNumberErrorVisible: true });
          setErrorState({ taskNumberErrorMessage: "Please enter a valid Oracle task number, e.g. 1000.1000. Numbers and dots allowed."});
          clearTaskNumberInfo();

        }else{
          setErrorState({ taskNumberErrorVisible: false });
          matchTaskNumbers(newValue)
        }
      }
    }


    //Validate field - expenditureType
    if(name === 'expenditureType'){
      //New data, so clear PTE warning
      setErrorState({ pteWarningVisible: false });

      // Indicates whether the field was changed automatically or by the user
      setExpTypeAutoSet(autoSet)

      //If field is empty > Set 'errorVisible' to true
      if(newValue.length <= 0){
        setErrorState({ expenditureTypeErrorVisible: true });
        setErrorState({ expenditureTypeErrorMessage: "Please enter a expenditure type."});
      
      } else if(!isString(newValue)){
        setErrorState({ expenditureTypeErrorVisible: true });
        setErrorState({ expenditureTypeErrorMessage: "Please enter a valid expenditure type."});
      
      } else {
        setErrorState({ expenditureTypeErrorVisible: false });
      }
    }

  
    //Validate field - supportContact
    if(name === 'supportContact'){
      
      //If field is invalid > Set 'errorVisible' to true
      if(newValue.length > 0 && !isStringEmail(newValue)){
        setErrorState({ supportContactErrorVisible: true });
        setErrorState({ supportContactErrorMessage: "Please enter a valid email address." });
      }else{
        setErrorState({ supportContactErrorVisible: false });
      }
    }


    //Validate field - deploymentAccount
    if(name === 'deploymentAccount'){
      
      //If field is invalid > Set 'errorVisible' to true
      if(newValue.length > 0 && !isStringEmail(newValue)){
        setErrorState({ deploymentAccountErrorVisible: true });
        setErrorState({ deploymentAccountErrorMessage: "Please enter a valid email address." });
      }else{
        setErrorState({ deploymentAccountErrorVisible: false });
      }
    }


    //Validate field - labelSupportQ
    if(name === 'labelSupportQ'){

      //a-z0-9_- characters, max 31 > Set 'errorVisible' to true
      if(newValue.length > 0 && IsValidLabelSupportQ(newValue)){
        setErrorState({ labelSupportQErrorVisible: true });
        setErrorState({ labelSupportQErrorMessage: "Invalid support queue name. Maximum 31, lower-case, alpha-numeric characters. Spaces allowed. Must start and end with a alpha-numeric character"});
      
      } else {
          setErrorState({ labelSupportQErrorVisible: false });
      }
    }

    //Set selected value to field values 
    setSelectedValue({ [name]: newValue });
    
  }

  //------------------------------------------------------
  //  Event Handler > Field Changes
  //------------------------------------------------------
  
    //Event Handler for field changes 
    const handleChange = evt => {
        
      const name = evt.target.name;
      const newValue = String(evt.target.value).trimStart();

      // Validate field that was changed (autoset = false)
      fieldChangeValidation(name, newValue, false);

    };

  //------------------------------------------------------
  //  Event Handler > On Exit Component (onBlur)
  //------------------------------------------------------
  
    //Event Handler for field changes 
    const handleBlur = evt => {

      const name = evt.target.name;
      
      // Process if PTE details have changed
      if (name === 'projectCode' || name === 'taskNumber' || name === 'expenditureType') {

        // Don't bother showing PTE warning if the fields haven't been filled out yet
        if (selectedValue.projectCode.length <= 0 || selectedValue.taskNumber.length <= 0 || selectedValue.expenditureType.length <= 0) return

        // Don't bother showing PTE warning if there are worse things going on
        if (errorState.projectCodeErrorVisible || errorState.taskNumberErrorVisible || errorState.expenditureTypeErrorVisible) return

        // Invalid project code
        if (!(validPteValues.projectCode)) {

          setErrorState({
            pteWarningMessage: `Unknown Oracle Project Code '${selectedValue.projectCode}' provided. You may proceed to submit however we recommended checking that your Oracle Project Code is correct.`,
            pteWarningVisible: true
          });

        // Invalid task number
        } else if (!(validPteValues.taskNumber)) {

          setErrorState({
            pteWarningMessage: `Unknown Task Number '${selectedValue.taskNumber}' provided. You may proceed to submit however we recommended checking that your Oracle Task Number is correct.`,
            pteWarningVisible: true
          });

        // Invalid expenditure type
        } else if (!(validPteValues.expenditureType)) {

          setErrorState({
            pteWarningMessage: `Unknown Expenditure Type '${selectedValue.expenditureType}' provided. You may proceed to submit however we recommended checking that your Oracle Expenditure Type is correct.`,
            pteWarningVisible: true
          });

        }
      }
    }

  //------------------------------------------------------
  //  Event Handler > Form Submission
  //------------------------------------------------------

    //Event handler when form is submitted > validate all fields
    function eventHandlerOnSubmit(){

      //This prevents the form from been submitted
      var preventSubmit = false;

      //projectType field empty > Set 'errorVisible' to true
      if(selectedValue.projectType.length <= 0){
        setErrorState({ projectTypeErrorVisible: true });
        setErrorState({ projectTypeErrorMessage: "Please select a request type." });
        preventSubmit = true;
      }

      //projectName field empty > Set 'errorVisible' to true
      if(selectedValue.projectName.length <= 0){
        setErrorState({ projectNameErrorVisible: true });
        setErrorState({ projectNameErrorMessage: "Please enter a Project Name." });
        preventSubmit = true;
      
      //Use regex to check projectname
      } else if(IsValidProjectName(selectedValue.projectName)){
          setErrorState({ projectNameErrorVisible: true });
          setErrorState({ projectNameErrorMessage: "Invalid project name. The project name must start with a lowercase letter and have up to 25 lowercase letters or numbers."});
          preventSubmit = true;
      
      //Check if reserved words used in projectName
      } else if(!checkReservedWords(selectedValue.projectName)){
        setErrorState({ projectNameErrorVisible: true });
        setErrorState({ projectNameErrorMessage: "Invalid project name. The project name cannot contain reserved words including 'lendlease', 'google' and 'appengine'."});
        preventSubmit = true;
      }

      //projectManagementType field empty > Set 'errorVisible' to true
      if(selectedValue.projectManagementType.length <= 0){
        setErrorState({ projectMgmtTypeErrorVisible: true });
        setErrorState({ projectMgmtTypeErrorMessage: "Please select who will manage this project." });
        preventSubmit = true;
      }

      //description field empty > Set 'errorVisible' to true
      if(selectedValue.description.length <= 0){
        setErrorState({ descriptionErrorVisible: true });
        setErrorState({ descriptionErrorMessage: "Please enter a description."});
        preventSubmit = true;
      }

      //Use regex to check description
      if(IsValidDescription(selectedValue.description)){
        setErrorState({ descriptionErrorVisible: true });
        setErrorState({ descriptionErrorMessage: `The description cannot be more than 250 characters and must contain ${errString}`});
        preventSubmit = true;
      }

      //budget field empty > Set 'errorVisible' to true
      if(selectedValue.budget.length <= 0){
        setErrorState({ budgetErrorVisible: true });
        setErrorState({ budgetErrorMessage: "Please enter a budget." });
        preventSubmit = true;
      }

      //Use regex to check budget
      else{
        if(!isStringUnsignedCurrency(selectedValue.budget)){
          setErrorState({ budgetErrorVisible: true });
          setErrorState({ budgetErrorMessage: "Only numbers are allowed, 0 to 9 (cents optional, eg. 150.00)"});
          preventSubmit = true;
        }
      }

      //projectCode field empty > Set 'errorVisible' to true
      if(selectedValue.projectCode.length <= 0){
        setErrorState({ projectCodeErrorVisible: true });
        setErrorState({ projectCodeErrorMessage: "Please enter a Project Code." });
        preventSubmit = true;
      }

      //Use regex to check projectCode
      else{
        if(IsValidProjectCode(selectedValue.projectCode)){
          setErrorState({ projectCodeErrorVisible: true });
          setErrorState({ projectCodeErrorMessage: "Only numbers are allowed, 0 to 9"});
          preventSubmit = true;
        }
      }

      //taskNumber field empty > Set 'errorVisible' to true
      if(selectedValue.taskNumber.length <= 0){
        setErrorState({ taskNumberErrorVisible: true });
        setErrorState({ taskNumberErrorMessage: "Please enter a Task Number." });
        preventSubmit = true;
      }

      //Use regex to check taskNumber
      else{
        if(IsValidTaskNumber(selectedValue.taskNumber)){
          setErrorState({ taskNumberErrorVisible: true });
          setErrorState({ taskNumberErrorMessage: "Please enter a valid Oracle task number, e.g. 1000.1000. Numbers and dots allowed."});
          preventSubmit = true;
        }
      }

      //Validate field - expenditureType
      if(selectedValue.expenditureType.length <= 0){
        setErrorState({ expenditureTypeErrorVisible: true });
        setErrorState({ expenditureTypeErrorMessage: "Please enter a expenditure type."});
        preventSubmit = true;
      }

      //Use regex to check expenditureType
      else{
        if(!isString(selectedValue.expenditureType)){
          setErrorState({ expenditureTypeErrorVisible: true });
          setErrorState({ expenditureTypeErrorMessage: "Please enter a valid expenditure type."});
          preventSubmit = true;
        }
      }
      //Set supportContact to project owner if not populated
      if (selectedValue.supportContact.length <= 0){
        selectedValue.supportContact = getUser?.profile?.emailaddress;
      }

      //Use regex to check supportContact is valid  > Set 'errorVisible' to true
      else{
        if(!isStringEmail(selectedValue.supportContact)){
          setErrorState({ supportContactErrorVisible: true });
          setErrorState({ supportContactErrorMessage: "Please enter a valid email address." });
          preventSubmit = true;
        }
      }

      //deploymentAccount field populated > Check service account structure is valid!
      if(selectedValue.deploymentAccount.length > 0){
        if(!isStringEmail(selectedValue.deploymentAccount)){
          setErrorState({ deploymentAccountErrorVisible: true });
          setErrorState({ deploymentAccountErrorMessage: "Please enter a valid email address." });
          preventSubmit = true;
        }
      }

      //Validate field - labelSupportQ
      if(selectedValue.labelSupportQ.length > 0){
        //a-z0-9_- characters, max 31 > Set 'errorVisible' to true
        if(IsValidLabelSupportQ(selectedValue.labelSupportQ)){
          setErrorState({ labelSupportQErrorVisible: true });
          setErrorState({ labelSupportQErrorMessage: "Invalid support queue name. Maximum 31, lower-case, alpha-numeric characters. Spaces allowed.  Must start and end with a alpha-numeric character"});
          preventSubmit = true;
        }
      }

      // No validation errors preventing form from being submitted
      if (preventSubmit === false){

        // Invalid PTE. Show confirmation to proceed or not
        if (!pteModalVisible && errorState.pteWarningVisible) {
          setPteModalVisible(true);

        //Allow the form to be submitted
      } else {        
          setFormStatus('pending')
          callAPI()
        }
      }

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // When the task numbers for a project code are made available
    useEffect(() => {

      // Haven't initialised yet, so no point validating task number
      if (selectedValue === undefined) return;
      if (selectedValue.taskNumber === undefined) return;
      if (validPteValues.projectCode === null) return;

      // If no task number provided by user and 11050 is a valid task number for this project
      // set it automatically
      if(selectedValue.taskNumber.length <= 0 && Object.keys(taskNumberList).includes('11050')) {
        setSelectedValue({ taskNumber: '11050' })
        fieldChangeValidation('taskNumber', '11050', true) // autoset = true
      
      // Re-check validity of whatever task is there
      // Cannot do full validation in case it's a new form load
      } else {
        matchTaskNumbers(selectedValue.taskNumber)
      }

    // eslint-disable-next-line
    }, [taskNumberList]);

    
//------------------------------------------------------
//  HTML
//------------------------------------------------------

    return (
      <PageComponent
        requireSelectedResource={false}
        requiredRoles={['lendleaseAccount']}
        requiredRolesValue={'true'}
        breadcrumb={{
          "name": "Projects",
          "route": "/projects"
        }}
        header={
          <PageHeader
            title={'Project Create'}
            body={
              <p> Please complete the form below to create a Google Cloud project.</p>
            }
          ></PageHeader>
        }
        status={formStatus}
        disclaimerContent={
          <Disclaimer
            cancelRoute="/projects"
            setStatus={setFormStatus}
            setStatusValue="onload"
            acceptanceCriteria={[
              <ul>
                <li>I am authorized to perform this action.</li>
                <li>I am a Lendlease employee.</li>
                <li>I understand and acknowledge the <a href="https://info.lendlease.cloud/servicedescriptions" target="_blank" rel="noopener noreferrer">Service Descriptions</a>.</li>
              </ul>
            ]}
              >
          </Disclaimer>
        }
        body={    
          <div className="Form-Container">
            <div className="Form-Body">

              {/*------------------------------------------------------------------ */}
              {/*   Project Type * === 'Sandbox' or 'Standard' *                     */}
              {/*------------------------------------------------------------------ */}   

                <p style={{fontWeight: "bold", fontSize: "18px"}}>Select your project type</p>

                <p>Types of Projects</p>

                <ul style={{lineHeight: "1.7"}}> 
                  <li> <b>Sandbox</b> - A sandbox project is active for 14 days, with $200 of free credit for all Lendlease users. Any remaining credit may be consumed in future sandbox projects.
                  </li>
                  <li><b>Standard</b> - A standard project that has no expiration date however all Google consumption and shared infrastructure costs will be charged to your project code and task number.
                  </li>
                </ul>
                
                <div className='Project-Type-Container'>
                  <div className={selectedValue.projectType === 'Sandbox'?('Project-Icon-Selected'):('Project-Type-Icon')} onClick={()=>SetProjectType('Sandbox')}>
                    <img src={SandboxIcon} alt="Sandbox Icon"></img>
                    <p>Sandbox</p>
                    
                  </div>
                  <div className={selectedValue.projectType === 'Standard'?('Project-Icon-Selected'):('Project-Type-Icon')}onClick={()=>SetProjectType('Standard')}>
                    <img src={StandardIcon} alt="Standard Icon"></img>
                    <p> Standard</p>
                  </div>
                </div>
                
              {/*------------------------------------------------------------------*/}
              {/*   Project Name *                                                 */}
              {/*------------------------------------------------------------------*/}              
              
                <QuestionLabel visibility={hideProjectName} question={"Project Name *"} helpText = {"Your project name must start with a lowercase letter. It can have up to 25 lowercase letters or numbers. To guarantee uniqueness, a random 5 digit number will automatically be appended when your project is created."}></QuestionLabel>
                
                <InlineInputField
                  name={"projectName"}
                  placeholder={"e.g. myapplicationprod"}
                  value={selectedValue.projectName}
                  position="left"
                  helpText = {"Enter in your GCP project name."}
                  errorVisible={errorState.projectNameErrorVisible}
                  visibility={hideProjectName}
                  inputFieldLength="shortinputfield"
                  onChange={handleChange}
                >
                </InlineInputField>

                <ErrorMessageLabel errorMessage={errorState.projectNameErrorMessage} errorVisible={errorState.projectNameErrorVisible && hideProjectName === 'edit'}></ErrorMessageLabel>

              {/*------------------------------------------------------------------*/}
              {/*   Project Description *                                        */}
              {/*------------------------------------------------------------------*/}              

              <QuestionLabel visibility={hideProjectName} question={"Description *"} helpText = {"Your description must not exceed 250 characters."}></QuestionLabel>
                
                <InlineInputField
                  name={"description"}
                  placeholder={"e.g. This is your project description"}
                  value={selectedValue.description}
                  position="left"
                  helpText = {"Enter in your project description."}
                  errorVisible={errorState.descriptionErrorVisible}
                  visibility={hideProjectName}
                  inputFieldLength="shortinputfield"
                  onChange={handleChange}
                >
                </InlineInputField>

                <ErrorMessageLabel errorMessage={errorState.descriptionErrorMessage} errorVisible={errorState.descriptionErrorVisible && hideProjectName === 'edit'}></ErrorMessageLabel>

              {/*------------------------------------------------------------------ */}
              {/*   Project Management Type                                         */}
              {/*------------------------------------------------------------------ */}   

              <QuestionLabel visibility={hideStandardProjectFields} question={"Management Type *"} helpText = {"Select who will manage this project."}></QuestionLabel>
              
              <SelectBox
                formId={"projectManagementType"}
                name={"projectManagementType"}
                placeholder={"Select Management Type"}
                value={selectedValue.projectManagementType}
                arrayOfOptions={["Accenture", "Capgemini", "Self-Managed"]}
                onChange={handleChange}
                visibility={hideStandardProjectFields}
              >
              </SelectBox>
              
              <ErrorMessageLabel errorMessage={errorState.projectMgmtTypeErrorMessage} errorVisible={errorState.projectMgmtTypeErrorVisible && hideStandardProjectFields === 'edit'}></ErrorMessageLabel>

              {/*------------------------------------------------------------------ */}
              {/*   Network Type [Optional: Defaults to false = standalone network] */}
              {/*------------------------------------------------------------------ */}

              <div hidden={hideStandardProjectFields === "hide"} className="question-label">
                <br></br><br></br>

                <input
                  type="checkbox"
                  name="networkType"
                  onChange={(e) => setNetworkType(e.target.checked)}
                />

                <label for="networkType"> Connect project to the Internal Network?</label>

                <br></br><br></br>
              </div>

              {/*------------------------------------------------------------------ */}
              {/*   Budget *                                                        */}
              {/*------------------------------------------------------------------ */}

                <QuestionLabel visibility={hideStandardProjectFields} question={"Monthly Budget *"} helpText = {"You will receive a budget alert when you reach 50%, 75% and 100% of your actual spend. Your project will still continue to operate as normal after reaching the 100% threshold. Refer to the 'Get Help' page for more info"}></QuestionLabel>
                
                <InlineInputField
                  name={"budget"}
                  placeholder={"e.g. 200"}
                  value={selectedValue.budget}
                  position = "middle"
                  inlinefieldlabel = "$"
                  inlinefieldlabelEnd = "(USD)"
                  errorVisible={errorState.budgetErrorVisible}
                  inputFieldLength="shortinputfield"
                  onChange={handleChange}
                  visibility={hideStandardProjectFields}
                >
                </InlineInputField>
                
                <ErrorMessageLabel errorMessage={errorState.budgetErrorMessage} errorVisible={errorState.budgetErrorVisible && hideStandardProjectFields === 'edit'}></ErrorMessageLabel>

              {/*------------------------------------------------------------------ */}
              {/*   Project Code  *                                                 */}
              {/*------------------------------------------------------------------ */}

                <QuestionLabel visibility={hideStandardProjectFields} question={"Oracle Project Code *"} helpText = {"Lendlease Project Code"}></QuestionLabel>
                  
                  <DataListField
                    name={"projectCode"}
                    placeholder={"e.g. 26000"}
                    value={selectedValue.projectCode}
                    position = "right"
                    errorVisible={errorState.projectCodeErrorVisible}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    visibility={hideStandardProjectFields}
                    options={projectCodeOptions}
                  >
                  </DataListField>

                  <ErrorMessageLabel errorMessage={errorState.projectCodeErrorMessage} errorVisible={errorState.projectCodeErrorVisible && hideStandardProjectFields === 'edit'}></ErrorMessageLabel>
                  
                  { /* Show Oracle project name if we have a valid project code */
                    oracleProjectName.length > 0 && hideStandardProjectFields === 'edit' ? (
                      <div className='Project-Create-response-label'>{oracleProjectName}</div>
                    ) : (null)
                  }

                {/*------------------------------------------------------------------ */}
                {/*   Task Number *                                                   */}
                {/*------------------------------------------------------------------ */}

                  <QuestionLabel visibility={hideStandardProjectFields} question={"Oracle Task Number *"} helpText = {"Lendlease Task Number, e.g. 2000.11050"}></QuestionLabel>

                  <DataListField
                    name={"taskNumber"}
                    placeholder={"e.g. 2000.11050"}
                    value={selectedValue.taskNumber}
                    position = "right"
                    errorVisible={errorState.taskNumberErrorVisible}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    visibility={hideStandardProjectFields}
                    options={taskNumberOptions}
                  >
                  </DataListField>

                  <ErrorMessageLabel errorMessage={errorState.taskNumberErrorMessage} errorVisible={errorState.taskNumberErrorVisible && hideStandardProjectFields === 'edit'}></ErrorMessageLabel>

                {/*------------------------------------------------------------------ */}
                {/*   Expenditure Type *                                              */}
                {/*------------------------------------------------------------------ */}

                  <QuestionLabel visibility={hideStandardProjectFields} question={"Oracle Expenditure Type *"} helpText = {"Where the project costs will be allocated, for example 'ICT Costs'."}></QuestionLabel>

                  <DataListField
                    name={"expenditureType"}
                    placeholder={"e.g. ICT Costs"}
                    value={selectedValue.expenditureType}
                    position = "right"
                    errorVisible={errorState.expenditureTypeErrorVisible}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    visibility={hideStandardProjectFields}
                    options={[]}
                  >
                  </DataListField>

                  <ErrorMessageLabel errorMessage={errorState.expenditureTypeErrorMessage} errorVisible={errorState.expenditureTypeErrorVisible && hideStandardProjectFields === 'edit'}></ErrorMessageLabel>

                {/*------------------------------------------------------------------ */}
                {/*   Invalid PTE label (if required)                                 */}
                {/*------------------------------------------------------------------ */}

                  {
                    errorState.pteWarningVisible && hideStandardProjectFields === 'edit' ? (
                      <div className='PageComponent-Label'>
                        <div className='PageComponent-LabelMessage'>
                          <img style={{marginRight: "15px"}} src={WarningIcon} alt="Warning Icon"></img>
                          <p>
                            {errorState.pteWarningMessage}
                          </p>
                        </div>
                      </div>
  
                    ) : (null)
                  }

                {/*------------------------------------------------------------------ */}
                {/*   Support contact                                                 */}
                {/*------------------------------------------------------------------ */}

                  <QuestionLabel visibility={hideStandardProjectFields} question={"Support Notifications Email (Optional)"} helpText = {"Email address for the employee/team responsible for all support-related questions. Defaults to the Project Owner if not provided."}></QuestionLabel>

                  <InlineInputField
                    name={"supportContact"}
                    placeholder={"e.g. myapp.support@lendlease.com"}
                    value={selectedValue.supportContact}
                    position = "right"
                    errorVisible={errorState.supportContactErrorVisible}
                    inputFieldLength="shortinputfield"
                    onChange={handleChange}
                    visibility={hideStandardProjectFields}
                  >
                  </InlineInputField>

                  <ErrorMessageLabel errorMessage={errorState.supportContactErrorMessage} errorVisible={errorState.supportContactErrorVisible && hideStandardProjectFields === 'edit'}></ErrorMessageLabel>

                {/*------------------------------------------------------------------ */}
                {/*   Deployment Account                                              */}
                {/*------------------------------------------------------------------ */}

                  <QuestionLabel visibility={hideStandardProjectFields} question={"Deployment Service Account (Optional)"} helpText = {"If provided, this service account (from another project) is granted the Security Admin role when the project is created, enabling the project to be managed using Infrastructure as Code."}></QuestionLabel>

                  <InlineInputField
                    name={"deploymentAccount"}
                    placeholder={"e.g. service-account@projectId.iam.gserviceaccount.com"}
                    value={selectedValue.deploymentAccount}
                    position = "right"
                    errorVisible={errorState.deploymentAccount}
                    inputFieldLength="shortinputfield"
                    onChange={handleChange}
                    visibility={hideStandardProjectFields}
                  >
                  </InlineInputField>

                  <ErrorMessageLabel errorMessage={errorState.deploymentAccountErrorMessage} errorVisible={errorState.deploymentAccountErrorVisible && hideStandardProjectFields === 'edit'}></ErrorMessageLabel>

                {/*------------------------------------------------------------------*/}
                {/*   Essential contact                                              */}
                {/*------------------------------------------------------------------*/}             

                <QuestionLabel question={"Essential contact"} 
                  visibility={hideEssentialContact}
                  helpText = {[
                    <div style={{textDecoration: "none"}}>
                      Essential Contacts, is a Google Cloud feature that allows Project Owners and Project Operators to receive notifications about Google services.
                      To find out more, click <DocumentLink docId="222894589182" title="here"></DocumentLink>.
                    </div>
                  ]}
                ></QuestionLabel>
                  
                  <InlineInputField
                    value={getUser?.profile?.emailaddress}
                    position="left"
                    visibility={hideEssentialContact}
                    inputFieldLength="shortinputfield"
                    onChange={handleChange}
                  >
                  </InlineInputField>

              {/*------------------------------------------------------------------ */}
              {/*   Project Label - environment                                     */}
              {/*------------------------------------------------------------------ */}   

              <QuestionLabel visibility={hideStandardProjectFields} question={"Project Environment (Optional)"} helpText = {"Select an environment type that best describes your project's intended use"}></QuestionLabel>
              
              <SelectBox
                formId="labelEnvironment"
                name="labelEnvironment"
                placeholder="Select environment label"
                value={selectedValue.labelEnvironment}
                arrayOfOptions={["unknown", "prototype", "development", "test", "uat", "stage", "preprod", "production"]}
                onChange={handleChange}
                visibility={hideStandardProjectFields}
              >
              </SelectBox>
              
              {/*------------------------------------------------------------------ */}
              {/*   Project Label - dataclass                                       */}
              {/*------------------------------------------------------------------ */}   

              <QuestionLabel visibility={hideStandardProjectFields} question={"Project Dataclass (Optional)"} helpText = {"Select a classification which best reflects any data stored (or processed) in the project"}></QuestionLabel>
              
              <SelectBox
                formId="labelDataclass"
                name="labelDataclass"
                placeholder="Select dataclass label"
                value={selectedValue.labelDataclass}
                arrayOfOptions={["unknown", "public", "internal", "confidential", "restricted"]}
                onChange={handleChange}
                visibility={hideStandardProjectFields}
              >
              </SelectBox>

                {/*------------------------------------------------------------------*/}
                {/*   Project Label - support                                        */}
                {/*------------------------------------------------------------------*/}              

                <QuestionLabel visibility={hideStandardProjectFields} question={"Support Queue Name (Optional)"} helpText = {"If applicable, enter a support queue name for internal support cases to be sent. Maximum 31, lower-case, alpha-numeric characters.  Spaces allowed.  Must start and end with a alpha-numeric character."}></QuestionLabel>

                <InlineInputField
                  name={"labelSupportQ"}
                  placeholder={"e.g. is - Lendlease Cloud"}
                  value={selectedValue.labelSupportQ}
                  position = "right"
                  errorVisible={errorState.labelSupportQ}
                  inputFieldLength="shortinputfield"
                  onChange={handleChange}
                  visibility={hideStandardProjectFields}
                >
                </InlineInputField>

                <ErrorMessageLabel errorMessage={errorState.labelSupportQErrorMessage} errorVisible={errorState.labelSupportQErrorVisible && hideStandardProjectFields === 'edit'}></ErrorMessageLabel>

              {/*------------------------------------------------------------------ */}
              {/*   Submit Button                                                   */}
              {/*------------------------------------------------------------------ */}   

              {/* Sorry for the below - Benno told me to do it! Hack to get submit button to appear when a project type is selected  */}
              <div hidden={hideProjectName === 'hide' ? true : false}>
                <div className='Button-Group'>
                  <button className="Primary-Button" onClick={()=>eventHandlerOnSubmit()}>Submit</button>
                  <button className='Secondary-Button' onClick={() => returnToProjects()}>Cancel</button>
                </div>
              </div>

            </div>

            {/*------------------------------------------------------------------ */}
            {/*   Confirmation Modal                                              */}
            {/*------------------------------------------------------------------ */}   
            
            <ConfirmationModal
              header="Do you wish to continue?"
              body={[
                <div>
                  <p>{errorState.pteWarningMessage}</p>
                  <p>If you wish to continue regardless, click <b>Continue</b>.</p>
                  <p>If you would like to go back without saving your changes, click <b>Cancel</b>.</p>
                </div>
              ]}
              modalVisible={pteModalVisible}
              setModalVisible={setPteModalVisible}
              eventHandlerOnSubmit={eventHandlerOnSubmit}
            ></ConfirmationModal>
          </div>
        }

        // This is the Success Page - without a header
        errorOtherContent={
          <div style={{lineHeight: "2.5"}} className="PageComponent-Errors-Container">
            <img style={{margin: "20px 0px", width:"40%"}} src={workingCatGraphic} alt="working cat"/>

            <h3>Your project is being prepared!</h3>
            <p>You will receive a confirmation email shortly.
            <br></br>
            First time creating a project? Make sure you follow the steps in the email to complete your GCP set up.
            <br></br>
            <b>Quick Tip: </b> Once your project is ready, click the edit icon next to your project on the projects page to configure your project settings.
            </p>
          </div>
        }
      ></PageComponent>
    )

}
