//================================================================
//  Page: Accept Ownership Transfer
//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import Disclaimer from '../../../Components/Disclaimer/disclaimer';
import QuestionLabel from '../../../Components/QuestionLabel/index';
import InlineInputField from '../../../Components/InlineInputField/inlineinputfield';
import ErrorMessageLabel from '../../../Components/ErrorMessageLabel/index';
import AcceptOwnershipTransferForm from './AcceptOwnershipTransferForm';
import ConfirmationModal from '../../../Components/ConfirmationModal/confirmationmodal'

//Images
import PrideCat from '../../../Components/Images/PrideCat.png';

//Functions
import WriteDocument from '../../../Library/WriteDocument';
import GetDocument from '../../../Library/GetDocument';

//CSS
import './AcceptOwnershipTransfer.css'


export default function AcceptOwnershipTransfer() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

    //React Router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Status of the component > 'disclaimer', 'onload', 'pending', 'success', 'error-fatal'
    const [formStatus, setFormStatus] = useState('disclaimer');

    //Prevents an incomplete form from been submitted
    const [preventSubmit, setPreventSubmit] = useState();
    const [preventSubmitAll, setPreventSubmitAll] = useState(true);

    //Indicates whether there's a PTE validation error or not
    const [pteValid, setPteValid] = useState();
    const [pteValidAll, setPteValidAll] = useState(false);

    //Hold list of all valid PTEs
    const [projectCodeList, setProjectCodeList] = useState();

    //Used to determine if the modal is visible
    const [modalVisible, setModalVisible] = useState(false);


  //------------------------------------------------------
  //  Load list of project codes
  //------------------------------------------------------

  if(projectCodeList === undefined) {
    GetDocument('oracleProjects', '+projectCodes')
    .then((results) => {
      if(results?.projectCodes === undefined || !Array.isArray(results.projectCodes)) {
        setAppErrors(`Could not retrieve project codes from document 'oracleProjects/+projectCodes' in Firestore`)
        setFormStatus('error-fatal')
        return;
      }

      setProjectCodeList(results.projectCodes)

    }).catch((error) => {
      setAppErrors(`Could not retrieve project codes from document 'oracleProjects/+projectCodes' in Firestore. Error: ${error.message}`)
      setFormStatus('error-fatal')
      return;
    })
  }

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function SubmitHandler(){

      //Conditions
      if (getUser?.preferences?.manageOwnershipTransfer === undefined) return;
      if (preventSubmitAll) return;

      if(!modalVisible && !pteValidAll) {
        setModalVisible(true);
        return
      }

      //Set page to pending
      setFormStatus('pending');

      // Write the projects array to 'pteedits' in 'project-owner-transfer' collection
      // Set the 'status' as 'pending'
      const document = {
        'pteedits': getUser?.preferences?.manageOwnershipTransfer.projects,
        'status': 'pending'
      }
      WriteDocument('project-owner-transfer', getUser?.preferences?.manageOwnershipTransfer.transactionid, document, true)
      .then(() =>{

         setFormStatus('success');

      })
      .catch((error) =>{

        setFormStatus('error-fatal');
        setAppErrors(`Failed to write acceptance of project transfer, error ${error}`);

      })

    }

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    useEffect(() =>{

      // Only allow people to reach this page through 'ManageOwnershipTransfer'
      if (getUser.preferences === undefined) return;
      if (getUser.preferences.manageOwnershipTransfer === undefined) return navigate('/projects/ownershiptransfer');
      if (getUser?.preferences?.manageOwnershipTransfer?.projects === undefined) return navigate('/projects/ownershiptransfer');

      // First time load, so initialise per project statuses
      if (pteValid === undefined) {
        let pteValidTemp = {}
        let preventSubmitTemp = {}

        for (const object of getUser?.preferences?.manageOwnershipTransfer?.projects) {
          pteValidTemp[object.projectid] = true
          preventSubmitTemp[object.projectid] = false
        }

        setPteValid({...pteValidTemp})
        setPreventSubmit({...preventSubmitTemp})
      }

    }, [getUser, navigate, pteValid, setPteValid, preventSubmit, setPreventSubmit]);

    // Update the overall submit status and PTE validity based on each project
    useEffect(() =>{

      // Page not yet initialised
      if (preventSubmit === undefined) return;
      if (pteValid === undefined) return;

      let newPreventSubmit = false

      for (const value of Object.values(preventSubmit)) {
        if (value) {
          newPreventSubmit = true
        }
      }

      setPreventSubmitAll(newPreventSubmit)

      let newPteValid = true

      for (const value of Object.values(pteValid)) {
        if (!value) {
          newPteValid = false
        }
      }

      setPteValidAll(newPteValid)

    }, [preventSubmit, setPreventSubmitAll, pteValid, setPteValidAll]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      requireSelectedResource={true}
      requireSelectedViews={['organization']}
      requiredRoles={['lendleaseAccount']}
      requiredRolesValue={'true'}
      status={formStatus}
      breadcrumb={{
        "name": "Manage Project Ownership", 
        "route": "/projects/ownershiptransfer",
        "view": "organization",
        "resource": "lendlease.cloud"
      }}
      header={
        <PageHeader
          title={'Accept Project Ownership Form'}
          body={
            <p>
              Transfer current projects you own to other cloud passport members as well as manage your pending transfers.
              <br></br>
              You are also able to accept oustanding projects transferred into your name. 
            </p>  
          }
        ></PageHeader>
      }
      disclaimerContent={
        <Disclaimer
          cancelRoute={'/projects/ownershiptransfer'}
          setStatus={setFormStatus}
          setStatusValue='onload'
          acceptanceCriteria={[
            <ul>
              <li>I am authorized to perform this action.</li>
              <li>I am a Lendlease employee.</li>
              <li>I understand and acknowledge the <a href="https://info.lendlease.cloud/servicedescriptions" target="_blank" rel="noopener noreferrer">Service Descriptions</a>.</li>
            </ul>
          ]}
        >
        </Disclaimer>
      }
      body={
        <div className='Form-Container'>
          <div className='Form-Body'>

            {/* Previous Project Owner */}
            <div>
              <QuestionLabel question={"Previous Project Owner"}></QuestionLabel>
              <InlineInputField
                name={"projectOwner"}
                placeholder={"e.g. ICT Costs"}
                value={getUser?.preferences?.manageOwnershipTransfer?.requester}
                visibility='read'
                position = "right"
                inputFieldLength="mediuminputfield"
                >
              </InlineInputField>
            </div>

            {/* Projects */}
            <div>
              <QuestionLabel question={"Projects"}></QuestionLabel>
              <strong>Note</strong>: Cost centre changes made before 5th of the month will take effect in this billing cycle.
              Cost centre changes made after 6th of the month will take effect in next months billing cycle.
            </div>

            {/* Cost Centre Form/Table */}
            <table className='AcceptOwnershipTransfer-Form-Table'>
              <tbody>
                <tr style={{padding: '10px'}}>
                  <th><QuestionLabel question="Project id"></QuestionLabel></th>
                  <th><QuestionLabel question="Project code" helpText="Lendlease Project Code"></QuestionLabel></th>
                  <th><QuestionLabel question="Task number" helpText="Lendlease Task Number, e.g. 2000.11050"></QuestionLabel></th>
                  <th><QuestionLabel question="Expenditure type" helpText="Where the project costs will be allocated, for example 'ICT Costs'."></QuestionLabel></th>
                </tr>
                {
                  getUser?.preferences?.manageOwnershipTransfer?.projects?.map((object) =>(
                    <AcceptOwnershipTransferForm
                      object={object}
                      transferObject={getUser?.preferences?.manageOwnershipTransfer}
                      preventSubmit={preventSubmit}
                      setPreventSubmit={setPreventSubmit}
                      pteValid={pteValid}
                      setPteValid={setPteValid}
                      projectCodeList={projectCodeList}
                    ></AcceptOwnershipTransferForm>
                  ))
                }
              </tbody>
            </table>

            {/* Buttons */}
            <div className='Button-Group'>
              <button className="Primary-Button" onClick={() => SubmitHandler()} disabled={preventSubmitAll}>Submit</button>
              <button className="Secondary-Button" onClick={()=> navigate('/projects/ownershiptransfer')}>Cancel</button>
            </div>

            {/* Complete the form to proceed */}
            <ErrorMessageLabel errorMessage={"Complete the form to proceed"} errorVisible={preventSubmitAll}></ErrorMessageLabel>
          
          </div>

          {/*------------------------------------------------------------------ */}
          {/*   Confirmation Modal                                              */}
          {/*------------------------------------------------------------------ */}   
          
          <ConfirmationModal
            header="Do you wish to continue?"
            body={[
              <div>
                <p>There are unknown cost centre details for one or more projects to be transferred. You may proceed to submit however we recommended checking that the cost centre details are correct.</p>
                <p>If you wish to continue regardless, click <b>Continue</b>.</p>
                <p>If you would like to go back without saving your changes, click <b>Cancel</b>.</p>
              </div>
            ]}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            eventHandlerOnSubmit={SubmitHandler}
          ></ConfirmationModal>
              
        </div>
      }
      successContent={
        <div className='PageComponent-Errors-Container'>
          <img style={{width: "35%"}} src={PrideCat} alt="happy cat"/>
          <h2>Success!</h2>
          <p> 
            You will receive an email shortly confirming the project(s) have been transferred to you.
          </p>
        </div>
      }
    ></PageComponent>
  )
}
