//==========================================================================
//   Component: Sustainability Response
//==========================================================================

//   Purpose: Handles queries to get all sustainability data required for the sustainability page

//   Properties: 
//   - header = {HTML to display sustainability summary and filter pane}
//   - tableView = {HTML to display table view of project emissions}
//   - chartView = {HTML to display chart view of project emissions}
//   - status = {page status (usually depends on Firebase call), e.g 'pending', 'success', etc.}
//   - view = {state to determine the current emissions view > 'table', 'chart', 'unattendedprojects'}

//   Example: 
//   <SustainabilityResponse
//     header={[
//         <div>
//             {/* Summary Pane */}
//             <div className='sustainability-summary-pane'>
//             </div>
//             {/* Filter Pane */}
//             <div className='sustainability-filter-pane'>
//             </div>
//         </div>]}
//     tableView={[
//         <ProjectEmissionsTable
//         emissionsData={projectEmissionsTableData}
//         getTrendStyle={getTrendStyle}
//     ></ProjectEmissionsTable>
//     ]}
//     chartView={[
//         <ProjectEmissionsChart
//         ></ProjectEmissionsChart>
//     ]}
//     status={sustainabilityQueryStatus}
//     view={view}
//  ></SustainabilityResponse>

//------------------------------------------------------

//Libraries
import React, { useContext } from 'react';

//Contexts
import { GetAppErrors } from '../../../Library/GlobalContexts';

//Images
import LoadingIcon from '../../../Components/Images/Loading_Ripple.svg';

//CSS
import './sustainabilitycomponents.css'

//------------------------------------------------------
//  'SustainabilityResponse' Component
//------------------------------------------------------

export default function SustainabilityResponse(props) {

    //------------------------------------------------------
    //  Extract props
    //------------------------------------------------------

        let header = props.header
        let tableView = props.tableView
        let chartView = props.chartView
        let unattendedProjects = props.unattendedProjects
        let status = props.status
        let view = props.view

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

        const getAppErrors = useContext(GetAppErrors)

    //------------------------------------------------------
    //  Returned HTML
    //------------------------------------------------------

        /*---------------------------------------------------- */
        /*   Status = 'pending'                                */
        /*---------------------------------------------------- */

        if(status === 'pending'){
            return (
            <div className="Sustainability-Response-Pending">
                <img alt="loading-circle-icon" src={LoadingIcon}></img>
                <h3>Please wait while we process your request.</h3>
                <div className="pageresponse-button-container">
                    <button className="form-submit-button" onClick={()=>{window.location.reload()}}>Cancel</button>
                </div>
            </div>
            );
        }

        /*---------------------------------------------------- */
        /*   Status = 'success'                                */
        /*---------------------------------------------------- */

        else if (status === 'success'){
            //Table View of Emissions
            if(view === 'table'){
                return (
                  <div>
                    {header}
                    {tableView}
                  </div> 
                )
            }

            //Chart View of Emissions
            else if(view === 'chart'){
                return (
                    <div>
                    {header}
                    {chartView}
                    </div>
                )
            }

            //Unattended projects
            else if(view === 'unattendedprojects'){
                return (
                    <div>
                    {header}
                    {unattendedProjects}
                    </div>
                )
            }
                

        }

        /*---------------------------------------------------- */
        /*   Status = 'error-invalid'                          */
        /*---------------------------------------------------- */

        else if (status === 'error-invalid'){
            return (
                <div className='Sustainability-Response-Error'>
                    No emissions found.
                </div>
            )
        }

        /*---------------------------------------------------- */
        /*   Status = 'error-fatal'                            */
        /*---------------------------------------------------- */
        
        else if(status === 'error-fatal'){
            return (
                <div style={{textAlign: "center"}} className="Sustainability-Response-Error">
                    <h2>Request Failed</h2>
                    <p style={{lineHeight: "1.7"}}>
                        An error occurred while we processed your request.
                        <br></br>
                        If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
                    </p>
                    <p style={{lineHeight: "1.7"}}>
                     <b>Message:</b> {getAppErrors} 
                    </p>
        
                    {/* Try Again Button */}
                    <button className="try-again-button" onClick={()=>{window.location.reload()}}>Try Again</button>
                </div>
            )
        }

        /*---------------------------------------------------- */
        /*   Catch all - error                                 */
        /*---------------------------------------------------- */
        
        else {
            return (
                <div style={{textAlign: "center"}} className="Sustainability-Response-Error">
                    <h2>Request Failed</h2>
                    <p style={{lineHeight: "1.7"}}>
                        An error occurred while we processed your request.
                        <br></br>
                        If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
                    </p>
        
                    {/* Try Again Button */}
                    <button className="try-again-button" onClick={()=>{window.location.reload()}}>Try Again</button>
                </div>
            )
        }

}
