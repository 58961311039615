//================================================================
//  DNS Page
//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import VideoPlayer from '../../Components/VideoPlayer/videoPlayer';
import DocumentLink from '../../Components/KnowledgeHubComponents/documentlink';
import RequestSummary from '../../Components/RequestSummary/requestsummary';
import ConfirmationModal from '../../Components/ConfirmationModal/confirmationmodal'
import TableResponseUserLoad from '../../Components/TableResponseUserLoad/tableresponseuserload'

//Functions
import QueryDocument from '../../Library/QueryDocument'

//Images
import InfoIcon from '../../Components/Images/Icon_Info_Black.svg';
import Delete from '../../Components/Images/Delete_Blue.svg'
import AddZoneIcon from '../../Components/Images/Large_Add_Icon_Grey.svg';

//CSS
import './DNS.css'


export default function DNS() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

    //React Router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'successContent', 'error-invalid', 'error-fatal', 'error-timeout' & 'error-other'
    const [pageStatus, setPageStatus] = useState();

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    // List of DNS zones
    const [dnsZones, setDnsZones] = useState([]);

    // Status and list of jobs for the all DNS zones table
    const [dnsAllZonesStatus, setAllDNSZonesStatus] = useState('pending-user');
    const [dnsAllZones, setDnsAllZones] = useState([]);

    // Status and list of jobs for the DNS jobs table
    const [dnsJobsStatus, setDnsJobsStatus] = useState('pending');
    const [dnsRequests, setDnsRequests] = useState([]);

    //Used to determine if the delete confirmation modal is visible
    const [modalVisible, setModalVisible] = useState(false);


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Retrieve DNS zones for the project, or optionally all projects
    const getDNSZonesAPI = async (allZones) =>  {

      const query = [
        ["status", "==", "complete"]
      ]

      if (!allZones) {
        query.push(["creator", "==", String(getUser?.profile?.emailaddress).toLowerCase()])
      }

      QueryDocument('dns', query)
      .then((zones) =>{
        
        if(allZones) {
          setDnsAllZones(zones)
          setAllDNSZonesStatus('success')
        } else {
          setDnsZones(zones)
        }
        
        return;

      })
      .catch((error) => {

        setAppErrors(`Could not retrieve DNS zones from dns collection in Firestore. Error: ${error.message}`)
        setPageStatus('error-invalid')
        return;

      })

    }

    // Retrieve previous DNS zone requests for this project
    const getDNSRequestsAPI = async () => {
      
      let processedRequests = []

      QueryDocument('jobtracking', [["creator", "==", getUser.profile.emailaddress], ["jobName", "==", "submitDNSZoneRequest"]])
      .then((rawRequests) =>{

        for (const request of rawRequests) {
          let jobsStruct = {
            'transactionId': request?.transactionId !== undefined && request?.transactionId.length > 0 ?
              request.transactionId : (request?.transactionid !== undefined && request?.transactionid.length > 0 ? request.transactionid : '-'),
            'projectId': request?.projectId !== undefined && request?.projectId.length > 0 ? request.projectId : '-',
            'jobType': request?.jobType !== undefined && request?.jobType.length > 0 ? request.jobType : '-',
            'creator': request?.creator !== undefined && request?.creator.length > 0 ? request.creator : '-',
            'status': request?.status !== undefined && request?.status.length > 0 ? request.status : '-',
          }
          
          if(request.created === undefined) {
            jobsStruct.rawDate = new Date(1970, 0, 1)
            jobsStruct.created = '-'

          } else {
            jobsStruct.rawDate = request.created.toDate()
            const dateString = jobsStruct.rawDate.toUTCString()
            jobsStruct.created = dateString.substr(0, dateString.length - 4)
          }

          processedRequests.push(jobsStruct)
        }

        processedRequests.sort((a, b) => b.rawDate - a.rawDate)
        setDnsRequests(processedRequests)
        setDnsJobsStatus('success')
        return;

      }).catch((error) => {

        setAppErrors(`Could not retrieve DNS requests from jobtracking collection in Firestore. Error: ${error.message}`)
        setPageStatus('error-invalid')
        return;

      })

    }

  //------------------------------------------------------
  //  Event Handler > Delete Confirmation
  //------------------------------------------------------

    const eventHandlerOnSubmit = e => {
      e.preventDefault()
      setModalVisible(false)
      navigate('/dns/request');
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Load the page when getUser is defined
    useEffect(() => {

      // These are the conditions for the DNS page (allows time for getUser to update before loading the page)
      if (getUser?.profile?.emailaddress === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      // Only act if org has changed
      if (!('dns' in getUser.preferences)) {
        getUser.preferences.dns = {}
      }

      //Call APIs
      getDNSZonesAPI(false);
      getDNSRequestsAPI();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

    //Load all zones when user clicks on the "Show zones" button
    useEffect(() => {

      if(dnsAllZonesStatus === 'pending'){
        getDNSZonesAPI(true)
      }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dnsAllZonesStatus])  

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedViews={['organization']}
        requireSelectedResource={true}
        requiredRoles={['lendleaseAccount']}
        requiredRolesValue={'true'}
        status={pageStatus}
        header={
          <PageHeader
            title={'DNS Zones'}
            modaltitle={'How To Manage Your DNS Zones'}
            modalbody={
              <div>
                <div className='modal-description' style={{lineHeight: "1.5"}}>
                  <br></br>
                    For more information, refer to <DocumentLink docId="973930558" title="DNS Services - How to Get Started"></DocumentLink>.
                </div>
                <VideoPlayer
                  item="Video_DNS_21_12_2021.mp4"
                  modalVisible={modalVisible}
                ></VideoPlayer>
              </div>
            }
            body={
              <div>
                A DNS zone is a container of DNS records for the same DNS name suffix. After creating your zone, you can add your own resource record sets. 
                <br></br>

                To create your own project specific DNS zone within the shared VPC network, you must be a <b>Lendlease User</b> and have a valid Google Service account.
                <br></br>

                Click here to learn <DocumentLink docId="222898684417" title="How to Request an SSL Certificate for Lendlease.cloud"></DocumentLink>
              </div>
            }
          ></PageHeader>
        }
        body={
          <div>

            {/*------------------------------------------------------------------ */}
            {/*   User's DNS Zones                                                */}
            {/*------------------------------------------------------------------ */}  

            <div className='DNS-Response-Container-Transparent'>
              <p className="Page-Subheading" style={{margin: "0px"}}>My zones in Lendlease.cloud</p>
              {
                dnsZones.length === 0 ? (
                  <div className='PageComponent-Label'>
                    <div className='PageComponent-LabelMessage'>
                      <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
                      <p>
                        No DNS zones found.
                      </p>
                    </div>
                  </div>
                ) 
                : 
                null
              }
              <div className='DNS-User-Zones-container'>
                <div className="DNS-User-Zones-card-add" onClick={()=> {
                    getUser.preferences.dns = {
                      'action': 'create',
                      'fields': {
                        "dnsName": {
                          "visibility": "edit",
                          "value": ""
                        },
                        "description": {
                          "visibility": "edit",
                          "value": ""
                        },
                        "serviceAccount": {
                          "visibility": "edit",
                          "value": ""
                        }
                      }
                    }
                    navigate('/dns/request')
                    
                  }}>
                  <img alt="AddZoneIcon" src={AddZoneIcon}></img>
                </div>
                {dnsZones.map((row, index) => (
                  <div className='DNS-User-Zones-card-container'>
                    <div className='DNS-User-Zones-card-top'>
                      <div className='DNS-User-Zones-card-title'>
                        {row.name}
                      </div>
                      <div className='DNS-User-Zones-card-top-icon'>
                        <img alt="delete" src={Delete} onClick={() => {
                          // Show confirmation dialog if a delete is requested
                          getUser.preferences.dns = {
                            'action': 'delete',
                            'fields': {
                              "dnsName": {
                                "visibility": "read",
                                "value": row.name
                              },
                              "description": {
                                "visibility": "read",
                                "value": row.description
                              },
                              "serviceAccount": {
                                "visibility": "read",
                                "value": row.serviceaccountemail
                              }
                            }
                          }
                          setModalVisible(true)
                        }}></img>
                      </div>
                    </div>
                    <div className='DNS-User-Zones-card-middle'>
                      {row.description}
                    </div>
                    <div className='DNS-User-Zones-card-bottom'>
                        <b>Service Account</b>
                        <br></br>
                        {row.serviceaccountemail}
                      </div>
                  </div>
                ))}

              </div>

              {/*------------------------------------------------------------------ */}
              {/*   Confirmation Modal                                              */}
              {/*------------------------------------------------------------------ */}   
              
              <ConfirmationModal
                  header="Do you wish to continue?"
                  body={[
                    <div>
                      <p>You are about to delete DNS zone <b>{getUser?.preferences?.dns?.fields?.dnsName?.value}</b>.</p>
                      <p>If you would like to go back without saving your changes, click <b>Cancel</b>.</p>
                    </div>
                  ]}
                  modalVisible={modalVisible}
                  setModalVisible={setModalVisible}
                  eventHandlerOnSubmit={eventHandlerOnSubmit}
              ></ConfirmationModal>

            </div>

            {/*------------------------------------------------------------------ */}
            {/*   Table of all DNS Zones                                          */}
            {/*------------------------------------------------------------------ */}  

            <div className='Table-Pane' style={{marginTop: "40px"}}>
              <h2 className="Page-Subheading">All zones in Lendlease.cloud</h2>

              <TableResponseUserLoad
                content={
                    dnsAllZones.length === 0 ? (
                      <div className='PageComponent-Label'>
                        <div className='PageComponent-LabelMessage'>
    
                          <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
                          <p>
                            No DNS zones found.
                          </p>
    
                        </div>
                      </div>
                    ) : (
                      <table className="Data-Table-Container">
                        <colgroup>
                          <col span="1" style={{width: "20%"}}></col>
                          <col span="1" style={{width: "45%"}}></col>
                          <col span="1" style={{width: "35%"}}></col>
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>Zone Name</th>
                            <th>Description</th>
                            <th>Owner</th>
                          </tr>
              
                          {dnsAllZones.map((row, index) =>
                            (
                              <tr key={index}>
                                <td>{row.name !== undefined && row.name.length > 0 ? row.name : '-'}</td>
                                <td>{row.description !== undefined && row.description.length > 0 ?
                                  row.description.charAt(0).toUpperCase() + row.description.slice(1) : '-'}</td>
                                <td>{row.creator !== undefined && row.creator.length > 0 ? row.creator : '-'}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    )
                }
                status={dnsAllZonesStatus}
                setStatus={setAllDNSZonesStatus}
                pendingUserText={"Click 'Show zones' below to load all zones in Lendlease.cloud."}
                pendingUserButtonText={'Show zones'}
              ></TableResponseUserLoad>
            </div>

            {/*------------------------------------------------------------------ */}
            {/*   Requests Summary                                                */}
            {/*------------------------------------------------------------------ */}  

            <div className='Table-Pane' style={{marginTop: "40px"}}>

              <h2 className="Page-Subheading">Requests Summary</h2>

                <RequestSummary
                  status={dnsJobsStatus}
                  setStatus={setDnsJobsStatus}
                  rows={dnsRequests}
                ></RequestSummary>

            </div>
          </div>
        }
      ></PageComponent>
    )
}