//================================================================
//  Project Ownership Transfer Page
//================================================================

// Libraries
import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../../Library/GlobalContexts';

//Functions
import QueryListener from '../../../Library/QueryListener';
import WriteDocument from '../../../Library/WriteDocument';

// Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import ConfirmationModal from '../../../Components/ConfirmationModal/confirmationmodal';
import StatusLabel from '../../../Components/StatusLabel/statuslabel';
import ProductFilterPane from '../../../Components/ProductFilterPane/productfilterpane';
import VideoPlayer from '../../../Components/VideoPlayer/videoPlayer';

// CSS Styling
import './OwnershipTransfer.css';


export default function ManageOwnershipTransfers() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

    //React router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates (global)
  //------------------------------------------------------

    //Used to render the page based on the page status > 'pending', 'onload', 'success', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    //Selected Row
    const [currentTransferObject, setCurrentTransferObject] = useState();

    //States that control the 'ModalContent'
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [confirmationModalRequestType, setConfirmationModalRequestType] = useState('onload');

    //Holds the data for two API calls
    const [incomingTransfers, setIncomingTransfers] = useState([]);
    const [outGoingTransfers, setOutGoingTransfers] = useState([]);
    const [requestSummary, setRequestSummary] = useState([]);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Handle the button interactions
    function AcceptHandler(object){

      //Save the current object to the global context
      getUser.preferences.manageOwnershipTransfer = object;

      //Route to another page
      navigate('/projects/ownershiptransfer/accept');

    }

    //Handles cancellations of requests
    function CancelHandler(object){

      setConfirmationModalVisible(true);
      setCurrentTransferObject(object);

    }

    //Cancel a pending transfer
    function CancelTransferHandler(){

      //Conditions
      if(currentTransferObject === undefined) return;

      //Show the pending screen
      setConfirmationModalRequestType('pending');

      //Write the document
      const documentId = currentTransferObject.transactionid;
      const document = {
        'cancelleddate': new Date(),
        'status': 'cancelled'
      }
      WriteDocument('project-owner-transfer', documentId, document, true).then(() =>{

        setConfirmationModalRequestType('success');

      }).catch((error) =>{

        console.log(`Unable to update ${documentId} in "project-owner-transfer", error`, error);
        setAppErrors(`Unable to update ${documentId} in "project-owner-transfer", error`, error);
        setConfirmationModalRequestType('error-fatal');

      });

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Get 'Incoming Project Transfers' data
    useEffect(()=>{

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.profile.emailaddress === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      function onLoad(document){

        setIncomingTransfers(document);

      }

      function onChange(document){
        
        setIncomingTransfers([...document]);

      }

      function onError(error){
        
        setAppErrors('Failed to query collection "project-owner-transfer", error', error);
        setPageStatus('error-invalid');

      }

      const unsubscribe = QueryListener('project-owner-transfer', [
        ['newowner', '==', getUser.profile.emailaddress],
        ['status', '==', 'pendingAcceptance'],
      ], onLoad, onChange, onError);

      return () =>{
        unsubscribe();

      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser])

    //Get 'Outgoing Project Transfers' data
    useEffect(()=>{

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.profile.emailaddress === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      function onLoad(document){
        setOutGoingTransfers(document);

      }

      function onChange(document){
        setOutGoingTransfers([...document]);

      }

      function onError(error){
        setAppErrors('Failed to query collection "project-owner-transfer", error', error);
        setPageStatus('error-invalid');

      }

      const unsubscribe = QueryListener('project-owner-transfer', [
        ['requester', '==', getUser?.profile?.emailaddress],
        ['status', 'in', ['pendingAcceptance', 'pending']],
      ], onLoad, onChange, onError)

      return () =>{
        unsubscribe();
      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

    //Get 'My Requested Transfers' data
    useEffect(()=>{

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.profile.emailaddress === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      function onLoad(document){
        setRequestSummary(document.reverse());

      }

      function onChange(document){
        setRequestSummary([...document.reverse()]);

      }

      function onError(error){
        setAppErrors('Failed to query collection "project-owner-transfer", error', error);
        setPageStatus('error-invalid');

      }

      const unsubscribe = QueryListener('project-owner-transfer', [
        ['requester', '==', getUser.profile.emailaddress]
      ], onLoad, onChange, onError);

      return () =>{
        unsubscribe();
      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    return (
    <PageComponent
      requireSelectedResource={true}
      requireSelectedViews={['organization']}
      requiredRoles={['lendleaseAccount']}
      requiredRolesValue={'true'}
      pageStatus={pageStatus}
      breadcrumb={{
        "name": "Projects", 
        "route": "/projects",
        "view": "organization",
        "resource": "lendlease.cloud"
      }}
      header={
        <PageHeader
          title={'Manage Project Ownership'}
          modaltitle={'Project Transfer Feature'}
          modalbody={
            <div className='Modal-Resize'>
              <VideoPlayer
                item="Video_Project_Transfer_19_05_2022.mp4"
              ></VideoPlayer>
            </div>
          }
          body={
            <span>
              As a project owner, you can transfer ownership of your project to another user and accept ownership of projects that need to be transferred to you here. 
            </span>
          }
        ></PageHeader>
      }
      body={
        <div className="Project-Owner-Transfership-Container">

          {/* Button Pane */}
          <ProductFilterPane
            productFilterComponents={
              <button className="Primary-Button" onClick={ ()=> navigate('/projects/ownershiptransfer/create')}>
                New Ownership Transfer
              </button>
            }
          ></ProductFilterPane>

          {/* Incoming Project Transfers */}
          <div className='Table-Pane'>
            <h2>
              Incoming Project Transfers
            </h2>
            <p style={{lineHeight: "1.6"}}>
              A request to transfer ownership of the projects below to you has been made. Click ‘Accept’ to finalise the transfer.
              <br></br>
              Please contact the current project owner if you do not wish to accept the transfer so they can amend their request. 
            </p>

            <table className="Data-Table-Container">
              <tbody>
                <tr>
                  <th>Transaction ID</th>
                  <th>Requester</th>
                  <th>Requested Project(s) Transfer</th>
                  <th></th>
                </tr>
                {
                  incomingTransfers?.length > 0 ? (

                    incomingTransfers?.map((object) =>(
                      <tr>
                        <td>{object.transactionid}</td>
                        <td>{object.requester}</td>
                        <td>
                          <ul className='Custom-Scroll-Bar' style={{maxHeight: '20vh'}}>
                            {
                              object?.projects?.map((project) =>(
                                <li style={{listStyle: 'none'}}>{project?.projectid}</li>
                              ))
                            }
                          </ul>
                        </td>
                        <td>
                          <button className='Primary-Button' onClick={()=> AcceptHandler(object)}>Accept</button>
                        </td>
                      </tr>
                    ))

                  ) 
                  : 
                  (
                    <tr>
                      <td colSpan="4">No incoming transfers</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>

          {/* Outgoing Project Transfers*/}
          <div className='Table-Pane'>
            <h2>
              Outgoing Project Transfers
            </h2>
            <p style={{lineHeight: "1.6"}}>
              This is a list of projects you have requested to be transferred to a new owner. Please ensure you have notified the proposed new owner of this request. To cancel your tranfer request, please click the delete icon. 
            </p>
            <table className="Data-Table-Container">
              <tbody>
                <tr>
                  <th>Transaction ID</th>
                  <th>Requested Project Owner</th>
                  <th>Requested Project(s) Transfer</th>
                  <th></th>
                </tr>
                {
                  outGoingTransfers?.length > 0? (

                    outGoingTransfers?.map((object) =>(
                      <tr>
                        <td>{object.transactionid}</td>
                        <td>{object.newowner}</td>
                        <td>
                          <ul className='Custom-Scroll-Bar' style={{maxHeight: '20vh'}}>
                            {
                              object?.projects?.map((project) =>(
                                <li style={{listStyle: 'none'}}>{project?.projectid}</li>
                              ))
                            }
                          </ul>
                        </td>
                        <td>
                          <button className='Primary-Button' onClick={()=> CancelHandler(object)}>Cancel</button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">No outgoing transfers</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>

          {/* Request Summary*/}
          <div className='Table-Pane'>
            <h2>
              Request Summary
            </h2>
            <p style={{lineHeight: "1.6"}}>
              Below is a summary of all outgoing project transfers.
            </p>
            <table className="Data-Table-Container">
              <tbody>
                <tr>
                  <th>Transaction ID</th>
                  <th>Requested Project Owner</th>
                  <th>Requested Project(s) Transfer</th>
                  <th>Status</th>
                </tr>
                {
                  requestSummary?.length > 0 ? (

                    requestSummary.map((object) =>(
                      <tr>
                        <td>{object.transactionid}</td>
                        <td>{object.newowner}</td>
                        <td>
                          <ul className='Custom-Scroll-Bar' style={{maxHeight: '20vh'}}>
                            {
                              object?.projects?.map((project) =>(
                                <li style={{listStyle: 'none'}}>{project?.projectid}</li>
                              ))
                            }
                          </ul>
                        </td>
                        <td>
                          <StatusLabel 
                            label={object.status}
                          ></StatusLabel>
                        </td>
                      </tr>
                    ))

                  ) : (
                    <tr>
                      <td colSpan="4">No requests</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>

          {/* Confirmation Modal */}
          <ConfirmationModal
            header={
              <div>
                Do you wish to continue?
              </div>
            }
            body={
              <div>
                Your request to transfer ownership of the following projects to <strong>{currentTransferObject?.newowner}</strong> will be cancelled and you will remain the Project Owner.
                <ul>
                  {
                    currentTransferObject?.projects?.map((object) =>(
                      <li style={{color: 'var(--button-action-colour)'}}>{object?.projectid}</li>
                    ))
                  }
                </ul>
              </div>
            }
            modalVisible={confirmationModalVisible}
            setModalVisible={setConfirmationModalVisible}
            requestType={confirmationModalRequestType}
            setRequestType={setConfirmationModalRequestType}
            eventHandlerOnSubmit={CancelTransferHandler}
          ></ConfirmationModal>

      </div>
      }
    ></PageComponent>
  )
}
