//Libaries
import React from 'react';

//Images
import LoadingPulseBar from '../Images/loadingPulseBarFast.svg'

//CSS
import './selectbox.css';


//------------------------------------------------------
//  How to use the 'SelectBox' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <SelectBox
//      //Form Properties
//      formId="Id of the form the component will be used in: firewallruleform"
//          
//      //Select Box Properties
//      name={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.name} - "Name of the select box, you can use this in an event handler (event.target.name) to get the value,e.g. projectId"
//      placeholder={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.placeholder} - "Default value for the select component"
//      value="Variable that holds the defaultValue in the select box: projectid" 
//      arrayOfOptions=Array that holds the selectable options: {projectIDs}
//      visibility= "read", "hide"
//          
//      //Function Properties
//      onChange="Name of the function that changes any changes in this component: {handleChange}"
//    >
//    </SelectBox>


//------------------------------------------------------
//  'SelectBox' component
//------------------------------------------------------


function SelectBox(props) { 
  

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //Functions to take a boolean value and change this to a CSS Style
  function toggleErrorBorderVisible(errorVisible){

    if (errorVisible === true){
      return {border: '2px solid rgb(211, 26, 26)'}
    }

  }

  //Function to handle selected value 
  function handleSelectedValue(item){
    
    if (item === props.value){
      return true 
    } else {
      return false;
    }
  }


  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

  if (props.visibility === "read"){
    return (
      <>
        {/* Select Component */}
        <select className="option" style={toggleErrorBorderVisible(props.errorVisible)} form={props.formId} name={props.name} id={props.name} onChange={props.onChange} disabled={true}>
            <option value="" className="dropdown-empty" defaultValue={handleSelectedValue("")}>{props.placeholder}</option>
            {props.arrayOfOptions.map((pID, index) =>
              <option className="dropdown" key={index} value={pID} defaultValue={handleSelectedValue(pID)}>{pID}</option>
            )}
        </select>
      </>
    );
  }

  else if (props.visibility === "hide"){
    return null
  }

  else if (props.visibility === "pending"){
    return (
      <img width="50px" alt="loading-pulse-bar" src={LoadingPulseBar}></img>
    )
  }

  else{
    return (
      <>
        {/* Select Component */}
        <select className="option" style={toggleErrorBorderVisible(props.errorVisible)} form={props.formId} name={props.name} id={props.name} onChange={props.onChange}>
            <option hidden style={{color: 'grey'}}>{props.placeholder}</option>
            {props.arrayOfOptions.map((pID, index) =>
              <option style={{color: 'rgb(1, 2, 3)'}} key={index} value={pID} defaultValue={handleSelectedValue(pID)}>{pID}</option>
            )}
        </select>
      </>
    );
  }

  //------------------------------------------------------
}


export default SelectBox;