//================================================================
//  ContactUs page
//================================================================

//  Purpose: This is the form users fill out to contact us for a portal walkthrough

//Libraries
import React, { useContext, useState, useReducer } from 'react';

//Contexts
import {SetAppErrors} from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import QuestionLabel from '../../Components/QuestionLabel';
import TextArea from '../../Components/TextArea/textarea';
import ErrorMessageLabel from '../../Components/ErrorMessageLabel/index'; 

//Function

import ReactBackend from '../../Library/reactBackend';
import { isString, errString } from '../../Library/ValidateUserInput';


//Images

import GrumpyFatal from '../../Components/Images/GrumpyCat.png';
import workingCatGraphic from '../../Components/Images/workingCatGraphic.svg';

//CSS
import './ContactUs.css'


export default function ContactUs() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

  const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState();

    //Used to determine the visibility of the date error message
    const [dateErrorVisible, setDateErrorVisible] = useState(false);

    //Used to determine the visibility of the 'other' field error message
    const [otherErrorVisible, setOtherErrorVisible] = useState(false);

    // State for the form (default)
    const [formState, setFormState] = useReducer(
      (state, newState) => ({ ...state, ...newState }),

      {
        all: false,
        manage: false,
        monitor: false,
        other: '',
        date: HandleDate(), 
      }

    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------
  
  // Form, triggered by submit button

  function handleSubmit(e){

    setPageStatus("pending"); 

    const customerRequest = {

        "manage": formState.manage,
        "monitor": formState.monitor,
        "other" : formState.other,
        "date" : formState.date

    }

    e.preventDefault();

    ReactBackend("submitContactRequest", customerRequest).then((response)=>{

      if (response.status === "success"){
        setPageStatus("success")
      }

      else {

        setPageStatus("error-other");
        setAppErrors(response);

      }
    
    
    }).catch((response)=> {

      setPageStatus("error-other");
      setAppErrors(response);

    });

  }

  // Select All
  function allHandler(value){

    if (value === false) {
      
      //set form changes
      formState.all = false;
      formState.manage = false;
      formState.monitor = false;
      
    }

    if (value === true) {

      //set form changes
      formState.all = true;
      formState.manage = true;
      formState.monitor = true; 
      
    }

    //apply form changes
    setFormState(formState);

  }

  // This function handles changes to the monitor or manage checkbox fields
  const CheckboxHandler = e => {

    formState[e.target.name] = !formState[e.target.name];
    
    if (formState.manage && formState.monitor) {
      formState.all = true;
    } else {
      formState.all = false;
    }

    setFormState(formState);
  }

  // Date Picker - Outputs a date in format 'yyyy-mm' e.g: '2021-04'
  function HandleDate() {

    // All new dates are calculated with an offset of one month
    // const preferredDate = new Date().toLocaleDateString(); 
    let preferredDate = new Date(); 

    // +1 from current day. 
    //E.g. User cannot request to meet us same day of request creation 
    preferredDate.setHours(preferredDate.getHours() + 24) 

    // convert new date to string
    preferredDate= preferredDate.toLocaleDateString()
    
    // format date
    const year = preferredDate.split("/")[2]
    const month = preferredDate.split("/")[1]
    const day = preferredDate.split("/")[0]

    return `${year}-${month}-${day}`;
  
  }

  // This function handles changes to the 'other'field
  const OtherHandler = e => {

    if (e.target.value.length > 0 && !isString(e.target.value)) {
      setOtherErrorVisible(true)
    }
    
    else {
      setOtherErrorVisible(false)
    };

    formState.other = e.target.value
    setFormState(formState);
  }

  // This function handles changes to the date picker field
  const DatePickerHandler = e => {

    if (e.target.value.length === 0) {
      setDateErrorVisible(true)
    }
    
    else {
      setDateErrorVisible(false)
    };

    formState.date = e.target.value
    setFormState(formState);
  }

    
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedViews={['organization']}
        requireSelectedResource={false}
        status={pageStatus}
        breadcrumb={{
          "name": "Get Help",
          "route": "/support"
        }}

        header={
          <PageHeader
            title={'Connect with a Team Member'}
            body={
              <>
                To help you get the most out of the portal, we can connect you with one of our team members to give you a walk through of the portal features. 
                <br></br>
              </>
            }
          ></PageHeader>
        }

        body={

          <div className="Form-Container">
          <div className="Form-Body">

            <form onSubmit={(e)=> handleSubmit(e)}>
              <QuestionLabel question={"Which portal features would you like to know more about?"}></QuestionLabel>

              <div className="check-box-container">
                <input type="checkbox" onClick={() => allHandler(!formState.all)} checked={formState.all}></input>
                <label className="check-box-label">All</label>
              </div>

              <div className="check-box-container">
                <input name="manage" type="checkbox" onClick={CheckboxHandler} checked={formState.manage}></input>
                <label className="check-box-label">Manage (Projects, Security, Shared VPC, Firewall, DNS Zones)</label>
              </div>

              <div className="check-box-container">
                <input name="monitor" type="checkbox" onClick={CheckboxHandler} checked={formState.monitor}></input>
                <label className="check-box-label">Monitor (Portfolios, Billing, Sustainability)</label>
              </div>

              <div className="check-box-container" style={{margin: "20px, 20px", width: "50%"}}>
                <label className="check-box-label1">Other:  </label>
                <TextArea
                  //TextArea Properties
                  name="other"
                  value={formState.other}
                  onChange={OtherHandler}
                  visibility={'edit'}
                ></TextArea>
                
                <ErrorMessageLabel
                  //Label Properties
                  errorMessage={`This field must contain a combination of ${errString}`}
                  errorVisible={otherErrorVisible}
                ></ErrorMessageLabel>
              </div>

              <QuestionLabel question={"Preferred Date?"}></QuestionLabel>

                <div style={{display: "flex", flexDirection: "column", gap: "10px 0px"}}>
                  <div className="datepicker">
                    <label className="check-box-label">Date: </label>
                    <input 
                      type="date" 
                      onChange={DatePickerHandler} 
                      value={formState.date}
                      min={HandleDate()}
                      defaultValue={HandleDate()}
                      clearValue={HandleDate()}
                    ></input>
                  </div>

                  <ErrorMessageLabel
                    //Label Properties
                    errorMessage="Please select a preferred date"
                    errorVisible={dateErrorVisible}
                  >
                  </ErrorMessageLabel>
                </div>

              <input 
                className="form-submit-button" 
                type="submit" 
                value="Submit" 
                disabled={!((formState.manage || formState.monitor || formState.all || formState.other.length > 0) &&
                  (formState.other.length === 0 || isString(formState.other))) ||
                  formState.date.length === 0}
              ></input>

            </form>
          </div>
        </div>
      }

        successContent={
          <div className="PageComponent-Errors-Container">
            <img style={{margin: "20px 0px", width:"40%"}} src={workingCatGraphic} alt="working cat"/>
            <h3>Thanks for your request. We're working on it!</h3>
            <p>You will receive a confirmation email shortly.</p>
          </div>
        }

        errorOtherContent={
          <div style={{lineHeight: "2.5"}} className='PageComponent-Errors-Container'>
            <img style={{margin: "20px 0px", width:"40%"}} alt="Error Fatal" src={GrumpyFatal}></img>
            <h2>Oops something went wrong</h2>
            <p>
              An error occurred while we processed your request.
              <br></br>
              If the error persists, please log a ticket through Service Central 
              <a 
                href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" 
                target="_blank" 
                rel="noopener noreferrer"> 
                 &nbsp;here.
              </a>
            </p>
         </div>
        }

      ></PageComponent>
    )
}