//Libaries
import React from 'react';

//Components
import Label from '../Label/label';

//CSS
import './inlineinputfield.css';


//------------------------------------------------------
//  How to use the 'InlineInputField' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <InlineInputField
//          
//      //Inline Input Field Properties
//      name={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.name} - "Name of the select box, you can use this in an event handler (event.target.name) to get the value,e.g. projectId"
//      placeholder={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.placeholder} - "Placeholder text for your text area, e.g. 'Enter a description'"
//      value={selectedValue.<FIELD NAME, e.g. description>} - "Variable that holds the entered value in the InlineInputField: description"
//      position=left, right or middle (middle required the 'inlinefieldlabelEnd' parm) - position of the inline field label relative to the input field
//      inlinefieldlabel =  text you want to present next to the field
//      inlinefieldlabelEnd = <IF YOU USE position 'middle'>
//      visibility =  "read", "hide", "edit"
//      inputFieldLength= "shortinputfield" or "mediuminputfield" or "longinputfield"
// 
//      //Function Properties
//      onChange="Name of the function that changes any changes in this component: {handleChange}"
//    >
//    </InlineInputField>


//------------------------------------------------------
//  'InlineInputField' component
//------------------------------------------------------


function InlineInputField(props) { 
  

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //Functions to take a boolean value and change this to a CSS Style
  function toggleErrorBorderVisible(errorVisible){

    if (errorVisible === true){
      return {border: '1px solid rgb(211, 26, 26)'}
    }

  }


  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------


  if(props.position === 'right'){

    if(props.visibility === 'read'){
      return(
        <input disabled type="text" className={props.inputFieldLength} value={props.value}/>
      );
    }
    else if(props.visibility === 'hide'){
      return null
      }
    else{
        return(
          <>
          {/* Inline Input Field */}
          <div>
              <input type="text" className={props.inputFieldLength} style={toggleErrorBorderVisible(props.errorVisible)} placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange} onPaste={props.onChange}/>
              <label className="inline-field-label"> {props.inlinefieldlabel} </label>
          </div>
      </>
        );
    }
  }
  
  if(props.position === 'left'){

    if(props.visibility === 'read'){
      return(
        <div>
          <Label label={props.value}></Label>
        </div>
      );
    }
    else if(props.visibility === 'hide'){
      return null
      }
    else{
      return (
        <>
            {/* Inline Input Field */}
            <div>
                <label className="inline-field-label"> {props.inlinefieldlabel} </label>
                <input type="text" className={props.inputFieldLength} style={toggleErrorBorderVisible(props.errorVisible)} placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange} onPaste={props.onChange}/>
            </div>
        </>
      );
    }
  }

  if(props.position === 'middle'){

    if(props.visibility === 'read'){
      return(
        <div>
          <Label label={props.value}></Label>
        </div>
      );
    }
    else if(props.visibility === 'hide'){
      return null
    }
    else{
      return (
        <>
            {/* Inline Input Field */}
            <div>
                <label className="inline-field-label"> {props.inlinefieldlabel} </label>
                <input type="text" className={props.inputFieldLength} style={toggleErrorBorderVisible(props.errorVisible)} placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange} onPaste={props.onChange}/>
                <label className="inline-field-label"> {props.inlinefieldlabelEnd} </label>
            </div>
        </>
      );
    }
  }


  //------------------------------------------------------
}

export default InlineInputField;