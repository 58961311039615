//Libaries
import React from 'react';

//CSS
import './textarea.css';


//------------------------------------------------------
//  How to use the 'TextArea' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <TextArea
//          
//      //Text Area Properties
//      name={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.name} - "Name of the select box, you can use this in an event handler (event.target.name) to get the value,e.g. projectId"
//      placeholder={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.placeholder} - "Placeholder text for your text area, e.g. 'Enter a description'"
//      value={selectedValue.<FIELD NAME, e.g. description>} - "Variable that holds the entered value in the textarea: description"
//          
//      //Function Properties
//      onChange="Name of the function that changes any changes in this component: {handleChange}"
//    >
//    </TextArea>


//------------------------------------------------------
//  'TextArea' component
//------------------------------------------------------


function TextArea(props) { 
  

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


    //Functions to take a boolean value and change this to a CSS Style
    function toggleErrorBorderVisible(errorVisible){

        if (errorVisible === true){
          return {border: '2px solid rgb(211, 26, 26)'}
        }
    }


  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------


  if (props.visibility === 'hide'){
    return (
      <>
          {/* Text Area (Multiline Text Field) */}
          <textarea style={{display: "none"}} className="textarea"  placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange}/>
      </>
      
    );
  }
  if (props.visibility === 'edit'){
    return (
      <>
          {/* Text Area (Multiline Text Field) */}
          <textarea className="textarea" style={toggleErrorBorderVisible(props.errorVisible)} placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange}/>
      </>
      
    );
  }
  else{
    return (
      <>
          {/* Text Area (Multiline Text Field) */}
          <div className="textarea" style={toggleErrorBorderVisible(props.errorVisible)} placeholder={props.placeholder} name={props.name} onChange={props.onChange}>{props.value}</div>
      </>
    );
  }



  //------------------------------------------------------
}


export default TextArea;