//================================================================
//  Component: Dashboard Tile
//================================================================

//  Purpose: This is the standard for creating a a home page dashboard tile

//  Properties:
//    - name = <String> - Name of the dashboard tile
//    - body = <HTML> - Body of the dashbord tile
//    - icon = <img> - Icon for the dashboard tile
//    - footer = <HTML> - Footer for the dashboard tile

//  Example:
//    <DashboardTile
//      name={"Project Information"}
//      body={<div>Project ID</div>}
//      icon={<img alt="Project_Icon" src={ProjectIcon}></img>}
//    ></DashboardTile>    

//================================================================

//Libraries
import React from 'react'

//Contexts

//Components

//Functions

//Images
import ArrowIcon from '../../../Components/Images/Icon_Arrow_Black.svg';

//CSS
import '../Home.css'


export default function DashboardTile({
  name,
  body,
  icon,
  footer
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------



  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='Dashboard-Tile-Container'>

        {/* Header */}
        <div className='Dashboard-Tile-Header'>
            {icon}
            {name}
        </div>

        {/* Body */}
        <div className='Dashboard-Tile-Body'>
            {body}
        </div>

        {/* Footer */}
        {
            footer ? 
            (<div className='Dashboard-Tile-Footer'>
                <img src={ArrowIcon} alt="Arrow_Icon"></img>
                {footer}
            </div>)
            :
            (<div></div>)
        }

    </div>
  )
}
