//Libaries
import React from 'react';

//Images
import unauthorisedUser from '../Images/unauthorisedUser.svg'

//Functions
import refreshPage from '../../Library/refreshPage'


//CSS
import './unauthoriseduser.css';


//------------------------------------------------------
//  How to use the 'UnauthorisedUser' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <UnauthorisedUser
//      message="The text you would like to say in the error message"
//      requestType={useState called 'requestType'}
//      refresh={true/false} - determines if refresh required for 'Try Again' button
//      >
//    </UnauthorisedUser>


//------------------------------------------------------
//  'UnauthorisedUser' component
//------------------------------------------------------




function UnauthorisedUser(props) { 

    if(props.refresh === true){
        return (
            <div className="product-parent-container">
                <div className="product-pane">
                    <img alt="unauthorised-user" className="form-interface-logo-img" src={unauthorisedUser} />
                    <div className="form-message-container">
                        {props.message}
                    </div>
                    <div className="form-button-container">
                        <button className="form-retry-button" onClick={() => refreshPage()}>Try Again</button>
                    </div>
                </div>
            </div> 
        )
    }

    else {
        return (
            <div className="product-parent-container">
                <div className="product-pane">
                    <img alt="unauthorised-user" className="form-interface-logo-img" src={unauthorisedUser} />
                    <div className="form-message-container">
                        {props.message}
                    </div>
                    <div className="form-button-container">
                        <button className="form-retry-button" onClick={e => { props.requestType('view') }}>Try Again</button>
                    </div>
                </div>
            </div>
        );
    }

}


export default UnauthorisedUser;