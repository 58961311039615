//------------------------------------------------------
//  How to use this component
//------------------------------------------------------

  //DOCUMENTATION
  //  - This component is leveraging React Portals, https://reactjs.org/docs/portals.html.
  //  - To achieve this, I ended up going with three distant containers declared in 'containers\portal-frontend\public\index.html'. 
  //  - This allows modals to breakout of the portals container ('root') and allows us to use z-layers effectively
  //  - Keep leveraging Reacts synthetic events, please keep doing this to prevent any performance issues!
  //    https://www.geeksforgeeks.org/why-we-use-synthetic-events-in-reactjs/#:~:text=Benefits%20of%20using%20synthetic%20events%3A%201%20Cross%20browsers,objects%2C%20by%20pooling%20them%2C%20which%20increase%20the%20performance.

  //PROPERTIES
  //  heading = text or HTML containing the header of the modal
  //  body = text or HTML containing the body of the modal
  //  modalVisible = useState, holds the model state
  //  setModalVisible= useState, sets the model state

  //EXAMPLE
  //  <ModalContent
  //    modalVisible = {modalVisible}
  //    setModalVisible = {setModalVisible}
  //    heading = "How To View Your Monthly Invoices"
  //    body = {
  //      <VideoPlayer
  //        item="Video_Billing_21_12_2021.mp4"
  //        modalVisible={modalVisible}
  //      ></VideoPlayer>
  //    }
  //  ></ModalContent>
  
//------------------------------------------------------

//Libraries
import React, {useEffect} from 'react';
import { createPortal } from "react-dom";

//Images
import Close from '../Images/Icon_Close_White.svg'

//CSS
import './modalcontent.css';


function ModalContent({
  heading,
  body,
  modalVisible,
  setModalVisible
}){
    
  //------------------------------------------------------
  //  Global varibles
  //------------------------------------------------------

    const modalRoot = document.getElementById('modalcontent');
    const modalElement = document.createElement('div');

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // appends the modal to portal once modal's children are mounted and 
    // removes it once we don't need it in the DOM anymore:
    useEffect(() => {

      if (modalRoot === undefined) return;
      if (modalElement === undefined) return;

      modalRoot.appendChild(modalElement);

      return () => {

        modalRoot.removeChild(modalElement);

      };
  
    }, [modalRoot, modalElement]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    if (modalVisible){

      return createPortal(
        <div className="modal-template-background">
          <div className="modal-content-container">
            <img className="modal-content-close-button" alt="undo-icon" src={Close} onClick={() => setModalVisible(false)}></img>
            
            {/* Heading */}
            <div className="modal-content-heading">
              {heading}
            </div>
  
            {/* Body */}
            <div className="modal-content-body">
              {body}
            </div>
  
          </div>
        </div>,
        modalRoot
      )
    }

  //------------------------------------------------------
}

export default ModalContent;