//Libaries
import React from 'react';

//Components
import Label from '../Label/label'

//CSS
import './radiobuttons.css';


//------------------------------------------------------
//  How to use the 'RadioButtons' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <RadioButtons
//          
//      //Radio Button Properties
//      value={"default value is the select box is hidden or read only"}
//      visibility = 'read', 'hide', 'edit' (determines if the component is visible, hidden, editable)
//      name={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.name} - "Name of the radio button field, you can use this in an event handler (event.target.name) to get the value,e.g. direction."
//      arrayOfButtons = array that holds the selectable buttons and their properties
// 
//      //Function Properties
//      onChange="Name of the function that changes any changes in this component: {handleChange}"
//    >
//    </RadioButtons>


//------------------------------------------------------
//  'RadioButtons' component
//------------------------------------------------------


export default function RadioButtons(props) { 

  //------------------------------------------------------
  //  props
  //------------------------------------------------------

    var value = props.value
    var visibility = props.visibility
    var name = props.name
    var arrayOfButtons = props.arrayOfButtons
    var onChange = props.onChange

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  Read Only
    //    - Hide radio buttons and just show text
    //------------------------------------------------------

      if (visibility === "read"){
        return(
          <div>
            <Label label={value}></Label>
          </div>
        );
      }

    //------------------------------------------------------
    //  Hidden
    //    - Hide radio buttons 
    //------------------------------------------------------

      if (visibility === "hide"){
        return null
      }

    //------------------------------------------------------
    //  Edit > Show radio buttons with NO displayname
    //    - Show radio buttons with NO displayname
    //    - This will show an array of toggles
    //    - See the Create Firewall Rule
    //------------------------------------------------------

      else{
        return (
          <div className="radio-buttons-container">
            {arrayOfButtons.map((button, index) =>
              <div key={index} className="radio-button-container">
                  <input className="radio-button" type="radio" id={button} name={name} value={button} onChange={onChange}></input>
                  <label className="radio-field-label" style={{textTransform: 'capitalize'}}> {button} </label> 
              </div>
              )}
          </div>
        );
      }

    //------------------------------------------------------
}