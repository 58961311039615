//Libraries
import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { GetUser, SetUser } from '../../../Library/GlobalContexts';

//Images
import goToConsoleImg from '../../../Components/Images/projects_goToConsole.svg';
import Edit from '../../../Components/Images/Edit_Blue.svg';

//CSS
import '../Projects.css'

function ProjectCard({
  project
}){

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setUser = useContext(SetUser);
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Sets global selector to the page user selects
    function setSelectedProject(projectid, view, route){

      getUser.preferences.globalSelector.selectedView = view
      getUser.preferences.globalSelector.selectedResource = projectid

      // Update the global context
      setUser({...getUser});

      //Route to the correct page
      navigate(route)

    }
   
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

  return (
    <div className='Project-Card-Container'>

      {/* Project Tile Header - Project Type, Launch GCP, Edit */}
      <div className='Project-Card-Top'>
        { 
          project.environment === "sand" ?
          (
            <label style={{background:"#A214C6", color:"white", borderRadius: "5px", height: "20px", padding: "2%"}}>Sandbox</label>
          )
          : 
          (
            <label style={{background:"#18B4EA", color:"white", borderRadius: "5px", height: "20px", padding: "2%"}}>Standard</label>
          )
        }
        
        {/* Icons */}
        <div className='Project-Card-Icons'>
          <a className='Project-Card-Console-Icon' href={`https://console.cloud.google.com/home/dashboard?project=${project.projectid}`} target="_blank" rel="noopener noreferrer">
            <img src={goToConsoleImg} alt="Go to project GCP console icon"/>
          </a>

          <div className='Project-Card-Edit-Icon'>
            <img alt="Edit" src={Edit}/>
            <div className='Project-Card-Menu'>
              <div onClick={()=>setSelectedProject(project.projectid, "project", '/projects/projectgroups')}> Manage Groups </div>
              { /* Can only modify budget and cost for non-sandboxes */
                project.environment !== "sand" ?(
                    <div onClick={()=>setSelectedProject(project.projectid, "project", '/projects/editbudgetandcost')}> Edit Budget and Cost Centre</div>
                  )
                : (
                  null
                )
              }
              { /* Can only delete projct if project owner */
                project.projectowner === getUser?.profile?.emailaddress ?(
                  <div className='Project-Card-Menu-Delete' onClick={()=>setSelectedProject(project.projectid, "project", '/projects/projectdelete')}> Delete Project </div>
                )
                : (
                  null
                )
              }
            </div>
          </div>
        </div>

      </div>
      
      {/* Project ID */}
      <div className='Project-Card-Middle'>{project.projectid}</div>

      {/* Project Budget */}
      <div className='Project-Card-Bottom'>
        {project.projectowner}
        <br></br>
        <br></br>
        Budget ${parseFloat(project.budget) % 1 ? project.budget.toFixed(2) : project.budget} USD
      </div>    
      
    </div>
  )
  //------------------------------------------------------
}

export default ProjectCard;