//================================================================
//  Component: Accept Ownership Transfer Form
//================================================================

//  Purpose: Form component for 'AcceptOwnershipTransfer'

//  Properties:
//    - object = {object, the project object}
//    - transferObject = {useState, transferObject}
//    - preventSubmit = {useState, prevents the submitting of an incomplete form}
//    - setPreventSubmit= {useState, prevents the submitting of an incomplete form}

//  Example:
//    <AcceptOwnershipTransferForm
//      object={ {current project object} }
//      transferObject={"Mark Bennett"}
//      preventSubmit={preventSubmit}
//      setPreventSubmit={setPreventSubmit}
//    ></AcceptOwnershipTransferForm>    

//================================================================


//Libraries
import React, {useReducer, useState, useEffect} from 'react';

//Components
import ErrorMessageLabel from '../../../Components/ErrorMessageLabel/index';
import DataListField from '../../../Components/DataListField/datalistfield';
import GetDocument from '../../../Library/GetDocument';

import { isString } from '../../../Library/ValidateUserInput';



//Images
import WarningIcon from '../../../Components/Images/Warning.png';

//CSS
import './AcceptOwnershipTransfer.css';


export default function AcceptOwnershipTransferForm({
  object,
  transferObject,
  preventSubmit,
  setPreventSubmit,
  pteValid,
  setPteValid,
  projectCodeList,
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const [code, setCode] = useState(object?.projectcode);
    const [codeError, setCodeError] = useState('');
    const [task, setTask] = useState(object?.tasknumber);
    const [taskError, setTaskError] = useState('');
    const [expenditure , setExpenditure] = useState(object?.expendituretype);
    const [expenditureError, setExpenditureError] = useState('');
    const [pteWarning, setPteWarning] = useState();

    //Hold list of all valid PTEs
    const [taskNumberList, setTaskNumberList] = useState([]);

    //Holds list of matching PTEs user can select from
    const [projectCodeOptions, setProjectCodeOptions] = useState([]);
    const [taskNumberOptions, setTaskNumberOptions] = useState([]);

    //Hold Oracleproject name for valid project code
    const [oracleProjectName, setOracleProjectName] = useState('');

    //Determines if the task number or expenditure type was automatically set
    const [taskNumberAutoSet, setTaskNumberAutoSet] = useState(false);
    const [expTypeAutoSet, setExpTypeAutoSet] = useState(false);

    //Do an initial check to start off with
    const [initialLoad, setInitialLoad] = useState(true);


  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------
  
    //Indicates whether PTE values are valid or not
    const [validPteValues, setValidPteValues] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        projectCode: null, // Set to null until initial validation is complete
        taskNumber: null, // Set to null until initial validation is complete
        expenditureType: true // Always true until expenditure type validation is possible
      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // No valid task number yet, so clear everything related to a task number
    function clearTaskNumberInfo() {
      setValidPteValues({ taskNumber: false });
      setTaskNumberOptions([]);
    }

    // No valid project code yet, so clear everything related to a project code
    function clearProjectCodeInfo() {
      setValidPteValues({ projectCode: false });
      setTaskNumberList([]);
      setProjectCodeOptions([]);
      setOracleProjectName('');
      setTaskNumberOptions([]);

      // On initial load, need to leave taskNumber as undefined
      // As we want to delay PTE validation warnings until all checks are complete
      if (!initialLoad) {
        setValidPteValues({ taskNumber: false });
      }
    }

    // Function to find matching project codes
    // If exact match, get info and task numbers
    function matchProjectCodes(input) {
      // Get list of matching project codes and show in project code datalist dropdown
      const searchResult = projectCodeList.filter(x => x.startsWith(input))
      setProjectCodeOptions(searchResult)

      // Exact match
      if(searchResult.length === 1 && searchResult[0] === input) {        
        // Get document for project code from oracleProjects collection
        GetDocument('oracleProjects', input)
        .then((results) => {

          // Project code has document, so accept as valid project code
          setValidPteValues({ projectCode: true })

          // Missing/invalid task number list, so cannot validate
          if(results?.taskNumbers === undefined || results.taskNumbers.constructor.name !== 'Object') {
            setTaskNumberList([]);
            clearTaskNumberInfo();
            return;
          }
          
          // Show the complete list of task numbers in the task number datalist dropdown
          setTaskNumberList(results.taskNumbers)

          // Set the project name if we have it
          if(results.projectName !== undefined) {
            setOracleProjectName(results.projectName)
          }
    
        // Something went wrong with this project code info, so pretend it's not valid
        }).catch((error) => {
          clearProjectCodeInfo()
          return;
        })

      // Haven't pinpointed on exact project code yet
      } else {
        setValidPteValues({ projectCode: false })

        // On initial load, need to leave taskNumber as undefined
        // As we want to delay PTE validation warnings until all checks are complete
        if (!initialLoad) {
          setValidPteValues({ taskNumber: false });
        }

        setTaskNumberList([])
        setTaskNumberOptions([])
        setOracleProjectName('')
      }

    }

    // Function to find matching task numbers
    function matchTaskNumbers(input) {

      // We don't have a task number list, so we cannot search for it
      if(taskNumberList.length <= 0) {
        clearTaskNumberInfo();
        return;
      }

      // No value entered for task number, so show all valid task numbers
      if(input.length <= 0) {
        setTaskNumberOptions(Object.keys(taskNumberList))
        setValidPteValues({ taskNumber: false })
        return;
      }

      // Search for task number
      const searchResult = Object.keys(taskNumberList).filter(x => x.startsWith(input))
      setTaskNumberOptions(searchResult)

      // Exact match
      if(searchResult.length === 1 && searchResult[0] === input) {

        setValidPteValues({ taskNumber: true })

        // If no expenditure type provided by user and task number is 11050
        // set expenditure type to 'ICT Costs' automatically
        if(expenditure.length <= 0 && input === '11050') {
          setExpenditure('ICT Costs')
          fieldChangeValidation('expenditure', 'ICT Costs', true) // autoset = true
        }

      // Multiple matches
      } else {
        setValidPteValues({ taskNumber: false })
      }
    }

  //------------------------------------------------------
  //  Function > Field change validation
  //------------------------------------------------------

    function fieldChangeValidation(name, value, autoSet) {

      // Project Code validation
      if (name === 'code'){

        //New data, so clear PTE warning
        setPteWarning('');

        // Any change to project code will clear the auto set task number and expenditure type
        if(taskNumberAutoSet) {
          setTaskNumberAutoSet(false)
          setTaskError('Invalid task number');
          setTask('')
        }

        if(expTypeAutoSet) {
          setExpTypeAutoSet(false)
          setExpenditure('')
          setExpenditureError('Invalid expenditure type');
        }

        //Field is empty or invalid > Set code error and and clear info derived from the project code
        const regexPattern = /^[0-9]+$/g;
        if(!regexPattern.test(value)){

          setCodeError('Invalid project code');
          clearProjectCodeInfo();

        } else {

          setCodeError('');

          //Only bother processing if there's at least three characters
          if(value.length >= 3) {
            matchProjectCodes(value)

          //Definitely no matching project code, so clear info derived from the project code
          }else{
            clearProjectCodeInfo();
          }
        
        }

        setCode(value);

      }

      // Task Number validation
      if (name === 'task'){

        //New data, so clear PTE warning
        setPteWarning('');

        // Any change to task number will clear the auto set expenditure type
        if(expTypeAutoSet) {
          setExpTypeAutoSet(false)
          setExpenditure('')
          setExpenditureError('Invalid expenditure type');
        }

        // Indicates whether the field was changed automatically or by the user
        setTaskNumberAutoSet(autoSet)

        const regexPattern = /^[a-zA-Z0-9.]+$/g;

        //Field is empty > Set task error and show all valid task numbers
        if(value.length <= 0){
          setTaskError('Invalid task number');
          matchTaskNumbers(value)

        //Field is invalid > Set task error and and clear valid task number
        } else if(!regexPattern.test(value)){

          setTaskError('Invalid task number');
          clearTaskNumberInfo();

        } else {

          setTaskError('');
          matchTaskNumbers(value)

        }

        setTask(value);

      }

      // Expenditure type validation
      if (name === 'expenditure'){

        //New data, so clear PTE warning
        setPteWarning('');

        // Indicates whether the field was changed automatically or by the user
        setExpTypeAutoSet(autoSet)

        if(!isString(value)){

          setExpenditureError('Invalid expenditure type');

        } else {

          setExpenditureError('');

        }

        setExpenditure(value);

      }

    }

    // Checks for PTE errors and show warnings
    function pteValidation(showWarning) {

      // Don't bother showing PTE warning if the fields haven't been filled out yet
      if (code.length <= 0 || task.length <= 0 || expenditure.length <= 0) return

      // Don't bother showing PTE warning if there are worse things going on
      if (codeError.length > 0 || taskError.length > 0 || expenditureError.length > 0) return

      const pteValidOrig = pteValid[object.projectid]
    
      // Invalid project code
      if (!(validPteValues.projectCode)) {
        
        if (showWarning) {
          setPteWarning(`Unknown Oracle Project Code '${code}' provided. You may proceed to submit however we recommended checking that your Oracle Project Code is correct.`)
        }

        pteValid[object.projectid] = false

      // Invalid task number
      } else if (!(validPteValues.taskNumber)) {

        if (showWarning) {
          setPteWarning(`Unknown Task Number '${task}' provided. You may proceed to submit however we recommended checking that your Oracle Task Number is correct.`)
        }

        pteValid[object.projectid] = false

      // Invalid expenditure type
      } else if (!(validPteValues.expenditureType)) {

        if (showWarning) {
          setPteWarning(`Unknown Expenditure Type '${expenditure}' provided. You may proceed to submit however we recommended checking that your Oracle Expenditure Type is correct.`)
        }

        pteValid[object.projectid] = false

      // No PTE errors
      } else {
        pteValid[object.projectid] = true
      }

      if (pteValidOrig !== pteValid[object.projectid]) {
        setPteValid({...pteValid})
      }

    }

    //Handles values from input and adds to useState
    function HandleChange(event){
      
      const name = event.target.name;
      const value = event.target.value;
      
      // Validate field that was changed (autoset = false)
      fieldChangeValidation(name, value, false);

    }


  //------------------------------------------------------
  //  Event Handler > On Exit Component (onBlur)
  //------------------------------------------------------
  
    //Event Handler for field changes 
    const handleBlur = evt => {

      const name = evt.target.name;

      // Process if PTE details have changed
      if (name === 'code' || name === 'task' || name === 'expenditure') {
        pteValidation(true) // Show warning
      }  
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Update fields
    useEffect(() =>{

      //Conditions
      if (transferObject?.projects === undefined && transferObject.projects.length === 0) return;
      if (object === undefined) return;
      if (preventSubmit === undefined) return;

      // Stop submission if any field is missing or invalid
      if (code === undefined || code === '' || task === undefined || task === '' || expenditure === undefined || expenditure === '' ||
        codeError.length > 0 || taskError.length > 0 || expenditureError.length > 0) {

        if(!preventSubmit[object.projectid]) {

          preventSubmit[object.projectid] = true
          setPreventSubmit({...preventSubmit})
        }
        return;
      }

      //Find the object and update with new values
      transferObject.projects.forEach((project) => {

        if (project.projectid === object.projectid){
          project.projectcode = code;
          project.tasknumber = task;
          project.expendituretype = expenditure;
        }
      });

      if(!initialLoad) {
        pteValidation(false); // Perform validation but don't show warnings yet
      }

      if(preventSubmit[object.projectid]) {

        preventSubmit[object.projectid] = false
        setPreventSubmit({...preventSubmit})
      }

    // eslint-disable-next-line
    }, [code, task, expenditure, object, transferObject, preventSubmit]);

    // Do initial validation
    useEffect(() => {

      // No values loaded yet
      if (code === undefined) return;
      if (task === undefined) return;
      if (expenditure === undefined) return;
      if (pteValid === undefined) return;
      if (preventSubmit === undefined) return;
   
      // Only run on initial load of PTE data
      if (initialLoad) {
        
        // Haven't kicked off any checks yet, so let's do that
        if (validPteValues.projectCode === null) {
          matchProjectCodes(code);
        }
        

        // Initial PTE checks are done, so let's show warnings if there is a problem
        if (validPteValues.projectCode !== null && validPteValues.taskNumber !== null) {
          pteValidation(true); // Show warnings
          setInitialLoad(false)
        }
      }

    // eslint-disable-next-line
    }, [code, task, expenditure, validPteValues, pteValid, preventSubmit]);

    // When the task numbers for a project code are made available
    useEffect(() => {

      // Haven't initialised yet, so no point validating task number
      if (task === undefined) return;
      if (validPteValues.projectCode === null) return;
      
      // If no task number provided by user and 11050 is a valid task number for this project
      // set it automatically
      if(task.length <= 0 && Object.keys(taskNumberList).includes('11050')) {
        setTask('11050')
        fieldChangeValidation('task', '11050', true) // autoset = true

      //Re-check validity of whatever task is there
      } else {
        fieldChangeValidation('task', task, false)
      }

    // eslint-disable-next-line
    }, [taskNumberList]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <>
      <tr key={object?.projectid}>
        <td>{object?.projectid}</td>

        {/* Project Code */}
        <td>
          <div className='AcceptOwnershipTransferForm-Input'>
            <DataListField name='code' value={code} onChange={(event) => HandleChange(event)} onBlur={handleBlur} options={projectCodeOptions} fullSize={true}></DataListField>
          </div>
        </td>

        {/* Task Number */}
        <td>
          <div className='AcceptOwnershipTransferForm-Input'>
            <DataListField name='task' value={task} onChange={(event) => HandleChange(event)} onBlur={handleBlur} options={taskNumberOptions} fullSize={true}></DataListField>
          </div>
          
        </td>

        {/* Expenditure Type */}
        <td>
          <div className='AcceptOwnershipTransferForm-Input'>
            <DataListField name='expenditure' value={expenditure} onChange={(event) => HandleChange(event)} onBlur={handleBlur} options={[]} fullSize={true}></DataListField>
          </div>
        </td>
      </tr>

      {/*------------------------------------------------------------------ */}
      {/*   Project name display                                            */}
      {/*------------------------------------------------------------------ */}

      <tr>
        <td></td>
        <td>
          <div className='AcceptOwnershipTransferForm-response-label'>
            { /* Show Oracle project name if we have a valid project code */
              oracleProjectName.length > 0 ? (
                oracleProjectName
              ) : (null)
            }
          </div>
          <div className='AcceptOwnershipTransferForm-error-label'>
            <ErrorMessageLabel errorMessage={codeError} errorVisible={true}></ErrorMessageLabel>
          </div>
        </td>
        <td>
          <div className='AcceptOwnershipTransferForm-error-label'>
            <ErrorMessageLabel errorMessage={taskError} errorVisible={true}></ErrorMessageLabel>
          </div>
        </td>
        <td>
          <div className='AcceptOwnershipTransferForm-error-label'>
            <ErrorMessageLabel errorMessage={expenditureError} errorVisible={true}></ErrorMessageLabel>
          </div>
        </td>
      </tr>

      {/*------------------------------------------------------------------ */}
      {/*   Invalid PTE label (if required)                                 */}
      {/*------------------------------------------------------------------ */}
      <tr>
        <td colSpan={4}>
          {
            pteWarning ? (
              <div className='PageComponent-Label'>
                <div className='PageComponent-LabelMessage'>
                  <img style={{marginRight: "15px"}} src={WarningIcon} alt="Warning Icon"></img>
                  <p>
                    {pteWarning}
                  </p>
                </div>
              </div>

            ) : (null)
          }
        </td>
      </tr>
    </>
  )
}

