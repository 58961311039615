//================================================================
//  Navigation Bar
//================================================================

//Libraries
import React, { useEffect, useContext } from 'react';

//Contexts
import { GetUser, SetUser } from '../../Library/GlobalContexts';

//Components
import GlobalSelectorModal from './Components/globalselectormodal';
import Logout from '../../Library/Logout'

//Images
import NavBarProfileIcon from '../Images/Profile_Icon.png';
import GlobalSelectorDropdown from '../Images/Icon_SelectorDropdown_Black.svg';
import OrganizationSelectorIcon from '../Images/Icon_Organization_Black.svg';
import ProjectSelectorIcon from '../Images/Icon_ProjectSelector_Black.svg';
import PortfolioSelectorIcon from '../Images/Icon_PortfolioSelector_Black.svg';


//CSS Style Example > https://www.w3schools.com/howto/howto_css_topnav_right.asp
import './navbar.css';


function NavBar({navStyle}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setUser = useContext(SetUser);

  //------------------------------------------------------
  //  Define variables
  //------------------------------------------------------

    const modalRoot = document.getElementById('modalcontent');
    const modalElement = document.createElement('div');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Set modal visibility for the global selector
    function showModal(visibility) {
    
      if(visibility === undefined) {

        getUser.preferences.globalSelector.visible = !getUser.preferences.globalSelector.visible

      } else {

        getUser.preferences.globalSelector.visible = visibility

      }

      //Update getUser context
      setUser({...getUser})

    };

    //Handle Views > Display dropdown
    function handleDropdownView(view){
      
      if(view === 'organization') {
        return (
          <>
            <img src={OrganizationSelectorIcon} alt="Organization Selector Icon"></img>
            <p>
              {
                (!getUser?.preferences?.globalSelector?.selectedResource || getUser?.preferences?.globalSelector?.selectedResource === "none") ? 
                ("Select an organization") 
                : 
                ('lendlease.cloud') 
              }
            </p>
          </>
        )
      }

      else if (view === 'portfolio') {
        return (
          <>
            <img src={PortfolioSelectorIcon} alt="Portfolio Selector Icon"></img>
            <p>
              {
                (!getUser?.preferences?.globalSelector?.selectedResource || getUser?.preferences?.globalSelector?.selectedResource === "none") ? 
                ("Select a portfolio") 
                : 
                (
                  // Portfolio Names are NOT UNIQUE, we need to lookup the portfolioid in 'selectedResource' to 'portfolioname' stored in 'allPortfoliosMetadata'
                  // Only one value SHOULD BE returned, 'portfolioid' SHOULD be unique!
                  (
                    getUser?.profile?.views?.allPortfoliosMetadata?.filter(
                      (object) => object.portfolioid === getUser?.preferences?.globalSelector?.selectedResource
                    )[0]?.portfolioname
                  )
                ) 
              }
            </p>
          </>
        )
      }

      else {
        return (
          <>
            <img src={ProjectSelectorIcon} alt="Project Selector Icon"></img>
            <p>
              {
                (!getUser?.preferences?.globalSelector?.selectedResource || getUser?.preferences?.globalSelector?.selectedResource === "none") ? 
                ("Select a project") 
                : 
                (getUser?.preferences?.globalSelector?.selectedResource) 
              }
            </p>
          </>
        )
      }

    }

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // Appends the modal to portal once modal's children are mounted and 
    // Removes it once we don't need it in the DOM anymore:
    useEffect(() => {

      if (modalRoot === undefined) return;
      if (modalElement === undefined) return;

      modalRoot.appendChild(modalElement);

      return () => {

        modalRoot.removeChild(modalElement);

      };
  
    }, [modalRoot, modalElement]);
    
  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------
 
  return (
      <nav className={navStyle}>           
        {/*----------------------------------------------- */}
        {/*   Global Selector                              */}
        {/*------------------------------------------------*/}

          <>

            {/* Toggle between Project/Portfolio/Organization view of select button */}
            <button className='Global-Selector-Dropdown' onClick={() => showModal()}> 
              {handleDropdownView(getUser?.preferences?.globalSelector?.selectedView)}     
              <img src={GlobalSelectorDropdown} alt="Dropdown"></img>
            </button>
        
            {/* Global Selector Modal */}
            {
              getUser.preferences.globalSelector.visible ? (
                <GlobalSelectorModal></GlobalSelectorModal>
              ) : (
                null
              )
            }

          </>

        {/*----------------------------------------------- */}
        {/*   Profile                                      */}
        {/*------------------------------------------------*/}

          <>
            {/* Profile Icon */}
            {
              (getUser?.profile?.givenname?.length > 0 && getUser?.profile?.surname?.length > 0) ?
              (
                <div className='NavBar-Profile-Icon'>{getUser?.profile?.givenname[0]?.toUpperCase()}{getUser?.profile?.surname[0]?.toUpperCase()}</div>
              )
              :
              (
                <img className="NavBar-Profile-Icon" style={{backgroundColor: "white", padding: "0px", margin: "0px", border: "none"}} src={NavBarProfileIcon} alt="Dark Profile Icon"></img>
              )
            }

            {/* Profile Dropdown */}
            <div className="NavBar-Profile-Dropdown">

              {/* Sign Out Button */}
              <Logout title="Sign Out" setStyle="navbar"></Logout>

            </div>
          </>
      </nav>
  );
}

export default NavBar;