//Libraries
import React from 'react';

//Components
import UnauthorisedUser from '../UnauthorisedUser/unauthoriseduser';

//Images
import LoadingIcon from '../Images/Loading_Ripple.svg'

//CSS
import './componentresponse.css';

//------------------------------------------------------
//  How to use the 'ComponentResponse' component
//  Description: This component returns the correct display depending the status returned from an API calls. 
//               It is generally used to build portfolio views but may be used elsewhere.
//------------------------------------------------------


//    1. Import this component (example below)

//    <ComponentResponse
//      status={financeAPIStatus}
//      setStatus={financeAPIResponse}
//      onloadcontent={[
//          <div>Select a portfolio above.</div>
//      ]}
//      successcontent={[
//        <div>
//          {Barcharts, etc.}
//        </div>
//      ]}
//      invaliderrorcontent={[
//        <div style={{textAlign: "center"}} className="component-response-container">
//          <p style={{lineHeight: "1.7"}}>
//            Unable to find anything.... {financeAPIResponse}
//          </p>
//        </div>
//      ]}
//      othererrorcontent={[
//        <div style={{textAlign: "center"}} className="component-response-container">
//          <p style={{lineHeight: "1.7"}}>
//            {financeAPIResponse}
//          </p>
//        </div>
//      ]}
//    >
//    </ComponentResponse>

//    2. Refer to the form properties below to understand their use

//    <ComponentResponse
//      status= The components state (similar to pageStatus or formStatus), e.g. 'onload', 'success', 'error-invalid', etc. 
//      setStatus= The setState function used to update the component's state, (similar to setPageStatus or setFormStatus), e.g. setFinancialAPIStatus()
//      setRequestType= The setState function used to update the parent's requestType state
//      onloadcontent= The HTML block that is displayed onload of the component, i.e. when status == 'onload'
//      successcontent= The HTML block that is displayed the component API call returns 'success', i.e. when status == 'success'
//      invaliderrorcontent= (Optional) The HTML block that is displayed the component API call returns 'error-invalid', i.e. when status == 'error-invalid'
//      othererrorcontent= (Optional) The HTML block that is displayed the component API call returns 'error-other', i.e. when status == 'error-other'
//    >
//    </ComponentResponse>


//------------------------------------------------------
//  'ComponentResponse' component
//------------------------------------------------------

function ComponentResponse(props){

  //------------------------------------------------------
  //  Extract props
  //------------------------------------------------------

    let status = props.status
    let setStatus = props.setStatus
    let setRequestType = props.setRequestType
    let onloadcontent = props.onloadcontent
    let successcontent = props.successcontent
    let invaliderrorcontent = props.invaliderrorcontent
    let othererrorcontent = props.othererrorcontent

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    /*---------------------------------------------------- */
    /*   Status = 'accessdenied'                           */
    /*---------------------------------------------------- */

    if (status === 'accessdenied'){
      return (
        <UnauthorisedUser
          message={
            <p>
              Sorry it seems like you do not have the necessary permissions to access this page. 
              <br></br>
              <br></br>
              If you feel this is a mistake, contact the Lendlease Cloud Team <a href = "https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96&sysparm_category=109f0438c6112276003ae8ac13e7009d" target="_blank" rel="noopener noreferrer"> here</a>.
            </p>
          }
          requestType={setRequestType}
          refresh={props.refresh}
        >
        </UnauthorisedUser>
      );
    }

    /*---------------------------------------------------- */
    /*   Status = 'onload'                                 */
    /*---------------------------------------------------- */

    //Call API > status = 'onload'
    if(status === 'onload'){
      if(onloadcontent){
        return onloadcontent
        
      } else {
        return (
          <div className="component-response-container">
            <p className="table-message">Please select a project ID above.</p>
          </div>
        );
      }
    }

    /*---------------------------------------------------- */
    /*   Status = 'pending'                                */
    /*---------------------------------------------------- */

    //Call API > status = 'pending'
    else if(status === 'pending'){
      return (
        <div className="component-response-container-pending">
          <img alt="loading-circle-icon" src={LoadingIcon}></img>
            <h3>Please wait while we process your request.</h3>
            <div>
              <button className="form-submit-button" onClick={()=>{setStatus('onload')}}>CANCEL</button>
            </div>
        </div>
      );
    }

    /*---------------------------------------------------- */
    /*   Status = 'success'                                */
    /*---------------------------------------------------- */

    //API call returns no errors > status = 'success'
    else if (status === 'success'){
      return successcontent
    }

    /*---------------------------------------------------- */
    /*   Status = 'error-invalid'                          */
    /*---------------------------------------------------- */
    
    //Request fails > status = 'error-invalid'
    else if(status === 'error-invalid'){
      if(invaliderrorcontent){
        return invaliderrorcontent

      } else{
        return (
          <div style={{textAlign: "center"}} className="component-response-container">
            <h2>Request Failed</h2>
            <p style={{lineHeight: "1.7"}}>
              An error occurred while we processed your request.
              <br></br>
              If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
            </p>
            <p> <b>Message:</b> Failed to load the page - Invalid request.</p>
            
            {/* Try Again Button */}
          <button className="try-again-button" onClick={()=>{window.location.reload()}}>Try Again</button>
        </div>
        )
      }
    }

    /*---------------------------------------------------- */
    /*   Status = 'error-fatal'                            */
    /*---------------------------------------------------- */

    //Request fails > status = 'error-fatal'
    else if(status === 'error-fatal'){
      return (
        <div style={{textAlign: "center"}} className="component-response-container">
          <h2>Oops something went wrong</h2>
          <p style={{lineHeight: "1.7"}}>
            An error occurred while we processed your request.
            <br></br>
            If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
          </p>
          <p> <b>Message:</b> Failed to load the page - Fatal Error.</p>
          
          {/* Try Again Button */}
        <button className="try-again-button" onClick={()=>{window.location.reload()}}>Try Again</button>
      </div>
      );
    }  

    /*---------------------------------------------------- */
    /*   Status = 'error-timeout'                          */
    /*---------------------------------------------------- */

    //Request fails > status = 'error-timeout'
    else if(status === 'error-timeout'){
      return (
        <div style={{textAlign: "center"}} className="component-response-container">
          <h2>Request Failed</h2>
          <p style={{lineHeight: "1.7"}}>
            An error occurred while we processed your request.
            <br></br>
            If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
          </p>
          <p> <b>Message:</b> Failed to load the page - Request timed out.</p>
          
          {/* Try Again Button */}
        <button className="try-again-button" onClick={()=>{window.location.reload()}}>Try Again</button>
      </div>
      )
    }

    /*---------------------------------------------------- */
    /*   Status = 'error-other'                            */
    /*---------------------------------------------------- */

    //Request fails > status = 'error-other'
    else if(status === 'error-other'){
      return (
        <div className="component-response-container">
          <p className="table-message">
            {othererrorcontent}
          </p>
        </div>
      )
    } 
}

export default ComponentResponse;

//------------------------------------------------------