//================================================================
//  Getting Started Page
//================================================================

//  Purpose: Checklist for new users who are getting started with the cloud

//================================================================

//Libraries
import { useNavigate } from "react-router-dom";

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';

//Images
// import Chippy from '../../Components/Images/GettingStarted_seagull_celebration.svg'
import IconGuild from '../../Components/Images/Icon_GettingStarted_Guild_Blue.svg'
import IconLearnMore from '../../Components/Images/Icon_GettingStarted_LearnMore_Blue.svg'
import IconNetworking from '../../Components/Images/Icon_GettingStarted_Networking_Blue.svg'
import IconProjects from '../../Components/Images/Icon_GettingStarted_Projects_Blue.svg'

//CSS
import './GettingStarted.css'


export default function GettingStarted() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------ 

    const navigate = useNavigate(); 

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <PageHeader
            title={'Getting Started'}
            // modaltitle={' '}
            // modalbody={
            //   <img className="modal-lucky-image-gettingstarted" alt="Chippy" src={Chippy}></img>
            // }
            body={
              <div>
                A guide to help you get started with the Lendlease Cloud Portal.  
              </div>
            }
          ></PageHeader>
        }
        body={
          <div className="Parent-Container-GettingStarted">

            {/* Create a Project */}
            <div className="Content-Container-GettingStarted">
              <img  className="Icon-Content-GettingStarted" alt="IconProjects" src={IconProjects}></img>
              <div className="Text-Content-GettingStarted">
                <h3>Create a Project</h3>
                The Lendlease Cloud Portal allows you to create and manage Google Cloud projects within the Lendlease.cloud organisation.
                <br></br>
                Learn how here
                <button onClick={() => {navigate("/projects/create")}}> Documentation </button>
              </div>
            </div>

            {/* Connect to the Lendlease network */}
            <div className="Content-Container-GettingStarted">
              <img className="Icon-Content-GettingStarted" alt="IconNetworking" src={IconNetworking}></img>
              <div className="Text-Content-GettingStarted">
                <h3>Connect to the Lendlease network</h3>
                The Lendlease Cloud Portal also allows you to self-serve firewall rules, DNS zones and certificates. 
                <br></br>
                Learn more here
                <button onClick={() => {navigate("/sharedvpc")}}> Documentation </button>
              </div>
            </div>

            {/* Learn more */}
            <div className="Content-Container-GettingStarted">
              <img className="Icon-Content-GettingStarted" alt="IconLearnMore" src={IconLearnMore}></img>
              <div className="Text-Content-GettingStarted">
                <h3>Learn more</h3>
                Check out our Knowledge Base articles and FAQ’s for more information.
                <br></br>
                Learn more here
                <button onClick={() => {navigate("/knowledgehub/all/knowledge")}}> Documentation </button>
              </div>
            </div>

            {/* Join the Cloud Guild */}
            <div className="Content-Container-GettingStarted">
              <img className="Icon-Content-GettingStarted" alt="IconGuild" src={IconGuild}></img>
              <div className="Text-Content-GettingStarted">
                <h3>Join the Cloud Guild</h3>
                Connect with the Lendlease Cloud community and get the latest GCP updates, access to training and events. 
                <br></br>
                Join here
                <button style={{backgroundColor: "#616E7A"}}>
                  <a href="https://info.lendlease.cloud/theguild" target="_blank" rel="noopener noreferrer" className="Link-Content-GettingStarted">
                    Community 
                  </a></button>
              </div>
            </div>

          </div>
        }
      ></PageComponent>
    )
}
