//------------------------------------------------------
//  Google LineChart
//  Created by Mark Bennett 18/01/2022
//------------------------------------------------------  

//Libaries
import React from 'react'
import Chart from "react-google-charts";


export default function LineChart(props) {

  //------------------------------------------------------
  //  How to use the 'LineChart' component
  //  https://react-google-charts-docs.netlify.app/examples/line-chart
  //------------------------------------------------------  
  //    
  //    1. Import this component
  //    2. Use the below inside a form component
  //    3. Note: Check out the above page for different options

  //     NOTE: the data needs to look like this:
  //     export const data = [
  //       ["Year", "Sales", "Expenses"],
  //       ["2004", 1000, 400],
  //       ["2005", 1170, 460],
  //       ["2006", 660, 1120],
  //       ["2007", 1030, 540],
  //     ];

  //      <LineChart
  //      width={'100%'}
  //      key={chartWidthKey}
  //      height={'400px'}
  //      data={LineChartInput(apiResponse)}
  //      options={
  //        {
  //          curveType: "function",
  //          legend: { position: "bottom" },
  //        }
  //      }
  //      >
  //      </LineChart>

  // NOTE: For charts to work, the following Content Security Policy additions need to be added to the nginx configuration file:
  // 'script-src-elem' must contain 'https://www.gstatic.com/charts/'
  // 'style-src' must contain 'https://www.gstatic.com/charts/' and '*.googleapis.com'
  // 'font-src' must contain 'https://fonts.gstatic.com'
  // See: https://github.com/google/google-visualization-issues/issues/2880


  //------------------------------------------------------
  // Props
  //------------------------------------------------------  

    var width = props.width
    var height = props.height
    var key = props.key
    var data = props.data
    var options = props.options

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    return (
        <Chart
          width={width}
          height={height}
          key={key}
          chartType="LineChart"
          data={data}
          options={options}
        >
        </Chart>
    )

  //------------------------------------------------------
}
