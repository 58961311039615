//Libaries
import React from 'react';

//CSS
import './statuslabel.css';


//------------------------------------------------------
//  How to use the 'StatusLabel' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <StatusLabel
//          
//      //StatusLabel Properties
//      label="e.g. complete, fail, etc."
//          
//    >
//    </StatusLabel>


//------------------------------------------------------
//  'StatusLabel' component
//------------------------------------------------------


function StatusLabel(props) { 
  
  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

  if(props.label === 'complete' || props.label === 'completed' || props.label === 'success'){
    return (
      <>
        {/* Success Label */}
        <label className="success-label">COMPLETE</label>
          
      </>
    );

  } else if(props.label === 'pending' || props.label === 'inprogress' || props.label === 'pendingAcceptance'){
    return (
      <>
        {/* Error Label */}
            <label className="pending-label">PENDING</label>
          
      </>
    );

  } else if(props.label === 'fail' || props.label === 'failed'){
    return (
      <>
        {/* Error Label */}
            <label className="error-label">FAILED</label>
          
      </>
    );
  } else if(props.label === 'rejected' || props.label === 'cancelled'){
    return (
      <>
        {/* Rejected Label */}
        <label className="rejected-label">REJECTED</label>
      </>
    );
  } else {
      return (
        <>
          {/* Error Label */}
          <label className="error-label">-</label>
        </>
      );
  }


  //------------------------------------------------------
}


export default StatusLabel;