//================================================================
//  Projects Page
//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

//Functions
import ReactBackend from '../../Library/reactBackend';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import RequestSummary from '../../Components/RequestSummary/requestsummary';
import ProjectCard from './Components/ProjectCard';

//Images
import AddProjectIcon from '../../Components/Images/Large_Add_Icon_Grey.svg';
import InfoIcon from '../../Components/Images/Icon_Info_Black.svg';

//CSS
import './Projects.css';
import GetDocument from '../../Library/GetDocument';


export default function Projects() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);
    
    //React router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to determine the status of the page > 'onload', 'error-invalid'
    const [pageStatus, setPageStatus] = useState('pending');

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    //Used to store projects
    const [projects, setProjects] = useState([]);
    
    //getJobs API States
    const [getProjectRequestStatus, setProjectRequestStatus] = useState('onload');
    const [getProjectRequestResponse, setProjectRequestResponse] = useState([]);

  //------------------------------------------------------
  //  API Calls
  //------------------------------------------------------

    //Used to call "getJobs" 
    async function getProjectRequestsAPI (){

      var requestBody = {
        "requestType": "user",
        "jobName": ["submitProjectRequest", "submitOperatorGroupManagement"]
      }

      setProjectRequestStatus('pending')

      //Call React Backend - Submit Form Response
      var response = await ReactBackend('getJobs', requestBody)
  
      //Set API Response
      setProjectRequestResponse(response.responseBody.message)

      //Switch on status
      if(response.status === 'success'){
            
        //Set status to 'success'
        setProjectRequestStatus('success')
  
      } else if(response.status === 'error-invalid'){
  
        //Set status to 'error-invalid'
        setProjectRequestStatus('error-invalid')
  
      } else if(response.status === 'error-fatal'){
          
        //Set status to 'error-fatal'
        setProjectRequestStatus('error-fatal')
  
      } else if(response.status === 'error-timeout'){
      
        //Set status to 'error-timeout'
        setProjectRequestStatus('error-timeout')
  
      }  
      return response
  
    } 
    
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Runs onload and calls the 'projects' collection
    useEffect(() => {
      
      // These are the conditions for the projects page (allows time for getUser to update before loading the page)
      if (getUser.profile?.views?.allProjectsMetadata === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      // Get Project Requests
      getProjectRequestsAPI()

      // Holds array of promises 
      let projectPromises = []

      // Holds array of documents 
      let resultsArray = []

      getUser.profile?.views?.allProjectsMetadata?.forEach((project) => {

        projectPromises.push(GetDocument("projects", project?.projectid))
        
      });

      // Settles all promises at once
      Promise.allSettled(projectPromises)
      .then((promises) =>{

        //Check promise fulfilled > Save to useState
        promises.forEach((result)=> {
          if (result.status === "fulfilled"){
            resultsArray.push(result.value)
          }
        })

        //Sort project in alphabetical order
        resultsArray.sort(function (a, b) {
          if (a.projectid < b.projectid) return -1;
          if (a.projectid > b.projectid) return 1;
          return 0;
        });

        setProjects(resultsArray);

        setPageStatus('success');

      }).catch((error) => {
          console.log("Failed to get records from project collection. Error: ", error);
          setAppErrors("Failed to get records from project collection. Error: ", error);
          setPageStatus('error-invalid');
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getUser])

    
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------
  
  return (
    <PageComponent
      requireSelectedResource={true}
      requireSelectedViews={['organization']}
      status={pageStatus}
      header={
        <PageHeader
          title={'Projects'}
          body={
            <span>
              Click the ‘+’ button below, to create your own GCP project in the lendlease.cloud organisation.
              <br></br>
              To grant users access to your projects and edit other project settings, click the edit icon next to the project.
              <br></br>
              <br></br>
              As a project owner, you can also transfer ownership of your project to another user and accept ownership of projects that need to be transferred to you.
                  <br></br>
                  To manage your project ownership transfers, click the 'Manage Project Ownership' button below.

            </span>
          }
        ></PageHeader>
      }
      successContent={
        <div className="Projects-Container">

            {/*------------------------------------------------------------------ */}
            {/*   Project owner transfer bar                                      */}
            {/*------------------------------------------------------------------ */}  

            <div className="Table-Pane">
              <button className="Primary-Button" style={{margin: "5px 0px"}} onClick={e => {
                navigate('/projects/ownershiptransfer')
              }}> Manage Project Ownership </button>

            </div>

          {/*------------------------------------------------------------------ */}
          {/*   Active Projects                                                 */}
          {/*------------------------------------------------------------------ */}  

          {
            projects?.length === 0 ? (
              <div className='PageComponent-Label' style={{marginTop: "40px"}}>
                <div className='PageComponent-LabelMessage'>
                  <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
                  <p>
                    No projects found.
                  </p>
                </div>
              </div>
            ) : (null)
          }
            <div className='Projects-Tile-Section' style={{marginTop: "40px"}}>
              {/* Create Project Button */}
              <div className='Project-Card-Add' onClick={()=> navigate("/projects/create")}>
                <img alt="AddProjectIcon" src={AddProjectIcon}/>
              </div>

              {
                projects?.length !== 0 ? (
                  <>
                    {/* Project Tiles */}
                    {
                      projects?.map((project, index) => (
                        <ProjectCard
                          key={index}
                          project={project}
                        ></ProjectCard>
                      ))
                    }
                  </>
                ) : (null)
              }
            </div>
            
          {/*------------------------------------------------------------------ */}
          {/*   Requests Summary                                                */}
          {/*------------------------------------------------------------------ */}  

            <div className="Table-Pane" style={{marginTop: "40px"}}>
              <p className='Page-Subheading'> Requests Summary </p>
              <RequestSummary className="project-request-summary-container"
                status={getProjectRequestStatus}
                setStatus={setProjectRequestStatus}
                rows={getProjectRequestResponse}
              ></RequestSummary>
            </div>

        </div>
      }
    ></PageComponent>
  )
}
