//Libaries
import React from 'react';

//Images
// import RefreshButton from '../RefreshButton/refreshbutton'

//CSS
import './productfilterpane.css';


//------------------------------------------------------
//  How to use the 'ProductFilterPane' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <ProductFilterPane
//    productFilterComponents={[
//        <button className="create-project-button" disabled={createButtonDisabled} onClick={() => {setRequestType('create')}}> Create GCP Project </button>
//    ]}
//    >
//    </ProductFilterPane>


function ProductFilterPane(props) { 

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

  return(
    <div className="productfilterpane-container">
      
      {/*------------------------------------------------------------------ */}
      {/*   Passed in components                                            */}
      {/*------------------------------------------------------------------ */}  

      <div className="productfilterpane-components">
        {props.productFilterComponents}
      </div>

      {/*------------------------------------------------------------------ */}
      {/*   Refresh button                                                  */}
      {/*------------------------------------------------------------------ */}  

      <div className="productfilterpane-refreshbutton">
        {/* <RefreshButton></RefreshButton> */}
      </div> 
    </div>

  );


  //------------------------------------------------------
}

export default ProductFilterPane;