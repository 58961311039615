//==========================================================================
//   Component: Project Emissions Table Row
//==========================================================================

//   Purpose: Allows user to expand/collapse row in the project emissions table

//   Properties: 
//   - rowData = {data used to populate the rows in the table}
//   - getTrendStyle = {Function to style the trend depending on if the trend is positive/negative}

//   Example: 
//   <ProjectEmissionsTableRow
//      rowData={row}
//      getTrendStyle={getTrendStyle}
//   ></ProjectEmissionsTableRow>

//------------------------------------------------------


//Libraries
import React, {useState} from 'react'

//Images
import ExpandIcon from '../../../Components/Images/Icon_Expand_Black.svg'

//CSS
import './sustainabilitycomponents.css'

export default function ProjectEmissionsTableRow({
    index,
    rowData,
    getTrendStyle
}) {

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

        //Used to determine if the row is expanded/collapsed
        const [rowExpanded, setRowExpanded] = useState(false);

    //------------------------------------------------------
    //  Return HTML
    //------------------------------------------------------

        //------------------------------------------------------
        //  Row Expanded
        //------------------------------------------------------
        
        if(rowExpanded) {
            return (
                <>
                    <tr key={index}>
                        <td>{rowData.projectId}</td>
                        <td>{rowData.previousMonthTotal ? rowData.previousMonthTotal : 0}</td>
                        <td>{rowData.latestMonthTotal ? rowData.latestMonthTotal : 0}</td>
                        <td>{getTrendStyle(rowData.trend)}</td>
                        <td>
                            <img className="Expand-Icon" src={ExpandIcon} style={{transform: "rotate(90deg)"}} alt="Expand_Icon" onClick={() => setRowExpanded(false)}></img>
                        </td>
                    </tr>
                    {/* Row Expanded > Show Service Emissions */}
                    <tr>
                        <td colSpan="5">
                        {
                            rowData.serviceEmissions.length > 0 ? 
                            (
                                rowData.serviceEmissions.map((service, index) => (
                                    <table className="Service-Emissions-Table">
                                        <colgroup>
                                            <col span="1" style={{width: "22.5%"}}></col>
                                            <col span="1" style={{width: "22.5%"}}></col>
                                            <col span="1" style={{width: "22.5%"}}></col>
                                            <col span="1" style={{width: "22.5%"}}></col>
                                            <col span="1" style={{width: "10%"}}></col>
                                        </colgroup>
                                        <tbody>
                                            {/* Service Name (Row Header) */}
                                            <tr key={index}>
                                                <th colSpan="5">{service.serviceName}</th>
                                            </tr>
                                            {/* Location Emissions */}
                                            {service.locationEmissions.map((location, index) => (
                                                <tr>
                                                    <td>{location.locationName}</td>
                                                    <td>{location.previousMonthTotal}</td>
                                                    <td>{location.latestMonthTotal}</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ))
                            )
                            : 
                            (
                                <div style={{fontSize: "14px", paddingLeft: "15px"}}>No emissions found.</div>
                            )
                        }
                        </td>
                    </tr>
                </>
            )
        }

        //------------------------------------------------------
        //  Row Collapsed
        //------------------------------------------------------

        else {
            return (
                <tr key={index}>
                    <td>{rowData.projectId}</td>
                    <td>{rowData.previousMonthTotal ? rowData.previousMonthTotal : 0}</td>
                    <td>{rowData.latestMonthTotal ? rowData.latestMonthTotal : 0}</td>
                    <td>{getTrendStyle(rowData.trend)}</td>
                    <td>
                        <img className="Expand-Icon" src={ExpandIcon} alt="Expand_Icon" onClick={() => setRowExpanded(true)}></img>
                    </td>
                </tr>
            )
        }
        
}
