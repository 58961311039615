//==========================================================================
//   Component: Firewall Table Row
//==========================================================================

//   Purpose: Shows an expandable row in the Active Firewall Rules table

//   Properties: 
//   - rowData = {data used to populate row in the table}
//   - allRowsExpanded = {useState[0] to force all rows to expand or collapse}
//   - setAllRowsExpanded = {useState[1] to clear flag used to expand or collapse all rows}
//   - managementType = {whether the firewall rule is managed by the portal or IaC}
//   - setModalVisible = {useState[1] to show the delete rule confirmation modal}

//   Example:
//   <FirewallTableRow
//     rowData={row}
//     allRowsExpanded={allRowsExpanded}
//     setAllRowsExpanded={setAllRowsExpanded}
//     managementType={getUser.preferences?.firewall?.managementType}
//     setModalVisible={setModalVisible}
//   ></FirewallTableRow>

//------------------------------------------------------


//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components

//Functions

//Images
import ExpandIcon from '../../../Components/Images/Icon_Expand_Black.svg';
import Edit from '../../../Components/Images/Edit_Blue.svg';
import Delete from '../../../Components/Images/Delete_Blue.svg';

//CSS
import '../Firewall.css';


export default function FirewallTableRow({
  rowData,
  allRowsExpanded,
  setAllRowsExpanded,
  managementType,
  setModalVisible
}) {

  const columns = managementType === 'portal' ? 7 : 5

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
      
      const navigate = useNavigate();
      const getUser  = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

      //Used to determine if the row is expanded/collapsed
      const [rowExpanded, setRowExpanded] = useState(false);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Used to normalize data type for target service account
    function convertToArray(row){

      // Convert the target props into an array for backwards compatibility
      const rowEntries = String(row).split(',');
      const rowArray = [];

      rowEntries.forEach(function(element){
        var rowEntry = element.trim()
        rowArray.push(rowEntry)
      })

      return rowArray
  
    };

    //------------------------------------------------------

    //Logic handler for ipRangeFilter

    const ipJoinHandler = (portType) => {
      if (portType.length > 0) {
        return portType.join(", ")
      } else {
        return portType;
      }
    }


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // If Expand/Collapse All buttons are clicked
    // The action is applied to this row
    useEffect(() => {
      if(allRowsExpanded !== undefined) {
        
        setRowExpanded(allRowsExpanded);

        // Need to clear flag so that it triggers this useEffect
        // next time Expand/Collapse All buttons are clicked
        setAllRowsExpanded();
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allRowsExpanded]);

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    return (
      <>
        <tr>
          {
            managementType === 'portal' ? (
              <td style={{textAlign: "center"}}>
                <img className="icon" style={{width:"20px"}} alt="edit" src={Edit} onClick={() => {
                  getUser.preferences.firewall = {
                    'action': 'modify',
                    'fields': {
                      "projectId":{
                        "visibility": "read",
                        "value": getUser.preferences?.globalSelector?.selectedResource
                      },
                      "ruleName":{
                        "visibility": "read",
                        "value": rowData.name
                      },
                      "description":{
                        "visibility": "edit",
                        "value": rowData.description
                      },
                      "target":{
                        "visibility": "edit",
                        "value": rowData.target
                      },
                      "direction":{
                        "visibility": "read",
                        "value": rowData.direction
                      },
                      "action":{
                        "visibility": "read",
                        "value": rowData.action
                      },
                      "ipRangeFilter":{
                        "visibility": "edit",
                        "value": ipJoinHandler(rowData.ipRangeFilter)
                      },
                      "serviceAccountFilter":{
                        "visibility": "edit",
                        "value": rowData.serviceAccountFilter
                      },
                      "tcpPorts":{
                        "visibility": "edit",
                        "value": ipJoinHandler(rowData.tcpPorts)
                      },
                      "udpPorts":{
                        "visibility": "edit",
                        "value": ipJoinHandler(rowData.udpPorts)
                      },
                      "priority":{
                        "visibility": "edit",
                        "value": rowData.priority
                      }
                    }      
                  }
                  navigate('/firewall/rule')
                  
                }}></img>
              </td>
            ) : (null)
          }
          {
            managementType === 'portal' ? (
              <td style={{textAlign: "center"}}>
                <img className="icon" alt="delete" src={Delete} onClick={() => {
                  getUser.preferences.firewall = {
                    'managementType': managementType,
                    'action': 'delete',
                    'fields': {
                      "projectId":{
                        "visibility": "read",
                        "value": getUser.preferences?.globalSelector?.selectedResource
                      },
                      "ruleName":{
                        "visibility": "read",
                        "value": rowData.name
                      },
                      "description":{
                        "visibility": "read",
                        "value": rowData.description
                      },
                      "target":{
                        "visibility": "read",
                        "value": rowData.target
                      },
                      "direction":{
                        "visibility": "read",
                        "value": rowData.direction
                      },
                      "action":{
                        "visibility": "read",
                        "value": rowData.action
                      },
                      "ipRangeFilter":{
                        "visibility": "read",
                        "value": ipJoinHandler(rowData.ipRangeFilter)
                      },
                      "serviceAccountFilter":{
                        "visibility": "read",
                        "value": rowData.serviceAccountFilter
                      },
                      "tcpPorts":{
                        "visibility": "read",
                        "value": ipJoinHandler(rowData.tcpPorts)
                      },
                      "udpPorts":{
                        "visibility": "read",
                        "value": ipJoinHandler(rowData.udpPorts)
                      },
                      "priority":{
                        "visibility": "read",
                        "value": rowData.priority
                      }
                    }
                  }
                  setModalVisible(true)
                }}></img>
              </td>
            ) : (null)
          }
          <td className='Firewall-InteractiveTable-Data-Collapsible' onClick={() => rowExpanded ? (setRowExpanded(false)) : (setRowExpanded(true))}>
            {rowData.name}
          </td>
          <td className='Firewall-InteractiveTable-Data-Collapsible' onClick={() => rowExpanded ? (setRowExpanded(false)) : (setRowExpanded(true))}>
            {rowData.description}
          </td>
          <td className='Firewall-InteractiveTable-Data-Collapsible' onClick={() => rowExpanded ? (setRowExpanded(false)) : (setRowExpanded(true))}>
            {rowData.action === 'allow' ? (
              <div style={{color:"#06B448"}}>{rowData.action}</div>
            ) : (
              <div style={{color:"#E90707"}}>{rowData.action}</div>
            )}
          </td>
          <td className='Firewall-InteractiveTable-Data-Collapsible' onClick={() => rowExpanded ? (setRowExpanded(false)) : (setRowExpanded(true))}>
            {rowData.direction === 'ingress' ? (
              <div style={{color:"#0C6CDE"}}>{rowData.direction}</div>
            ) : (
              <div style={{color:"#616E7A"}}>{rowData.direction}</div>
            )}
          </td>
          <td style={{textAlign: "center"}} className='Firewall-InteractiveTable-Data-Collapsible' onClick={() => rowExpanded ? (setRowExpanded(false)) : (setRowExpanded(true))}>
            {
              rowExpanded ? (
                <img src={ExpandIcon} style={{transform: "rotate(90deg)"}} alt="Expand_Icon"></img>
              ) : (
                <img src={ExpandIcon} alt="Expand_Icon"></img>
              )
            }
          </td>
        </tr> 
        
        {/* Row Expanded > Firewall Details */}
        {
          rowExpanded ? (
            <tr>
              <td className='Firewall-InteractiveTable-Row-Expanded' colSpan={columns}>
                <div className='Firewall-ExpandedRow'>

                  {/* Target Service Account, IP ranges, Service Accounts */}
                  <div className='Firewall-ExpandedRow-Left'>
                    <p className='Firewall-ExpandedRow-FieldLabel'>IP Ranges</p>
                    {
                      rowData?.ipRangeFilter.length > 0 && rowData?.ipRangeFilter[0].length > 0 ? (
                        rowData.ipRangeFilter.map((row) => (<div>{row}</div>))
                      ) : ('-')
                    }

                    <p className='Firewall-ExpandedRow-FieldLabel'>Ports</p>
                    tcp: {rowData.tcpPorts.join(", ")} 
                    <br></br>
                    udp: {rowData.udpPorts.join(", ")}

                    <p className='Firewall-ExpandedRow-FieldLabel'>Priority</p>
                    {rowData?.priority}

                  </div>

                  {/* Ports, Priority, Requested By */}
                  <div className='Firewall-ExpandedRow-Right'>
                    <p className='Firewall-ExpandedRow-FieldLabel'>Source Service Accounts</p>
                    {
                      rowData?.serviceAccountFilter.length > 0 ? (
                        convertToArray(rowData.serviceAccountFilter).map((row) => (<div>{row}</div>))
                      ) : ('-')
                    }

                    <p className='Firewall-ExpandedRow-FieldLabel'>Target Service Accounts</p>
                      {
                        convertToArray(rowData.target).map((row) => (<div>{row}</div>))
                      }

                    <p className='Firewall-ExpandedRow-FieldLabel'>Requested By</p>
                    {rowData?.creator}
                  </div>
                </div>
              </td>
            </tr>
          ) : (null)
        }
      </>
    )
      
}
