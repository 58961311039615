//==========================================================================
//   Component: Project Emissions Chart
//==========================================================================

//   Purpose: Chart displaying all project level emissions for the last 12 months

//   Properties: 
//   - emissionsData = {data used to build the line chart}

//   Example: 
//   <ProjectEmissionsChart
//      emissionsData={projectEmissionsChartData}
//   ></ProjectEmissionsChart>

//------------------------------------------------------

//Libraries
import React, {useState, useEffect} from 'react';

//Images
import LoadingIcon from '../../../Components/Images/Loading_Ripple.svg';

//Components
import LineChart from '../../../Components/Charts/LineChart';

//CSS
import './sustainabilitycomponents.css';

export default function ProjectEmissionsChart({emissionsData}) {

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

        const [chartWidthKey, setChartWidthKey] = useState(false)
        const windowWidth = window.innerWidth
    
    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

        // Update width of chart if window size changes
        useEffect(() => {
            setChartWidthKey(prevWidth => !prevWidth)
        }, [windowWidth])

    //------------------------------------------------------
    //  Return HTML
    //------------------------------------------------------

    return (
        <div className="Sustainability-Response-Success">
            {
                emissionsData.length === 0 ? 
                //Chart takes a few seconds to load > Display pending while it is undefined 
                (
                    <div className="Sustainability-Response-Pending">
                        <img alt="loading-circle-icon" src={LoadingIcon}></img>
                        <h3>Please wait while we process your request.</h3>
                        <div className="pageresponse-button-container">
                            <button className="form-submit-button" onClick={()=>{window.location.reload()}}>Cancel</button>
                        </div>
                    </div>
                )
                :
                //Chart loaded > Display Line Chart
                (
                    <LineChart
                        width={'98%'}
                        key={chartWidthKey}
                        height={'600px'}
                        data={emissionsData}
                        options={
                            {
                            curveType: "function",
                            title: "Drag to zoom. Right click to reset.",
                            titleTextStyle: {
                                bold: false,
                                italic: true,
                            },
                            explorer: { 
                                actions: ['dragToZoom', 'rightClickToReset'] 
                            },
                            legend: { 
                                position: "right",
                                alignment: "center"
                            },
                            lineWidth: 3,
                            fontSize: 15,
                            fontName: "circular-body",
                            vAxis: {
                                textStyle: {
                                    fontSize: 13
                                },
                                format: '#.## kgCOe2'
                            },
                            }
                        }
                    >
                    </LineChart>
                )
            }
        </div>
    )
}

//------------------------------------------------------
