//================================================================
//  Delete Portfolio Form
//================================================================

//Libraries
import React, { useContext, useState, useReducer, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import InlineInputField from '../../Components/InlineInputField/inlineinputfield';
import ErrorMessageLabel from '../../Components/ErrorMessageLabel';
import QuestionLabel from '../../Components/QuestionLabel';

//Functions
import WriteDocument from '../../Library/WriteDocument';
import GetDocument from '../../Library/GetDocument';

//CSS
import './Portfolios.css'


export default function DeletePortfolio() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to render the page based on the page status > 'pending', 'onload', 'success', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

    // Stores Current Portfolio
    const [selectedPortfolio, setSelectedPortfolio] = useState();

    // Stores the current portfolio name
    const [portfolioName, setPortfolioName] = useState("")

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    //Used to toggle error messages
    const [errorState, setErrorState] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
        {
          errorVisible: false,
          errorMessage: ""
        }
    );
    
  //------------------------------------------------------
  //  Event Handler > Field Changes
  //------------------------------------------------------

    function inputHandler(e) {

      // Validation check that input value is equal to the portfolio name
      e.target.value !== selectedPortfolio?.portfolioname ? setErrorState({ errorVisible: true, errorMessage: `Please enter ${selectedPortfolio?.portfolioname} to continue!` }) : setErrorState({ errorVisible: false })

      setPortfolioName(e.target.value) 

    }

  //------------------------------------------------------
  //  Form Submit Handler
  //------------------------------------------------------

    function submitHandler(){  
    
      // --- Prepare Document to be written to Firestore --- // 

      setPageStatus('pending')

      const document = {
        "decommissiondate": new Date(),
        "status": "inactive" 
      }

      // Write to DB
      WriteDocument("portfolios",  getUser?.preferences?.globalSelector?.selectedResource, document, true)
      .then(() => {

        // Change view to organization > Navigate to portfolios home page
        getUser.preferences.globalSelector.selectedView = 'organization';
        getUser.preferences.globalSelector.selectedResource = 'lendlease.cloud';

        navigate('/portfolios')

      })

      .catch((error) => {
        
        setAppErrors(`Unable to delete portfolio ${getUser?.preferences?.globalSelector?.selectedResource} ${error}`);
        setPageStatus('error-invalid')

      });
        
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() => {

      if(getUser === undefined) return; 

      // Query 'portfolios' collection
      GetDocument("portfolios",  getUser?.preferences?.globalSelector?.selectedResource)
      .then((results) => {

        // Set Portfolio
        setSelectedPortfolio(results)
        setPageStatus('onload')

      })

      .catch((error) => {
        setAppErrors(`Failed to get portfolio information. Error: ${error}`);
        setPageStatus('error-invalid');
      });
      
          
    }, [getUser, setAppErrors] );
    

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedResource={false}
        requiredRoles={['portfolioOwner']}
        status={pageStatus}
        breadcrumb={{
          name: "Portfolios",
          route: "/portfolios"
        }}
        header={
          <PageHeader
            title='Delete Portfolio'
            body={
              <span>
                <b>Note:</b> This action is irreversible. Once deleted this portfolio will no longer be available to view by the owner or viewers. 
              </span>
            }
          ></PageHeader>
        }
        body={
          <div className="Form-Container">
            <div className="Form-Body">

                {/* Portfolio Name */}
                <h2>{selectedPortfolio?.portfolioname}</h2>
              
                {/*------------------------------------------------------------------ */}
                {/*   Input Field - > Verify Deletion *                               */}
                {/*------------------------------------------------------------------ */}
                
                <div>
                
                    {/* Label */}
                    <QuestionLabel question="To permanently delete this portfolio, please enter the portfolio name below:"/>

                    {/* Input Field */}
                    <InlineInputField
                      name="portfolioName"
                      placeholder="Portfolio Name"
                      value={portfolioName}
                      position="right"
                      errorVisible={errorState.errorVisible}
                      inputFieldLength="mediuminputfield"
                      onChange={(e) => inputHandler(e)}
                    />
                    
                    {/* Error Label */}
                    <ErrorMessageLabel errorMessage={errorState.errorMessage} errorVisible={errorState.errorVisible}/>

                </div>

                {/*------------------------------------------------------------------ */}
                {/*   Buttons *                                                       */}
                {/*------------------------------------------------------------------ */}

                <div className='Button-Group'>
                    <button className='Primary-Button' onClick={() => submitHandler()} disabled={selectedPortfolio?.portfolioname !== portfolioName} > Submit </button>
                    <button className="Secondary-Button" onClick={() => navigate('/portfolios')}>Cancel</button>
                </div>
              
            </div>
          </div>
        }

      ></PageComponent>
    )
}
