//=======================================================================================
//  Document Link 
//  Description - This is used to display signed URLs for Confluence documentation links
//  Created by Nowshin Hassan 27/01/2022
//=======================================================================================

//------------------------------------------------------
//  How to Use this Component
//
//  Properties:
//
//  docId - 'Confluence document ID from Firestore - get this from the URL, e.g. docID (222952194398) - 'https://lendleasegroup.atlassian.net/wiki/spaces/NEB/pages/222952194398/Customer+Portal+Release+-+1.3.20.0'
//  title - 'Confluence document title - used to display as link text'
//
//  Example:
//    <DocumentLink 
//      docId={'222843474170'} 
//      title={'Managing Users & Groups - How to Get Started' }
//    ></DocumentLink>
//
//------------------------------------------------------

//Libraries
import React, { useState, useEffect } from 'react';

//Functions
import GetDocument from '../../Library/GetDocument';

//CSS
import './knowledgearticle.css'

export default function DocumentLink(props) {

  //------------------------------------------------------
  //  Extract Props
  //------------------------------------------------------

    const docId = props.docId
    const title = props.title

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Component status > 'pending', 'success', 'error-fatal'
    const [componentStatus, setComponentStatus] = useState('success')
    const [signedURL, setSignedURL] = useState('')

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Onload get document with signed url
    useEffect(() => {

      GetDocument('knowledgebase/confluence/documents', docId).then((results) =>{

        setSignedURL(results.doc_url);
        setComponentStatus('success');

      }).catch((error) =>{

        console.log('Unable to retrieve link, error:', error)
        setComponentStatus('error-fatal');

      });
                      
    },[docId])

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------
    
  //Pending Status
  if (componentStatus === 'pending'){
    return(
      <a style={{pointerEvents: 'none', color: 'gray'}} href={signedURL} target='_blank' rel='noopener noreferrer'>{title}</a>
    );
  }

  //Success Status
  if (componentStatus === 'success'){
    return(
      <a href={signedURL} target='_blank' rel='noopener noreferrer'>{title}</a>
    );
  }

  //Error-Fatal Status
  if (componentStatus === 'error-fatal'){
    return(
      <a style={{pointerEvents: 'none', textDecoration: 'none', color: 'red'}} href={signedURL} target='_blank' rel='noopener noreferrer'> Unable to retrieve link, try again later</a>
    );
  }

}
