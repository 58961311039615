//================================================================
//  Portfolios Page
//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

//Contexts
import {GetUser, SetUser, SetAppErrors} from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';

//Functions
import QueryListener from '../../Library/QueryListener';

//Images
import AddIcon from '../../Components/Images/Large_Add_Icon_Grey.svg';
import EditIcon from'../../Components/Images/Edit_Blue.svg';

//CSS
import './Portfolios.css'


export default function Portfolios() {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------
      
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setUser = useContext(SetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to render the page based on the page status > 'pending', 'onload', 'success', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Holds list of portfolios //
    const [portfolios, setPortfolios] = useState([]);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Updates the global selector when porfolio operations (EDIT || DELETE) are clicked and routes to specified page  //
    function handleClick(portfolioId, route){
    
      // Update selected resource
      getUser.preferences.globalSelector.selectedView = 'portfolio';
      getUser.preferences.globalSelector.selectedResource = portfolioId;
      setUser({...getUser});
    
      // Route to Edit Page // 
      navigate(`/portfolios/${route}`)

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //Query Listener > listens to the 'portfolios' collection
    useEffect(() => {
         
      if (getUser === undefined) return;
      if (getUser?.profile?.emailaddress === undefined) return;
  
      function onLoadChange(document){

        //Sort portfolios in alphabetical order > Save to useState
        document.sort(function (a, b) {
          if (a.portfolioname < b.portfolioname) return -1;
          if (a.portfolioname > b.portfolioname) return 1;
          return 0;
        });

        setPortfolios([...document]);

        setPageStatus('onload');
      }
  
      function onError(error){
        console.log(`Unable to retrieve portfolios for ${getUser?.profile?.emailaddress} ${error}`)
        setAppErrors(`Unable to retrieve portfolios for ${getUser?.profile?.emailaddress} ${error}`);
        setPageStatus('error-fatal');
      }

      const unsubscribe = QueryListener('portfolios', [
        ['owner', '==', getUser?.profile?.emailaddress],
        ['status', '==', 'active']
      ], onLoadChange, onLoadChange, onError)
    
        return () => {
          unsubscribe();
        };
  
    }, [getUser, setPortfolios, setAppErrors]);
    
    
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedResource={false}
        requireSelectedViews={['organization']}
        requiredRoles={['lendleaseAccount']}
        requiredRolesValue={'true'}
        status={pageStatus}
        header={
          <PageHeader
            title={'Portfolios'}
            body={
              <span>
                Below are the list of portfolios that you own. If you are responsible for managing a group of projects that form a product or program of work, you can create a portfolio here.
              </span>
            }
          ></PageHeader>
        }
        body={
          <>
            {/* Cards Container */}
            <div className="Cards-Container">

              {/* Create New Portfolio Card */}
              <div className='Card Add-Card' onClick={() => navigate('/portfolios/create')}>
                <img src={AddIcon} alt="Add Icon"></img>
              </div>

              {/* Render Portfolios if they exist */}
              {portfolios?.length > 0 && portfolios?.map((portfolio, index) => (
                <div key={index} className='Card'>

                  {/* Portfolio Header */}
                  <div className="Portfolio-Card-Header">

                    {/* Portfolio Name */}
                    <p className="Portfolio-Name">{portfolio.portfolioname}</p>
                    
                    {/* Dropdown Menu */}
                    <div className="Dropdown">
                      <img alt="Edit Icon" src={EditIcon} />
                      
                      <div className='Dropdown-Content'>
                        <p onClick={() => handleClick(portfolio.portfolioid, "edit")}>Edit</p>
                        <p onClick={() => handleClick(portfolio.portfolioid, "delete")}>Delete</p>
                      </div>
                    </div>

                  </div>

                  {/* Description */}
                  <p className="Portfolio-Card-Description">{portfolio.description}</p>

                  {/* Projects within the Portfolio -> Return max of 2 only to avoid spilling out of card  */}
                  <div style={{height: '60px'}}>

                    {portfolio?.projects.slice(0, 2).map((project, index) => (
                      <p key={index} className="Portfolio-Card-Projects">{project}</p>
                    ))}
                  
                  </div>

                  {/* Total Number of Projects in the Portfolio */}
                  {portfolio?.projects.length > 2 && 
                    <p className="Portfolio-Card-Footer">+ {portfolio?.projects?.length - 2} more projects</p>
                  }

                </div>
                ))
              }

          </div>
        </>
        }
      ></PageComponent>
    )
}
