//================================================================
//  Manage Groups Page
//================================================================

//Libraries
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../../Library/GlobalContexts';

//Functions
import reactBackend from '../../../Library/reactBackend';
import writeDocument from '../../../Library/WriteDocument';
import queryListener from '../../../Library/QueryListener'

//Actions/Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import TableResponse from '../../../Components/TableResponse/tableresponse';
import RequestSummary from '../../../Components/RequestSummary/requestsummary';
import ConfirmationModal from '../../../Components/ConfirmationModal/confirmationmodal';
import DocumentLink from '../../../Components/KnowledgeHubComponents/documentlink';

//Images
//Edit - https://www.flaticon.com/free-icon/edit_3597075?term=edit&page=1&position=42&page=1&position=42&related_id=3597075&origin=search
//Delete - https://icons8.com/icons/set/trash-can
import Edit from '../../../Components/Images/Edit_Blue.svg';
import Delete from '../../../Components/Images/Delete_Blue.svg';

//CSS
import './projectgroups.css';

export default function ProjectGroups(){
  
  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------
  
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    //Used to store API status >  'onload', 'success', 'error-invalid', 'error-fatal'
    const [getAzureGroupsAPIStatus, setAzureGroupsAPIStatus] = useState('pending');
    const [getGroupRequestsAPIStatus, setGroupRequestsAPIStatus] = useState('pending');
    const [groupDeletionStatus, setGroupDeletionStatus] = useState('pending');

    //Used to save the API response
    const [getAzureGroupsAPIResponse, setAzureGroupsAPIResponse] = useState([]);
    const [getGroupRequestsAPIResponse, setGroupRequestsAPIResponse] = useState([]);

    //Used to save the groups
    const [projectOperatorGroup, setProjectOperatorGroup] = useState([]);
    const [myGroups, setMyGroups] = useState([]);

    // Toggle the Confirmation Modal // 
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

  //------------------------------------------------------
  //  Define Functions
  //------------------------------------------------------

    //Used for onClick event for modifying group members
    function modifyOnClick(row, requestType){

      //Update the useContext
      getUser.preferences.projectgroup = row;
      getUser.preferences.projectgrouptype = requestType;

      //Navigate to the modify project ownership lookup page
      navigate('/projects/modifyprojectgroups');

    };

    // Toggles the visibility of the confirmation modal //
    function confirmationModalHandler(row){

      //Update the useContext
      getUser.preferences.projectgroup = row;

      // Toggle between open & closed modal state //
      setConfirmationModalVisible(!confirmationModalVisible);

    }

  //------------------------------------------------------
  //  Define Functions > callAPI & HandleResponse
  //------------------------------------------------------

    //Used to call "getJobs" > Get a list of all group management jobs
    async function getGroupManagementRequests(){
      
      const requestBody = {
        "requestType": "project",
        "projectId": getUser?.preferences?.globalSelector?.selectedResource,
        "jobName": ["submitGroupManagement", "submitGroupCreation", "submitOperatorGroupManagement", "submitGroupDeletion"]
      };
    
      setGroupRequestsAPIStatus('pending');
    
      //Call React Backend - Submit Form Response
      const response = await reactBackend('getJobs', requestBody);
    
      //Set API Response
      setGroupRequestsAPIResponse(response.responseBody.message);
    
      //Switch on status
      if(response.status === 'success'){
            
        //Set status to 'success'
        setGroupRequestsAPIStatus('success');
    
      } else if(response.status === 'error-invalid'){
    
        //Set status to 'error-invalid'
        setGroupRequestsAPIStatus('error-invalid');
    
      } else if(response.status === 'error-fatal'){
          
        //Set status to 'error-fatal'
        setGroupRequestsAPIStatus('error-fatal');
          
      } else if(response.status === 'error-timeout'){
            
        //Set status to 'error-timeout'
        setGroupRequestsAPIStatus('error-timeout');
    
      }
      
      return;
    
    } 

    // Call to Delete Group from AAD & GCP // 
    async function deleteGroup(){

      const document = {
        'transactionid': null,
        'requester': getUser?.profile.emailaddress,
        'deleterequested': true,
        'actionrequired': true,
      };
      const groupId = getUser?.preferences?.projectgroup?.id.toLowerCase();

      writeDocument('groups', groupId, document, true).then(() => {

        setGroupDeletionStatus('success');

      }).catch((error) => {

        setAppErrors(`Failed to submit group deletion for ${getUser?.preferences?.projectgroup?.groupName?.groupName}. Error: ${error}`);
        setGroupDeletionStatus('error-fatal');

      });
    
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() => {

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      // Get Requests
      getGroupManagementRequests();

      //------------------------------------------------------
      // Get Groups
      //------------------------------------------------------

      function onLoadChange(groups){

        setAzureGroupsAPIStatus('pending');

        setAzureGroupsAPIResponse(groups);

        //Get Project Operators i.e. names that matches reservedGroupNames
        const csReservedWords = ['operators', 'Operators', 'editor', 'Editor', 'owner', 'Owner'];

        setProjectOperatorGroup(
          groups.filter(group => csReservedWords.includes(
            group.id.split('-').pop()
          ))
        );

        //Get My Groups
        // 1. Remove any groups with reserved words
        const reservedWords = groups.filter(group => !(csReservedWords.includes(
          group?.id?.split('-').pop())
        ));

        // 2. Remove pending deletion groups
        setMyGroups(
          reservedWords.filter((group) => (
            group?.deleterequested === undefined || group?.deleterequested === false
          )
        ));

        //Set setAzureGroupsAPIStatus to 'success'
        setAzureGroupsAPIStatus('success');

        return;

      }

      function onError(){

        //Set setAzureGroupsAPIStatus to 'error-fatal'
        setAzureGroupsAPIStatus('error-fatal');

        return;

      }

      const unsubscribe = queryListener('groups', [
        ['projectid', '==', getUser?.preferences?.globalSelector?.selectedResource],
      ], onLoadChange, onLoadChange, onError);
    
      return () => {

        unsubscribe();

      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getUser]);
    
  
  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

  return(
    <PageComponent
      requireSelectedResource={true}
      requireSelectedViews={['project']}
      requiredRoles={['projectOwner', 'projectOperator']}
      breadcrumb={{
        "name": "Projects", 
        "route": "/projects",
        "view": "organization",
        "resource": "lendlease.cloud"
      }}
      header={
        <PageHeader
          title={"Manage Access"}
          body={
            <p>
              Below are the groups that you have access to and its members. 
              To edit which members have access to your groups, please click the edit icon next to the group you would like to edit. 
            </p> 
          }
        ></PageHeader>
      }
      body={
        <>
          {/* Wrap the view page with modal component to enable the confirmation modal toggle */}
          <div className='Groups-Page-Container'>

            {/*------------------------------------------------------------------ */}
            {/*   Project Operators                                               */}
            {/*------------------------------------------------------------------ */}  

            <div className='Table-Pane'>

              {/* Header */}
              <div className='Project-Operators-Header'>
                <p className="Page-Subheading" style={{margin: "10px 0px"}}>Project Operators</p>
                <p style={{lineHeight: "1.7", padding: "0px 5px"}}>
                  This is the project operators group provisioned by the Lendlease Cloud team. 
                  Essential Contacts is a Google Cloud feature that allows project operators to receive notifications about Google services. 
                  To find out more, click <DocumentLink docId="222894589182" title="here"></DocumentLink>.
                </p>
              </div>

              {/* Table */}
              <TableResponse
                content={
                  projectOperatorGroup?.length === 0 ? 
                  (
                    <div className="table-response-container">
                      <p className="table-message">No groups found.</p> 
                    </div>
                  ) 
                  : 
                  (
                    <table className="Data-Table-Container">
                      <colgroup>
                        <col span="1" style={{width: "30%"}}></col>
                        <col span="1" style={{width: "30%"}}></col>
                        <col span="1" style={{width: "30%"}}></col>
                        <col span="1" style={{width: "10%"}}></col>
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>Group Name</th>
                          <th>Group Description</th>
                          <th>Group Members</th>
                          <th></th>
                        </tr>
                        {
                          projectOperatorGroup?.map((row, index) => (
                            <tr key={index}>
                              <td>{row.id + '@' + process.env.REACT_APP_ORG_NAME}</td>
                              <td>{row.description}</td>
                              <td>
                                {/* Map the member object into the table*/}
                                {
                                  Object.keys(row.members).length > 0 ? (

                                    Object.keys(row.members).map((user) => (
                                      <div style={{padding: '0px 20px'}}>
                                        {user}
                                      </div>
                                    ))

                                  ) : (

                                    <div style={{padding: "0px 20px"}}>
                                      None
                                    </div>

                                  )
                                }
                              </td>
                              <td>
                                <img className="icon-edit" alt="edit" src={Edit} onClick={() => modifyOnClick(row, 'operators')}></img>
                              </td>                            
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  )
                }
                response={getAzureGroupsAPIResponse}
                status={getAzureGroupsAPIStatus}
                setStatus={setAzureGroupsAPIStatus}
              ></TableResponse>

            </div>
            

            {/*------------------------------------------------------------------ */}
            {/*   My Groups                                                       */}
            {/*------------------------------------------------------------------ */}  

            <div className='Table-Pane'>

              {/* Header */}
              <div className='MyGroups-Header'>
                <p className="Page-Subheading">My Groups</p>
                <button className="Primary-Button" onClick={() => navigate('/projects/createprojectgroups')}> Create Group </button>
              </div>

              {/* Table */}
              <TableResponse
                content={
                  
                  myGroups?.length === 0 ? 
                  (
                    <div className="table-response-container">
                      <p className="table-message">No groups found.</p> 
                    </div>
                  )
                  : 
                  (
                    <table className="Data-Table-Container">
                      <colgroup>
                        <col span="1" style={{width: "30%"}}></col>
                        <col span="1" style={{width: "30%"}}></col>
                        <col span="1" style={{width: "30%"}}></col>
                        <col span="1" style={{width: "10%"}}></col>
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>Group Name</th>
                          <th>Group Description</th>
                          <th>Group Members</th>
                          <th></th>
                        </tr>
                        {
                          myGroups?.map((row, index) => (

                            <tr key={index}>
                              <td style={{paddingRight: "20px"}}>{row.id + '@' + process.env.REACT_APP_ORG_NAME}</td>
                              <td>{row.description}</td>
                              <td>
                                {/* Map the member object into the table*/}
                                {
                                  Object.keys(row.members).length > 0 ? (

                                    Object.keys(row.members).map((user) => (
                                      <div style={{padding: "0px 20px"}}>
                                        {user}
                                      </div>
                                    ))

                                  ) : (

                                    <div style={{padding: "0px 20px"}}>
                                      None
                                    </div>

                                  )
                                }
                              </td>
                              <td>
                                <img className="icon-edit" alt="edit" src={Edit} onClick={() => modifyOnClick(row, 'project')}></img>
                                <img className="icon-edit" alt="delete" src={Delete} onClick={() => confirmationModalHandler(row)}></img>
                              </td>
                            </tr>
                          
                          ))
                        }
                      </tbody>
                    </table>
                  )
                }
                response={getAzureGroupsAPIResponse}
                status={getAzureGroupsAPIStatus}
                setStatus={setAzureGroupsAPIStatus}
              ></TableResponse>

            </div>
            

            {/*------------------------------------------------------------------ */}
            {/*   Requests Summary                                                */}
            {/*------------------------------------------------------------------ */}  

            <div className='Table-Pane'>
              
              <p className="Page-Subheading">Requests Summary </p>

              <RequestSummary
                status={getGroupRequestsAPIStatus}
                setStatus={setGroupRequestsAPIStatus}
                rows={getGroupRequestsAPIResponse}
              ></RequestSummary>

            </div>

          </div>
          
          {/* Confirmation Modal - safegaurd before deletion */}
          <ConfirmationModal
            modalVisible = {confirmationModalVisible}         
            setModalVisible = {setConfirmationModalVisible}
            header = "Do you wish to continue?"
            body = {[
              <div>
                <div style={{display: 'flex', flexDirection: "column"}}>
                  <p> Are you sure that you would like to delete the group <strong>{getUser?.preferences?.projectgroup?.id}?</strong> </p>
                  <p>Please consider any impacts that this may cause your project.</p>
                  <p><strong>Note:</strong> As per the service description document, it is your responsibility to remove any IAM permissions within your project.</p>
                </div>
              </div>
            ]}
            requestType={groupDeletionStatus}
            setRequestType={setGroupDeletionStatus}
            eventHandlerOnSubmit={deleteGroup}
          ></ConfirmationModal>
        </>
      }
    ></PageComponent>
  )
    
  //------------------------------------------------------
};