//Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

//Components
import NavItem from './navitem';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Images
import loadingPulseBarFast from '../Images/loadingPulseBarFast.svg';
import Logo from '../Images/Icon_LendleaseCloudLogo_OneLine.svg';
import HomeIcon from '../Images/Icon_NavHome_White.svg';
import ProjectsIcon from '../Images/Icon_Projects_White.svg';
import ProjectOwnershipLookupIcon from '../Images/Icon_ProjectOwnershipLookup_White.svg'
import PortfoliosIcon from '../Images/Icon_Portfolios_White.svg'
import SharedVPCIcon from '../Images/Icon_SharedVPC_White.svg';
import FirewallIcon from '../Images/Icon_Firewall_White.svg';
import DNSIcon from '../Images/Icon_DNS_White.svg';
import SecurityIcon from '../Images/Icon_Security_White.svg';
import BillingIcon from '../Images/Icon_Billing_White.svg';
import ConsumptionIcon from '../Images/Icon_Consumption_Grey.svg';
import SustainabilityIcon from '../Images/Icon_Sustainability_White.svg';
import GettingStartedIcon from '../Images/Icon_GettingStarted_White.svg';
import GetHelpIcon from '../Images/Icon_GetHelp_White.svg';
import MoneyBagIcon from '../Images/icon_moneybag_white.svg'

import './sidebar.css';


function Sidebar(props) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useParams from 'react-router'
  //------------------------------------------------------
  
    const routerUseLocation = useLocation();

  //------------------------------------------------------
  //  Define useStates
  //------------------------------------------------------

    //Used to change between > 'pending', 'success'
    const [pageStatus, setPageStatus] = useState('pending');

  //------------------------------------------------------
  //  Define variables
  //------------------------------------------------------

    const roles = getUser?.profile?.roles

  //------------------------------------------------------
  //  Define Functions
  //------------------------------------------------------

    //Returns the style of each component
    function changeStyleHandler(link) {

      //Exception for the default route '/' > We want to the home icon to be selected
      if(routerUseLocation.pathname === '/' && link.toLowerCase() === '/home') {

        //Update the style to clicked
        return "NavItem-Clicked";
      
      //Clicked item
      } else if (link.toLowerCase() === (routerUseLocation.pathname).toLowerCase()){
    
        //Update the style to clicked
        return "NavItem-Clicked";

      //Update the style to NOT clicked
      } else {
        
        return "NavItem";

      }

    }
    
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //OnLoad
    useEffect(() => {

      if (roles !== undefined){
        setPageStatus('success')
      }

    },[pageStatus, roles])


  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  Pending status
    //------------------------------------------------------

      if(pageStatus === 'pending'){
        return (
          <div className="Sidebar">
            <div className="Sidebar-Pending">
              <img alt="loadingPulseBarFast" src={loadingPulseBarFast}></img>
            </div>
          </div>
        )
      }

    //------------------------------------------------------
    //  Success status
    //------------------------------------------------------

      else if(pageStatus === 'success'){
        return (
          <div className={props.sidebarStyle}>
            
            {/* Lendlease Cloud Logo */}
            <div className="Sidebar-Row1-Logo">
              <Link to="/home">  
                <img className="Logo" src={Logo} alt="Lendlease Cloud Lockup"></img>
              </Link>
            </div>
            
            {/* Home */}
            <div className="Sidebar-Row2-Home">
              <NavItem style={changeStyleHandler("/home")} link={"/home"} title={"Home"} icon={HomeIcon}></NavItem>
              
            </div>
            
            {/* Manage Links */}
            <div className="Sidebar-Row3-Manage">
              <p>Manage</p>
              <NavItem style={changeStyleHandler("/projects")} link={"/projects"} title={"Projects"} icon={ProjectsIcon} roles={["portalAdmin","portalUser"]}></NavItem>
              <NavItem style={changeStyleHandler("/projectownerlookup/view")} link={"/projectownerlookup/view"} title={"Project Owner Lookup"} icon={ProjectOwnershipLookupIcon} roles={["portalAdmin","portalUser"]}></NavItem>
              <NavItem style={changeStyleHandler("/portfolios")} link={"/portfolios"} title={"Portfolios"} icon={PortfoliosIcon} roles={["portalAdmin","portalUser"]}></NavItem>
              <NavItem style={changeStyleHandler("/sharedvpc")} link={"/sharedvpc"} title={"Shared VPC"} icon={SharedVPCIcon} roles={["portalAdmin","portalUser"]}></NavItem>
              <NavItem style={changeStyleHandler("/firewall")} link={"/firewall"} title={"Firewall"} icon={FirewallIcon} roles={["portalAdmin","portalUser"]}></NavItem>
              <NavItem style={changeStyleHandler("/dns")} link={"/dns"} title={"DNS Zones"} icon={DNSIcon} roles={["portalAdmin","portalUser"]}></NavItem>
    
            </div>
            
            {/* Monitor Links */}
            <div className="Sidebar-Row4-Monitor">
              {/* Depending on the projectID or portfolio ID --> navigate to the correct route */}
              <p>Monitor</p>
              <NavItem style={changeStyleHandler("/security")} link={"/security"} title={"Security"} icon={SecurityIcon} roles={["portalAdmin","portalUser"]}></NavItem>
              <NavItem style={changeStyleHandler("/billing")} link={"/billing"} title={"Billing"} icon={BillingIcon} roles={["portalAdmin","portalUser"]}></NavItem>
              <NavItem style={changeStyleHandler("/consumption")} link={"/consumption"} title={"Consumption"} icon={ConsumptionIcon} roles={["portalAdmin","portalUser"]}></NavItem>
              <NavItem style={changeStyleHandler("/sustainability")} link={"/sustainability"} title={"Sustainability"} icon={SustainabilityIcon} roles={["portalAdmin","portalUser"]}></NavItem>
              <NavItem style={changeStyleHandler("/budgets")} link={"/budgets"} title={"Budgets"} icon={MoneyBagIcon} roles={["portalAdmin","portalUser"]}></NavItem>

            </div>
            
            {/* Support Links */}
            <div className="Sidebar-Row5-Support">
              <p>Support</p>
              <NavItem style={changeStyleHandler("/gettingstarted")} link={"/gettingstarted"} title={"Getting Started"} icon={GettingStartedIcon}></NavItem>
              <NavItem style={changeStyleHandler("/support")} link={"/support"} title={"Get Help"} icon={GetHelpIcon}></NavItem>

            </div>

          </div>

        );
      }

    //------------------------------------------------------

}

export default Sidebar;