//================================================================
//  Knowledge Hub Page
//================================================================

//Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';

//Contexts
import { SetAppErrors } from '../../Library/GlobalContexts';

//Functions & Actions
import ReactBackend from '../../Library/reactBackend'
import GetDocument from '../../Library/GetDocument';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import KnowledgeArticleLink from '../../Components/KnowledgeHubComponents/knowledgearticlelink'
import KnowledgeArticleView from '../../Components/KnowledgeHubComponents/knowledgearticleview';

//Images
import ClearSearchIcon from '../../Components/Images/Close_Icon_Grey.svg'
import LoadingIcon from '../../Components/Images/Loading_Ripple.svg';

//CSS
import './KnowledgeHub.css';


export default function KnowledgeHub() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useParams from 'react-router'
  //------------------------------------------------------

    const routerUseParams = useParams();

  //------------------------------------------------------
  //  Define useStates
  //------------------------------------------------------

    //Used to change between > 'onload', 'pending', 'inprogress', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');
    
    //Used to render the page based on the request type > 'search' 'viewdoc'
    const [requestType, setRequestType] = useState('search')

    //Used to store a list of knowledge articles displayed to the user
    const [knowledgeArticles, setKnowledgeArticles] = useState([]);

    //Used to store the selected knowledge article
    const [selectedCategory, setSelectedCategory] = useState(routerUseParams.category);

    //Holds the current value for the search bar
    const [searchBar, setSearchBar] = useState();

    //State for storing the selected knowledge article (clicked by the user)
    const [selectedArticle, setSelectedArticle] = useState("");

    //State for storing signed URL for selected knowledge article
    const [signedURL, setSignedURL] = useState("")

    //State for holding the current search query > This is set via the search bar
    var query = routerUseParams.query.split(",")
    const [searchQuery, setSearchQuery] = useState(query);

    //State used to determine if the search bar is disabled
    const [searchDisabled, setSearchDisabled] = useState(false);

  //------------------------------------------------------
  //  Define Functions > API Calls
  //------------------------------------------------------

    //Used to search the knowledge articles
    async function searchKnowledgeBase(query){
    
      setPageStatus('inprogress')

      //Set request body
      var requestBody = {
        "queryStrings": query
      }

      //Call React Backend - Submit Form Response
      var response = await ReactBackend('searchKnowledgeBase', requestBody)

      //Switch on pageStatus
      if(response.status === 'success'){

        //Convert response to an array
        var apiResponse = [[response.responseBody.message.message][0]]
        apiResponse = [].concat(...apiResponse.map(Object.values));

        //Update list of knowledge articles displayed to the user
        setKnowledgeArticles(apiResponse)
        setPageStatus('onload')

      } else if(response.status === 'error-invalid'){
        
        //Set pageStatus to 'error-invalid'
        setAppErrors(response?.responseBody?.message)
        setPageStatus('error-invalid')

      } else {

        //All other fatal errors
        setPageStatus('error-fatal')
      }

      return response
    }

    //Used to return a signed URL for a knowledge article
    async function getKnowledgeArticle(docId){

      //Set request type to show document
      setRequestType('viewdoc')
      setPageStatus('inprogress')

      //Set request body
      var requestBody = {
        "docId": docId
      }

      //Call React Backend - Submit Form Response
      var response = await ReactBackend('getKnowledgeArticle', requestBody)

      //Switch on pageStatus
      if(response.status === 'success'){
        
        //Add results to the 'FAQ' useState
        setSignedURL(response.responseBody.message)
        setPageStatus('onload')

      } else if(response.status === 'error-invalid'){
        
        //Set pageStatus to 'error-invalid'
        setAppErrors(response?.responseBody?.message)
        setPageStatus('error-invalid')

      } else {
          
        //All other fatal errors
        setPageStatus('error-fatal')

      }

      return response
    }    
    
  //------------------------------------------------------
  //  Define Functions > Event Handlers 
  //------------------------------------------------------

    //Search Bar Function > used call the API with the 'query' 
    const SearchBar = e => {

      //Get query
      var tempNewQuery = e.target.value;
      setSearchBar(tempNewQuery)

      //Only look for enterkey
      //https://www.pluralsight.com/guides/how-to-enter-key-event-handler-on-a-react-bootstrap-input-component
      if (e.keyCode !== 13){
        return;
      }

      //Strip any non-ASCII characters
      //https://stackoverflow.com/questions/20856197/remove-non-ascii-character-in-string
      // eslint-disable-next-line
      tempNewQuery = tempNewQuery.replace(/[^\x00-\x7F]/g, "")

      //Remove any bad characters > we only support a-z, 0-9, "'", "-"
      tempNewQuery = tempNewQuery.replace(/[^a-z, 0-9, "'", "-"]/gi, "")

      //Update url
      updateURL(tempNewQuery, selectedCategory)
    }

  //------------------------------------------------------
  //  Define Functions
  //------------------------------------------------------

    //Function to remove bad strings from querys
    function updateURL(newQuery, newSelectedCategory){

      var tempNewQuery = newQuery;
      var tempNewSelectedCategory = newSelectedCategory;

      //Update url & global variable
      var newUrl = `/knowledgehub/${tempNewQuery}/${tempNewSelectedCategory}`
      window.history.replaceState({}, "knowledgehub", newUrl)

      //Update usestates
      setSearchQuery(tempNewQuery)
      setSelectedCategory(tempNewSelectedCategory)
    }

    //Function to search popular topic
    function searchPopularTopic(topic){

      //Set search bar
      setSearchBar(topic)

      //Update url
      updateURL(topic.replace(/ /g, '-'), selectedCategory)

    }

    //Category Search Function > used to call API with the 'category' 
    function CategorySearch(category){

      //Update URL 
      updateURL(["all"], category)

      //Clear search bar
      setSearchBar("")

    }

    //Function for highlighting selected category
    function changeStyleHandler(category) {

      //Clicked category
      if (selectedCategory === category) {
        //Update the style to clicked
        return "Knowledge-Category-Button-Selected";
      }
      else{
        //Update the style to NOT clicked
        return 'Knowledge-Category-Button' ;
      }
    }

    //Function for highlighting selected FAQ item
    function ClearSearch() {
      
      //Update 'selectedCategory' style
      changeStyleHandler('getting-started')

      //Update url
      updateURL(["all"], "getting-started")

      //Clear search bar
      setSearchBar("")

      window.location.reload(false)

    }
    
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //When 'searchQuery' entered > call search API
    useEffect(() => {

      if(searchQuery[0] !== 'all'){
        searchKnowledgeBase(searchQuery)
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery])

    //When category selected > get docs from Firestore
    useEffect(() => {

      //Get the documents from Firestore (cache)
      GetDocument("knowledgebase/confluence/labels", selectedCategory)
      .then((results) =>{

        //Convert response to an array
        var articles = [[results][0]]
        articles = [].concat(...articles.map(Object.values));

        //Update list of knowledge articles displayed to the user
        setKnowledgeArticles(articles)

      }).catch((error) => {
          setAppErrors("Knowledge Hub - Failed to fetch records. Error: ", error)
          setPageStatus('error-invalid')
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

    //When a user clicks on an article > setRequestType to 'management' to change the view
    useEffect(() => {

      if(selectedArticle !== ""){
        getKnowledgeArticle(selectedArticle.docId)
      }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedArticle])

    //When API is onload > Disable search bar 
    useEffect(() => {

      if(pageStatus === "pending" || pageStatus === "inprogress"){
        setSearchDisabled(true)
      } else {
        setSearchDisabled(false)
      }
      
    }, [pageStatus])

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    return(
      <PageComponent
        status={pageStatus}
        breadcrumb={{
          name: "Get Help",
          route: "/support"
        }}
        header={
          <PageHeader
            title={'Knowledge Hub'}
            body={
              <p>
                Search our knowledge articles or browse by category below.
                <br></br>
                Can't find what you are looking for? Reach out to our cloud guild or contact our support team <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96&sysparm_category=109f0438c6112276003ae8ac13e7009d" target="_blank" rel="noopener noreferrer">here</a>.
              </p>
            }
          ></PageHeader>
        }
        body={
          requestType === 'search' ? (
            <div>

              {/* ------------------------------------------- */}
              {/*  Knowledge Hub Search Pane                  */}
              {/* ------------------------------------------- */}

              <div className="Knowledge-Search-Pane">

                {/* Search Heading */}
                <div className="Knowledge-Heading">
                  How can we help you?
                </div>

                {/* Search Bar */}
                <div className="Knowledge-Search">
                  <input className="Knowledge-Search-Input" value={searchBar} type="text" placeholder="Search" onChange={SearchBar} onKeyDown={SearchBar} disabled={searchDisabled}></input>
                  
                  {/* Clear Search Button */}
                  <div className="tooltip">
                    <img className="Knowledge-Search-Clear" alt="Clear Search" src={ClearSearchIcon} onClick={ClearSearch}></img>
                    <span className="tooltiptext" style={{width: "fit-content"}}> Clear Search </span>
                  </div> 

                </div>

                {/* Popular Topics */}
                <div className="Knowledge-Popular-Topics">
                  <b>Popular Topics: </b>
                  <u className="Knowledge-Topic-Link" onClick={() => searchPopularTopic("projects")}>projects</u>, <u className="Knowledge-Topic-Link" onClick={() => searchPopularTopic("vpc")}>vpc</u>, <u className="Knowledge-Topic-Link" onClick={() => searchPopularTopic("data")}>data</u>, <u className="Knowledge-Topic-Link" onClick={() => searchPopularTopic("firewall")}>firewall</u>
                </div>
              </div>

              {/* ------------------------------------------- */}
              {/*  Knowledge Hub Body                         */}
              {/* ------------------------------------------- */}

              {
                pageStatus === 'inprogress' ? (

                  /* In progress, so show pending screen for the lower pane only */
                  <div className='PageComponent-Errors-Container' style={{margin: "0px"}}>
                    <img alt="loading-circle-icon" src={LoadingIcon}></img>
                  </div>

                ) : (

                  /* Show filter and search results components */
                  <div className="Knowledge-Results-Pane">

                    {/* Filter Pane */}
                    <div className="Knowledge-Filter-Pane">
    
                      <h3>Filter by Category</h3>
    
                      {/* <button className={changeStyleHandler("all")} onClick={e => { CategorySearch("all")}}>All</button> */}
                      <button className={changeStyleHandler("getting-started")} onClick={e => { CategorySearch("getting-started")}}>Getting Started</button>
                      <button className={changeStyleHandler("projects")} onClick={e => {CategorySearch("projects")}}>Projects</button>
                      <button className={changeStyleHandler("billing")} onClick={e => {CategorySearch("billing")}}>Billing</button>
                      <button className={changeStyleHandler("networking")} onClick={e => {CategorySearch("networking")}}>Networking</button>
                      <button className={changeStyleHandler("knowledge")} onClick={e => {CategorySearch("knowledge")}}>Knowledge</button>
                    
                      {/* Clear Filter (May be used in the future) */}
                      {/* <button className="Knowledge-Clear-Filters"> x Clear Filters </button> */}
    
                    </div>
    
                    {/* Search Results > Articles */}
                    <div className="Knowledge-Article-Pane">
    
                      {knowledgeArticles.length > 0 ? 
                      (
                        //If articles found > return list of knowledge articles
                        <div>
                          {knowledgeArticles.map(object => (
                            <KnowledgeArticleLink 
                              article={object}
                              setSelectedArticle={setSelectedArticle}
                            ></KnowledgeArticleLink>
                          ))}
    
                          {/* When search performed > Display total results  */}
                          {searchQuery[0] === 'all' ? 
                          (
                            <></>
                          ):(
                            <p style={{textAlign: 'center', color: "#333131"}}>Showing {knowledgeArticles.length} of {knowledgeArticles.length} results</p>
                          )}
                        </div>
                      )
                      
                      : (
                        // Else > return message
                        <div style={{margin: "2%"}}>
                          No results found.
                        </div>
                      )}
    
                    </div>
                  </div>
                  )
              }
            </div>
          ) : (
            pageStatus === 'inprogress' ? (

              /* In progress, so show pending screen for the lower pane only */
              <div className='PageComponent-Errors-Container'>
                <img alt="loading-circle-icon" src={LoadingIcon}></img>
              </div>

            ) : (

              /* Show article */
              <KnowledgeArticleView
                objectURL={signedURL}
                article={selectedArticle}
                setRequestType={setRequestType}
              ></KnowledgeArticleView>
              
            )
          )
        }
      ></PageComponent>
    )

    //------------------------------------------------------
}
