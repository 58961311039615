//==========================================================================
//   Function: Get Collection
//   Description: Generic function to get a Firestore Collection
//   Documentation:
//   - https://firebase.google.com/docs/firestore/query-data/get-data
//==========================================================================

//   Example - How to use this function: 
//
//   GetCollection("users")
//     .then((results) =>{
//       setContextUser(results[0])
//       setAppStatus("authenticated")
//     })
//     .catch((error) =>{
//       setAppErrors(`Unable to extract user profile, error: ${error}`)
//       setAppStatus("failed")
//     })

//------------------------------------------------------

//Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';
import { collection, getDocs } from "firebase/firestore";

export default async function GetCollection(collectionId){

  //------------------------------------------------------
  //  Get Collection
  //------------------------------------------------------

    try {

      //Firestore database reference
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      //Set collection reference > Get collection docs
      const collectionRef = collection(db, collectionId)
      const docsSnap = await getDocs(collectionRef);
          
      var results = []

      //Extract results into an array
      docsSnap.forEach((doc) => {
          results.push(doc.data())
      });
      
      return results

    } catch (error) {
      throw new Error(`Function GetCollection failed to complete, error ${error}`)

    }

  //------------------------------------------------------
}