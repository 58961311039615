//----------------------------------------------------------
//  Reducer: Used to store Profile information from the ProfileAPI
//  Documentation:
//      https://react-redux.js.org/tutorials/quick-start
//      https://redux-toolkit.js.org/api/createSlice
//      https://redux-toolkit.js.org/api/createSlice#customizing-generated-action-creators
//      https://redux.js.org/usage/structuring-reducers/immutable-update-patterns#updating-nested-objects
//----------------------------------------------------------

import { createSlice } from '@reduxjs/toolkit'

//Define the default State
const initalstate = {
    data: {
        "emailAddress": "",
        "givenname": "",
        "surname": "",
        "displayName": "",
        "groups": [],
        "roles": {},
        "projectsAll": [],
        "projectsActive": [],
        "allProjectsMetadata": [],
        "projectsDecomissioned": [],
        "projectsSandboxesActive": [],
        "projectsNPrdActive": [],
        "projectsOperator": [],
        "projectsOwner": [],
        "projectsOwnerActive": [],
        "projectsOperatorOwnerActive": [],
        "portfolioOwner": [],
        "portfolioViewer": [],
        "notifications": {}
    }
};

export const SetProfile = createSlice({
  name: 'SetProfile',
  initialState: initalstate,
  reducers: {
    update: (state, action) => {
        
        state.data.emailAddress = action.payload.emailAddress;
        state.data.givenname = action.payload.givenname;
        state.data.surname = action.payload.surname;
        state.data.displayName = action.payload.displayName;
        state.data.groups = action.payload.groups;
        state.data.roles = action.payload.roles;
        state.data.projectsAll = action.payload.projectsAll;
        state.data.projectsActive = action.payload.projectsActive;
        state.data.allProjectsMetadata = action.payload.allProjectsMetadata;
        state.data.projectsDecomissioned = action.payload.projectsDecomissioned;
        state.data.projectsSandboxesActive = action.payload.projectsSandboxesActive;
        state.data.projectsNPrdActive = action.payload.projectsNPrdActive;
        state.data.projectsOperator = action.payload.projectsOperator;
        state.data.projectsOwner = action.payload.projectsOwner;
        state.data.projectsOwnerActive = action.payload.projectsOwnerActive;
        state.data.projectsOperatorOwnerActive = action.payload.projectsOperatorOwnerActive;
        state.data.portfolioOwner = action.payload.portfolioOwner;
        state.data.portfolioViewer = action.payload.portfolioViewer;
        state.data.notifications = action.payload.notifications;
    }
  },
})

// Action creators are generated for each case reducer function
export const { update } = SetProfile.actions
export default SetProfile.reducer