//------------------------------------------------------
//  How to use this component
//------------------------------------------------------

    //DOCUMENTATION
    //  - This component is leveraging React Portals, https://reactjs.org/docs/portals.html.
    //  - To achieve this, I ended up going with three distant containers declared in 'containers\portal-frontend\public\index.html'. 
    //  - This allows modals to breakout of the portals container ('root') and allows us to use z-layers effectively
    //  - Keep leveraging Reacts synthetic events, please keep doing this to prevent any performance issues!
    //    https://www.geeksforgeeks.org/why-we-use-synthetic-events-in-reactjs/#:~:text=Benefits%20of%20using%20synthetic%20events%3A%201%20Cross%20browsers,objects%2C%20by%20pooling%20them%2C%20which%20increase%20the%20performance.

    //PROPERTIES
    //body = text or HTML containing the header of the modal
    //incompleteUserDetails = text or HTML containing the body of the modal
    //modalVisible = useState, holds the model state
    //setModalVisible= useState, sets the model state
    //eventHandlerOnSubmit = function for submitting the form 

    //EXAMPLE 
    // <ConfirmationModal
    //     header={header}
    //     body={body}
    //     modalVisible={modalVisible}
    //     setModalVisible = function to set toggle the visibility of the modal
    //     eventHandlerOnSubmit={eventHandlerOnSubmit}
    //     requestType <optional> = useState, this component CAN HAVE requestType {onload, pending, success, error-fatal} like form response, leave the prop undefined if you dont need it
    //     setRequestType <optional> = useState, this component CAN HAVE requestType {onload, pending, success, error-fatal} like form response, leave the prop undefined if you dont need it
    // ></ConfirmationModal>

//------------------------------------------------------

//Libraries
import React, { useEffect } from 'react'
import { createPortal } from "react-dom";

//Images
import LoadingIcon from '../Images/Loading_Ripple.svg'
import SuccessCat from '../Images/SuccessCat.svg'
import errorFatal from '../Images/errorFatal.svg'

//CSS
import './confirmationmodal.css';


function ConfirmationModal({
    header,
    body,
    modalVisible,
    setModalVisible,
    eventHandlerOnSubmit,
    requestType,
    setRequestType
}) {

    //------------------------------------------------------
    //  Global varibles
    //------------------------------------------------------

        // https://reactjs.org/docs/portals.html
        const modalRoot = document.getElementById('confirmationmodal');
        const modalElement = document.createElement('div');

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

        // https://reactjs.org/docs/portals.html
        // appends the modal to portal once modal's children are mounted and 
        // removes it once we don't need it in the DOM anymore:
        useEffect(() => {

            if (modalRoot === undefined) return;
            if (modalElement === undefined) return;

            modalRoot.appendChild(modalElement);

            return () => {

                modalRoot.removeChild(modalElement);

            };
        
        }, [modalRoot, modalElement]);

        //Resets the requestType when the modal is closed
        useEffect(()=>{

            if(requestType === 'success' && modalVisible === false) return setRequestType('onload');
            if(requestType === 'pending' && modalVisible === false) return setRequestType('onload');
            if(requestType === 'error-fatal' && modalVisible === false) return setRequestType('onload');

        }, [modalVisible, requestType, setRequestType])

    //------------------------------------------------------
    //  Returned HTML
    //------------------------------------------------------

        //undefined or onload
        if (modalVisible && (requestType === undefined || requestType === 'onload')){
            
            return createPortal(
                <div className="modal-background">
                    <div className="modal-container">
                        <div className="modal-header">
                            <h2>{header}</h2>
                        </div>
        
                        <div className="modal-content">
                            {body}
                        </div>
        
                        <div className="modal-footer">
        
                            <button className="continue-button" onClick={eventHandlerOnSubmit}> Continue </button>
                            <button className="cancel-button" onClick={() => setModalVisible(false)}> Cancel </button>
        
                        </div>
                    </div>
                </div>,
                modalRoot
            )

        }

        //Pending
        else if (requestType === 'pending'){

            return createPortal(
                <div className="modal-background">
                    <div className="modal-container">

                        <div className="modal-header">
                            <h2>{header}</h2>
                        </div>
        
                        <div className="modal-content">
                            <div className="modal-content-pending">
                                <img alt="loading-circle-icon" src={LoadingIcon}></img>
                            </div>
                        </div>
        
                        <div className="modal-footer">
                            <button className="cancel-button" onClick={() => setRequestType('onload')}> Cancel </button>
                        </div>
                    </div>
                </div>,
                modalRoot
            )
        }

        //Success
        else if (requestType === 'success'){

            return createPortal(
                <div className="modal-background">
                    <div className="modal-container">

                        <div className="modal-header">
                            <h2>Request Successful</h2>
                        </div>
        
                        <div className="modal-content-success">
                            <img style={{margin: "20px 0px"}} src={SuccessCat} alt="happy cat" height={"200px"}/>
                        </div>

        
                        <div className="modal-footer">
                            <button className="cancel-button" onClick={() => setModalVisible(false)}> Close </button>
                        </div>
                    </div>
                </div>,
                modalRoot
            )
        }

        //Error-Fatal
        else if (requestType === 'error-fatal'){

            return createPortal(
                <div className="modal-background">
                    <div className="modal-container">

                        <div className="modal-header">
                            <h2>Oops something went wrong</h2>
                        </div>
        
                        <div className="modal-content-error-fatal">
                            <img style={{margin: "20px 0px"}} alt="Error Fatal" src={errorFatal}></img>
                        </div>

        
                        <div className="modal-footer">
                            <button className="cancel-button" onClick={() => setRequestType('onload')}> try again </button>
                        </div>
                    </div>
                </div>,
                modalRoot
            )
        }
}

export default ConfirmationModal;