//------------------------------------------------------
//    Logout Handler > Allows the user to click a button to Signout
//------------------------------------------------------

//Libraries
import React, { useContext } from "react";
import { getAuth, signOut } from "firebase/auth";

//Contexts
import { SetAppStatus, SetAppErrors } from './GlobalContexts'

//Images
import LogoutImage from '../Components/Images/Logout.png'


export default function Logout(props) {

  //------------------------------------------------------
  // props
  //------------------------------------------------------

    //Used by onboarding when a signin attempt fails
    let failedAppStatus = props.failedAppStatus;
    if (failedAppStatus === undefined) {

      failedAppStatus = "unauthenticated";

    }

    //Sets the title of the button
    const title = props.title;

    //Changes the style of the logout button (One is in Nav and the other is a button)
    const setStyle = props.setStyle;

  //------------------------------------------------------
  // useContexts & useStates
  //------------------------------------------------------

    //Firebase
    const setAppStatus = useContext(SetAppStatus);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  Import Firebase
  //  https://firebase.google.com/docs/auth/web/microsoft-oauth#next_steps
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  //  Logout handler
  //------------------------------------------------------

    function LogOutHandler(){

      signOut(auth).then(() => {

        setAppStatus(failedAppStatus);
        window.location.reload(false);

      })
      //An error happened.
      .catch((error) => {

        setAppErrors(`Failed to log out user from the application. Error: ${error}`);
        setAppStatus(failedAppStatus);

      })
    }

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  Navbar signout button
    //------------------------------------------------------

      if (setStyle === 'navbar'){
        return (
          <div style={{borderTop: "1px solid rgb(236, 236, 236)"}}>
            <button className="NavBar-Button" onClick={() => LogOutHandler()}>
              <img className="Logout-Icon" src={LogoutImage} alt="Logout Icon" width="15px" height="15px"></img>
              {title}
            </button>
          </div>
        )
      }
    
    //------------------------------------------------------
    //  Normal button
    //------------------------------------------------------

      else{
        return (
          <div style={{alignContent: "center"}}>
            <button className="LoginHandler-TryAgain-Button" onClick={() => LogOutHandler()}>
              {title}
            </button>
          </div>
        )
      }

    //------------------------------------------------------
}