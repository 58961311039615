//================================================================
//  Component: PageHeader
//================================================================

//  Purpose: Standard page header for the portal, this also contains the 'Learn More' modal

//  Properties:
//    - title = <String> or <HTML> title of the header
//    - modaltitle = <String> or <HTML> title of the modal
//    - modalbody = <String> or <HTML> body of the modal
//    - body = <String> or <HTML> body of the header

//  PageHeader:
//    <PageHeader
//      title={"Projects"}
//      modaltitle={"Learn about creating projects"}
//      modalbody={<HTML Content>}
//      body={<HTML Content>}
//    ></PageHeader>    

//================================================================

//Libraries
import React, {useState, useEffect} from 'react'

//Images
import Close from '../Images/Close_Icon_Gray.svg';

//CSS
import './PageHeader.css'


export default function PageHeader({
  title,
  modaltitle,
  modalbody,
  body
}) {

  //------------------------------------------------------
  //  Global varibles
  //------------------------------------------------------

    const modalRoot = document.getElementById('modalcontent');
    const modalElement = document.createElement('div');

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to determine modal visibility
    const [showModal, setShowModal] = useState(false);

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // appends the modal to portal once modal's children are mounted and 
    // removes it once we don't need it in the DOM anymore:
    useEffect(() => {

      if (modalRoot === undefined) return;
      if (modalElement === undefined) return;

      modalRoot.appendChild(modalElement);

      return () => {

        modalRoot.removeChild(modalElement);

      };
  
    }, [modalRoot, modalElement]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='PageHeader-Container'>

      {/* Title & learn more icon */}
      <div className='PageHeader-Header'>
        {title}
        {modaltitle ? (

          // Benno ~ Disabled tooltips for 2.0 release

          // <img 
          //   src={LightBlub} alt='light-blub' 
          //   onClick={()=> setShowModal(!showModal)}
          // ></img>
          null

        ) : (
          null
        )}
      </div>

      {/* Modal*/}
      {
        showModal ? (
          <div className='Modal-Background'>
            <div className='PageHeader-Modal-Container'>
              <div className='PageHeader-Modal-Header'>
                {modaltitle}
                <img alt="undo-icon" src={Close} onClick={() => setShowModal(false)}></img>
              </div>
              
              {modalbody}
            </div>
          </div>
        ) : (
          null
        )
      }

      {/* Body */}
      <div className='PageHeader-Body'>
        {body}
      </div>

    </div>
  )
}
