//================================================================
//  Create Portfolio Form
//================================================================

//Libraries
import React, { useContext, useState, useReducer } from 'react'
import { useNavigate } from 'react-router-dom';

//Contexts
import {GetUser, SetAppErrors} from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import InlineInputField from '../../Components/InlineInputField/inlineinputfield';
import ErrorMessageLabel from '../../Components/ErrorMessageLabel';
import QuestionLabel from '../../Components/QuestionLabel';

//Functions
import WriteDocument from '../../Library/WriteDocument';
import ReactBackend from "../../Library/reactBackend";

import {
  isStringAlphaNumSpaceHyphenSlash,
  errStringAlphaNumSpaceHyphenSlash,
  isString,
  errString,
  isStringEmail
} from '../../Library/ValidateUserInput';

//Images
import DeleteIcon from '../../Components/Images/Delete.svg';
import AddIcon from '../../Components/Images/Icon_Add.svg';
import LoadingIcon from '../../Components/Images/loadingPulseBarFast.svg';

//CSS
import './Portfolios.css'

export default function CreatePortfolio() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

    // Get all active projects (owner or operator)
    const activeProjects = getUser?.profile?.views?.projectsActive;

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to render the page based on the page status > 'pending', 'onload', 'success', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

    // Stores the current selected project in the portfolio project selector
    const [selectedProject, setSelectedProject] = useState();

    // Stores all the projects in this portfolio
    const [allPortfolioProjects, setAllPortfolioProjects] = useState([]);

    // Stores all the available projects to add to this portfolio 
    const [allAvailableProjects, setAvailableProjects] = useState(activeProjects);

    // Array of Portfolio Viewers for the portfolio
    const [portfolioViewers, setPortfolioViewers] = useState([]);

    // Used to disable the add project to portfolio button
    const [addProjectButtonDisabled, setAddProjectButtonDisabled] = useState(true);

    // Used to disable the remove project from portfolio button
    const [removeProjectButtonDisabled, setRemoveProjectButtonDisabled] = useState(true);
    
    
  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Holds the inputs of the Form //
    const [selectedValue, setSelectedValue] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        name: "",
        description: "",
        viewers: ""
      }
    );

    //Holds Errors for all fields > Used for error messaging
    const [errorState, setErrorState] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        nameErrorVisible: false,
        nameErrorMessage: "Please enter a portfolio name between 1-30 characters.",
        descriptionErrorVisible: false,
        descriptionErrorMessage: "Please enter a description between 1-250 characters.",
        viewersErrorVisible: false,
        viewersErrorMessage: "",
        viewersLoading: false,
        selectedprojectsErrorMessage: ""
      }
    );
    

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Add a Portfolio Viewer
    function handleAddViewers() {

      if(selectedValue.viewers.length === 0) return;

      if(selectedValue.viewers === getUser?.profile?.emailaddress) {

        setErrorState({ viewersErrorVisible: true });
        setErrorState({ viewersErrorMessage: "You are already added to this group by default."});
        return;

      }

      if(!isStringEmail(selectedValue.viewers)) {

        setErrorState({ viewersErrorVisible: true });
        setErrorState({ viewersErrorMessage: "Please enter a valid email address."});
        return;

      }

      // Call 'getActiveUserCheck' > reject any non-passport users
      // Benno 17/11/2022 ~ This is a short term solution, we plan to add user invitations into portfolios!
      const requestBody = [
        selectedValue.viewers
      ]
      setErrorState({ viewersLoading: true });
      ReactBackend("getActiveUserCheck", requestBody).then((results) => {

        // Handle failed API responses
        if (results.status !== 'success') throw new Error(`Failed to call api 'getActiveUserCheck' in CreatePortfolio.js, error ${results}`);

        // Handle successful API responses
        if (results.responseBody.message[selectedValue.viewers] !== true) {

          setErrorState({ viewersErrorVisible: true });
          setErrorState({ viewersErrorMessage: `${selectedValue.viewers} is not a member of Cloud Passport, please invite this person to a Google Project and then add to a portfolio.`});
          setErrorState({ viewersLoading: false });
          return;

        }

        // If the input value is NOT in the array then add to Portfolio Viewers //
        if (!portfolioViewers.includes(selectedValue.viewers)) setPortfolioViewers([...portfolioViewers, selectedValue.viewers])
        
        // Clear the input field & Disable loading
        setErrorState({ viewersLoading: false });
        setSelectedValue({viewers: ""});

      }).catch((error) => {

        setAppErrors(error);
        setErrorState({ viewersErrorVisible: true });
        setErrorState({ viewersErrorMessage: `Failed to validate users email address, please try again.`});
        setErrorState({ viewersLoading: false });

      });

    }

    // Remove a Portfolio Viewer
    function removeViewer(index) {

      // Remove the viewer based upon index match // 
      const removedArr = [...portfolioViewers].filter(
        (viewer) => viewer !== portfolioViewers[index]
      );

      setPortfolioViewers(removedArr);
    };

    // Add a project to portfolio
    function addProjectToPortfolio(project){

      // Remove project from allAvailableProjects
      allAvailableProjects.splice(allAvailableProjects.indexOf(project), 1)
      setAvailableProjects([...allAvailableProjects])

      // Add project to allPortfolioProjects
      allPortfolioProjects.push(project)
      setAllPortfolioProjects([...allPortfolioProjects])


      // Reset selected project
      setSelectedProject(undefined);

    }

    // Function to add all projects to a portfolio
    function addAllProjectsToPortfolio(){

      // Remove all projects from allAvailableProjects
      setAvailableProjects([]);

      // Add all projects to allPortfolioProjects
      setAllPortfolioProjects(allPortfolioProjects.concat(allAvailableProjects))

      // Reset selected project
      setSelectedProject(undefined);

    }

    // Remove a project from a portfolio
    function removeProjectToPortfolio(project){

      // Remove project from allPortfolioProjects
      allPortfolioProjects.splice(allPortfolioProjects.indexOf(project), 1)
      setAllPortfolioProjects([...allPortfolioProjects])

      // Add project to allAvailableProjects
      allAvailableProjects.push(project)
      setAvailableProjects([...allAvailableProjects])

      // Reset selected project
      setSelectedProject(undefined);

    }

    // Function to remove all projects to a portfolio
    function removeAllProjectsToPortfolio(){

      // Remove all projects from allPortfolioProjects
      setAllPortfolioProjects([]);

      // Remove all projects to allAvailableProjects
      setAvailableProjects(allAvailableProjects.concat(allPortfolioProjects))

      // Reset selected project
      setSelectedProject(undefined);

    }
        
    // Update the selected project > Disable add/remove button
    function updateSelectedProject(project, action){

      setSelectedProject(project)

      if(action === 'add') {
        setAddProjectButtonDisabled(false)
        setRemoveProjectButtonDisabled(true)

      } else {
        setRemoveProjectButtonDisabled(false)
        setAddProjectButtonDisabled(true)

      }

    }

  //------------------------------------------------------
  //  Event Handler > Field Changes
  //------------------------------------------------------

    function HandleFormChange(e) {
        
      const name = e.target.name;
      const newValue = e.target.value;

      // Validate field - name //
      if(name === 'name') {
         
        // Field is empty or > 30 characters or contains invalid charaters -> Show Error Message //
        if(newValue.length === 0 || newValue.length > 30 || !isStringAlphaNumSpaceHyphenSlash(newValue)) {
          setErrorState({ nameErrorVisible: true });
          setErrorState({ nameErrorMessage: `Please enter a portfolio name between 1-30 characters, and contains ${errStringAlphaNumSpaceHyphenSlash}.`});
        }
           
        else setErrorState({ nameErrorVisible: false });

      }

      // Validate field - Description //
      if(name === 'description') {

        // Field is empty or > 250 characters or contains invalid charaters -> Show Error Message
        if(newValue.length === 0 || newValue.length >= 250 || !isString(newValue))  {
          setErrorState({ descriptionErrorVisible: true });
          setErrorState({ descriptionErrorMessage: `Please enter a description between 1-250 characters, and contains ${errString}.`});
        }
     
        else setErrorState({ descriptionErrorVisible: false });
        
      }

      // Validate field - Portfolio Viewers //
      if(name === 'viewers') {

        if(newValue.length > 0 && !isStringEmail(newValue)) {
          setErrorState({ viewersErrorVisible: true });
          setErrorState({ viewersErrorMessage: "Please enter a valid email address."});
        }

        else setErrorState({viewersErrorVisible: false});
      }

      setSelectedValue({ [name]: newValue });

    };

  //------------------------------------------------------
  //  Form Submit Handler
  //------------------------------------------------------

    function submitHandler(){  

      // --- Form Validations --- // 
      
      // Validate portfolio name
      if(selectedValue?.name?.length === 0 || selectedValue?.name?.length > 30 || !isStringAlphaNumSpaceHyphenSlash(selectedValue?.name)){

        setErrorState({ nameErrorMessage: `Please enter a portfolio name between 1-30 characters, and contains ${errStringAlphaNumSpaceHyphenSlash}.` });
        setErrorState({ nameErrorVisible: true });
        return;
  
      } else {

        setErrorState({ nameErrorMessage: "" });
        setErrorState({ nameErrorVisible: false });
      }

      // Ensure that the portfolio has a description
      if (selectedValue?.description?.length === 0 || selectedValue?.description?.length > 250 || !isString(selectedValue?.description)){

        setErrorState({ descriptionErrorMessage: `Please enter a description between 1-250 characters, and contains ${errString}.` });
        setErrorState({ descriptionErrorVisible: true });
        return;
  
      } else {

        setErrorState({ descriptionErrorMessage: "" });
        setErrorState({ descriptionErrorVisible: false });
      
      }

      // --- Prepare Document to be written to Firestore --- // 

      setPageStatus('pending')

      // Set the portfolio ID
      const portfolioid = `portfolio-${Date.now()}`

      // Document
      const document = {
        "portfolioid" : portfolioid,
        "portfolioname": selectedValue.name, 
        "owner": getUser?.profile?.emailaddress,
        "description": selectedValue.description,
        "projects": allPortfolioProjects,   
        "portfolioviewers" : [getUser?.profile?.emailaddress, ...portfolioViewers],
        "creationdate" : new Date(),
        "status" : "active",
        "decommissiondate": "unknown" 
      }
      
      // Write to DB
      WriteDocument("portfolios", portfolioid, document, false)
      .then(() => {

        // Change view to organization > Navigate to portfolios home page
        getUser.preferences.globalSelector.selectedView = 'organization';
        getUser.preferences.globalSelector.selectedResource = 'lendlease.cloud';

        navigate('/portfolios')

      })

      .catch((error) => {
        
        setAppErrors(`Unable to create portfolio for ${getUser?.profile?.emailaddress} ${error}`);
        setPageStatus('error-invalid')
        
      });
        
    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedResource={false}
        requiredRoles={['lendleaseAccount']}
        requiredRolesValue={'true'}
        status={pageStatus}
        breadcrumb={{
          name: "Portfolios",
          route: "/portfolios"
        }}
        header={
          <PageHeader
            title={'Create Portfolio'}
            body={
              <span>
                All project owners and project operators can add their projects to your portfolio via the projects page.
                <br></br>
                Portfolio views are only accessible to the portfolio owner and portfolio viewers.
              </span>
            }
          ></PageHeader>
        }
        body={
          <div className="Form-Container">
            <div className="Form-Body">
              
              {/*------------------------------------------------------------------ */}
              {/*   Name *                                                          */}
              {/*------------------------------------------------------------------ */}
              
              <div>

                  {/* Label */}
                  <QuestionLabel question="Name *"/>

                  {/* Input Field */}
                  <InlineInputField
                    name="name"
                    placeholder={"Name of your portfolio"}
                    value={selectedValue.name}
                    position="right"
                    errorVisible={errorState.nameErrorVisible}
                    inputFieldLength="mediuminputfield"
                    onChange={(e) => HandleFormChange(e)}
                  />
                  
                  {/* Error Label */}
                  <ErrorMessageLabel errorMessage={errorState.nameErrorMessage} errorVisible={errorState.nameErrorVisible}/>

              </div>

              {/*------------------------------------------------------------------ */}
              {/*   Description *                                                   */}
              {/*------------------------------------------------------------------ */}

              <div>

                  {/* Label */}
                  <QuestionLabel question="Description *"/>
            
                  {/* Input Field */}
                  <InlineInputField
                      name="description"
                      placeholder={"Description of your portfolio"}
                      value={selectedValue.description}
                      position="right"
                      errorVisible={errorState.descriptionErrorVisible}
                      inputFieldLength="mediuminputfield"
                      onChange={(e) => HandleFormChange(e)}
                  />
                  
                  {/* Error Label */}
                  <ErrorMessageLabel errorMessage={errorState.descriptionErrorMessage} errorVisible={errorState.descriptionErrorVisible}/>
              
              </div>

              {/*------------------------------------------------------------------ */}
              {/*   Add Portfolio Viewers *                                         */}
              {/*------------------------------------------------------------------ */}

              <div style={{display: "flex", flexDirection: "column"}}>

                  {/* Label */}
                  <QuestionLabel question="Would you like to add any viewers to your portfolio? " helpText = "Please enter a valid email address for any viewers you wish to add. Note, all viewers must be a current passport member."/>
            
                  <div className='Portfolio-Viewer-Grid'>

                      <div className='Portfolio-Viewer-Left'>
                        
                          {/* Input Field */}
                          <InlineInputField
                              name="viewers"
                              placeholder="Add email address"
                              value={selectedValue.viewers}
                              position="right"
                              errorVisible={errorState.viewersErrorVisible}
                              inputFieldLength="longinputfield"
                              onChange={(e) => HandleFormChange(e)}
                              disabled={errorState.viewersLoading}
                            />
                            
                      </div>

                      {/* ADD Button - With a loading screen */}
                      {
                        errorState.viewersLoading === true ? (
                          <img className="Portfolio-Viewer-Loading" src={LoadingIcon} alt="Loading Icon" />
                        ) : errorState.viewersErrorVisible === true || selectedValue.viewers.length <= 0 ? (
                          <img className="Portfolio-Viewer-Right" src={AddIcon} style={{filter: 'grayscale(1)', cursor: 'not-allowed'}} alt="Add Icon" />
                        ) : (
                          <img className="Portfolio-Viewer-Right" src={AddIcon} alt="Add Icon"  onClick={handleAddViewers} />
                        )
                      }
                      
                  </div>
                  {/* Error Label */}
                  <ErrorMessageLabel errorMessage={errorState.viewersErrorMessage} errorVisible={errorState.viewersErrorVisible}/>
                  
                  

                  {/* List of Portfolio Viewers */}
                  <div className={portfolioViewers?.length > 0 && 'Scroll-Container'}>
      
                      {portfolioViewers?.length > 0 && portfolioViewers.map((viewer, index) => (
                        
                        <div key={index} className="Viewers">
                            <p>{viewer}</p>
                            <img alt="Delete Icon" src={DeleteIcon} style={{}} onClick={() => removeViewer(index)}/>
                        </div>

                      ))}

                  </div>

              </div>
              
              {/*------------------------------------------------------------------ */}
              {/*   Add Projects *                                                  */}
              {/*------------------------------------------------------------------ */}
              
              <div>

                {/* Label */}
                <QuestionLabel question="Select the projects you would like to add to your portfolio *"/>

                {/* Portfolio Project Selector */}
                <div className="Portfolios-Projects-Editor-Container">
                  
                  {/* Header */}

                  <div className="Projects-Available-Header">
                    Projects Available
                  </div>

                  <div className="Projects-Selector-Header"></div> 
                  
                  <div className="Projects-Added-Header">
                    Projects Added
                  </div>

                  {/* Projects Available */}
                  <div className="Projects-Available-Body">
                    {
                      allAvailableProjects?.sort().map((project, index) =>(
                        <div key={index} className={selectedProject === project ? 'Portfolio-Project-Label-Active': 'Portfolio-Project-Label'} onClick={() => updateSelectedProject(project, "add")}>
                          {project}
                        </div>
                      ))
                    }
                  </div>
                  
                  {/* Selector Buttons*/}
                  <div className="Projects-Selector-Body">

                    {/* Add Button */}
                    <div className="button-tooltip">
                      <button className='Primary-Button' style={{padding: "5px 20px"}} disabled={selectedProject === undefined ? true : addProjectButtonDisabled} onClick={() => addProjectToPortfolio(selectedProject)}> {'>'} </button>
                      <span className="tooltiptext"> Add project </span>
                    </div> 
                    
                    {/* Add All Button */}
                    <div className="button-tooltip">
                      <button className='Primary-Button' disabled={allAvailableProjects?.length === 0} style={{padding: "5px 20px"}} onClick={() => addAllProjectsToPortfolio()}> {'>|'} </button>
                      <span className="tooltiptext"> Add all projects </span>
                    </div> 

                    {/* Remove Button */}
                    <div className="button-tooltip">
                      <button className='Primary-Button' style={{padding: "5px 20px"}} disabled={selectedProject === undefined ? true : removeProjectButtonDisabled}  onClick={() => removeProjectToPortfolio(selectedProject)}> {'<'} </button>
                      <span className="tooltiptext"> Remove project </span>
                    </div> 

                    {/* Remove All Button */}
                    <div className="button-tooltip">
                      <button className='Primary-Button' disabled={allPortfolioProjects?.length === 0} style={{padding: "5px 20px"}} onClick={() => removeAllProjectsToPortfolio()}> {'|<'} </button>
                      <span className="tooltiptext"> Remove all projects </span>
                    </div> 


                  </div>
                  
                  {/* Projects Added */}
                  <div className="Projects-Added-Body">
                    {
                      allPortfolioProjects?.sort().map((project, index) =>(
                        <div key={index} className={selectedProject === project ? 'Portfolio-Project-Label-Active': 'Portfolio-Project-Label'} onClick={() => updateSelectedProject(project, "remove")}>
                          {project}
                        </div>
                      ))
                    }
                  </div>

                </div>

              </div>

              {/*------------------------------------------------------------------ */}
              {/*   Buttons *                                                       */}
              {/*------------------------------------------------------------------ */}
              
              <div className='Button-Group'>
                  <button className='Primary-Button' onClick={() => submitHandler()}> Submit </button>
                  <button className='Secondary-Button' onClick={() => navigate('/portfolios')}> Cancel </button>
              </div>

            </div>
          </div>
        }
        successContent={
          <div className='PageComponent-Errors-Container'>
            <h2>Success!</h2>
              <p>
                Portfolio Created!.
              </p>
          </div>
        }
      ></PageComponent>
    )
}
