//------------------------------------------------------
//  Handles any auth interactions with FireBase Auth
//   https://firebase.google.com/docs/auth/web/manage-users#get_the_currently_signed-in_user
//------------------------------------------------------

//Libraries
// eslint-disable-next-line
import React, { useContext, useEffect } from 'react';
import { getAuth, signOut, onAuthStateChanged, OAuthProvider } from "firebase/auth";

//Contexts
import {SetFireBaseProvider, SetFireBaseUser, GetAppStatus, SetAppStatus} from './GlobalContexts';


export default function AuthProvider({children}) {

  //------------------------------------------------------
  //  Firebase
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const setFireBaseUser = useContext(SetFireBaseUser);
    const setFireBaseProvider = useContext(SetFireBaseProvider);
    const getAppStatus = useContext(GetAppStatus);
    const setAppStatus = useContext(SetAppStatus);

  //------------------------------------------------------
  //  Local variables
  //------------------------------------------------------

    // Number of minutes before the user is signed out
    const sessionExpiryMinutes = 60; 

  //------------------------------------------------------
  //  Find the users current auth state when the apps auth state changes
  //------------------------------------------------------

    // Signs the user out if the session has expired
    function expireSession(sessionTimeout) {

      // Clear the session timeout
      sessionTimeout.value && clearTimeout(sessionTimeout.value);
      sessionTimeout.value = null;

      // Sign the user out
      signOut(auth).then(() => {

        setAppStatus('expired');
        sessionStorage.setItem('accessTokenAuthTime', '');
        window.location.reload();

      });

    };

    //Onload auth state check
    useEffect(() => {

      // Make sure all the times are in milliseconds!
      const sessionDuration = 1000 * 60 * sessionExpiryMinutes;

      // Check if session has already expired, even if no auth state changed - if so the user needs to log in
      const authTime = sessionStorage.getItem('accessTokenAuthTime');

      if (authTime){
        const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);

        if(millisecondsUntilExpiration < 0) {
          // Sign the user out
          signOut(auth).then(() => {

            setAppStatus(null);
            sessionStorage.setItem('accessTokenAuthTime', '');
            window.location.reload();

          })

          return;
        }
      }

      // At this stage we know the session hasn't expired
      onAuthStateChanged(auth, (user) =>{

        const sessionTimeout = { 
          value: null 
        };

        //Successful sign in 
        if(user){

          // User object saved to the useContext
          setFireBaseUser(user);

          // Retrieve ID token (and pretend it's an access token)
          user.getIdToken().then((value) => sessionStorage.setItem('accessToken', value));

          // Fetch the decoded ID token and create a session timeout which signs the user out.
          // A variant of https://medium.com/@jwngr/implementing-firebase-auth-session-durations-82fa7b1fff08

          user.getIdTokenResult(false).then((idTokenResult) => {

            // Make sure all the times are in milliseconds!
            const authTime = idTokenResult.claims.auth_time * 1000;
            const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
            
            // Check if session has already expired if auth state changed - if so the user needs to log in without the expired screen
            if(millisecondsUntilExpiration < 0) {

              // Sign the user out
              signOut(auth).then(() => {

                setAppStatus(null);
                sessionStorage.setItem('accessTokenAuthTime', '');
                window.location.reload();

              })
    
              return;
            }

            // Save to session storage so we can re-check expiry if the app is reloaded
            sessionStorage.setItem('accessTokenAuthTime', authTime);
            
            // Set session timeout and load app
            setAppStatus("authenticated");
            sessionTimeout.value = setTimeout(() => expireSession(sessionTimeout), millisecondsUntilExpiration);
          
          });

        } 
        
        //Ask the user signin
        else {

          setAppStatus('unauthenticated');
    
        }
    
      });

    // eslint-disable-next-line
    }, []);

    //Save the auth state to session storage
    //This allows us to presist data after refreshes
    useEffect(() => {

      if(getAppStatus === undefined) return setAppStatus('pending');
      sessionStorage.setItem("getAppStatus", getAppStatus);
    
    }, [getAppStatus, setAppStatus]);

  //------------------------------------------------------
  //  Define firebase OAuthProvider > 'microsoft.com'
  //------------------------------------------------------

    useEffect(() => {

      //We want to use the 'OAuthProvider' > 'microsoft.com'
      const Provider = new OAuthProvider('microsoft.com');
      Provider.setCustomParameters(({
        tenant: process.env.REACT_APP_FIREBASE_AZURE_TENANT_ID,
      }));

      //Add scopes
      Provider.addScope('email');
      Provider.addScope('openid');
      Provider.addScope('profile');
      Provider.addScope('User.Read');

      //Save to useContext
      setFireBaseProvider(Provider);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  //------------------------------------------------------
  //  Pass down all Parent components to childern
  //------------------------------------------------------

    return children;

  //------------------------------------------------------
}
