//Libraries
import React from 'react';


//CSS
import './label.css';


//------------------------------------------------------
//  How to use the 'Label' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <Label
//          
//      //Label Properties
//      label={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.question} - "Label value to display on the form."
//      type= "tag" or "" (can be styled as a tag or plain)
//      visibility=  is either 'read' or 'hide'
//          
//    >
//    </Label>


//------------------------------------------------------
//  'Label' component
//------------------------------------------------------


function Label(props) { 


  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

  //Hide > Hide Label
  if(props.visibility === 'hide'){

    return null

  //Show > Show Label
  } else {

    //Tag > Use 'tag-label' class 
    if(props.type === "tag"){
      return (
        <>
          {/* Label */}
          <div className="tag-label-container">
            <label className="tag-label">{props.label}</label>
          </div>
        </>
      );
    } 
    
    //Plain > Use 'plain-label' class
    else {
      return (
        <>
          {/* Label */}
          <div className="plain-label-container">
            <label className="plain-label">{props.label}</label>
          </div>
        </>
      );
    }
  }

  //------------------------------------------------------
}


export default Label;