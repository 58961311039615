//================================================================
//  Create Groups Form
//================================================================

//Libraries
import React, {useEffect, useReducer, useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../../Library/GlobalContexts';

//Functions
import getDocument from '../../../Library/GetDocument';
import writeDocument from '../../../Library/WriteDocument';

import { isStringAlphaNum, errStringAlphaNum, isString, errString } from '../../../Library/ValidateUserInput';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import FormResponse from '../../../Components/FormResponse/formresponse';
import QuestionLabel from '../../../Components/QuestionLabel';
import ListItem from '../../../Components/ListItem/listitem';
import ErrorMessageLabel from '../../../Components/ErrorMessageLabel';
import InlineInputField from '../../../Components/InlineInputField/inlineinputfield';
import TextArea from '../../../Components/TextArea/textarea';

//CSS
import './projectgroups.css';


export default function CreateProjectGroups(){

  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

    //React Router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  Define Variables
  //------------------------------------------------------

    const projectId = getUser?.preferences?.globalSelector?.selectedResource;
    const csReservedWords = ["owner", "editor", "operators"];
  
  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Used to change between > 'accessdenied', 'onload', 'pending', 'success', 'error-invalid', 'error-fatal', 'error-other'
    const [formStatus, setFormStatus] = useState('onload');

    //Used to save the API response
    const [apiResponse, setAPIResponse] = useState();

    //Used to store the list of group members displayed on the page
    let currentGroups = [];
    const [finalGroups, setFinalGroups] = useState(currentGroups);

    //Used to hold the user account field inputs
    const [currentGroupValue, setCurrentGroupValue] = useState("");

    //Used to hold the user account field inputs
    const [currentDescriptionValue, setCurrentDescriptionValue] = useState("");

    //Used to determine if the 'Submit' button is disabled
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    //Used to toggle error messages
    const [errorState, setErrorState] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        errorGroupVisible: false,
        errorGroupMessage: "Please enter a valid group name.",
        errorDescriptionVisible: false,
        errorDescriptionMessage: "Please enter a valid group description.",
        errorAddButtonVisible: false,
        errorAddButtonMessage: "Please complete form."
      }
    );

  //------------------------------------------------------
  //  Define Functions > Field Validation (Regex)
  //  * - Paste Regex in 'https://regexr.com/' for explanation
  //------------------------------------------------------
  
    //Function to check for reserved words in the group name
    function checkCSReservedWords(name){

      let isValid = true;

      //Loop through the reserved words and check if it exists in the project name
      csReservedWords.forEach(word => {

        if (name.toLowerCase() === word){

          isValid = false;

        } 

      });

      return isValid;
    }

  //------------------------------------------------------
  //  Define Functions > Adding/Removing Group Members
  //------------------------------------------------------

    // Function for adding a new group
    function addGroup(groupObject) {

      //extract values from 'groupObject'
      const name = groupObject.name;

      //Check for duplicate group
      const duplicateMembers = finalGroups.filter(

        function(existingGroupObject){

          if (existingGroupObject.name === name){

            return true;

          } else {

            return false;

          }
        }
      )

      //---VALIDATION CHECKS--//
      if (duplicateMembers.length > 0){

        setErrorState({ errorGroupVisible: true });
        setErrorState({ errorGroupMessage: "This group has already been added to the list above."});
      
      //Default border and error visibility
      } 
      else {

        //Add item to list
        currentGroups = [...finalGroups, groupObject];
        setFinalGroups(currentGroups);

        //Reset Form to blank
        setErrorState({ errorGroupVisible: false });
        setErrorState({ errorDescriptionMessage: false });
        setErrorState({ errorAddButtonVisible: false });
        setCurrentGroupValue("");
        setCurrentDescriptionValue("");

      };

    };

    //Function for removing a group from 'currentGroups'
    function removeGroup(index){

      //Get the current values from the useState
      currentGroups = finalGroups;

      //Filter the array
      currentGroups = currentGroups.filter(function(item) {

        return item.name !== currentGroups[index].name;

      });
    
      //Save to useState
      setFinalGroups(currentGroups);
    
    };

  //------------------------------------------------------
  //  Define Functions > callAPI & HandleResponse
  //------------------------------------------------------

    //Used to call "submitGroupCreation"
    async function callAPI (){

      getDocument('projects', getUser?.preferences?.globalSelector?.selectedResource).then((project) => {

        const promises = [];
        finalGroups.forEach((group) => {
  
          const documentId = group.name.toLowerCase();
          const document = {
  
            'id': group.name,
            'transactionid': null,
            'requester': getUser.profile.emailaddress,
            'actionrequired': true,
            'description': group.description,
            'projectid': getUser?.preferences?.globalSelector?.selectedResource,
            'projectowners': [
              project?.projectowner
            ],
            'requesttype': 'project',
            'deleterequested': false,
            'members': {},

          };

          console.log('document', document)

          promises.push(
            writeDocument('groups', documentId, document, false)
          )
  
        });

        Promise.all(promises).then(() => {

          setFormStatus('success');
          setAPIResponse('success');
  
        }).catch((error) => {
  
          setAppErrors(`Unable to submit group creation request, error ${error}`);
          setFormStatus('error-fatal');
  
        });

      });

    }   

  //------------------------------------------------------
  //  Event Handler > Service Account Form Submission & Field Changes
  //------------------------------------------------------

    //Event Handler for form submission
    const handleSubmit = e => {

      //Extract values from event
      var name = currentGroupValue
      name.replace(/ /g, "")
      const fullGroupName = "NEB-CPL-" + projectId + "-" + name
      var description = currentDescriptionValue

      //Simple check to make sure both fields are completed
      if(name.length <= 0 || errorState.errorGroupVisible ){
        setErrorState({ errorAddButtonVisible: true });
      }
      else if(description.length <= 0 || errorState.errorDescriptionVisible){
        setErrorState({ errorAddButtonVisible: true });
      }

      //Everything is good > proceed with calling 'addGroup()'
      else{
        
        //Function that will validated and then add
        var groupObject = {
          "name": fullGroupName,
          "description": description
        }
        addGroup(groupObject)
  
        //Reset form to blank
        setCurrentGroupValue("");
        setCurrentDescriptionValue("")
      }

      //Prevent refresh
      e.preventDefault();
    }

    //Event Handler for field changes 
    const handleChange = evt => {

      const name = evt.target.name
      const newValue = evt.target.value;
      const fullGroupName = "NEB-CPL-" + projectId + "-" + newValue

      //Group Name Validation
      if(name === 'name'){
        //No currentGroupValue provided > Set 'errorGroupVisible' to false 
        if(newValue.length <= 0){
          setErrorState({ errorGroupVisible: true });
          setErrorState({ errorGroupMessage: "Please enter a group name." });

        //Invalid group name > Set 'errorGroupVisible' to true
        } else if(!isStringAlphaNum(newValue)){
          setErrorState({ errorGroupVisible: true });
          setErrorState({ errorGroupMessage: `Please enter a combination of ${errStringAlphaNum}.` });
        }
        else if (fullGroupName.length > 60){
          setErrorState({ errorGroupVisible: true });
          setErrorState({ errorGroupMessage: "Group name must be no longer than 60 characters. " + fullGroupName.length + "/60 characters used."});
        //Default border and error visibility
        } 
        //Check if Group Name contains a reserved word
        else if(!checkCSReservedWords(newValue)){
          setErrorState({ errorGroupVisible: true });
          setErrorState({ errorGroupMessage: "Invalid group name. The name must not be equal to reserved words: 'Owner', 'Editor' and 'Operators'." });     
        } 
        else {
          setErrorState({ errorGroupVisible: false });
        }
        //Set currentGroupValue to input values 
        setCurrentGroupValue(newValue);
      }

      //Group Description Validation
      if(name === 'description'){
        //No currentGroupValue provided > Set 'errorGroupVisible' to false 
        if(newValue.length <= 0){
          setErrorState({ errorDescriptionVisible: true });
          setErrorState({ errorDescriptionMessage: "Please enter a group description." });

          //Invalid group name > Set 'errorGroupVisible' to true
        } else if(!isString(newValue)){
          setErrorState({ errorDescriptionVisible: true });
          setErrorState({ errorDescriptionMessage: `Please enter a combination of ${errString}.` });
        }
        else if (fullGroupName.length > 200){
          setErrorState({ errorDescriptionVisible: true });
          setErrorState({ errorDescriptionMessage: "Group descrirption must be no longer than 200 characters. " + fullGroupName.length + "/60 characters used."});
        //Default border and error visibility
        } else {
          setErrorState({ errorDescriptionVisible: false });
        }
        //Set currentDescriptionValue to input values 
        setCurrentDescriptionValue(newValue);
      }

    };

  //------------------------------------------------------
  //  Event Handler > Parent Form Submission
  //------------------------------------------------------

    //Event handler when form is submitted > validate all fields
    const eventHandlerOnSubmit = e => {

      //This prevents the form from been submitted
      let preventSubmit = false;

      //If the 'add email' box has a value, warn user to click the '+ Add' button first
      if(currentGroupValue !== "" || currentDescriptionValue !== "") {
    
        setErrorState({ errorAddButtonMessage: "Please click the '+ Add' button below to add this group prior to submitting request." });
        setErrorState({ errorAddButtonVisible: true });

        //Prevent submission
        preventSubmit = true;
      
      //If the group is empty && no changes have been made > Display error message
      } else if(finalGroups <= 0){
  
        setErrorState({ errorGroupMessage: "Please add at least one group before submitting the form." });
        setErrorState({ errorGroupVisible: true });
        
        //Prevent submission
        preventSubmit = true;


      //If no changes are made > Display error message
      } else if(finalGroups.length <= 0){
  
        setErrorState({ errorGroupMessage: "No changes have been made. Please edit your group above." });
        setErrorState({ errorGroupVisible: true });
        
        //Prevent submission
        preventSubmit = true;

      }

      //Allow the form to be submitted
      if (preventSubmit === false){

        setFormStatus('pending');
        e.preventDefault();
        callAPI();

      }

      //Prevent reload
      e.preventDefault();
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    //===================================================
    // When new users are added/removed > enable 'Submit' button
    //===================================================

    useEffect(() => {

      //If changes are made > Enable 'Submit' button
      if(finalGroups.length <= 0){
        setSubmitButtonDisabled(true)

      } else {
        setSubmitButtonDisabled(false)
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[finalGroups])

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------
  
  return (
    <PageComponent
      requireSelectedResource={true}
      requireSelectedViews={['project']}
      requiredRoles={['projectOwner', 'projectOperator']}
      requiredRolesValue={projectId}
      header={
        <PageHeader
          title={"Create Group"}
          body={[
            <p>
              Please enter the name of the group(s) you would like to create. Once complete, click 'Submit' below.
              <br></br>
            </p>   
          ]}
        ></PageHeader>
      }
      breadcrumb={{
        "name": "Manage Groups", 
        "route": "/projects/projectgroups"
      }}
      body={
        <FormResponse
          requestType={"view"}
          setRequestType={setFormStatus}
          requestTypeDisplayname={"Return to Manage Groups"}
          formStatus={formStatus}
          setFormStatus={setFormStatus}
          initialState={"onload"}
          formOnload={[
            <div className="Form-Container">
              <form className='Form-Body' id="editusergroupform" onSubmit={eventHandlerOnSubmit}>

                {/*------------------------------------------------------------------ */}
                {/*   Service Accounts                                                */}
                {/*------------------------------------------------------------------ */} 

                {/* Service Account Summary */}
                <div className="list-container">

                  {/*------------------------------------------------------------------ */}
                  {/*   Groups                                                          */}
                  {/*------------------------------------------------------------------ */}      

                  <QuestionLabel question={"Groups you would like to create"}></QuestionLabel>

                      {/* If group members exist > render a list  */}
                      {finalGroups.length > 0 ? 
                        (
                          finalGroups.map((member, index) => (
                            <ListItem
                              item={member.name}
                              index={index}
                              removeItem={removeGroup}
                              type='new'
                            ></ListItem>

                          ))
                        ) :
                        (
                          <p><em>No groups to create.</em></p>
                        )
                      }
                  
                  <QuestionLabel question="Enter a group name and description, then click the '+ Add' button below."></QuestionLabel>

                  {/* Add Group Form */}
                  <div className="create-item" >
                      <form onSubmit={handleSubmit}>

                        <InlineInputField
                          //Inline Input Field Properties
                          name="name"
                          inputFieldLength="shortinputfield"
                          placeholder="e.g. viewer"
                          value={currentGroupValue}
                          inlinefieldlabel= {"NEB-CPL-" + projectId + "-"} 
                          position = "left"
                          errorVisible={errorState.errorGroupVisible}

                          //Function Properties
                          onChange={handleChange}
                        >
                        </InlineInputField>
                        <ErrorMessageLabel errorMessage={errorState.errorGroupMessage} errorVisible={errorState.errorGroupVisible}></ErrorMessageLabel>

                        <br></br><br></br>

                        <TextArea
                          //TextArea Properties
                          name="description"
                          placeholder={"Enter a description"}
                          value={currentDescriptionValue}
                          errorVisible={errorState.errorDescriptionVisible}
                          visibility={'edit'}

                          //Function Properties
                          onChange={handleChange}
                        >
                        </TextArea>
                        <ErrorMessageLabel errorMessage={errorState.errorDescriptionMessage} errorVisible={errorState.errorDescriptionVisible}></ErrorMessageLabel>

                        <br></br><br></br>

                        {/* Add Button */}
                        <input className="add-account-button" value="+ Add" onClick={handleSubmit}></input>
                        <div>
                          <ErrorMessageLabel errorMessage={errorState.errorAddButtonMessage} errorVisible={errorState.errorAddButtonVisible}></ErrorMessageLabel>
                        </div>

                      </form>
                  </div>
              </div>

                {/*------------------------------------------------------------------ */}
                {/*   Submit Button                                                   */}
                {/*------------------------------------------------------------------ */}   

                <div className='Button-Group'>
                  <input className='Primary-Button' disabled={submitButtonDisabled} type="submit" value="Submit"></input>
                  <button className='Secondary-Button' onClick={() => navigate('/projects/projectgroups')}>Cancel</button>
                </div>    

              {/*------------------------------------------------------------------ */}   

              </form>
            </div>
          ]}
          formSuccessDescription={[
            <p>
              Your request to create this group(s) has been completed.
            </p>
          ]}
          apiResponse={apiResponse}
          workflowType={'http'}
        ></FormResponse>
      }
    ></PageComponent>
  );


  //------------------------------------------------------
};