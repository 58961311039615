//Libraries
import React from 'react';

//Components
import StatusLabel from '../StatusLabel/statuslabel'

//Images
import LoadingIcon from '../Images/Loading_Ripple.svg'
import InfoIcon from '../Images/Icon_Info_Black.svg';


//CSS
import './requestsummary.css';


function RequestSummary(props){

  //------------------------------------------------------
  //  Extract props
  //------------------------------------------------------

    let rows = props.rows
    var setStatus = props.setStatus
    
  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    /*---------------------------------------------------- */
    /*   Status = 'onload'                                 */
    /*---------------------------------------------------- */

    //Call API > status = 'pending'
    if(props.status === 'onload'){
      return (
        <div className="request-table-response-text-container">
          <div className='PageComponent-Label'>
            <div className='PageComponent-LabelMessage'>

              <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
              <p>
                Request cancelled. Refresh page to load info.
              </p>

            </div>
          </div>
        </div>
      );

    /*---------------------------------------------------- */
    /*   Status = 'pending'                                */
    /*---------------------------------------------------- */

    //Call API > status = 'pending'
    } else if(props.status === 'pending'){
      return (
        <div className="request-table-response-container">
          <img alt="loading-circle-icon" src={LoadingIcon}></img>
          <h3>Please wait while we process your request.</h3>
          <div className="pageresponse-button-container">
              <button className="form-submit-button" onClick={()=>{setStatus('onload')}}>CANCEL</button>
          </div>
        </div>
      );

    /*---------------------------------------------------- */
    /*   Status = 'success'                                */
    /*---------------------------------------------------- */

    //API call returns no errors > status = 'success'
    } else if (props.status === 'success'){
      
      if(rows.length === 0 || typeof(rows) === 'string'){
        return(
          <div className="request-table-response-text-container">
            <p className="request-table-message">{rows}</p>
          </div>
        );
      } else {
          return (
            <table className="Data-Table-Container">
              <colgroup>
                <col span="1" style={{width: "15%"}}></col>
                <col span="1" style={{width: "15%"}}></col>
                <col span="1" style={{width: "15%"}}></col>
                <col span="1" style={{width: "15%"}}></col>
                <col span="1" style={{width: "15%"}}></col>
                <col span="1" style={{width: "15%"}}></col>
              </colgroup>
              <tbody>
                <tr>
                    <th>Transaction ID</th>
                    <th>Project ID</th>
                    <th>Job Type</th>
                    <th>Requested By</th>
                    <th>Created (UTC)</th>
                    <th>Status</th>
                </tr>
    
                {rows.map((row, index) =>
                  (
                  <tr key={index}>
                    <td>{row.transactionId}</td>
                    <td>{row.projectId}</td>
                    <td>{row.jobType}</td>
                    <td>{row.creator}</td>
                    <td>{row.created}</td>
                    <td>
                        <StatusLabel
                          label={row.status}
                        ></StatusLabel>
                    </td>
                  </tr>
                  )
                )}

              </tbody>
            </table>
          );
      }



    /*---------------------------------------------------- */
    /*   Status = 'error-invalid'                          */
    /*---------------------------------------------------- */
    

    //Request fails > status = 'error-invalid'
    } else if(props.status === 'error-invalid'){
        return (
          <div style={{textAlign: "center"}} className="table-response-container">
            <h2>Request Failed</h2>
            <p style={{lineHeight: "1.7"}}>
              An error occurred while we processed your request.
              <br></br>
              If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
            </p>
            <p> <b>Message:</b> Failed to load the page - Invalid request.</p>
            
            {/* Try Again Button */}
          <button className="try-again-button" onClick={()=>{window.location.reload()}}>Try Again</button>
        </div>
        );
    
    /*---------------------------------------------------- */
    /*   Status = 'error-fatal'                            */
    /*---------------------------------------------------- */

    //Request fails > status = 'error-fatal'
    } else if(props.status === 'error-fatal'){
        return (
          <div style={{textAlign: "center"}} className="table-response-container">
            <h2>Request Failed</h2>
            <p style={{lineHeight: "1.7"}}>
              An error occurred while we processed your request.
              <br></br>
              If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
            </p>
            <p> <b>Message:</b> Failed to load the page - Fatal error.</p>
            
            {/* Try Again Button */}
          <button className="try-again-button" onClick={()=>{window.location.reload()}}>Try Again</button>
        </div>
        );

        
    /*---------------------------------------------------- */
    /*   Status = 'error-timeout'                          */
    /*---------------------------------------------------- */

    //Request fails > status = 'error-timeout'
    } else if(props.status === 'error-timeout'){
      return (
        <div style={{textAlign: "center"}} className="table-response-container">
          <h2>Request Failed</h2>
          <p style={{lineHeight: "1.7"}}>
            An error occurred while we processed your request.
            <br></br>
            If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
          </p>
          <p> <b>Message:</b> Failed to load the page - Request timed out.</p>
          
          {/* Try Again Button */}
        <button className="try-again-button" onClick={()=>{window.location.reload()}}>Try Again</button>
      </div>
      );
    }
}

export default RequestSummary;

//------------------------------------------------------