//Libraries
import React from "react";

//CSS
import './listitem.css';

//Images
import Delete from '../Images/Delete.svg'
import Undo from '../Images/Undo.svg'

//------------------------------------------------------
//  How to use the 'ListItem' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <ListItem
//          
//      //ListItem Properties
//        item= item value, e.g. test123@nowshintestproject.iam.gserviceaccount.com
//        index= index of list item in the array e.g. 1 
//        removeItem= pass in the removeItem() function for removing an item from the list 
//        readdItem= pass in the readdItem() function for readding an item from the list
//        disabled= Boolean true or false to toogle if the button is disabled or not 
//    >
//    </ListItem>


//------------------------------------------------------
//  'ListItem' component
//------------------------------------------------------


function ListItem(props) {

  //------------------------------------------------------
  //  Extract props
  //------------------------------------------------------


    var item = props.item
    var index = props.index
    var removeItem = props.removeItem
    var readdItem = props.readdItem
    var type = props.type
    var disabled = props.disabled

  
  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

  if(type === 'new' || type === 'existing'){  
    return (
      <div className="list-item-container">
        {/* Item Value */}
        <div className="list-item">
          {item}
        </div>
        {/* Delete Icon */}
        <div className="delete-list-item">
          <button type="button" className="delete-list-item" disabled={disabled} onClick={() => removeItem(index)}>
             <img className="delete-icon" alt="delete-icon" src={Delete}></img>
           </button>
        </div>
      </div>
    );
  }

  else if(type === 'deleted'){
    return (
      <div className="list-item-container">
        {/* Item Value */}
        <div className="deleted-list-item">
          {item}
        </div>
        {/* Delete Icon */}
        <div className="delete-list-item">
          <button type="button" className="delete-list-item" disabled={disabled} onClick={() => readdItem(index)}>
             <img className="undo-icon" alt="undo-icon" src={Undo}></img>
           </button>
        </div>
      </div>
      
    );
  }
}


export default ListItem;