//------------------------------------------------------
//  Component: Knowledge Article PDF View
//------------------------------------------------------

// Purpose: To display a knowledge article in a PDF viewer

// Properties:
//  - objectURL - "string" - signed URL for document
//  - article - {object} - selected article object
//  - setRequestType - setState -> to return to knowledge hub home

// Example:
//    <KnowledgeArticleView
//      objectURL={signedURL}
//      article={selectedArticle}
//      setRequestType={setRequestType}
//    ></KnowledgeArticleView> 
//
//------------------------------------------------------

//Libraries
import React from 'react';

//CSS
import './knowledgearticle.css'

function KnowledgeArticleView({
    article,
    objectURL,
    setRequestType
}) {

    //------------------------------------------------------
    //  Define variables
    //------------------------------------------------------

        //Label > Exclude 'cs-customer' label and capitalize and remove dash
        var labels = article.labels.filter(label => label !== 'cs-customer')[0].replace('-', ' ')

    //------------------------------------------------------
    //  Return HTML
    //------------------------------------------------------
    
        return (
            <div className="Knowledge-Article-View">
                <div className="Knowledge-Article-Summary">
                    
                    <div className="Knowledge-Article-Heading">
                        <h3>{article.title}</h3>
                        <button className="Knowledge-Article-Back-Button" onClick={() => setRequestType('search')}>Go Back</button>
                    </div>

                    <div className="Knowledge-Article-Date">
                        <b>Last Modified: </b>{article.friendlyModified}
                    </div>

                    <div className="Knowledge-Article-Tags" style={{marginTop: "15px"}}>
                        <div className="Knowledge-Article-Label">
                            {labels}
                        </div>
                    </div>

                </div>

                {/* PDF View */}
                <div className="Knowledge-Article-PDF">
                    <iframe
                        src={objectURL}
                        style={{width: "100%", height: "100vh"}}
                        title={article.docId}
                        sandbox
                    ></iframe>
                </div>

            </div>
        )

    }

//------------------------------------------------------

export default KnowledgeArticleView;