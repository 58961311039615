//================================================================
//  Component: Navigation Item
//================================================================

//  Purpose: This is the standard for adding a navigation link to the side menu

//  Properties:
//    - style = <String> - CSS class > determines if the link is active/inactive
//    - link = <String> - url/route
//    - title = <String> - display name for navigation link
//    - icon = <img> - icon for navigation item
//    - roles = [Array] - list of roles required to view this navigation item

//  Example:
//    <NavItem 
//      style={changeStyleHandler("/projects")} 
//      link={"/projects"} 
//      title={"Projects"} 
//      icon={ProjectsIcon} 
//      roles={["portalAdmin","portalUser"]}
//    ></NavItem>

//================================================================


//Libraries
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Functions
import CheckUserRole from '../../Library/checkUserRole';

//CSS
import './navitem.css';

function NavItem({
  style,
  link, 
  title,
  icon, 
  roles
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  Check Role Service
  //------------------------------------------------------

    //Get the user's roles 
    const userRoles = getUser?.profile?.roles;

    //Check if the current item has the required role
    const roleCheck = CheckUserRole(roles, userRoles, "true");

  //------------------------------------------------------
  //  Append current 'selectedView' and 'selectedResource' to route
  //------------------------------------------------------

    const newLink = `${link}?view=${getUser.preferences?.globalSelector?.selectedView}&id=${getUser.preferences?.globalSelector?.selectedResource}`;

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------
 
    // Check user role > display nav link
    if(roleCheck === true || roles === undefined)
      return (
        <Link className={style} to={newLink}>
          <div className="NavLink">
            <img alt={title} src={icon}></img>
            {title}
          </div>
        </Link>
      )
  ;

  //------------------------------------------------------
}

export default NavItem;