//================================================================
//  Security Page
//================================================================

//Libraries
import React, { useContext, useEffect, useState} from 'react';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import SelectBox from '../../Components/SelectBox/selectbox';
import VideoPlayer from '../../Components/VideoPlayer/videoPlayer';
import ExportToCSV from '../../Components/ExportToCSV/ExportToCSV';

//Functions
import GetDocument from '../../Library/GetDocument';

//Images
import Critical from '../../Components/Images/scc_Critical.svg';
import High from '../../Components/Images/scc_High.svg';
import Medium from '../../Components/Images/scc_Medium.svg';
import Low from '../../Components/Images/scc_Low.svg';
import Unspecified from '../../Components/Images/scc_Unspecified.svg';
import InfoIcon from '../../Components/Images/Icon_Info_Black.svg';

//CSS
import './Security.css'


export default function Security() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates (global)
  //------------------------------------------------------

    //Used to render the page based on the page status > 'pending', 'onload', 'success', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

  //------------------------------------------------------
  //  useStates (child)
  //------------------------------------------------------

    // Holds ALL findings
    const [findings, setFindings] = useState([]);

    // Filtered findings
    const [filteredFindings, setFilteredFindings] = useState([]);

    // User has selected one finding
    const [selectedFinding, setSelectedFinding] = useState();

    // Holds all projects
    const [allProjects, setAllProjects] = useState([]);

    // Holds the current severity
    const [severity, setSeverity] = useState('All Severities');

    // Holds what project has the most findings, changes with the filters
    const [mostFindings, setMostFindings] = useState({
      'projectid': undefined,
      'count': 0
    });


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Returns the correct severity icon for each finding in the selector pane
    function severityTag(object) {

      if (object === 'CRITICAL') {

        return (
          <img alt='Critical SCC finding tag' src={Critical} />
        );
        
      }
      else if (object === 'HIGH') {

        return (
          <img alt='High SCC finding tag' src={High} />
        );
        
      }
      else if (object === 'MEDIUM') {

        return (
          <img alt='Medium SCC finding tag' src={Medium} />
        );

      }
      else if (object === 'LOW') {

        return (
          <img alt='Low SCC finding tag' src={Low} />
        );
    
      }
      else if (object === 'UNCATEGORIZED') {

        return (
          <img alt='Unspecified SCC finding tag' src={Unspecified} />
        );

      } else {

        return (
          <img alt='Unspecified SCC finding tag' src={Unspecified} />
        );

      }
    }

    //Function to handle any types of filters
    function FilterByHours(hours) {

      // Clear the selected finding
      setSelectedFinding(undefined);

      //Reset filter
      if (hours === 'All Hours') return setFilteredFindings(findings);

      // Get the current time
      const now = new Date()

      // Subtract the passed in hours
      now.setHours(now.getHours() - hours);
      const epochTime = parseInt(now.getTime()/1000);
      
      // Filter finds by the last number of hours 
      setFilteredFindings(findings.filter((value) => (parseInt(value.create_time_epoch) >= epochTime)));
    
    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------
    
    // Onload > Get SCC findings
    useEffect(()=>{

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      // Reset useStates and show pending screen
      setPageStatus('pending');
      setFindings([]);
      setFilteredFindings([]);
      setAllProjects([]);
      setMostFindings({
        'projectid': undefined,
        'count': 0
      });

      //------------------------------------------
      //  Project view was selected
      //------------------------------------------

      if (getUser.preferences.globalSelector.selectedView === 'project') {

        setAllProjects([
          getUser.preferences.globalSelector.selectedResource
        ]);

        // Get all findings
        GetDocument('security-sccfindings', getUser.preferences.globalSelector.selectedResource)
        .then((findings) =>{

          //No security findings 
          if (findings.length === 0) {
            
            setFindings([]);
            setFilteredFindings([]);
            setSelectedFinding(undefined);
            setPageStatus('onload');
            return;
  
          }

          // Our horrible DB Schema, we have an array of keys/values...
          const newArray = []
          Object.values(findings)?.map((obj) =>(

            newArray.push(obj)

          ))

          // Create a new array by 'severityLevel', unable to achieve this via sort :(
          const results = [
            ...newArray.filter((value) => (value.severityLevel === 'CRITICAL')),
            ...newArray.filter((value) => (value.severityLevel === 'HIGH')),
            ...newArray.filter((value) => (value.severityLevel === 'MEDIUM')),
            ...newArray.filter((value) => (value.severityLevel === 'LOW')),
            ...newArray.filter((value) => (value.severityLevel === 'UNCATEGORIZED')),
          ]
          
          //Save out to useState
          setFindings(results);
          setFilteredFindings(results);
          setPageStatus('onload');

        })
        .catch((error) =>{

          // If no documents exist, GetDocument will fail with 'Document 'PROJECT_ID' does not exist'
          if (error?.message?.includes('does not exist')){
            
            setFindings([]);
            setFilteredFindings([]);
            setSelectedFinding(undefined);
            setPageStatus('onload');
            return;
  
          }

          // Catch all > Something went wrong!
          setAppErrors(`Unable to retrieve projects from the portfolio ${getUser.preferences.id} ${error}`);
          setPageStatus('error-fatal');

        });
        
      }

      //------------------------------------------
      // Portfolio view was selected
      //------------------------------------------

      if (getUser.preferences.globalSelector.selectedView === 'portfolio') {

        GetDocument('portfolios', getUser.preferences.globalSelector.selectedResource)
        .then((portfolio) => {

          // Extract the projects from the portfolio document
          const projects = portfolio?.projects;
          setAllProjects([...projects]);

          // Portfolio doesnt have any projects, so no security findings!
          if (projects?.length === 0) {
            
            setFindings([]);
            setFilteredFindings([]);
            setSelectedFinding(undefined);
            setPageStatus('onload');
            return;
  
          }

          // Get findings for each project
          const promises = [];
          projects?.forEach(project => {

            promises.push(GetDocument('security-sccfindings', project));
            
          });
          Promise.allSettled(promises)
          .then((settledPromises) =>{

            const findings = [];
            settledPromises?.forEach(promise =>{

              // No SCC findings for this project!
              if (promise?.status === 'rejected' && (promise?.reason?.message?.includes('does not exist'))) {
                return;

              // An unknown error has occured
              } else if (promise?.status === 'rejected') {

                setPageStatus('error-invalid');
                throw new Error(promise?.reason?.message);

              }

              // Our horrible DB Schema, we have an array of keys/values...
              Object.values(promise?.value)?.map((obj) =>(

                findings.push(obj)

              ));

            });

            // Projects don't have any findings!
            if (projects?.length === 0) {
              
              setFindings([]);
              setFilteredFindings([]);
              setPageStatus('onload');
              return;
    
            }

            // Create a new array by 'severityLevel', unable to achieve this via sort :(
            const results = [
              ...findings.filter((value) => (value.severityLevel === 'CRITICAL')),
              ...findings.filter((value) => (value.severityLevel === 'HIGH')),
              ...findings.filter((value) => (value.severityLevel === 'MEDIUM')),
              ...findings.filter((value) => (value.severityLevel === 'LOW')),
              ...findings.filter((value) => (value.severityLevel === 'UNCATEGORIZED')),
            ]
            
            //Save out to useState
            setFindings(results);
            setFilteredFindings(results);
            setPageStatus('onload');
  
          })
          .catch((error) =>{
  
            setAppErrors(`Unable to retrieve findings for project ${getUser.preferences.globalSelector.selectedResource} ${error}`);
            setPageStatus('error-invalid');
  
          });

        })
        .catch((error) =>{

          setAppErrors(`Unable to retrieve projects from the portfolio ${getUser.preferences.id} ${error}`);
          setPageStatus('error-fatal');

        });

      }

    // eslint-disable-next-line
    }, [getUser]);

    // Loop through the projects and find what has the most findings > Used in the 'Security-Dashboard' container
    useEffect(()=>{

      // Conditions
      if (filteredFindings ===  undefined) return;
      if (allProjects ===  undefined) return;
      if (filteredFindings.length === 0) return;
      if (allProjects.length === 0) return;

      let currentFindingCount = 0;

      //Find the project with the most findings
      allProjects?.forEach(project => {

        const findings = filteredFindings.filter((finding) => (finding.projectid === project)).length

        if(findings > currentFindingCount) {

          // Save to useState
          mostFindings.projectid = project;
          mostFindings.count = findings;

          // Update counter
          currentFindingCount = findings;

        }
        
      });

      setMostFindings({...mostFindings});

    // eslint-disable-next-line
    }, [filteredFindings]);

    // Triggered by changes to 'severity' > Filter findings
    useEffect(()=>{

      // Clear the selected finding
      setSelectedFinding(undefined);

      //Reset filter
      if (severity === 'All Severities') return setFilteredFindings(findings);

      //Write results out to useState
      setFilteredFindings(findings.filter((value) => (value.severityLevel === severity)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [severity])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedResource={true}
        requireSelectedViews={['project', 'portfolio']}
        requiredRoles={['projectOwner', 'projectOperator', 'portfolioOwner', 'portfolioViewer']}
        status={pageStatus}
        header={
          <PageHeader
            title={'Security Findings'}
            modaltitle='How to View Security Findings'
            modalbody={
              <div>
                <div className='modal-description' style={{lineHeight: '1.5'}}>
                  <br></br>
                  Security Command Center detects data and security risks in your projects. For more information, refer to Google's documentation on Security Command Center <a href='https://cloud.google.com/security-command-center/docs/concepts-vulnerabilities-findings' target='_blank' rel='noreferrer' style={{ color: '#0C6CDE', textDecoration: 'none' }}>here</a>.
                  <br></br><br></br>
                  Want to grant other users access to see these findings and receive security alerts? You can add them as Project Operators on the 'Projects' page.                                 </div>

                  <VideoPlayer
                    item='Video_Security_14_12_2021.mp4'
                  ></VideoPlayer>
              </div>
            }
            body={
              <>
                All security findings displayed on this page, have been sourced from GCP's Security Command Center and can be filtered by project and level of severity.
              </>
            }
          ></PageHeader>
        }
        body={
          <div className='Security-Container'>

            {/* ------------------------------------------------- */}
            {/*  Header Container                                 */}
            {/* ------------------------------------------------- */}

            <div className='Security-Header'>

              <div className='Security-Filter-Pane'>
                {/* Severity Filter */}
                <span>Filter by:</span>
                <SelectBox
                  name='severityFilter'
                  placeholder={severity}
                  arrayOfOptions={['All Severities', 'CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'UNCATEGORIZED'
                  ]}
                  onChange={(e) => setSeverity(e.target.value)}
                  visibility={findings?.length === 0 ? 'read' : null} 
                ></SelectBox>

                {/* Hours filter */}   
                <SelectBox
                  name='severityFilter'
                  placeholder='All Hours'
                  arrayOfOptions={['All Hours', 24, 12, 6, 3, 1]}
                  onChange={(e) => FilterByHours(e.target.value)}
                  visibility={findings?.length === 0 ? 'read' : null} 
                ></SelectBox>
              </div>

              {/* Download to CSV button */}
              <div hidden={filteredFindings?.length === 0}>
                <ExportToCSV
                  filename='findings'
                  data={filteredFindings} 
                ></ExportToCSV>   
              </div>

            </div>

            {/* ------------------------------------------------- */}
            {/*  Security Findings Summary                        */}
            {/* ------------------------------------------------- */}

            {
              // No projects in the portfolio
              allProjects?.length === 0 ?
              (
                <div className='No-Findings-Container '>
                  <label className='Global-Selector-Message'>
                    <img className='Global-Selector-Info-Icon' src={InfoIcon} alt="Information Icon"></img>
                    No projects in this portfolio.
                  </label>
                </div>
              )
              // No findings exist
              : filteredFindings?.length === 0 ?
              (
                <div className='No-Findings-Container '>
                  <label className='Global-Selector-Message'>
                    <img className='Global-Selector-Info-Icon' src={InfoIcon} alt="Information Icon"></img>
                    No findings found.
                  </label>
                </div>
              )
              : //Findings exist
              (
              <>
                
                {/* ------------------------------------------------- */}
                {/*  Dashboard Container                              */}
                {/* ------------------------------------------------- */}

                <div className='Security-Dashboard'>

                  {/* Total findings */}
                  <div className='Security-Dashboard-Info'>

                    {/* This is slightly gross, this allows us to support different messages based on the number of findings/projects */}
                    {
                      // Has findings
                      allProjects?.length === 1 && filteredFindings?.length === 1 ? (
                        <div>
                          {filteredFindings?.length} finding in {allProjects[0]} project
                        </div>
                      ) :
                      allProjects?.length === 1 && filteredFindings?.length > 0 ? (
                        <div>
                          {filteredFindings?.length} findings in project {allProjects[0]}
                        </div>
                      ) : 
                      allProjects?.length > 1 && filteredFindings?.length === 1 ? (
                        <div>
                          {filteredFindings?.length} finding found, across {allProjects?.length} projects
                        </div>
                      ) :
                      allProjects?.length > 1 && filteredFindings?.length > 1 ? (
                        <div>
                          {filteredFindings?.length} findings found, across {allProjects?.length} projects
                        </div>
                      ) :

                      // No projects
                      (
                        <label className='Global-Selector-Message'>
                          <img className='Global-Selector-Info-Icon' src={InfoIcon} alt="Information Icon"></img>
                          No projects in this portfolio
                        </label>
                      )
                    }

                    {/* Show highest findings count for a project */}
                    {
                      (mostFindings?.projectid !== undefined && getUser?.preferences?.globalSelector?.selectedResource === 'portfolio') && (
                        <div>
                          {mostFindings?.projectid} has the most findings ({mostFindings?.count})
                        </div>
                      )
                    }
                    
                  </div>

                  <div className='Findings-By-Severity'>

                    {/* CRITICAL */}
                    <div className='Security-Dashboard-Findings' onClick={() => setSeverity('CRITICAL')}>
                      <img alt='Critical SCC finding tag' src={Critical} />
                      {filteredFindings?.filter((value) => (value.severityLevel === 'CRITICAL')).length}
                    </div>

                    {/* HIGH */}
                    <div className='Security-Dashboard-Findings' onClick={() => setSeverity('HIGH')}>
                      <img alt='High SCC finding tag' src={High} />
                      {filteredFindings?.filter((value) => (value.severityLevel === 'HIGH')).length}
                    </div>

                    {/* MEDIUM */}
                    <div className='Security-Dashboard-Findings' onClick={() => setSeverity('MEDIUM')}>
                      <img alt='Medium SCC finding tag' src={Medium} />
                      {filteredFindings?.filter((value) => (value.severityLevel === 'MEDIUM')).length}
                    </div>

                    {/* LOW */}
                    <div className='Security-Dashboard-Findings' onClick={() => setSeverity('LOW')}>
                      <img alt='Low SCC finding tag' src={Low} />
                      {filteredFindings?.filter((value) => (value.severityLevel === 'LOW')).length}
                    </div>

                    {/* UNCATEGORIZED */}
                    <div className='Security-Dashboard-Findings' onClick={() => setSeverity('UNCATEGORIZED')}>
                      <img alt='Unspecified SCC finding tag' src={Unspecified} />
                      {filteredFindings?.filter((value) => (value.severityLevel === 'UNCATEGORIZED')).length}
                    </div>

                  </div>

                </div>
          
                {/* ------------------------------------------------- */}
                {/*  Security Findings                                */}
                {/* ------------------------------------------------- */}

                <div className='Security-Findings-Summary'>

                  {/* ------------------------------------------------- */}
                  {/*  Findings Container                               */}
                  {/* ------------------------------------------------- */}
    
                  <div className='Security-Findings'>                
                    {
                      filteredFindings?.map((object, key) =>(
                        <div key={key} className='Security-Findings-Item' onClick={()=> setSelectedFinding(object)}>
                          <div className='Security-Findings-Item-Header'>
                            {object?.projectid}
                            {severityTag(object?.severityLevel)}
                          </div>
                          <p>
                            <span>{object?.finding_class}</span>
                            <span>{object?.finding_category}</span>
                            <span>{object?.create_time?.toDate()?.toLocaleString()}</span>
                          </p>
                        </div>
                      ))
                    }
                  </div>
    
                  {/* ------------------------------------------------- */}
                  {/*  Description Container                            */}
                  {/* ------------------------------------------------- */}
    
                  <div className='Security-Description'>
                    {
                      selectedFinding === undefined ? (
                        <div className='PageComponent-Label'>
                          <div className='PageComponent-LabelMessage'>
    
                            <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
                            <p>
                              Select a finding on the left
                            </p>
    
                          </div>
                        </div>
                      ) 
                      : 
                      (
                        <table style={{height: '100%'}}>
                          <tbody>
                              <tr>
                                <td>Name</td>
                                <td>{selectedFinding?.name}</td>
                              </tr>
                              <tr>
                                <td>Project ID</td>
                                <td>{selectedFinding?.projectid}</td>
                              </tr>
                              <tr>
                                <td>Finding ID</td>
                                <td>{selectedFinding?.finding_id}</td>
                              </tr>
                              <tr>
                                <td>Severity</td>
                                <td>{selectedFinding?.severityLevel}</td>
                              </tr>
                              <tr>
                                <td>Create Time</td>
                                <td>{selectedFinding?.create_time?.toDate()?.toLocaleString()}</td>
                              </tr>
                              <tr>
                                <td>Event Time</td>
                                <td>{selectedFinding?.event_time?.toDate()?.toLocaleString()}</td>
                              </tr>
                              <tr>
                                <td>Last updated</td>
                                <td>{selectedFinding?.lastUpdated?.toDate()?.toLocaleString()}</td>
                              </tr>
                              <tr>
                                <td>Category</td>
                                <td>{selectedFinding?.category}</td>
                              </tr>
                              <tr>
                                <td>State</td>
                                <td>{selectedFinding?.state}</td>
                              </tr>
                              <tr>
                                <td>Resource name</td>
                                <td>{selectedFinding?.resourceName}</td>
                              </tr>
                              <tr>
                                <td>External URI</td>
                                <td>
                                  {
                                    selectedFinding?.externalUri.length > 0 ?
                                    (
                                      <a href= {selectedFinding?.externalUri} target="_blank" rel="noopener noreferrer">{selectedFinding?.externalUri}</a>
                                    ) : (
                                      <>-</>
                                    )
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Recommendation</td>
                                <td>{selectedFinding?.recommendation}</td>
                              </tr>
                              <tr>
                                <td>Explanation</td>
                                <td>{selectedFinding?.explanation}</td>
                              </tr>
                              <tr>
                                <td style={{verticalAlign: 'baseline'}}>Security Marks</td>
                                {
                                    Object.keys(selectedFinding?.security_marks).map((key, value) => (
                                      <tr>
                                        <td style={{padding: '10px 5px'}}>{key}</td>
                                        <td style={{padding: '10px 5px'}}>{value}</td>
                                      </tr>
                                    ))
                                  }
                              </tr>
                              <tr>
                                <td style={{verticalAlign: 'baseline'}}>Source Properties</td>
                                  {
                                    Object.keys(selectedFinding?.sourceProperties).map((key, value) => (
                                      <tr>
                                        <td style={{padding: '10px 5px'}}>{key}</td>
                                        <td style={{padding: '10px 5px'}}>{value}</td>
                                      </tr>
                                    ))
                                  }
                              </tr>
                          </tbody>
                        </table>
                      )
                    }
                  </div>
    
                </div>

                </>
              )
            }

          </div>
        }
      ></PageComponent>
    )
}
