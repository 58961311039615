//================================================================
//  Component: GroupManagement
//================================================================

//  Purpose: Standardised way to manage a Passport group, via the 'groups' collection

//  Properties:
//    - groupId = <String> the Azure group name, 'neb-cpl-project123-operators'
//    - requestType = <String> 'project', 'operators' or 'secops'
//    - header = <HTML> Page header component
//    - breadcrumb = <Object> Standard PageComponent breadcrumb
//    - requireSelectedResource = <Booleen>
//    - requireSelectedViews= <Array> The required view 
//    - requiredRoles= <Array> The required role
//    - requiredRolesValue = <String> Required value in the role
//    - requiredEnvironments = <Array> Type of project

//  GroupManagement:
//    <GroupManagement
//      groupId={'neb-cpl-project123-operators'}
//      requestType={'project'}
//      header={
//          <>
//              I am a header!
//          </>
//      }
//      breadcrumb={{
//          "name": "Manage Groups", 
//          "route": "/projects/projectgroups"
//      }}
//      requireSelectedResource={true}
//      requireSelectedViews={['project', 'portfolio', 'organization']}
//      requiredRoles={['projectOwner', 'projectOperator']}
//      requiredRolesValue={getUser?.preferences?.globalSelector?.selectedResource}
//      requiredEnvironments={['Sandbox', 'Standard']}
//    ></GroupManagement>    

//================================================================


//Libraries
import React, { useContext, useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../PageComponent/PageComponent';
import QuestionLabel from '../QuestionLabel/index';
import TextArea from '../TextArea/textarea';
import ErrorMessageLabel from '../ErrorMessageLabel';
import InputField from '../InputField/inputfield';
import ModalContent from '../ModalContent/modalcontent';

//Functions
import getDocument from '../../Library/GetDocument';
import ReactBackend from "../../Library/reactBackend";
import updateDocument from '../../Library/UpdateDocument';
import { errString, isStringEmail } from '../../Library/ValidateUserInput';

//Images
import addIcon from '../Images/Icon_Add.svg';
import loadingBar from '../Images/loadingPulseBarFast.svg';
import InfoIcon from '../Images/Icon_Info_Black.svg';
import DeleteIcon from '../Images/Delete_Blue.svg';
import WarningIcon from '../Images/Icon_Warning_Yellow.svg';
import workingCatGraphic from '../Images/workingCatGraphic.svg'

//CSS
import './GroupManagement.css';


export default function GroupManagement({
    groupId,
    requestType,
    header,
    breadcrumb,
    requireSelectedResource,
    requireSelectedViews,
    requiredRoles,
    requiredRolesValue,
    requiredEnvironments,
}) {

  //------------------------------------------------------
  //  useContexts & hooks
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

    //React Router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const [pageStatus, setPageStatus] = useState('pending');

    // Toggles the Add Button types --> 'disabled', 'add', 'loading'
    const [addButtonType, setAddButtonType] = useState('disabled');

    // Toggles visiblity of the invite form
    const [inviteFormHidden, setInviteFormHidden] = useState(true);

    // Shows the "please wait" modal
    const [modalVisible, setModalVisible] = useState(false);

    
  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    //Holds the inputs of the Form
    const [formData, setFormData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {

            // blacklisted domains ~ Requested by Arun/Rads 07/02/2023
            blacklistedDomains: [
                'accenture.com'
            ],

            // shared states
            groupId: groupId,
            description: undefined,
            descriptionError: undefined,
            onloadMembers: {},

            // search input
            search: '',
            searchMessage: undefined,

            // invite form state
            user: {},
            givenname: undefined,
            givennameError: undefined,
            surname: undefined,
            surnameError: undefined,
            preventSave: true,

            // submit states
            onSubmitMembers: {},
            onSubmitError: undefined,
            preventSubmit: true,
            formProcessing: false,
        }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Generic field handler for all input fields
    function handleInput(field, fieldError, regExp, regExpMessage, value, preventSubmit) {

        // field: 'givenname'
        // fieldError: 'givennameError'
        // regExp: /^(?=.* [a-zA-Z]) (?=.* [0-9]) [A-Za-z0-9]+$/
        // regExpMessage: 'This field only supports letters'
        // value: event.target.value
        // preventSubmit: Optional boolen to disable the submit button

        formData[field] = value;

        //  No value provided
        if (value.length === 0) {

            formData[fieldError] = 'Value required';

            // <Optional> --> Prevent submit of form
            if (preventSubmit === true) {

                formData.preventSubmit = true;

            }

        } else if (!(regExp.test(value))) {

            formData[fieldError] = regExpMessage;

            // <Optional> --> Prevent submit of form
            if (preventSubmit === true) {

                formData.preventSubmit = true;

            }
        
        //  No errors --> remove error state
        } else {

            formData[fieldError] = undefined;

            // <Optional> --> Prevent submit of form
            if (preventSubmit === true) {

                formData.preventSubmit = false;

            }

        }

        setFormData(formData);
    };

    // Validates a users input --> Allows the add button to be used
    function handleSearch(value) {

        //  No value provided
        if (value.length === 0) {

            setAddButtonType('disabled');
        
        }

        formData.search = value;
        const emailaddress = value.toLowerCase();

        // Validate emailaddress
        if (isStringEmail(formData.search)) {

            // Check domains blacklist
            if (formData.blacklistedDomains.filter((domain) => (domain === emailaddress.split('@')[1])).length > 0) {

                setAddButtonType('disabled');
                formData.searchMessage = `${emailaddress.split('@')[1]} is not supported, provide another email address.`;

            // validate if user has ALREADY been added!
            } else if (formData.onSubmitMembers[emailaddress] !== undefined && formData.onSubmitMembers[emailaddress]?.deleted === false) {

                setAddButtonType('disabled');
                formData.searchMessage = `${emailaddress} has already been added!`;

            // validate if user is in the group or waiting to be added!
            } else if (formData.onSubmitMembers[emailaddress]?.deleted === true || formData.onloadMembers[emailaddress] === undefined) {

            setAddButtonType('add');
            formData.searchMessage = `Click the '+ Add' button`;

            // User is IN the group --> disabled add button and show message
            } else {

                setAddButtonType('disabled');
                formData.searchMessage = `${emailaddress} is already in this group!`;

            }

        // Invalid emailaddress
        } else {

            setAddButtonType('disabled');
            formData.searchMessage = undefined;

        }

        setFormData(formData);
    };

    // Validates if a user is in Passport or Not --> updates 'formData' state
    function addUser(){

        const emailaddress = formData.search.toLowerCase();

        // Ignore empty searches
        if (emailaddress === undefined || emailaddress.length === 0) return;

        setAddButtonType('loading');

        //------------------------------------------------------
        // User is NOT in the group --> Check passport status --> Add user to 'formData.onSubmitMembers' --> Update 'formData.preventSubmit'
        //------------------------------------------------------

        if (formData.onSubmitMembers[emailaddress]?.deleted === true || formData.onloadMembers[emailaddress] === undefined) {

            const requestBody = [
                emailaddress
            ];
            ReactBackend("getActiveUserCheck", requestBody).then((results) => {

                // Handle failed API responses
                if (results.status !== 'success') {

                    formData.onSubmitError = 'Failed to find user, try again. If the error persists contact the lendlease.cloud team.'

                //------------------------------------------------------
                // NOT a passport user
                //------------------------------------------------------

                } else if (results.responseBody.message[emailaddress] !== true) {


                    // User has re-added an existing user
                    if (formData.onSubmitMembers[emailaddress] !== undefined) {

                        formData.onSubmitMembers[emailaddress].deleted = false

                    } else {

                        const newObject = {
                            [emailaddress]: {
                                'actionRequired': true,
                                'deleted': false,
                                'emailaddress': emailaddress,
                                'passportaccount': false,
                                'givenname': '',
                                'surname': '',
                            },
                            ...formData.onSubmitMembers
                        };
                        formData.onSubmitMembers = newObject;

                    }

                    formData.onSubmitError = `User(s) require additional information before you can proceed, click on the yellow icon(s) to learn more.`

                //------------------------------------------------------
                // IS a passport user
                //------------------------------------------------------

                } else if (results.responseBody.message[emailaddress] === true) {

                    // User has re-added an existing user
                    if (formData.onSubmitMembers[emailaddress] !== undefined) {

                        formData.onSubmitMembers[emailaddress].deleted = false

                    } else {

                        const newObject = {
                            [emailaddress]: {
                                'emailaddress': emailaddress,
                                'passportaccount': true,
                                'actionRequired': false,
                                'deleted': false,
                            },
                            ...formData.onSubmitMembers
                        };
                        formData.onSubmitMembers = newObject;

                    }

                }

                //------------------------------------------------------
                // Check if we can we enable the 'Submit' button
                //------------------------------------------------------

                let actionRequired = false;
                Object.values(formData.onSubmitMembers).forEach((user) => {

                    if (user.deleted === false && user.actionRequired === true){

                        actionRequired = true;

                    }

                });

                if (actionRequired === false){

                    formData.onSubmitError = undefined;

                }

                //------------------------------------------------------
                // Update the formData state
                //------------------------------------------------------

                formData.preventSubmit = actionRequired;
                formData.search = '';
                formData.searchMessage = undefined;
                setAddButtonType('add');
                setFormData(formData);

            }).catch((error) => {

                formData.onSubmitError = 'Failed to find user, try again. If the error persists contact the lendlease.cloud team.';
                formData.preventSubmit = true;
                setAppErrors(`Failed to call api 'getActiveUserCheck' in GroupManagement.js, error ${error}`);
                setFormData(formData);

            });

        }

    }

    // Removes a user from 'formData.onSubmitMembers'
    function deleteUser(emailaddress){

        // Flag user to be removed > IMPORTANT 'delete' method WILL NOT WORK!
        formData.onSubmitMembers[emailaddress].deleted = true;

        // Check if we can we enable the 'Submit' button & remove error messages
        let preventSubmit = false;
        Object.values(formData.onSubmitMembers).forEach((user) => {

            if (user.deleted === false && user.actionRequired === true){

                preventSubmit = true;

            }

        });

        // Remove error messages
        if (preventSubmit === false) {

            formData.onSubmitError = undefined;

        }

        formData.preventSubmit = preventSubmit;

        // Save to useReducer
        setFormData(formData);

    }

    // Used by the invite form and controls the save button state
    function handleSave() {

        //Save form inputs
        formData.onSubmitMembers[formData.user.emailaddress] = {

            'actionRequired': false,
            'deleted': false,
            'emailaddress': formData.user.emailaddress,
            'passportaccount': false,
            'givenname': formData.givenname,
            'surname': formData.surname,                                 

        }

        // Can we enable the Submit button?
        let actionRequired = false;
        Object.values(formData.onSubmitMembers).forEach((user) => {

            if (user.deleted === false && user.actionRequired === true){

                actionRequired = true;

            }

        });

        if (actionRequired === false){

            formData.onSubmitError = undefined;

        }

        formData.preventSubmit = actionRequired;

        // Reset form
        formData.user = {};
        formData.givenname = undefined;
        formData.givennameError = undefined;
        formData.surname = undefined;
        formData.surnameError = undefined;
        formData.preventSave = true;
        setFormData(formData);

        // Hide form
        setInviteFormHidden(true);

    }

    // submit handler for the form --> Everything SHOULD be valid at this point!
    async function handleOnSubmit() {

        // Disable form immediately on submit
        formData.formProcessing = true;
        setFormData(formData);

        // Check to see if job is in progress for this group
        getDocument('groups', groupId.toLowerCase()).then((group) => {

            var inProgress = null;
            
            try {

                inProgress = group.inprogress.toDate();

            } catch {

                inProgress = null;

            }
            
            // If in progress less than 10 minutes ago, if so show warning and prevent submit
            if (inProgress > (new Date()) - 600000) {

                setModalVisible(true);

                // Allow the form to be edited again, after modal closed
                formData.formProcessing = false;
                setFormData(formData);

                return;

            }

            setPageStatus('pending');
    
            const members = {};
    
            // Remove deleted' users from 'formData.onSubmitMembers' 
            Object.values(formData.onSubmitMembers).forEach((user) => {
    
                if (user.deleted === false) {
    
                    members[user.emailaddress] = user;
    
                    // Convert to Firestore Schema --> remove frontend fields
                    delete members[user.emailaddress].actionRequired;
                    delete members[user.emailaddress].deleted;
    
                }
    
            });
    
            // Convert to Firestore Schema
            const document = {
                'transactionid': null,
                'actionrequired': true,
                'requesttype': requestType,
                'requester': getUser.profile.emailaddress,
                'description': formData.description,
                'deleterequested': false,
                'members': members,
            };
    
            return updateDocument('groups', formData.groupId, document).then(() => {
    
                setPageStatus('success');
    
            }).catch((error) => {
    
                setPageStatus('error-fatal');
                setAppErrors(`Unable to submit request, error ${error}`);
    
            });
    
        }).catch((error) => {

            setAppErrors(`Unable to find group ${groupId}, error ${error}`);
            setPageStatus('error-fatal');

        });

    }


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() => {

        // Conditions
        if (breadcrumb.route === undefined) return navigate('error-invalid');
        if (groupId === undefined) return navigate(breadcrumb.route);
        
        // Get group
        getDocument('groups', groupId.toLowerCase()).then((group) => {

            formData.groupId = groupId.toLowerCase();
            formData.description = group.description;
            formData.onloadMembers = group.members;
            formData.onSubmitMembers = group.members;

            // Due to JS delete methods, I require the deleted key in each object
            Object.keys(formData.onSubmitMembers).forEach((key) => {

                formData.onSubmitMembers[key] = {
                    'deleted': false,
                    ...formData.onSubmitMembers[key],
                }

            });

            setFormData(formData);
            setPageStatus('onload');

        }).catch((error) => {

            setAppErrors(`Unable to find group ${groupId}, error ${error}`);
            setPageStatus('error-fatal');

        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
        header={header}
        breadcrumb={breadcrumb}
        status={pageStatus}
        body={
            <div className="Form-Container">
                <div className='Form-Body'>

                {/*------------------------------------------------------------------ */}
                {/*   Group Name                                                      */}
                {/*------------------------------------------------------------------ */}      

                    <QuestionLabel question={"Group Name"}></QuestionLabel>

                    <div style={{fontSize: "15px"}}>{groupId}</div>
                    <br></br>

                {/*------------------------------------------------------------------ */}
                {/*   Group Description                                               */}
                {/*------------------------------------------------------------------ */}      

                    <QuestionLabel question={"Group Description"}></QuestionLabel>

                    <TextArea
                        name="description"
                        placeholder={"Enter a description"}
                        value={formData.description}
                        visibility={formData.formProcessing? 'view': 'edit'}
                        onChange={(e) => handleInput(
                            'description', 
                            'descriptionError',
                            /^([-a-zA-Z0-9()$@:!%_+,.~#?&/="[\]']|\s){0,200}$/,
                            `Invalid input, maximum of 200 characters is supported and must be a combination of ${errString}.`, 
                            e.target.value,
                            true,
                        )}
                        errorVisible={formData.descriptionError === undefined ? (false):(true)}
                    ></TextArea>
                    <ErrorMessageLabel errorVisible={true} errorMessage={formData.descriptionError}></ErrorMessageLabel>

                    <br></br>

                {/*------------------------------------------------------------------ */}
                {/*   GroupManagement Groups Container                                */}
                {/*                                                                   */}
                {/*   Contains the below functionality:                               */}
                {/*   - Searchbox                                                     */}
                {/*   - Add button                                                    */}
                {/*   - Invite form                                                   */}
                {/*------------------------------------------------------------------ */}      

                    <QuestionLabel question={"Group Members"} helpText = {"Enter a user email address below to add them to the group listed above. If this user does not have a Cloud Passport account, you will be prompted to invite them, when you click 'Submit'. Click the 'bin' icon to remove any users from the group."}></QuestionLabel>

                    <div className='GroupManagement-Groups-Container'>

                        {
                            // Hide search box when invite form is open
                            inviteFormHidden === true && (

                                /* Search box & add button */
                                <div className='GroupManagement-Search'>

                                    <InputField
                                        placeholder="e.g. john.smith@lendlease.com"
                                        visibility={formData.formProcessing? 'view' : ''}
                                        value={formData.search}
                                        className={"longinputfield"}
                                        onChange={(e) => handleSearch(e.target.value)}
                                    ></InputField>

                                    {/* API call is required --> prevent multiple and/or invalid requests */}
                                    {
                                        addButtonType === 'loading' ? (

                                            <img style={{cursor: 'none', height: '40px'}} src={loadingBar} alt='loading-icon'></img>

                                        ): addButtonType === 'disabled' ? (

                                            <img src={addIcon} style={{filter: 'grayscale(1)', cursor: 'not-allowed'}} alt='add-icon'></img>

                                        ) : (

                                            <img src={addIcon} alt='add-icon' onClick={() => addUser()}></img>
                        
                                        )
                                    }
                                    
                                </div>

                            )
                        }

                        {/*------------------------------------------------------------------ */
                        /*   Members container (Contains the invite form)                     */
                        /*------------------------------------------------------------------ */}

                        <div className='GroupManagement-Members'>
                            {
                                // Any search related errors or API failures
                                formData.searchMessage !== undefined ? (

                                    <div className='GroupManagement-Label'>
                                        <img className='Global-Selector-Info-Icon' src={InfoIcon} alt="Information Icon"></img>
                                        {formData.searchMessage}
                                    </div>

                                ) : (

                                    // No members in group
                                    Object.values(formData?.onSubmitMembers).length === 0 ? (

                                        <div className='GroupManagement-Label'>
                                            <img className='Global-Selector-Info-Icon' src={InfoIcon} alt="Information Icon"></img>
                                            No users are in this group!
                                        </div>

                                    ) : (

                                        // Invite form is hidden
                                        inviteFormHidden === true ? (

                                            /*------------------------------------------------------------------ */
                                            /*   Show members in group                                           */
                                            /*------------------------------------------------------------------ */

                                            Object.values(formData?.onSubmitMembers).map((user) => (

                                                // Hide deleted users
                                                user.deleted === true ? (
                                                    null
                                                ):

                                                // non-passport user (They require additional details)
                                                user.actionRequired === true ? (

                                                    <div className='GroupManagement-EmailAddress'>
                                                        {user.emailaddress}
                                                        <div style={{display: 'flex', alignItems:'center'}}>
                                                            <img 
                                                                className='GroupManagement-Icon'
                                                                style={{width: '32px', marginRight: '20px'}}
                                                                src={WarningIcon} 
                                                                alt="Warning Icon"
                                                                onClick={() => {
                                                                    setInviteFormHidden(false);
                                                                    formData.user = user;
                                                                }}
                                                            ></img>
                                                            <img
                                                                className='GroupManagement-Icon'
                                                                src={DeleteIcon}
                                                                disa
                                                                alt="Delete Icon"
                                                                onClick={() => deleteUser(user.emailaddress)}
                                                            ></img>                                                    
                                                        </div>
                                                    </div>

                                                // Form currently process, so no modifications allows
                                                ) : formData.formProcessing ? (
                                                    <div className='GroupManagement-EmailAddress'>
                                                        {user.emailaddress}
                                                        <img 
                                                            className='GroupManagement-Icon'    
                                                            src={DeleteIcon}
                                                            style={{filter: 'grayscale(1)', cursor: 'not-allowed'}}
                                                            alt='Delete Icon'>    
                                                        </img>
                                                    </div>

                                                ) : (

                                                    // passport user
                                                    <div className='GroupManagement-EmailAddress'>
                                                        {user.emailaddress}
                                                        <img 
                                                            className='GroupManagement-Icon'
                                                            src={DeleteIcon} 
                                                            alt="Delete Icon"
                                                            onClick={() => deleteUser(user.emailaddress)}
                                                        ></img>
                                                    </div>

                                                )

                                            ))
                                            
                                        ):(

                                            /*------------------------------------------------------------------ */
                                            /*   Invite form                                                     */
                                            /*------------------------------------------------------------------ */

                                            <div 
                                                className='GroupManagement-InviteForm' 
                                                onChange={() => {

                                                    // Handles the state of the 'Save' button
                                                    if (formData.givennameError !== undefined || formData.surnameError !== undefined) {

                                                        formData.preventSave = true;
                                            
                                                    } else if (formData.givenname !== undefined && formData.surname !== undefined) {
                                            
                                                        formData.preventSave = false;
                                            
                                                    }
                                            
                                                    setFormData(formData);

                                                }}
                                            >

                                                {/* Info banner */}
                                                <div className='GroupManagement-Label'>
                                                    <img className='Global-Selector-Info-Icon' src={InfoIcon} alt="Information Icon"></img>
                                                    '{formData.user.emailaddress}' hasn't been onboarded to lendlease.cloud yet, complete the below form.
                                                </div>

                                                {/* First name */}
                                                <QuestionLabel question={"First name"} helpText = {"Provide the persons first name."}></QuestionLabel>
                                                <InputField
                                                    placeholder='Mary'
                                                    value={formData.givenname}
                                                    className={"mediuminputfield"}
                                                    onChange={(e) => handleInput(
                                                        'givenname', 
                                                        'givennameError',
                                                        /^[a-z']{1,24}$/i,
                                                        'Invalid input', 
                                                        e.target.value
                                                    )}
                                                    errorVisible={formData.givennameError === undefined ? (false):(true)}
                                                ></InputField>
                                                <ErrorMessageLabel errorVisible={true} errorMessage={formData.givennameError}></ErrorMessageLabel>
                                            
                                                {/* Surname */}
                                                <QuestionLabel question={"Surname"} helpText = {"Provide the persons surname."}></QuestionLabel>
                                                <InputField
                                                    placeholder='Smith'
                                                    value={formData.surname}
                                                    className={"mediuminputfield"}
                                                    onChange={(e) => handleInput(
                                                        'surname', 
                                                        'surnameError',
                                                        /^[a-z']{1,24}$/i,
                                                        'Invalid input', 
                                                        e.target.value
                                                    )}
                                                    errorVisible={formData.surnameError === undefined ? (false):(true)}
                                                ></InputField>
                                                <ErrorMessageLabel errorVisible={true} errorMessage={formData.surnameError}></ErrorMessageLabel>

                                                {/* Buttons container */}
                                                <div>

                                                    {/* Save */}
                                                    <button 
                                                        disabled={formData.preventSave} 
                                                        style={{marginTop: '20px', marginRight: '20px'}} 
                                                        className='Primary-Button'
                                                        onClick={() => handleSave()}
                                                    >
                                                        Save
                                                    </button>

                                                    {/* Back */}
                                                    <button 
                                                        className='Secondary-Button' 
                                                        onClick={() => {

                                                            // Reset form
                                                            formData.user = {};
                                                            formData.givenname = undefined;
                                                            formData.givennameError = undefined;
                                                            formData.surname = undefined;
                                                            formData.surnameError = undefined;
                                                            formData.preventSave = true;
                                                            setFormData(formData);

                                                            // Hide form
                                                            setInviteFormHidden(true);

                                                        }}
                                                    >
                                                        Back
                                                    </button>

                                                </div>
                                                
                                            </div>


                                        )
                                    )

                                )

                            }
                        </div>

                        {/* Form related errors */}
                        <div className='GroupManagement-Buttons'>

                            {
                                inviteFormHidden === true && formData.onSubmitError !== undefined && (

                                    <div className='GroupManagement-Label'>
                                        <img className='Global-Selector-Info-Icon' src={InfoIcon} alt="Information Icon"></img>
                                        {formData.onSubmitError}
                                    </div>

                                )
                            }

                        </div>

                    </div>

                    {/*------------------------------------------------------------------ */}
                    {/*   Submit Buttons                                                  */}
                    {/*------------------------------------------------------------------ */}   
                        
                    {
                        inviteFormHidden === true && (

                            <div className='Button-Group'>
                                <button
                                    className='Primary-Button'
                                    disabled={formData.preventSubmit || formData.formProcessing}
                                    onClick={() => handleOnSubmit()}
                                >Submit</button>
                                
                                <button
                                    className='Secondary-Button'
                                    disabled={formData.formProcessing}
                                    onClick={() => navigate(breadcrumb.route)}
                                >Cancel</button>
                            </div>   
                     
                        )
                    }

                </div>

                {/*------------------------------------------------------------------ */}
                {/*   Modal for existing in progress job                              */}
                {/*------------------------------------------------------------------ */}   
                
                <ModalContent
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    heading="Existing request in progress"
                    body = {
                        <div>
                            <p>Another request is in progress for group <b>{groupId}</b>.</p>
                            <p>Please wait a few minutes and try again.</p>
                        </div>
                    }
                ></ModalContent>
            </div>
        }
        successContent={
            <div className="FormResponse-Success">
                
                {/* Success Image - Working Cat*/}
                <img style={{margin: "20px 0px"}} src={workingCatGraphic} alt="working cat"/>

                {/* Success Title */}
                <p className="FormResponse-Success-Title">Thanks for your request. We're working on it!</p>
    
                {/* Summary */}
                <div style={{fontSize: "18px", fontWeight: "lighter"}}>
                {
                    requestType === 'project' || requestType === 'operators' || requestType === 'secops' ? (

                        <>
                            <p>You will receive a confirmation email shortly.</p>
                            <p>Make sure you follow the steps in the email to complete your request.</p>
                        </>

                    ) : (

                        <>
                            <p>Your request has been received.</p>
                        </>

                    )
                }

                </div>
            </div>
        }
        requireSelectedViews={requireSelectedViews}
        requireSelectedResource={requireSelectedResource}
        requiredRoles={requiredRoles}
        requiredRolesValue={requiredRolesValue}
        requiredEnvironments={requiredEnvironments}
    ></PageComponent>    
  )
}
