//Libaries
import React from 'react';

//CSS
import './index.css';


//------------------------------------------------------
//  How to use the 'ErrorMessageLabel' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <ErrorMessageLabel
//          
//      //Label Properties
//      errorMessage={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.errorMessage} - "Error message to display to user when there is an error."
//      errorVisible={formQuestions.<INSERT QUESTION NUMBER, e.g. q1>.errorVisible} - "Boolean value to determine if the error message is visible."
//    >
//    </ErrorMessageLabel>


//------------------------------------------------------
//  'ErrorMessageLabel' component
//------------------------------------------------------


function ErrorMessageLabel(props) { 
  

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //Functions to take a boolean value and change this to a CSS Style
  function toggleErrorMessageVisible(errorVisible){

    if (errorVisible === true){
      return {visibility: 'visible'}
    }
    else{
      return {display: 'none'}
    }
  }

  
  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------


  return (
    <>  
        {/* Error Message */}
        <label className="error-message-label" style={toggleErrorMessageVisible(props.errorVisible)}> {props.errorMessage} </label>
    </>
  );


  //------------------------------------------------------
}


export default ErrorMessageLabel;