//Libaries
import { Link } from 'react-router-dom'

//CSS
import './SupportItemPanel.css';


function SupportItemPanel(props) {

  //------------------------------------------------------
  //  props
  //------------------------------------------------------

    var link = props.link
    var image = props.image
    var title = props.title
    var body = props.body
    var label = props.label

    //optional
    var linkType = props.linkType

  //------------------------------------------------------
  //  Styling for Links
  //------------------------------------------------------
  
    var linkActiveStyle = {
      color: "#30373F",
      textDecoration: "none",
      gridColumnEnd: 'span 1'
    };

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Check if the user has access to this item
    function HasLabelForItem() {

      if (props.label === "") {
        return true;
      }
      else {
        return false;
      }
    };

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  If we are passing in a different websites url > "website"
    //------------------------------------------------------

    if(linkType === "website"){
      return (
        <a href={link} style={linkActiveStyle} target="_blank" rel="noopener noreferrer">
          
          <div className="SupportItemPanel-Panel">
  
            {/* Support Item Icon */}
            <div className="Support-Item-Icon">
              <img alt="support-item" src={image} width="65px" height="65px"></img>
            </div>
  
            {/* Support Item Title */}
            <div className="Support-Item-Title">
              {title}
            </div>
  
            {/* Support Item Description */}
            <div className="Support-Item-Description">
              {body}
            </div>
  
            {/* Label */}
            <div className="Support-Item-Label" hidden={HasLabelForItem()}>
              {label}
            </div>
  
          </div>
        </a>
      )
    }

    //------------------------------------------------------
    //  Normal React route
    //------------------------------------------------------

      else{
        return (
          <Link style={linkActiveStyle} to={link}>
            <div className="SupportItemPanel-Panel">
    
              {/* Support Item Icon */}
              <div className="Support-Item-Icon">
                <img alt="support-item" src={image} width="65px" height="65px"></img>
              </div>
    
              {/* Support Item Title */}
              <div className="Support-Item-Title">
                {title}
              </div>
    
              {/* Support Item Description */}
              <div className="Support-Item-Description">
                {body}
              </div>
    
              {/* Label */}
              <div className="Support-Item-Label" hidden={HasLabelForItem()}>
                {label}
              </div>
    
            </div>
          </Link>
        );
      }

  //------------------------------------------------------
}

export default SupportItemPanel;