//------------------------------------------------------
//  Initialize React
//------------------------------------------------------

import React from 'react';
import ReactDOM from 'react-dom/client';

//------------------------------------------------------
//  Initialize redux, useContexts & styling
//------------------------------------------------------

import GlobalContexts from './Library/GlobalContexts'
import {Provider} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import SetProfile from './Reducers/setprofile'
import './index.css';

//------------------------------------------------------
//  Initialize Firebase & Auth
//  We are using MS App Reg, see the readme for more information
//------------------------------------------------------

import { initializeApp } from 'firebase/app';
import {firebaseConfig} from './Library/FirebaseConfig';
import AuthProvider from './Library/AuthProvider';
import LoginHandler from './Library/LoginHandler';

//Initialize Firebase App
initializeApp({...firebaseConfig});

//------------------------------------------------------
//  Create Store, import all reducers & setup dev tools
//  https://redux-toolkit.js.org/usage/usage-guide#simplifying-store-setup-with-configurestore
//  https://redux-toolkit.js.org/api/configureStore
//------------------------------------------------------


const store = configureStore({
  reducer: {
    SetProfile: SetProfile
  },
})

//------------------------------------------------------
//  Initialize global variables inside 'sessionStorage'
//  Checks for the variable in sessionStorage
//  If it doesn't exist create it!
//------------------------------------------------------

//accessToken
const accessToken = sessionStorage.getItem('accessToken');
if (accessToken === null){

  sessionStorage.setItem('accessToken', '');

}

//accessTokenAuthTime
const accessTokenAuthTime = sessionStorage.getItem('accessTokenAuthTime');
if (accessTokenAuthTime === null){

  sessionStorage.setItem('accessTokenAuthTime', '');

}

//getAppStatus
const getAppStatus = sessionStorage.getItem('getAppStatus');
if (getAppStatus === null){

  sessionStorage.setItem('getAppStatus', '');

}

//------------------------------------------------------
//  Return the App
//------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <GlobalContexts>
      <AuthProvider>
        <LoginHandler></LoginHandler>
      </AuthProvider>
    </GlobalContexts>
  </Provider>
);

//------------------------------------------------------