//================================================================
//  Delete Project Form
//================================================================

//Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

// Functions
import ReactBackend from '../../../Library/reactBackend';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import PageHeader from '../../../Components/PageHeader/PageHeader';
import FormResponse from '../../../Components/FormResponse/formresponse'
import InlineInputField from '../../../Components/InlineInputField/inlineinputfield';
import ErrorMessageLabel from '../../../Components/ErrorMessageLabel';
import QuestionLabel from '../../../Components/QuestionLabel';

//CSS
import './ProjectDelete.css'


export default function ProjectDelete() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

    const getUser = useContext(GetUser);

    //React Router
    const navigate = useNavigate();
  
  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Updates the form status -> 'onload', 'pending', 'success', 'error' etc.
    const [formStatus, setFormStatus] = useState('onload');

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    // Hold the value of the input field
    const [projectIdField, setProjectIdField] = useState(null);

    // Determines error message visibility
    const [errorVisible, setErrorVisible] = useState(false);

  //------------------------------------------------------
  //  Define Functions
  //------------------------------------------------------

    //Used to reset the selected view and resource and return to projects page
    function returnToProjects(){

      getUser.preferences.globalSelector.selectedView = 'organization';
      getUser.preferences.globalSelector.selectedResource = 'lendlease.cloud';

      navigate('/projects');

    }

  //------------------------------------------------------
  //  Event Handlers > Form Submission
  //------------------------------------------------------

    // Event Handler for input field changes
    const inputHandler = (e) => {

      // validation check that input value == the projectId
      e.target.value !== getUser?.preferences?.globalSelector?.selectedResource ? setErrorVisible(true) : setErrorVisible(false);

      // Capture & set the input value
      setProjectIdField(e.target.value) 

    }

    // Delete API Call
    const deleteProject = async (e) => {

      //Prevent refresh
      e.preventDefault();
      
      // request body for API call
      const requestBody = {
        'projectId': getUser?.preferences?.globalSelector?.selectedResource,
        'requestType': 'delete'
      }
      
      // set view to pending state whilst awaiting response
      setFormStatus('pending');
      
      //Call React Backend -> pass in requestBody to submitProjectRequest function
      const response = await ReactBackend('submitProjectRequest', requestBody);

      //Set status accordingly based upon response
      if(response.status === 'success'){
        setFormStatus('success');

      } else  {          
        setFormStatus('error-fatal');
        
      }
        
    } 

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Only enable the delete button if the input value matches the project ID/
    useEffect(() => {

      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser])
    
  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------
  
  return (
    <PageComponent
      requireSelectedResource={true}
      requireSelectedViews={['project']}
      requiredRoles={['projectOwner']}
      breadcrumb={{
        'name': 'Projects', 
        'route': '/projects',
        'view': 'organization',
        'resource': 'lendlease.cloud'
      }}
      header={
        <PageHeader
          title={'Delete Project'}
          body={
            <p>
              <strong>Note: </strong>Please carefully consider the impacts of deleting this project. We advise project owners to perform any necessary data back ups prior to deletion. After submission this action is irreversible. 
            </p>   
          }
        ></PageHeader>
      }
      body={
        <FormResponse
          requestType={'view'}
          setRequestType={setFormStatus}
          routerLink={true}
          formStatus={formStatus}
          setFormStatus={setFormStatus}
          initialState={'onload'}
          formOnload={
            <div className='Form-Container'>     
              <form className="Form-Body" id='deletePojectForm' onSubmit={(e) => deleteProject(e)}>
                    
                {/* Project Name */}
                <p className='Page-Subheading' style={{margin: "15px 0px"}}>{getUser?.preferences?.globalSelector?.selectedResource}</p>

                {/* Form Body */}

                <p>When you delete a project, this immediately happens:</p>
                
                <ul style={{lineHeight: "1.6"}}>
                    <li>All data and resources within the project will be deleted.</li>
                    <li>Groups and identity are erased.</li>
                    <li>Billing will cease, however you will still be billed for your monthly consumption up to this time.</li>
                </ul>

                <p>Any data retention for legal or regulatory reasons should be performed now before continuing.</p>
              
                {/* Label */}
                <QuestionLabel question="To permanently delete this project, please enter the project name below:"/>
        
                {/* Input  */}
                <InlineInputField
                    name={'project'}
                    placeholder={'Project ID'}
                    value={projectIdField}
                    position = 'right'
                    errorVisible={errorVisible}
                    inputFieldLength='mediuminputfield'
                    onChange={inputHandler}
                ></InlineInputField>

                {/* Error Message - Invalid Project Id */}
                <ErrorMessageLabel errorMessage={"Invalid Project Id"} errorVisible={errorVisible}></ErrorMessageLabel>

                {/*------------------------------------------------------------------ */}
                {/*   Buttons *                                                       */}
                {/*------------------------------------------------------------------ */}
                
                <div className='Button-Group'>
                    <input className='Primary-Button' disabled={projectIdField === getUser?.preferences?.globalSelector?.selectedResource ? false : true} type='submit' value='Delete'></input>
                    <button className='Secondary-Button' onClick={() => returnToProjects()}>Cancel</button>
                </div>

              </form>
            </div>
          }
          formSuccessDescription={
            <div style={{display:'flex', flexDirection: 'column'}}>
              <p>Your project deletion is currently being processed, a confirmation email will arrive shortly.</p> 
              <p><b>Please note:</b> It may take a few minutes for the project to disappear from the console.</p> 
            </div>
          }
          workflowType={'pub/sub'}
        ></FormResponse>
      }
    ></PageComponent>
  )
}
