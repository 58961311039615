//Libraries
import React from 'react';

//Components
import UnauthorisedUser from '../UnauthorisedUser/unauthoriseduser';

//Images
import LoadingIcon from '../Images/Loading_Ripple.svg'
import InfoIcon from '../Images/Icon_Info_Black.svg';

//CSS
import './tableresponse.css';

//------------------------------------------------------
//  How to use the 'TableResponse' component
//------------------------------------------------------


//    1. Import this component
//    2. Use the below inside a form component


//    <TableResponse
//      //Table Response Properties
//      content={Enter in the HTML for displaying the table API response, i.e. if it's a table > display table, if it's a message > display message}
//               Note* - If the table has > 4 columns, set the table tag to this <table className="table-response" style={{display: "block", overflowX: "scroll"}}>
//      response={API response, e.g. getDNSResponse}
//      status={API Status, e.g. dnsZonesStatus}
//                          Can be one of: accessdenied, onload, pending, success, error-invalid, error-fatal, error-timeout}
//      setStatus={setState for setting the API Status, e.g. 'setGetDNSResponse'}
//      setAltLoadScreen={pass Boolean value to toogle between alternative load screens}
//    >
//    </TableResponse>


//------------------------------------------------------
//  'TableResponse' component
//------------------------------------------------------

function TableResponse(props){
  
  //------------------------------------------------------
  //  Extract props
  //------------------------------------------------------

    let content = props.content
    let status = props.status
    let setStatus = props.setStatus
    let setRequestType = props.setRequestType
    let onloadcontent = props.onloadcontent
    let invaliderrorcontent = props.invaliderrorcontent
    let othererrorcontent = props.othererrorcontent
    let setAltLoadScreen = props.setAltLoadScreen

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    /*---------------------------------------------------- */
    /*   Status = 'accessdenied'                           */
    /*---------------------------------------------------- */

    if (status === 'accessdenied'){
      return (
        <UnauthorisedUser
          message={
            <p>
              Sorry it seems like you do not have the necessary permissions to access this page. 
              <br></br>
              <br></br>
              If you feel this is a mistake, contact the Lendlease Cloud Team <a href = "https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96&sysparm_category=109f0438c6112276003ae8ac13e7009d" target="_blank" rel="noopener noreferrer"> here</a>.
            </p>
          }
          requestType={setRequestType}
          refresh={props.refresh}
        >
        </UnauthorisedUser>
      );
    }

    /*---------------------------------------------------- */
    /*   Status = 'onload'                                 */
    /*---------------------------------------------------- */

    //Call API > status = 'pending'
    if(status === 'onload'){
      if(onloadcontent){
        return onloadcontent
        
      } else {
        return (
          <div className="table-response-text-container">
            <div className='PageComponent-Label'>
              <div className='PageComponent-LabelMessage'>

                <img style={{marginRight: "15px"}} src={InfoIcon} alt="Information Icon"></img>
                <p>
                  Request cancelled. Refresh page to load info.
                </p>

              </div>
            </div>
          </div>
        );
      }

    /*---------------------------------------------------- */
    /*   Status = 'pending'                                */
    /*---------------------------------------------------- */

    //Call API > status = 'pending'
    } else if(status === 'pending'){
      return (
        <div className="table-response-container-pending">
          {/* if true show moving box load screen else use default pulse image */}
            { setAltLoadScreen ? 
              <div className='loading' hidden={true}>
                <div className='loading-square'></div>
                <div className='loading-square'></div>
                <div className='loading-square'></div>
                <div className='loading-square'></div>
                <div className='loading-square'></div>
                <div className='loading-square'></div>
                <div className='loading-square'></div>
              </div>
                :
              <img alt="loading-circle-icon" src={LoadingIcon}></img>
            }
            <h3>Please wait while we process your request.</h3>
            <div className="pageresponse-button-container">
                <button className="form-submit-button" onClick={()=>{setStatus('onload')}}>CANCEL</button>
            </div>
        </div>
      );

    /*---------------------------------------------------- */
    /*   Status = 'success'                                */
    /*---------------------------------------------------- */

    //API call returns no errors > status = 'success'
    } else if (status === 'success'){
        return content


    /*---------------------------------------------------- */
    /*   Status = 'error-invalid'                          */
    /*---------------------------------------------------- */
    

    //Request fails > status = 'error-invalid'
    } else if(status === 'error-invalid'){
      if(invaliderrorcontent){
        return invaliderrorcontent

      } else{
        return (
          <div style={{textAlign: "center"}} className="table-response-container">
            <h2>Request Failed</h2>
            <p style={{lineHeight: "1.7"}}>
              An error occurred while we processed your request.
              <br></br>
              If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
            </p>
            <p> <b>Message:</b> Failed to load the page - Invalid request.</p>
            
            {/* Try Again Button */}
          <button className="try-again-button" onClick={()=>{window.location.reload()}}>Try Again</button>
        </div>
        )
      }
    }

    
    /*---------------------------------------------------- */
    /*   Status = 'error-fatal'                            */
    /*---------------------------------------------------- */

    //Request fails > status = 'error-fatal'
    else if(status === 'error-fatal'){
      return (
        <div style={{textAlign: "center"}} className="table-response-container">
          <h2>Oops something went wrong</h2>
          <p style={{lineHeight: "1.7"}}>
            An error occurred while we processed your request.
            <br></br>
            If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
          </p>
          <p> <b>Message:</b> Failed to load the page - Fatal Error.</p>
          
          {/* Try Again Button */}
        <button className="try-again-button" onClick={()=>{window.location.reload()}}>Try Again</button>
      </div>
      );
    }

    /*---------------------------------------------------- */
    /*   Status = 'error-timeout'                          */
    /*---------------------------------------------------- */

    //Request fails > status = 'error-timeout'
    else if(status === 'error-timeout'){
      return (
        <div style={{textAlign: "center"}} className="table-response-container">
          <h2>Request Failed</h2>
          <p style={{lineHeight: "1.7"}}>
            An error occurred while we processed your request.
            <br></br>
            If the error persists, please log a ticket through Service Central <a href="https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96" target="_blank" rel="noopener noreferrer"> here</a> and include the error message below. 
          </p>
          <p> <b>Message:</b> Failed to load the page - Request timed out.</p>
          
          {/* Try Again Button */}
          <button className="try-again-button" onClick={()=>{window.location.reload()}}>Try Again</button>
        </div>
      )
    } 

    /*---------------------------------------------------- */
    /*   Status = 'error-other'                          */
    /*---------------------------------------------------- */

    //Request fails > status = 'error-other'
    else if(status === 'error-other'){
      return othererrorcontent
    } 
    
}

export default TableResponse;

//------------------------------------------------------