//------------------------------------------------------
//  404 - Not Found Page
//  If no route for this page is found
//  Created by Mark Bennett 05/11/2021
//------------------------------------------------------

//Libraries
import React from 'react'

//Images
import errorFatal from '../../Components/Images/errorFatal.svg'

//Styling
import './NotFound.css'


export default function NotFound() {
  
  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------

    return (
      <div className="NotFound-Container">

        {/* Plug Image */}
        <img style={{margin: "20px 0px"}} alt="Error Fatal" src={errorFatal}></img>

        {/* Title */}
       <p className="NotFound-Title">Sorry there is nothing here!</p>

      </div>
    )

  //------------------------------------------------------
}
