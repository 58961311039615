//================================================================
//  Shared VPC Page
//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import ProductFilterPane from '../../Components/ProductFilterPane/productfilterpane'
import RequestSummary from '../../Components/RequestSummary/requestsummary'
import TableResponse from '../../Components/TableResponse/tableresponse'
import VideoPlayer from '../../Components/VideoPlayer/videoPlayer';
import DocumentLink from '../../Components/KnowledgeHubComponents/documentlink'

//Functions
import QueryDocument from '../../Library/QueryDocument';
import GetDocument from '../../Library/GetDocument';

//Images
import Warning from '../../Components/Images/Warning.png'

//CSS
import './SharedVPC.css'


export default function SharedVPC() {

  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------ 
  
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

    //React Router
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Prevents reload of page, unless required
    const [previousResource, setPreviousResource] = useState();

    //Used to save the API status >  'onload', 'pending', 'success', 'error-invalid', 'error-fatal'
    const [getVPCStatus, setVPCStatus] = useState("onload");
    const [getVPCJobsStatus, setVPCJobsStatus] = useState("onload");

    //Used to save the API response
    const [getVPCResponse, setVPCResponse] = useState("");
    const [getVPCJobsResponse, setVPCJobsResponse] = useState("");

    //Used to determine the table response type > 'message' or 'table'
    const [tableResponseType, setTableResponseType] = useState('message');

    //Used to toggle visibility of 'Attach Project to VPC' button
    const [attachVPCButtonDisabled, setAttachVPCButtonDisabled] = useState("none");

    //Used to toggle visibility of 'Manage Access' button
    const [manageAccessButtonDisabled, setManageAccessButtonDisabled] = useState("none");
    
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Used for processing 'getProjectVPCMetadata' API response
    function processAPIResponse(response){

      var isAttached = response.isAttached
      var serviceAccounts = response.sharedVPCServiceAccounts

      //If project attached to VPC && service accounts exist > display message
      if(isAttached === 'false' && serviceAccounts.length <= 0){
        setTableResponseType('message')
        setVPCResponse("Click 'Attach Project to VPC' above to attach your project to the Shared VPC network.")

        //Hide 'Manage VPC Access' button > Display 'Attach to VPC' button
        setAttachVPCButtonDisabled("inline")
        setManageAccessButtonDisabled("none")
      }      
      
      //If project attached to VPC && no service accounts > display message
      if(isAttached === 'true' && serviceAccounts.length <= 0){
        setTableResponseType('message')
        setVPCResponse("No attached service accounts found.")

        //Hide 'Attach To VPC' button > Display 'Manage VPC Access' button
        setManageAccessButtonDisabled("inline")
        setAttachVPCButtonDisabled("none")
      }
      
      //If project attached to VPC && service accounts exist > display service accounts
      if(isAttached === 'true' && serviceAccounts.length > 0){

        setVPCResponse(serviceAccounts)
        setTableResponseType('table')

        //Hide 'Attach To VPC' button > Display 'Manage VPC Access' button
        setManageAccessButtonDisabled("inline")
        setAttachVPCButtonDisabled("none")
      }

    };

    //Gets project SVPC attachment data from Firestore
    async function getProjectVPCMetadata(selectedResource){

      setVPCStatus('pending');

      //Hide 'Attach to VPC' button
      setAttachVPCButtonDisabled('none');
      
      //Hide 'Manage VPC button
      setManageAccessButtonDisabled('none');
                    
      //Fetch project info
      const queryResponse = GetDocument("projects", selectedResource)

      return Promise.resolve(queryResponse)
      .then((results) => {
        
        //Prepare project data 
        var projectData = {
          "projectId": results.projectid,
          "isAttached": results.sharedvpcattached,
          "sharedVPCServiceAccounts": results.sharedvpcaccounts,
        } 

        //Set status to 'success'
        setVPCStatus('success');

        //Set API Response
        processAPIResponse(projectData);

      })
      .catch((error) =>{
        console.log(`'getProjectVPCMetadata' failed to complete. Error Message: ${error.message}`);
        setAppErrors(`'getProjectVPCMetadata' failed to complete. Error Message: ${error.message}`);

        //Set status to 'error-fatal'
        setVPCStatus('error-fatal');

        //Set API Response
        setTableResponseType('message');
        setVPCResponse(error.message);

      })

    }

    //Gets project jobtracking jobs
    async function getVPCAccessJobs(selectedResource, jobFilter){

      setVPCJobsStatus('pending');

      // Array that will contain matching jobs
      const allJobs = []

      // Firebase DB query
      const dbQuery = [['projectId', '==', selectedResource]]

      //Firebase call
      const queryResponse = QueryDocument("jobtracking", dbQuery)

        //resolve the promise
        return Promise.resolve(queryResponse)
        .then((results) => {

          results.forEach(job => {

            // Check if the jobname matches the job filters we want
            if (jobFilter.includes(job?.jobName)){

              //Prepare job data 
              var jobData = {
                "transactionId": job.transactionId,
                "status": job.status,
                "requestBody": job.requestBody,
                "projectId": job.projectId,
                "jobType": job.jobType,
                "creator": job.creator,
                "created": job.created.toDate().toUTCString().split(' ').slice(0, 5).join(' '),
                "sortKey": job.created.toDate().toUTCString(),
              } 

              allJobs.push(jobData)

            }

          });

          //Check results & then sort it
          if(allJobs.length === 0){
              setVPCJobsResponse("No requests found.")

          } else {

              var sortedJobs = [...allJobs].sort((a, b) => b["sortKey"] - a["sortKey"]).reverse();
              setVPCJobsResponse(sortedJobs)
          }

          setVPCJobsStatus('success');

          return results

        })
        .catch((error) =>{
          console.log(`'getVPCAccessJobs' failed to complete. Error Message: ${error.message}`);
          setAppErrors(`'getVPCAccessJobs' failed to complete. Error Message: ${error.message}`);
          setVPCJobsStatus('error-fatal');
        })

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() => {
    
      // Default conditions > Talk to Nowshin, Benno or Nisa
      if (getUser === undefined) return;
      if (getUser.preferences.globalSelector.selectedView === 'none') return;
      if (getUser.preferences.globalSelector.selectedResource === 'none') return;
      if (getUser.preferences.globalSelector.visible) return;
      if (previousResource === getUser.preferences.globalSelector.selectedResource) return;
      setPreviousResource(getUser.preferences.globalSelector.selectedResource);

      // Define the default 
      const jobFilter = ["submitSharedVPCAccessRequest", "submitSharedVPCAttachmentRequest"]

      //Call the functions
      getProjectVPCMetadata(getUser?.preferences?.globalSelector?.selectedResource)
      getVPCAccessJobs(getUser?.preferences?.globalSelector?.selectedResource, jobFilter)       

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        requireSelectedResource={true}
        requireSelectedViews={['project']}
        requiredRoles={['projectOwner', 'projectOperator']}
        requiredEnvironments={['Standard']}
        header={
          <PageHeader
            title={'Shared VPC'}
            modaltitle={'How To Access the Shared VPC Network'}
            modalbody={
              <span>
                <div>
                  <div className='modal-description' style={{lineHeight: "1.5"}}>
                    <br></br>
                    The shared VPC takes care of all networking between GCP projects and on-premise networks and contains network subnets in supported GCP regions, to which service projects can request access.
                    <br></br><br></br>
                    For more information, refer to <DocumentLink docId="863209417" title="Shared VPC for Private Networking"></DocumentLink>.
                  </div>
                  <VideoPlayer
                      item="Video_SVPC_22_12_2021.mp4"
                      modalVisible="true"
                  ></VideoPlayer>

                  <div className='modal-description' style={{lineHeight: "1.5"}}>
                    Now let's learn about the Shared VPC in an awesome cartoon! Watch our Shared VPC story next:                 
                  </div>
                  <VideoPlayer
                    item="Video_SVPC_Story_21_12_2021.mp4"
                    modalVisible="true"
                  ></VideoPlayer>

                </div>
              </span>
            }
            body={
              <span>
                <div style={{margin: "10px 0px"}}>
                  The 'lendlease.cloud' shared VPC provides access to the lendlease global private network from GCP, enabling use of fully routable private IPs and internal DNS services for cloud-based resources. 
                  <div className="svpc-warning-container">
                      <img className="svpc-warning-icon" alt="warning" src={Warning}></img>

                      <div className="svpc-warning-label">
                          <b>Warning:</b> All resources connected to the shared VPC that require internet access will need to be tagged with <q>cnatgw-default</q> in order to egress to the internet.
                      </div>
                  </div>

                </div>
              </span>
            }
          ></PageHeader>
        }
        body={
          <span>

            {/*------------------------------------------------------------------ */}
            {/*  Shared VPC Filter/Button Pane                                    */}
            {/*------------------------------------------------------------------ */}  

            <ProductFilterPane
              productFilterComponents={
                <>
                {/* Attach Project to VPC Button */}
                <button className="attach-to-vpc-button" style={{display: attachVPCButtonDisabled}} onClick={() => {navigate("/sharedvpc/attach")}}> Attach Project to VPC </button>

                {/* Manage Access Button */}
                <button className="manage-vpc-access-button" style={{display: manageAccessButtonDisabled}} onClick={() => {navigate("/sharedvpc/manage")}}> Manage VPC Access </button>
                </>
              }
              ></ProductFilterPane>

            <div className="Table-Pane" style={{marginTop: "40px"}}>

              {/*------------------------------------------------------------------ */}
              {/*   Shared VPC Attachments                                          */}
              {/*------------------------------------------------------------------ */}  
              
              <h2 className="Page-Subheading">Shared VPC Attachment Permissions</h2>

              <TableResponse
                content={
                  typeof(getVPCResponse) === 'string' || tableResponseType === "message" ? 
                    (
                      <div className="table-response-text-container">
                        <p className="table-message">{getVPCResponse}</p>
                      </div>
                    ) 
                  :
                    (
                      <table className="Data-Table-Container">
                        <colgroup>
                          <col span="1" style={{width: "100%"}}></col>
                        </colgroup>
                        <tbody>
                          <tr>
                            <th>Deployment Accounts</th>
                          </tr>
                
                          <tr style={{border: "none"}}>
                            <td>
                              {getVPCResponse.map((row, index) => (
                                  <li key={index}>{row}</li>
                              ))
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )
                  }
                response={getVPCResponse}
                status={getVPCStatus}
                setStatus={setVPCStatus}              
              ></TableResponse>
            </div>

              {/*------------------------------------------------------------------ */}
              {/*   Requests Summary                                                */}
              {/*------------------------------------------------------------------ */}  

            <div className="Table-Pane" style={{marginTop: "40px"}}>
              <h2 className="Page-Subheading">Requests Summary </h2>

              <RequestSummary
                status={getVPCJobsStatus}
                setStatus={setVPCJobsStatus}
                rows={getVPCJobsResponse}
              ></RequestSummary>
                          
            </div>
          </span>
        }
      ></PageComponent>
    )
}
