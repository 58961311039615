//================================================================
//  Functions and variables to help validate user input
//  Created by Vincent Willard 12/Feb/2023
//================================================================

//  Purpose: 
//    1. Provides regex strings for pattern matching in bootstrap forms (variables start with 're')
//    2. Provides part of an error string to show what format is required (functions start with 'err')
//    3. Provides functions to validate data directly (variables start with 'is')

//  Examples:
//    <Form.Control 
//      style={{marginTop: "10px"}}
//      type='text'
//      pattern=reStringUnsignedInteger
//    />
//    <Form.Control.Feedback type="invalid" className='PublishForm-Invalid-Message'>
//      Please enter the total number of licenses. Must be {errStringUnsignedInteger}
//    </Form.Control.Feedback>
//
//    owner = owner.toLowerCase()
//    if(isStringEmail(owner) && owner.includes('@lendlease.com')) {
//        setProductOwnerErrorHidden(true) 
//    } else {
//        setProductOwnerErrorHidden(false)
//        return
//    };

//================================================================

// Regex strings for Bootstrap form pattern matching (where a generic pattern makes sense)
export const reStringAlphaNum = '^[a-zA-Z0-9]+$';
export const reStringAlphaNumSpaceHyphenSlash = '^[-a-zA-Z0-9 /]+$';
export const reString = '^([-a-zA-Z0-9()$@:!%_+,.~#?&/="\\[\\]\']|\\s)+$'; // Allows newlines
export const reStringEmail = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
export const reStringUnsignedInteger = '^[0-9]+$';
export const reStringUnsignedCurrency = '^(\\d+([.]\\d{0,2}){0,1}|[.]\\d{0,2})$';

// Part of error strings detailing what format is required for input
export const errStringAlphaNum = 'letters (a-z, A-Z) and digits (0-9)';
export const errStringAlphaNumSpaceHyphenSlash = 'letters (a-z, A-Z), digits (0-9), spaces, hyphens (-) and forward slashes (/)';
export const errString = 'letters (a-z, A-Z), digits (0-9), spaces, and the following symbols: ()@:!%_+,.~#?&/="[]\'';
export const errStringUnsignedInteger = 'a non-negative whole number';

// Functions that take a value to validate, returning true if validated, otherwise false
export function isStringAlphaNum(val) {
    if(typeof(val) != 'string') return false;
    return val.search(reStringAlphaNum) >= 0 ? true : false;
}

export function isStringAlphaNumSpaceHyphenSlash(val) {
    if(typeof(val) != 'string') return false;
    return val.search(reStringAlphaNumSpaceHyphenSlash) >= 0 ? true : false;
}

export function isString(val) {
    if(typeof(val) != 'string') return false;
    return val.search(reString) >= 0 ? true : false;
}

export function isStringEmail(val) {
    if(typeof(val) != 'string') return false;
    return val.search(reStringEmail) >= 0 ? true : false;
}

export function isStringUnsignedInteger(val) {
    if(typeof(val) != 'string') return false;
    return val.search(reStringUnsignedInteger) >= 0 ? true : false;
}

export function isStringUnsignedCurrency(val) {
    if(typeof(val) != 'string') return false;
    return val.search(reStringUnsignedCurrency) >= 0 ? true : false;
}

//--------------------------------------------------------